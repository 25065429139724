// FE-3 : https://www.figma.com/file/rjvTEGNOs42Sv2L7TEA5Ef/DRC_%E6%A5%AD%E5%8B%99%E7%AE%A1%E7%90%86%E3%82%B7%E3%82%B9%E3%83%86%E3%83%A0_%E7%AE%A1%E7%90%86%E8%80%85%E7%94%A8?type=design&node-id=104-273&mode=design&t=eFJyFimQ7ANTtCe4-0
import React, { useCallback, useEffect, useState } from "react";
import ContentLayout from "app/layouts/ContentLayout";
import routePaths from "app/route/routePaths";
import PrimaryBtn from "app/components/UI/Button/PrimaryBtn";
import TestScheduleListTable from "app/components/Table/Project/TestScheduleListTable";
import TestScheduleItem from "app/components/Table/Project/TestScheduleItem";

import { projectServices } from "app/services/api";
import { toast } from "react-toastify";
import PageLoading from "app/components/CommonPage/PageLoading";
import PageError from "app/components/CommonPage/PageError";
import PageNoData from "app/components/CommonPage/PageNoData";
import { useNavigate, useOutletContext } from "react-router-dom";
import messages from "../../services/api/messages";

function ProjectPage() {
  const [breadcrumb] = useOutletContext();
  const [loadInitState, setLoadInitState] = useState({
    isLoading: false,
    data: {
      caseList: null,
      scheduleList: null,
    },
    error: null,
  });

  const navigate = useNavigate();
  const { getScheduleList } = projectServices;

  const loadInit = useCallback(() => {
    setLoadInitState({
      isLoading: true,
      data: {
        caseList: null,
        scheduleList: null,
      },
      error: null,
    });

    getScheduleList()
      .then((res) => {
        setLoadInitState({
          isLoading: false,
          data: {
            scheduleList: res.scheduleList,
            caseList: res.caseList,
          },
          error: null,
        });

        // Scroll down to the hashtag if possible
        setTimeout(() => {
          const hashtag = window.location?.hash;
          if (hashtag) {
            document
              .getElementById(hashtag.slice(1))
              ?.scrollIntoView({ behavior: "smooth" });
          }
        }, 200);
      })
      .catch((err) => {
        toast.error(messages.callApiFail);
        setLoadInitState({
          isLoading: false,
          data: {
            caseList: null,
            scheduleList: null,
          },
          error: err,
        });
      });
  }, [getScheduleList]);

  const updateScheduleList = () => {
    getScheduleList()
      .then((res) => {
        setLoadInitState((v) => ({
          ...v,
          data: {
            ...v.data,
            scheduleList: res.scheduleList,
            caseList: res.caseList,
          },
        }));
      })
      .catch(() => {});
  };

  useEffect(() => {
    loadInit();
  }, [loadInit]);

  return (
    <ContentLayout
      sideMenuLinkOptions={[
        { linkTo: routePaths.project, linkTitle: "試験スケジュール" },
        {
          linkTo: routePaths.projectTestSchedule,
          linkTitle: "スケジュールの登録",
        },
        { linkTo: routePaths.topMenu, linkTitle: "トップへ戻る" },
      ]}
      breadcrumb={breadcrumb}
    >
      <div>
        <div className="flex items-center mb-[6px]">
          <div className="w-full h-auto text-custom-size">
            <p>試験スケジュール</p>
          </div>
          <PrimaryBtn
            text="変更する"
            onClick={() => navigate(routePaths.projectTestSchedule)}
          />
        </div>

        {loadInitState.isLoading ? <PageLoading /> : null}

        {!loadInitState.isLoading && loadInitState.error ? (
          <PageError />
        ) : null}

        {!loadInitState.isLoading &&
        !loadInitState.error &&
        Array.isArray(loadInitState.data.scheduleList) ? (
          loadInitState.data.scheduleList.length > 0 ? (
            <div>
              <TestScheduleListTable
                list={loadInitState.data.scheduleList.sort((a, b) => {
                  const date1 = new Date(a.testDate);
                  const date2 = new Date(b.testDate);
                  return date2 - date1;
                })}
              />
              {loadInitState.data.caseList
                .sort((a, b) => {
                  const date1 = new Date(a.testDate);
                  const date2 = new Date(b.testDate);
                  return date2 - date1;
                })
                .map((i, index) => (
                  <TestScheduleItem
                    key={i.caseId}
                    item={i}
                    scheduleNo={index + 1}
                    onUpdateSchedule={updateScheduleList}
                  />
                ))}
            </div>
          ) : (
            <PageNoData noDataMessage="データなし!" noDataAction />
          )
        ) : null}
      </div>
    </ContentLayout>
  );
}

export default ProjectPage;
