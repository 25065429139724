import React from "react";

function TestScheduleHeader(props) {
  const { tableConfig } = props;
  return (
    <div className="w-full h-8 flex gap-1">
      {Array.isArray(tableConfig)
        ? tableConfig.map((cellConfig, index) =>
            !cellConfig.isHeaderBlank ? (
              <div
                key={index}
                className="h-full border border-[#B3B3B3] bg-[#EBEBEB] flex items-center justify-center"
                style={{
                  width: cellConfig.width,
                }}
              >
                <p className="text-custom-size truncate">{cellConfig.title}</p>
              </div>
            ) : (
              <div
                key={index}
                className="h-full flex items-center justify-center"
                style={{
                  width: cellConfig.width,
                }}
              >
                <p className="text-custom-size truncate">{cellConfig.title}</p>
              </div>
            )
          )
        : null}
    </div>
  );
}

export default TestScheduleHeader;
