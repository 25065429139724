import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import moment from "moment";
import { toast } from "react-toastify";
import readXlsxFile from "read-excel-file";
import { useMediaQuery } from "@mui/material";
import Button from "app/components/UI/Button";
import PageLoading from "app/components/CommonPage/PageLoading";
import PageError from "app/components/CommonPage/PageError";
import InputText from "app/components/UI/Form/Input/InputText";
import SelectPicStaff from "app/components/UI/Form/Select/SelectPicStaff";
import InputFile from "app/components/UI/Form/Input/InputFile";
import { reportServices, commonServices } from "app/services/api";
import {
  callApiStatus,
  fileNameType,
  testResultRequiredRowName,
  LABEL_WIDTH,
} from "app/constants";
import ReportMaterialInfosTable from "./ReportMaterialInfosTable";
import PageLoadingBackdrop from "../../../components/CommonPage/PageLoadingBackdrop";
import messages from "../../../services/api/messages";
import ReportPicTable from "./ReportPicTable";

const CreateReportForm = ({
  initReport,
  customerId,
  scheduleId,
  caseInfo,
  customerName,
}) => {
  const isMd = useMediaQuery("(max-width:768px)");

  const [reportInfo, setReportInfo] = useState(initReport);
  const [fileData, setFileData] = useState(null);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreated, setIsCreated] = useState();

  const handleFileChange = (e) => {
    try {
      const file = e.target.files[0];
      readXlsxFile(file, { sheet: "list" }).then((rows) => {
        const filterFileData = rows
          .slice(1)
          .filter((row) => {
            const name = row?.[3] || "";
            if (testResultRequiredRowName.includes(name))
              return false;

            const caseId = row?.[6] || "";
            if (caseId !== caseInfo.caseId) {
              return false;
            }

            const companyNameKanji = row?.[4] || "";
            if (customerName === companyNameKanji) return true;
            return false;
          })
          .map((row) => ({
            name: row?.[3] || "",
            preprocess: row?.[9] || "",
            materialNumber: row?.[1] || "",
            capacity: row?.[10] || "",
          }));
        setFileData(filterFileData);
        setFile(file);

        // Remove confirm generatedPdf and generatedWord file.
        setReportInfo((oldState) => ({
          ...oldState,
          generatedPdf: null,
          generatedWord: null,
        }));
      });
    } catch (error) {
      toast.error(messages.callApiFail);
    }
  };

  const handleClickCreate = () => {
    if (Array.isArray(fileData) && fileData.length > 0)
      setReportInfo({
        ...reportInfo,
        reportTestMaterialInfos: fileData,
      });
  };

  const isDisabledCreate = useMemo(() => {
    return (
      !file ||
      !Array.isArray(reportInfo.reportTestMaterialInfos) ||
      reportInfo.reportTestMaterialInfos.length === 0 ||
      !reportInfo?.generatedPdf ||
      !reportInfo?.generatedWord
    );
  }, [file, reportInfo]);

  const isDisabledConfirm = useMemo(() => {
    return (
      !file ||
      !Array.isArray(reportInfo.reportTestMaterialInfos) ||
      reportInfo.reportTestMaterialInfos.length === 0
    );
  }, [file, reportInfo]);

  const handleConfirmReport = () => {
    if (!reportInfo.doctor) {
      toast.error(messages.required("判定医師"));
    }
    if (!reportInfo?.reportCustomerPIC?.department) {
      toast.error(messages.required("担当部署"));
    }
    if (
      !reportInfo.doctor ||
      !reportInfo?.reportCustomerPIC?.department
    ) {
      return;
    }

    setIsLoading(true);
    const data = {
      customerId: customerId,
      testScheduleId: scheduleId,
      testId: reportInfo.testId,
      doctor: reportInfo.doctor,
      hasTestPlan: !!caseInfo?.testPlanInfo,
      department: reportInfo?.reportCustomerPIC?.department || "",
      reportTestMaterialInfos: reportInfo.reportTestMaterialInfos,
      file: file,
    };

    reportServices
      .createFileDownload(data)
      .then((res) => {
        if (res.data?.success) {
          setReportInfo((oldState) => ({
            ...oldState,
            fileResult: res.data?.result?.fileResult?._id,
            generatedPdf: res.data?.result?.generatedWord?._id,
            generatedWord: res.data?.result?.generatedWord?._id,
          }));

          const caseId = caseInfo?.caseId || "";
          const fileType = fileNameType["report"];
          const timeStamp = moment().format("YYYYMMDDHHmmss");

          setTimeout(() => {
            commonServices
              .downloadWithFileId(
                res.data?.result?.generatedWord?._id,
                "docx",
                `${fileType}_${caseId}_${customerName}_${timeStamp}`
              )
              .catch(() => toast.error(messages.downloadFail));
          }, 500);

          // setTimeout(() => {
          //   commonServices
          //     .downloadPDFWithFileId(
          //       res.data?.result?.generatedWord?._id,
          //       `${fileType}_${caseId}_${customerName}_${timeStamp}`
          //     )
          //     .catch(() => toast.error(messages.downloadFail));
          // }, 500);
        }
        toast.success(messages.confirm(true));
        setIsLoading(false);
      })
      .catch(() => {
        toast.error(messages.confirm(false));
        setIsLoading(false);
      });
  };

  const handleSubmitCreateReport = () => {
    if (!reportInfo.doctor) {
      toast.error(messages.required("判定医師"));
    }
    if (!reportInfo?.reportCustomerPIC?.department) {
      toast.error(messages.required("担当部署"));
    }
    if (
      !reportInfo.doctor ||
      !reportInfo?.reportCustomerPIC?.department
    ) {
      return;
    }
    setIsLoading(true);
    const data = {
      customerId: customerId,
      testScheduleId: scheduleId,
      caseInfoId: caseInfo._id,
      testId: reportInfo.testId,
      doctor: reportInfo.doctor,
      department: reportInfo?.reportCustomerPIC?.department || "",
      reportCustomerPIC: reportInfo?.reportCustomerPIC,
      reportTestMaterialInfos: Array.isArray(
        reportInfo.reportTestMaterialInfos
      )
        ? reportInfo.reportTestMaterialInfos.map((item) => ({
            name: item.name,
            preprocess: item.preprocess,
            materialNumber: item.materialNumber,
            capacity: item.capacity,
          }))
        : [],
      testResult: reportInfo.testResult,
      generatedPdf: reportInfo.generatedPdf,
      generatedWord: reportInfo.generatedWord,
    };

    reportServices
      .createReport(data)
      .then((res) => {
        toast.success(messages.create(true));
        setIsLoading(false);
        setIsCreated(true);
      })
      .catch((error) => {
        toast.error(messages.create(false));
        setIsLoading(false);
      });
  };

  if (isCreated)
    return (
      <div className="w-full h-auto my-12 flex items-center justify-center text-xl">
        報告書は作成しました。修正する場合は、報告書の修正で修正していください。
      </div>
    );

  return (
    <>
      <div className="w-full h-auto">
        <div className="w-full h-auto flex items-center justify-center mb-3 flex-wrap">
          <div className="w-full mb-3 md:mb-0 md:w-1/2 h-full md:pr-12">
            <InputText
              id="testId"
              value={reportInfo.testId}
              label="試験番号"
              disabled={true}
              labelWidth={isMd ? LABEL_WIDTH.RIGHT : LABEL_WIDTH.LEFT}
            />
          </div>

          <div className="w-full md:w-1/2 h-full">
            <SelectPicStaff
              pic={{ id: reportInfo.picStaff }}
              setPic={(newPic) => {
                setReportInfo({
                  ...reportInfo,
                  picStaff: newPic?.id,
                });
              }}
              labelWidth={LABEL_WIDTH.RIGHT}
            />
          </div>
        </div>

        <div className="w-full h-auto flex items-center justify-center mb-3 flex-wrap">
          <div className="w-full mb-3 md:mb-0 md:w-1/2 h-full md:pr-12">
            <InputFile
              id="upload-report-file"
              handleFileChange={handleFileChange}
              label="試験結果"
              labelWidth={isMd ? LABEL_WIDTH.RIGHT : LABEL_WIDTH.LEFT}
              fileName={file?.name || ""}
            />
          </div>

          <div className="w-full md:w-1/2 h-full">
            <InputText
              id="doctor"
              value={reportInfo.doctor}
              onChange={(newValue) => {
                setReportInfo({ ...reportInfo, doctor: newValue });
              }}
              label="判定医師"
              labelWidth={LABEL_WIDTH.RIGHT}
              isRequired
            />
          </div>
        </div>

        <div className="w-full h-auto flex items-center justify-end mb-4">
          <Button
            type={
              Array.isArray(fileData) && fileData.length > 0
                ? "primary"
                : "disabled"
            }
            onClick={handleClickCreate}
          >
            作成する
          </Button>
        </div>

        <div className="mb-[14px]">
          <div className="w-full h-auto mb-5 overflow-x-auto">
            <p className="text-custom-size mb-3">報告書の内容</p>
            <ReportPicTable
              companyNameKanji={reportInfo.companyNameKanji}
              companyAddress={reportInfo.companyAddress}
              reportPICInfo={reportInfo.reportCustomerPIC}
              setReportPICInfo={(newValue) => {
                setReportInfo({
                  ...reportInfo,
                  reportCustomerPIC: newValue,
                });
              }}
            />
          </div>

          <div className="w-full h-auto mb-5 overflow-x-auto">
            <p className="text-custom-size mb-3">被験物質の概要</p>
            <ReportMaterialInfosTable
              reportTestMaterialInfos={
                reportInfo.reportTestMaterialInfos
              }
              setReportTestMaterialInfos={(newValue) => {
                setReportInfo({
                  ...reportInfo,
                  reportTestMaterialInfos: newValue,
                });
              }}
            />
          </div>
        </div>

        <div className="w-full h-auto flex items-center justify-end gap-3">
          <Button
            type={isDisabledConfirm ? "disabled" : "primary"}
            disabled={isDisabledConfirm}
            onClick={handleConfirmReport}
          >
            確認する
          </Button>

          <Button
            type={isDisabledCreate ? "disabled" : "primary"}
            disabled={isDisabledCreate}
            onClick={handleSubmitCreateReport}
          >
            提出する
          </Button>
        </div>
      </div>
      <PageLoadingBackdrop open={isLoading} />
    </>
  );
};

const CreateReport = ({ caseInfo, customer, schedule }) => {
  const [loadInitState, setLoadInitState] = useState({
    loadingStatus: callApiStatus.idle,
    data: null,
    error: null,
  });

  const loadInitReport = useCallback(() => {
    if (!customer?.id || !schedule?.id || !caseInfo?._id) return;

    setLoadInitState({
      loadingStatus: callApiStatus.loading,
      data: null,
      error: null,
    });

    reportServices
      .getInitNewReport(customer.id, caseInfo.quotation)
      .then((initReport) => {
        console.log("initReport", initReport);
        setLoadInitState({
          loadingStatus: callApiStatus.success,
          data: initReport,
          error: null,
        });
      })
      .catch((error) => {
        toast.error(messages.callApiFail);
        setLoadInitState({
          loadingStatus: callApiStatus.error,
          data: null,
          error: error,
        });
      });
  }, [customer?.id, schedule?.id, caseInfo]);

  useEffect(() => loadInitReport(), [loadInitReport]);

  if (loadInitState.loadingStatus === callApiStatus.loading) {
    return <PageLoading />;
  }

  if (
    loadInitState.loadingStatus === callApiStatus.error ||
    loadInitState.error
  ) {
    return <PageError />;
  }

  if (
    loadInitState.loadingStatus === callApiStatus.success &&
    loadInitState.data
  ) {
    return (
      <CreateReportForm
        initReport={loadInitState.data}
        customerId={customer?.id}
        scheduleId={schedule?.id}
        caseInfo={caseInfo}
        customerName={customer?.companyNameKanji || ""}
      />
    );
  }

  return null;
};

export default CreateReport;
