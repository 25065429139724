import client from "./client";
import customerServices from "./customerServices";
import systemSettingsServices from "./systemSettingsServices";
import dashboardServices from "./dashboardServices";
import projectServices from "./projectServices";
import scheduleServices from "./scheduleServices";
import contractServices from "./contractServices";
import reportServices from "./reportServices";
import quotationServices from "./quotationServices";
import caseServices from "./caseServices";
import invoiceServices from "./invoiceServices";
import testFormServices from "./testFormServices";
import testPlanServices from "./testPlanServices";
import commonServices from "./commonServices";

const login = (email, password) => {
  return client.post("/login", { loginName: email, password });
};

const authServices = {
  login,
};

export {
  authServices,
  customerServices,
  systemSettingsServices,
  dashboardServices,
  contractServices,
  projectServices,
  scheduleServices,
  reportServices,
  quotationServices,
  caseServices,
  invoiceServices,
  testFormServices,
  testPlanServices,
  commonServices,
};
