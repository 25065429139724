import client from "./client";
import clientFormData from "./clientFormData";

const getInitTestSampleInfo = (caseList) => {
  const testSampleList = [];

  if (!Array.isArray(caseList) || caseList.length === 0)
    return testSampleList;

  caseList.forEach((caseInfo) => {
    const customerInfo = caseInfo.customer;

    const requestFormInfo = caseInfo.requestFormInfo;
    const testContentInfoList = requestFormInfo?.content;
    if (
      !Array.isArray(testContentInfoList) ||
      testContentInfoList.length === 0
    )
      return;
    testContentInfoList.forEach((testContentInfo) => {
      const testMaterialInfoList = testContentInfo?.testMaterialData;
      if (
        !Array.isArray(testMaterialInfoList) ||
        testMaterialInfoList.length === 0
      )
        return;

      testMaterialInfoList.forEach((testMaterialInfo) => {
        const testSample = {
          testMaterialInfoId: testMaterialInfo?._id,
          cosmeticUsage: testMaterialInfo?.cosmeticUsage || "",
          caseId: caseInfo?.caseId || "",
          companyNameKanji: customerInfo.companyNameKanji,
          companyNameKana: customerInfo.companyNameKana,
          name: testMaterialInfo.name,
          capacity: testMaterialInfo.capacity,
          preprocess: testMaterialInfo.preprocess,
        };

        testSampleList.push(testSample);
      });
    });
  });

  return testSampleList;
};

const getInitTestFormByTestSchedule = (idSchedule) => {
  return client
    .get("/test-form-info/get-by-test-schedule-id", {
      params: {
        scheduleId: idSchedule,
      },
    })
    .then((resTestForm) => {
      if (resTestForm.data?.success) {
        const result = resTestForm.data.result;
        if (result?._id) {
          return client
            .get("/test-schedule-info/get-material", {
              params: {
                id: idSchedule,
              },
            })
            .then((resTestMaterial) => {
              if (resTestMaterial.data.success) {
                return {
                  data: {
                    id: result?._id,
                    monitor1: result?.monitor1 || "24時間",
                    monitor2: result?.monitor2 || "48時間",
                    monitor3: result?.monitor3 || "",
                    monitor4: result?.monitor4 || "",
                    monitor5: result?.monitor5 || "",
                    monitor6: result?.monitor6 || "",
                    monitor7: result?.monitor7 || "",
                    monitor8: result?.monitor8 || "",
                    monitor9: result?.monitor9 || "",
                    monitor10: result?.monitor10 || "",

                    testSamples: getInitTestSampleInfo(
                      resTestMaterial.data?.result
                    ),
                    monitors: result?.monitorData || [],
                  },
                };
              }
              throw new Error("Malformed data!");
            });
        }

        return client
          .get("/test-schedule-info/get-material", {
            params: {
              id: idSchedule,
            },
          })
          .then((resTestMaterial) => {
            if (resTestMaterial.data.success) {
              return {
                data: {
                  monitor1: "24時間",
                  monitor2: "48時間",
                  monitor3: "",
                  monitor4: "",
                  monitor5: "",
                  monitor6: "",
                  monitor7: "",
                  monitor8: "",
                  monitor9: "",
                  monitor10: "",

                  testSamples: getInitTestSampleInfo(
                    resTestMaterial.data?.result
                  ),
                  monitors: [],
                },
              };
            }
            throw new Error("Malformed data!");
          });
      }
      throw new Error("Malformed data!");
    });
};

const createTestForm = (formData) => {
  const data = {
    monitor1: formData?.monitor1 || "",
    monitor2: formData?.monitor2 || "",
    monitor3: formData?.monitor3 || "",
    monitor4: formData?.monitor4 || "",
    monitor5: formData?.monitor5 || "",
    monitor6: formData?.monitor6 || "",
    monitor7: formData?.monitor7 || "",
    monitor8: formData?.monitor8 || "",
    monitor9: formData?.monitor9 || "",
    monitor10: formData?.monitor10 || "",
    testSchedule: formData?.testSchedule || "",
    totalTestPeople: formData?.totalTestPeople || 0,

    testSamples: formData?.testSamples?.map((item) => ({
      testMaterialInfoId: item?.testMaterialInfoId || "",
      cosmeticUsage: item?.cosmeticUsage || "",
      caseId: item?.caseId || "",
      companyNameKanji: item?.companyNameKanji || "",
      companyNameKana: item?.companyNameKana || "",
      name: item?.name || "",
      capacity: item?.capacity || "",
      preprocess: item?.preprocess || "",
    })),
    monitors: formData?.monitors?.map((item) => ({
      gender: item.gender || "",
      dob: item.dob || "",
      age: item.age || "",
      monitorId: item.monitorId || "",
      monitorItem: item.monitorItem || "",
    })),
    caseInfos: formData.caseInfos,
    file: formData.file,
  };

  return clientFormData
    .post("/test-form-info/create", data)
    .then((res) => {
      if (res.data?.success)
        return {
          data: res.data.result,
        };
      throw new Error("Malformed data!");
    });
};

const updateTestForm = (formData) => {
  const data = {
    monitor1: formData?.monitor1 || "",
    monitor2: formData?.monitor2 || "",
    monitor3: formData?.monitor3 || "",
    monitor4: formData?.monitor4 || "",
    monitor5: formData?.monitor5 || "",
    monitor6: formData?.monitor6 || "",
    monitor7: formData?.monitor7 || "",
    monitor8: formData?.monitor8 || "",
    monitor9: formData?.monitor9 || "",
    monitor10: formData?.monitor10 || "",
    testSchedule: formData?.testSchedule || "",
    totalTestPeople: formData?.totalTestPeople || 0,

    testSamples: formData?.testSamples?.map((item) => ({
      testMaterialInfoId: item?.testMaterialInfoId || "",
      cosmeticUsage: item?.cosmeticUsage || "",
      caseId: item?.caseId || "",
      companyNameKanji: item?.companyNameKanji || "",
      companyNameKana: item?.companyNameKana || "",
      name: item?.name || "",
      capacity: item?.capacity || "",
      preprocess: item?.preprocess || "",
    })),
    monitors: formData?.monitors?.map((item) => ({
      gender: item.gender || "",
      dob: item.dob || "",
      age: item.age || "",
      monitorId: item.monitorId || "",
      monitorItem: item.monitorItem || "",
    })),
    caseInfos: formData.caseInfos,
    file: formData.file,
  };
  return clientFormData
    .put(`/test-form-info/update?id=${formData.id}`, data)
    .then((res) => {
      if (res.data?.success)
        return {
          data: res.data.result,
        };
      throw new Error("Malformed data!");
    });
};

const testFormServices = {
  getInitTestFormByTestSchedule,
  createTestForm,
  updateTestForm,
};

export default testFormServices;
