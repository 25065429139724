import React, { useMemo } from "react";
import TableInfoHeader from "../../../components/Table/CommonComponents/TableInfo/TableInfoHeader";
import PageNoData from "../../../components/CommonPage/PageNoData";
import { gender } from "../../../constants";

const tableConfigRow1 = [
  {
    title: (
      <span className="text-[12px] sm:text-custom-size-sm md:text-custom-size">
        モニター性別
      </span>
    ),
    width: "50%",
  },
  {
    title: (
      <span className="text-[12px] sm:text-custom-size-sm md:text-custom-size">
        年齢
      </span>
    ),
    width: "50%",
  },
];
const tableConfigRow2 = [
  {
    title: (
      <span className="text-[12px] sm:text-custom-size-sm md:text-custom-size">
        総勢
      </span>
    ),
    width: "16.6%",
  },
  {
    title: (
      <span className="text-[12px] sm:text-custom-size-sm md:text-custom-size">
        男性
      </span>
    ),
    width: "16.7%",
  },
  {
    title: (
      <span className="text-[12px] sm:text-custom-size-sm md:text-custom-size">
        女性
      </span>
    ),
    width: "16.7%",
  },

  {
    title: (
      <span className="text-[12px] sm:text-custom-size-sm md:text-custom-size">
        最年少
      </span>
    ),
    width: "16.6%",
  },
  {
    title: (
      <span className="text-[12px] sm:text-custom-size-sm md:text-custom-size">
        平均年齢
      </span>
    ),
    width: "16.7%",
  },
  {
    title: (
      <span className="text-[12px] sm:text-custom-size-sm md:text-custom-size">
        最年長
      </span>
    ),
    width: "16.7%",
  },
];

function TestMonitorList(props) {
  const { monitorList } = props;

  const tableConfigRow3 = useMemo(() => {
    if (!Array.isArray(monitorList) || monitorList.length === 0)
      return [
        {
          title: "",
          width: "16.6%",
        },
        {
          title: "",
          width: "16.7%",
        },
        {
          title: "",
          width: "16.7%",
        },

        {
          title: "",
          width: "16.6%",
        },
        {
          title: "",
          width: "16.7%",
        },
        {
          title: "",
          width: "16.7%",
        },
      ];

    const numberOfAllTestSample = monitorList.length;
    const numberOfAllMale = monitorList.reduce(
      (a, b) => a + (b?.gender === gender.male ? 1 : 0),
      0
    );
    const numberOfAllFemale = monitorList.reduce(
      (a, b) => a + (b?.gender === gender.female ? 1 : 0),
      0
    );

    const ageList = monitorList.map((item) => Number(item.age));
    const minOfAge = Math.min(...ageList);
    const maxOfAge = Math.max(...ageList);
    const averageOfAge = Math.round(
      ageList.reduce((a, b) => a + b, 0) / ageList.length
    );

    return [
      {
        title: (
          <span className="text-[12px] sm:text-custom-size-sm md:text-custom-size">{`${numberOfAllTestSample}名`}</span>
        ),
        width: "16.6%",
      },
      {
        title: (
          <span className="text-[12px] sm:text-custom-size-sm md:text-custom-size">{`${numberOfAllMale}名`}</span>
        ),
        width: "16.7%",
      },
      {
        title: (
          <span className="text-[12px] sm:text-custom-size-sm md:text-custom-size">{`${numberOfAllFemale}名`}</span>
        ),
        width: "16.7%",
      },

      {
        title: (
          <span className="text-[12px] sm:text-custom-size-sm md:text-custom-size">{`${minOfAge}歳`}</span>
        ),
        width: "16.6%",
      },
      {
        title: (
          <span className="text-[12px] sm:text-custom-size-sm md:text-custom-size">{`${averageOfAge}歳`}</span>
        ),
        width: "16.7%",
      },
      {
        title: (
          <span className="text-[12px] sm:text-custom-size-sm md:text-custom-size">{`${maxOfAge}歳`}</span>
        ),
        width: "16.7%",
      },
    ];
  }, [monitorList]);

  return (
    <div className="w-full h-auto overflow-auto mb-6">
      <div className="text-custom-size mb-4 ">モニター情報</div>

      {Array.isArray(monitorList) && monitorList.length > 0 ? (
        <div className="w-full h-auto">
          <TableInfoHeader tableConfig={tableConfigRow1} />
          <TableInfoHeader tableConfig={tableConfigRow2} />
          <TableInfoHeader tableConfig={tableConfigRow3} />
        </div>
      ) : (
        <PageNoData />
      )}
    </div>
  );
}

export default TestMonitorList;
