import React from "react";
import TableInfo from "../../CommonComponents/TableInfo";

function CustomerDetailBaseInfo(props) {
  const { customerBaseInfo } = props;
  return (
    <TableInfo
      tableConfig={[
        {
          title: "項目",
          width: "25%",
          dataKey: "title",
        },
        {
          title: "詳細",
          width: "75%",
          dataKey: "value",
        },
      ]}
      tableData={[
        {
          title: "お客様ID",
          value: customerBaseInfo?.customerId || "",
        },
        {
          title: "会社名",
          value: customerBaseInfo?.companyNameKanji || "",
        },
        {
          title: "フリガナ",
          value: customerBaseInfo?.companyNameKana || "",
        },
        {
          title: "住所",
          value: customerBaseInfo?.address3 || "", // TODO FE-1
        },
        {
          title: "代表番号",
          value: customerBaseInfo?.phone1 || "",
        },
      ]}
    />
  );
}

export default CustomerDetailBaseInfo;
