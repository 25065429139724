import React, { useMemo, useState } from "react";
import TableInfo from "../../CommonComponents/TableInfo";
import PageNoData from "../../../CommonPage/PageNoData";
import {
  DEFAULT_JP_DATE_FORMAT,
  contractCategoryLabel,
  contractProgressTypeLabel,
  contractTypeLabel,
  fileNameType,
} from "app/constants";
import moment from "moment";
import { commonServices } from "../../../../services/api";
import { toast } from "react-toastify";
import PageLoadingBackdrop from "../../../CommonPage/PageLoadingBackdrop";
import messages from "../../../../services/api/messages";

function ContractIdCell(props) {
  const { cellContent, width, textAlign } = props;
  const [showLoading, setShowLoading] = useState(false);

  const handleClick = () => {
    if (showLoading) return;
    setShowLoading(true);
    if (cellContent?.generatedWord) {
      const contractCategory =
        contractCategoryLabel[cellContent.contractCategory] || "";
      const customerNameKanji =
        cellContent.customerBaseInfo?.companyNameKanji || "";
      const timeStamp = moment().format("YYYYMMDDHHmmss");

      commonServices
        .downloadPDFWithFileId(
          cellContent?.generatedWord,
          `${contractCategory}_${customerNameKanji}_${timeStamp}`
        )
        .then((res) => {
          setShowLoading(false);
        })
        .catch((err) => {
          toast.error(messages.downloadFail);
          console.log("error: ", err);
          setShowLoading(false);
        });
    }
  };

  return (
    <>
      <div
        className="h-full border border-[#B3B3B3] px-2 py-4 shrink-0h-full shrink-0"
        style={{
          width: width,
          textAlign: textAlign || "start",
        }}
      >
        <button
          type="button"
          onClick={handleClick}
          className="text-sm leading-[18px] tracking-[.2em] truncate text-[#08978E]"
        >
          {cellContent?.contractId || "XXXXXXXXX"}
        </button>
      </div>
      <PageLoadingBackdrop open={showLoading} />
    </>
  );
}

function CustomerDetailContractContent({ contractList, customerBaseInfo }) {
  const tableData = useMemo(() => {
    return contractList.map((item) => ({
      id: item.id,
      contract: { ...item, customerBaseInfo: customerBaseInfo },
      name: item.name,
      contractDate: item.contractDate
        ? moment(item.contractDate).format(DEFAULT_JP_DATE_FORMAT)
        : "",
      progress: contractProgressTypeLabel[item.progress],
    }));
  }, [contractList, customerBaseInfo]);

  return (
    <TableInfo
      tableConfig={[
        {
          title: "契約書ID",
          width: "20%",
          textAlign: "center",
          dataKey: "contract",
          CustomTableCell: ContractIdCell,
        },
        {
          title: "契約書名",
          width: "36%",
          textAlign: "center",
          dataKey: "name",
        },
        {
          title: "締結日",
          width: "22%",
          textAlign: "center",
          dataKey: "contractDate",
        },
        {
          title: "進捗",
          width: "22%",
          textAlign: "center",
          dataKey: "progress",
        },
      ]}
      tableData={tableData}
      className="min-w-[700px]"
    />
  );
}

function CustomerDetailContract(props) {
  const { contractList, customerBaseInfo } = props;

  if (!Array.isArray(contractList) || contractList.length === 0)
    return <PageNoData noDataMessage="まだ契約はありません！" />;

  return (
    <CustomerDetailContractContent
      contractList={contractList}
      customerBaseInfo={customerBaseInfo}
    />
  );
}

export default CustomerDetailContract;
