import React from "react";
import EditBtn from "../../../UI/Button/EditBtn";

function TextField(props) {
  const { value, onChange } = props;

  return (
    <div className="w-full h-full">
      <input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="w-full h-10 border border-[#B3B3B3] bg-white rounded-[10px] !outline-none text-custom-size py-2.5 px-3"
      />
    </div>
  );
}

function PlanTableRow(props) {
  const { onClickEdit, rowData, handleChange, indexNum } = props;

  return (
    <div className="w-full h-[40px] flex">
      <div
        className="h-full border border-[#B3B3B3] bg-[#B3B3B3] bg-opacity-[26%] px-0 py-[10px] text-sm"
        style={{
          width: "30%",
          textAlign: rowData.textAlign || "start",
        }}
      >
        <p className="leading-[18px] pl-4 tracking-[1.75px] truncate">
          {rowData.name}
        </p>
      </div>

      {rowData.showInput ? (
        <div className="w-[67%] h-full">
          <TextField
            InputProps={{
              style: {
                borderRadius: "10px",
              },
            }}
            value={rowData.preprocess}
            onChange={(newValue) => handleChange(indexNum, newValue)}
            size="small"
          />
        </div>
      ) : (
        <div
          className="h-full border border-[#B3B3B3] bg-[#B3B3B3] bg-opacity-[26%] px-0 py-[10px] text-sm"
          style={{
            width: "67%",
            textAlign: rowData.textAlign || "start",
          }}
        >
          <p className="leading-[18px] pl-4 tracking-[1.75px] truncate">
            {rowData.preprocess || "-"}
          </p>
        </div>
      )}

      {rowData.shouldEdit && (
        <EditBtn
          width="3%"
          style={{ justifyContent: "center" }}
          onClick={() => onClickEdit(indexNum)}
          showInput={rowData.showInput}
        />
      )}
    </div>
  );
}

export default PlanTableRow;
