import client from "./client";
import {
  contractType,
  contractTypeLabel,
} from "../../constants";
import { DEFAULT_QUOTATION_BASICS } from "../../pages/Customer/CreateCustomer/constants";

const getQuotationsByCustomerAndSchedule = (
  customerId,
  scheduleId
) => {
  return client
    .get("/quotation-info/get-by-customer-id-and-test-schedule", {
      params: {
        "customer-id": customerId,
        "test-schedule-id": scheduleId,
      },
    })
    .then((res) => {
      if (res.data?.success)
        return {
          data: res.data.result || null,
        };
      throw new Error("Malformed data!");
    });
};

const getQuotationById = (id) => {
  return client
    .get("/quotation-info/get-by-id", {
      params: { id },
    })
    .then((res) => {
      if (res.data?.success)
        return {
          data: res.data.result || null,
        };
      throw new Error("Malformed data!");
    });
};

const getDetailCustomerPicAndQuotation = (id) => {
  return client.get(`/customer-info/get?id=${id}`).then((res) => {
    if (res.data?.success && res.data?.result)
      return {
        data: {
          pic: res.data.result?.pic
            ? {
                id: res.data.result?.pic?._id,
                ...res.data.result?.pic,
              }
            : null,
          quotationBasics: Array.isArray(
            res.data.result?.quotation_basics
          )
            ? res.data.result?.quotation_basics
            : [],
        },
      };
    throw new Error("Malformed data!");
  });
};

const generateQuotationId = (scheduleId) => {
  return client
    .get("/quotation-info/get-quotation-id-by-test-schedule", {
      params: { "test-schedule-id": scheduleId },
    })
    .then((res) => {
      if (res.data?.success)
        return {
          data: {
            quotationId: res.data?.result,
          },
        };
      throw new Error("Malformed data!");
    });
};

const getInitNewQuotation = (idCustomer, idSchedule) => {
  return Promise.all([
    getDetailCustomerPicAndQuotation(idCustomer),
    generateQuotationId(idSchedule),
  ]).then((res) => {
    const quotationId = res[1].data.quotationId;
    const quotationBasics = res[0].data.quotationBasics;
    const isUsingDefaultQuotationBasics =
      quotationBasics.length === 0;
    const arrangedQuotationBasics = isUsingDefaultQuotationBasics
      ? DEFAULT_QUOTATION_BASICS
      : quotationBasics;

    return {
      data: {
        customerId: idCustomer,
        testScheduleId: idSchedule,
        deliveryDate: "",
        quotationId: quotationId,
        deliveryPlace: "",
        paymentMethod: "",
        expiryDateStatement: "発行日より 60 日",
        requirements: "安全性試験",
        memo: "",
        paymentDeadline1: "",
        paymentDeadline2: "",
        paymentDeadline3: "",
        paymentDeadline4: "",
        paymentDeadline5: "",
        paymentDeadline6: "",
        paymentDeadline7: "",
        paymentDeadline8: "",
        picStaff: "",
        quotationTasks: arrangedQuotationBasics.map((item) => ({
          name: "24時間閉塞ヒトパッチテスト",
          contractType: item.contractType,
          volumn: item.testSampleNumber,
          inspectionPeopleNumber: item.inspectionPeopleNumber,
          testSampleNumber: 1,
          quotationPrice: item.quotationPrice,
          quotationPriceUnit: item.quotationPriceUnit,
          memo:
            item.contractType === "eachTime"
              ? ""
              : contractTypeLabel[contractType.annually],
        })),
      },
    };
  });
};

const createQuotation = (formData) => {
  return client
    .post("/quotation-info/create", formData)
    .then((res) => {
      if (res.data.result?._id) {
        return { id: res.data.result?._id };
      }

      if (res.data?.result.code === 400) {
        throw new Error("001");
      } else {
        throw new Error("Malformed data!");
      }
    });
};

const updateQuotation = (id, formData) => {
  return client
    .put(`/quotation-info/update?id=${id}`, formData)
    .then((res) => {
      if (res.data?.success)
        return {
          data: res.data.result ? { id: res.data.result?._id } : null,
        };
      throw new Error("Malformed data!");
    });
};

const createFileDownload = (formData) => {
  return client
    .post("/quotation-info/create-file-download", formData)
    .then((res) => {
      if (res.data?.success)
        return {
          data: res.data.result,
        };
      throw new Error("Malformed data!");
    });
};

const quotationService = {
  getQuotationsByCustomerAndSchedule,
  getQuotationById,
  getInitNewQuotation,
  createQuotation,
  updateQuotation,
  createFileDownload,
};

export default quotationService;
