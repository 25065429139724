import TailComponent from "app/assets/svg/Tail";
import BlankAvatar from "app/assets/images/blank_avatar.png";
import sanitizeHtml from "sanitize-html";

export default function MessageCard({
  isIncomingMsg,
  content,
  className,
  ...rest
}) {
  const options = { allowedTags: ["br"] };
  const sanitizedContent = sanitizeHtml(content, options);

  return (
    <div
      {...rest}
      className={`flex gap-4 ${
        !isIncomingMsg && "flex-row-reverse"
      } ${className}`}
    >
      <div className="flex flex-col flex-[0_0_40px] justify-end">
        <img
          className="h-[40px] w-[40px] object-cover rounded-full"
          src={BlankAvatar}
          alt=""
        />
      </div>
      <div
        className={`relative ${
          isIncomingMsg
            ? "bg-[#FF8AB4] rounded-br-2xl"
            : "bg-[#97DDFF] rounded-bl-2xl"
        } rounded-t-2xl px-4 py-2 text-white`}
      >
        <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
        <TailComponent
          className={`absolute ${
            isIncomingMsg
              ? "left-[-10px]"
              : "right-[-10px] revert-tail"
          } bottom-[0px] w-4 h-4`}
        />
      </div>
      <div className="flex-[0_0_40px]" />
    </div>
  );
}
