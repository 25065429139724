export default function ChatBox({
  textAreaRef,
  msgVal,
  setMsgVal,
  handleSendMsg,
}) {
  return (
    <>
      <textarea
        ref={textAreaRef}
        value={msgVal}
        onChange={(e) => {
          setMsgVal(e?.target?.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSendMsg();
          }
        }}
        className="w-full h-[30px] border px-2"
      />
    </>
  );
}
