import React, { useMemo } from "react";
import TableInfo from "app/components/Table/CommonComponents/TableInfo";
import ProjectTableInfo from "../ProjectTableInfo";
import moment from "moment";
import { convertDateStringToJpFormat } from "app/utils";

function CustomLinkCell(props) {
  const { width, cellContent, textAlign, caseId } = props;
  return (
    <div
      className="h-full border border-[#B3B3B3] px-[10px] py-[2px]"
      style={{
        width: width,
        textAlign: textAlign || "start",
      }}
    >
      <a
        href={`#${caseId}`}
        className="text-[14px] leading-[18px] tracking-[1.75px] truncate text-green"
      >
        {cellContent.value}
      </a>
    </div>
  );
}

function TestScheduleListTable(props) {
  const { list } = props;

  const formatList = useMemo(() => {
    return list.map((item, index) => ({
      ...item,
      testDate: {
        id: item.cases[index],
        value: convertDateStringToJpFormat(item.testDate),
      },
      reportDeliveryDate: convertDateStringToJpFormat(
        item.reportDeliveryDate
      ),
      samplePaymentDeadline: convertDateStringToJpFormat(
        item.samplePaymentDeadline
      ),
      applicationDeadline: convertDateStringToJpFormat(
        item.applicationDeadline
      ),
      availableApplicationNumber:
        item.possibleApplicationNumber -
        (item.arrivedSampleNumber + item.undeliveredSampleNumber),
    }));
  }, [list]);

  return (
    <div className="overflow-x-auto">
      <ProjectTableInfo
        className="custom-scrollbar pr-1.5 max-h-[260px]"
        tableConfig={[
          {
            title: "試験日",
            width: "155px",
            dataKey: "testDate",
            CustomTableCell: CustomLinkCell,
            textAlign: "center",
          },
          {
            title: "報告書納品日",
            width: "155px",
            dataKey: "reportDeliveryDate",
            textAlign: "center",
          },
          {
            title: "検体納付期限",
            width: "155px",
            dataKey: "samplePaymentDeadline",
            textAlign: "center",
          },
          {
            title: "申込期限",
            width: "155px",
            dataKey: "applicationDeadline",
            textAlign: "center",
          },
          {
            title: "申込可能数",
            width: "100px",
            dataKey: "availableApplicationNumber",
            textAlign: "center",
          },
          {
            title: "到着検体数",
            width: "100px",
            dataKey: "arrivedSampleNumber",
            textAlign: "center",
          },
          {
            title: "未着検体数",
            width: "100px",
            dataKey: "undeliveredSampleNumber",
            textAlign: "center",
          },
        ]}
        tableData={formatList}
      />
    </div>
  );
}

export default TestScheduleListTable;
