// FE-1
import React, { useCallback, useEffect, useState } from "react";
import { Link, useOutletContext, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ContentLayout from "app/layouts/ContentLayout";
import PageError from "app/components/CommonPage/PageError";
import PageLoading from "app/components/CommonPage/PageLoading";
import CustomerDetailBaseInfo from "app/components/Table/Customer/CustomerDetailBaseInfo";
import CustomerPICDetail from "app/components/Table/Customer/CustomerPICDetail";
import CustomerBillingInfoDetail from "app/components/Table/Customer/CustomerBillingInfoDetail";
import CustomerDetailContract from "app/components/Table/Customer/CustomerDetailContract";
import CustomerDetailEstimateBasic from "app/components/Table/Customer/CustomerDetailEstimateBasic";
import CustomerDetailTransactionHistory from "app/components/Table/Customer/CustomerDetailTransactionHistory";
import { customerServices } from "app/services/api";
import routePaths from "app/route/routePaths";
import messages from "../../../services/api/messages";

function DetailCustomerPageContent({ customerBaseInfo, id }) {
  return (
    <>
      <div className="w-full h-auto mb-6">
        <div className="w-full h-auto pt-0 pb-[17px] flex items-center justify-between">
          <h3 className="text-custom-size">顧客情報</h3>

          <div className="flex items-center gap-4">
            <Link
              to={`${routePaths.chat}?id=${customerBaseInfo.id}`}
              className="rounded-[10px] px-[1.375em] py-[0.75em] text-[0.8125em] bg-[#001C4F] text-white"
            >
              連絡する
            </Link>

            <Link
              to={routePaths.updateCustomer(id)}
              className="rounded-[10px] px-[1.375em] py-[0.75em] text-[0.8125em] bg-[#001C4F] text-white"
            >
              変更する
            </Link>
          </div>
        </div>
        <CustomerDetailBaseInfo customerBaseInfo={customerBaseInfo} />
      </div>

      <div className="w-full h-auto mb-6">
        <div className="w-full h-auto pt-0 pb-[17px]">
          <h3 className="custom-size">担当者情報</h3>
        </div>
        <CustomerPICDetail picInfo={customerBaseInfo.pic} />
      </div>

      <div className="w-full h-auto mb-6">
        <div className="w-full h-auto pt-0 pb-[17px]">
          <h3 className="text-custom-size">請求先情報</h3>
        </div>
        <CustomerBillingInfoDetail
          billingInfo={customerBaseInfo.billing}
        />
      </div>

      <div className="w-full h-auto mb-6">
        <div className="w-full h-auto pt-0 pb-[17px] flex items-center justify-between">
          <h3 className="text-custom-size">契約書締結状況</h3>
          <div className="flex items-center gap-4">
            <Link
              to={`${routePaths.chat}?id=${customerBaseInfo.id}`}
              className="rounded-[10px] px-[1.375em] py-[0.75em] text-[0.8125em] bg-[#001C4F] text-white"
            >
              連絡する
            </Link>

            <Link
              to={routePaths.updateCustomer(id)}
              className="rounded-[10px] px-[1.375em] py-[0.75em] text-[0.8125em] bg-[#001C4F] text-white"
            >
              変更する
            </Link>
          </div>
        </div>
        <div className="w-full h-auto overflow-x-auto">
          <CustomerDetailContract
            contractList={customerBaseInfo.contracts}
            customerBaseInfo={customerBaseInfo}
          />
        </div>
      </div>

      <div className="w-full h-auto mb-6">
        <div className="w-full h-auto pt-0 pb-[17px]">
          <h3 className="text-custom-size">取引履歴</h3>
        </div>
        <CustomerDetailTransactionHistory id={id} />
      </div>

      <div className="w-full h-auto mb-6">
        <div className="w-full h-auto pt-0 pb-[17px]">
          <h3 className="text-custom-size">見積り基本情報</h3>
        </div>
        <CustomerDetailEstimateBasic
          quotationBasics={customerBaseInfo.quotationBasics}
        />
      </div>
    </>
  );
}

function DetailCustomerPage(props) {
  const { id } = useParams();
  const [breadcrumb] = useOutletContext();

  const [loadInitState, setLoadInitState] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const loadInit = useCallback(() => {
    setLoadInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    customerServices
      .getDetail(id)
      .then((res) => {
        setLoadInitState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        toast.error(messages.callApiFail);
        setLoadInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [id]);

  useEffect(() => {
    loadInit();
  }, [loadInit]);

  return (
    <ContentLayout
      sideMenuLinkOptions={[
        { linkTo: "/customer", linkTitle: "顧客情報の検索" },
        { linkTo: "/customer/create", linkTitle: "顧客情報の登録" },
        { linkTo: routePaths.topMenu, linkTitle: "トップへ戻る" },
      ]}
      breadcrumb={breadcrumb}
    >
      <div className="w-full grow flex flex-col pt-2.5">
        {loadInitState.isLoading ? <PageLoading /> : null}

        {!loadInitState.isLoading && loadInitState.error ? (
          <PageError />
        ) : null}

        {!loadInitState.isLoading &&
        !loadInitState.error &&
        loadInitState.data ? (
          <DetailCustomerPageContent
            id={id}
            customerBaseInfo={loadInitState.data}
          />
        ) : null}
      </div>
    </ContentLayout>
  );
}

export default DetailCustomerPage;
