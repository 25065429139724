import React from "react";

import ProjectTableHeader from "./ProjectTableHeader";
import ProjectTableRow from "./ProjectTableRow";
import PageNoData from "../../../CommonPage/PageNoData";

function ProjectTableInfo(props) {
  const { tableConfig, tableData, className } = props;

  return (
    <div className={`w-full h-full overflow-auto relative min-w-[944px] ${className}`}>
      <ProjectTableHeader tableConfig={tableConfig} />

      {Array.isArray(tableData) && tableData.length > 0 ? (
        tableData.map((rowData, index) => (
          <ProjectTableRow
            key={index}
            tableConfig={tableConfig}
            rowData={rowData}
          />
        ))
      ) : (
        <PageNoData />
      )}
    </div>
  );
}

export default ProjectTableInfo;
