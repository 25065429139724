import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import ContentLayout from "app/layouts/ContentLayout";
import Button from "app/components/UI/Button";
import SelectCustomerIdAndTestSchedule from "app/components/UI/Form/SelectGroup/SelectCustomerIdAndTestSchedule";
import messages from "../../../services/api/messages";
import CreateReport from "./CreateReport";
import UpdateReport from "./UpdateReport";
import { SIDE_MENU } from "../constants";

function CreateAndUpdate({ isUpdate, caseInfo, customer, schedule }) {
  if (caseInfo.report) {
    return isUpdate ? (
      <UpdateReport
        caseInfo={caseInfo}
        customer={customer}
        schedule={schedule}
        reportId={caseInfo.report}
      />
    ) : (
      <div className="w-full h-full flex flex-col items-center justify-center py-12">
        <p>報告書は既に作成しました。</p>
        <p>修正する場合は、報告書の修正で修正していください。</p>
      </div>
    );
  }

  return !isUpdate ? (
    <CreateReport
      caseInfo={caseInfo}
      customer={customer}
      schedule={schedule}
    />
  ) : (
    <div className="w-full h-full flex flex-col items-center justify-center py-12">
      <p>報告書はまだ作成しません。</p>
      <p>報告書の作成から作成していください。</p>
    </div>
  );
}

function CreateAndUpdatePage({ isUpdate }) {
  const [breadcrumb] = useOutletContext();
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [selectedCaseInfo, setSelectedCaseInfo] = useState(null);
  const [caseInfo, setCaseInfo] = useState(null);

  const reset = () => setCaseInfo(null);

  return (
    <ContentLayout
      sideMenuLinkOptions={SIDE_MENU}
      breadcrumb={breadcrumb}
    >
      <div className="w-full h-auto">
        <div className="w-full h-auto mb-5 overflow-x-auto">
          <p className="text-custom-size mb-3">
            {!isUpdate ? "報告書の作成" : "報告書の修正"}
          </p>
          <SelectCustomerIdAndTestSchedule
            customer={selectedCustomer}
            setCustomer={(newCustomer) => {
              reset();
              setSelectedCustomer(newCustomer);
            }}
            schedule={selectedSchedule}
            setSchedule={(newSchedule) => {
              reset();
              setSelectedSchedule(newSchedule);
            }}
            caseInfo={selectedCaseInfo}
            setCaseInfo={(newCaseInfo) => {
              reset();
              setSelectedCaseInfo(newCaseInfo);
            }}
          />
          <div className="w-full h-auto flex items-center justify-end mt-5">
            <Button
              disabled={!selectedCaseInfo}
              type={!selectedCaseInfo ? "disabled" : "primary"}
              onClick={() => {
                setCaseInfo(selectedCaseInfo);
              }}
            >
              呼び出す
            </Button>
          </div>
        </div>

        {caseInfo && (
          <CreateAndUpdate
            isUpdate={isUpdate}
            customer={selectedCustomer}
            schedule={selectedSchedule}
            caseInfo={caseInfo}
          />
        )}

        {caseInfo && !caseInfo?._id && (
          <div className="w-full h-auto my-12 flex items-center justify-center text-xl">
            {messages.quotationExist(false)}
          </div>
        )}
      </div>
    </ContentLayout>
  );
}

export default CreateAndUpdatePage;
