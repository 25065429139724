import React from "react";

function ProjectTableCell(props) {
  const { width, cellContent, textAlign } = props;
  return (
    <div
      className="h-full border border-[#B3B3B3] px-0 py-[5px] shrink-0"
      style={{
        width: width,
        textAlign: textAlign || "start",
      }}
    >
      <div className="text-sm leading-[18px] tracking-[1.75px] truncate">
        {cellContent}
      </div>
    </div>
  );
}

export default ProjectTableCell;
