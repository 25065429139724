import client from "./client";
import clientFormData from "./clientFormData";
import Apis from "./Path";

const getScheduleList = async () => {
  return client.get(Apis.testScheduleList).then((res) => {
    if (res.data?.success && res.data?.result) {
      return {
        scheduleList: res.data.result.map((i) => ({
          ...i,
          id: i._id,
        })),
        caseList: res.data.result.map((i) => ({
          testDate: i.testDate,
          caseInfos: i.caseInfos,
          caseId: i._id,
        })),
      };
    }
    throw new Error("Malformed data!");
  });
};

const updateTestSchedule = async (body) => {
  const sendData = body.map((i) => ({
    id: i._id,
    testDate: i.testDate,
    reportDeliveryDate: i.reportDeliveryDate,
    samplePaymentDeadline: i.samplePaymentDeadline,
    applicationDeadline: i.applicationDeadline,
    possibleApplicationNumber: i.possibleApplicationNumber,
    arrivedSampleNumber: i.arrivedSampleNumber,
    undeliveredSampleNumber: i.undeliveredSampleNumber,
  }));
  return client.post(Apis.updateTestSchedule, sendData);
};

const updateStatus = async ({ id, statusType, value, data }) => {
  const body = {
    [statusType]: value,
    ...data,
  };
  return client.put(Apis.updateStatus(id), body);
};

const uploadDocument = async (caseInfoId, documentType, file) => {
  return clientFormData.post(Apis.uploadDocument, {
    caseInfoId,
    documentType,
    file,
  });
};

const projectServices = {
  getScheduleList,
  updateTestSchedule,
  updateStatus,
  uploadDocument,
};

export default projectServices;
