import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import TableInfoHeader from "app/components/Table/CommonComponents/TableInfo/TableInfoHeader";
import PageNoData from "app/components/CommonPage/PageNoData";
import PencilSVG from "app/assets/svg/pencil.svg";

const tableConfig = [
  {
    title: "会社名",
    width: "25.55%",
  },
  {
    title: "名称",
    width: "25.55%",
  },
  {
    title: "容量",
    width: "9.44%",
  },
  {
    title: "前処理",
    width: "39.55%",
  },
];

function CellInput({ value, onChange, className }) {
  return (
    <div className="w-full h-full">
      <input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={
          "w-full h-10 border border-[#B3B3B3] bg-white rounded-[10px] !outline-none text-custom-size py-2.5 px-3 " +
          (className || "")
        }
      />
    </div>
  );
}

// function CellInputNumber(props) {
//   const { value, onChange, isReadonly } = props;

//   return isReadonly ? (
//     <div className="w-full h-full border border-[#B3B3B3] bg-[#EBEBEB] px-2 py-2.5 text-center">
//       <p className="text-sm leading-[18px] tracking-[.2em] truncate">
//         {`${value || 0}uL`}
//       </p>
//     </div>
//   ) : (
//     <div className="w-full h-full border border-solid border-[#B3B3B3] bg-white rounded-[10px]">
//       <NumericFormat
//         value={value}
//         onValueChange={(values) => {
//           if (typeof values.floatValue === "number")
//             onChange(values.floatValue);
//           else onChange(0);
//         }}
//         allowNegative={false}
//         decimalScale={2}
//         valueIsNumericString={true}
//         className="w-full h-full !outline-none rounded-[10px] px-3 py-3 text-center text-custom-size"
//         min={0}
//         thousandSeparator=","
//       />
//     </div>
//   );
// }

function InvoiceItemInfosInfoRow({ rowData, handleChangeRow }) {
  const handleChangeField = (field, newValue) => {
    handleChangeRow({ ...rowData, [field]: newValue });
  };
  return (
    <div className="w-full h-10 flex items-center pr-8 relative">
      <div className="w-[25.55%] h-full shrink-0">
        <div className="w-full h-full border border-[#B3B3B3] bg-[#EBEBEB] px-2 py-2.5 text-center">
          <p className="text-sm leading-[18px] tracking-[.2em] truncate">
            {rowData?.companyNameKanji}
          </p>
        </div>
      </div>
      <div className="w-[25.55%] h-full shrink-0">
        <div className="w-full h-full border border-[#B3B3B3] bg-[#EBEBEB] px-2 py-2.5 text-center">
          <p className="text-sm leading-[18px] tracking-[.2em] truncate">
            {rowData?.name}
          </p>
        </div>
      </div>
      <div className="w-[9.44%] h-full shrink-0">
        {/* <CellInputNumber
          value={rowData?.capacity}
          onChange={(newValue) => handleChangeField("capacity", newValue)}
          isReadonly={!rowData?.isShowEdit}
        /> */}
        {rowData?.isShowEdit ? (
          <CellInput
            value={rowData?.capacity}
            onChange={(newValue) =>
              handleChangeField("capacity", newValue)
            }
            className="text-end"
          />
        ) : (
          <div className="w-full h-full border border-[#B3B3B3] bg-[#EBEBEB] px-2 py-2.5 text-center">
            <p className="text-sm leading-[18px] tracking-[.2em] truncate">
              {rowData?.capacity}
            </p>
          </div>
        )}
      </div>

      <div className="w-[39.55%] h-full shrink-0">
        {rowData?.isShowEdit ? (
          <CellInput
            value={rowData?.preprocess}
            onChange={(newValue) =>
              handleChangeField("preprocess", newValue)
            }
          />
        ) : (
          <div className="w-full h-full border border-[#B3B3B3] bg-[#EBEBEB] px-2 py-2.5 text-center">
            <p className="text-sm leading-[18px] tracking-[.2em] truncate">
              {rowData?.preprocess}
            </p>
          </div>
        )}
      </div>

      <button
        type="button"
        onClick={() =>
          handleChangeField("isShowEdit", !rowData?.isShowEdit)
        }
        className="absolute top-1 right-0 w-8 h-8 flex items-center justify-center"
      >
        {rowData?.isShowEdit ? (
          <FontAwesomeIcon icon={faCheck} size="lg" />
        ) : (
          <img src={PencilSVG} className="" alt="" />
        )}
      </button>
    </div>
  );
}

function TestSampleList({ testSampleList, setTestSampleList }) {
  const handleChangeRow = (index, newRowData) => {
    const newData = [...testSampleList];
    newData[index] = newRowData;
    setTestSampleList(newData);
  };

  return (
    <div className="w-full h-auto overflow-auto mb-6">
      <div className="text-custom-size mb-4 ">試験サンプル一覧</div>
      <div className="w-full h-auto pr-8">
        <TableInfoHeader tableConfig={tableConfig} />
      </div>

      {Array.isArray(testSampleList) && testSampleList.length > 0 ? (
        testSampleList.map((rowData, index) => (
          <InvoiceItemInfosInfoRow
            key={index}
            index={index}
            tableConfig={tableConfig}
            rowData={rowData}
            handleChangeRow={(newValue) =>
              handleChangeRow(index, newValue)
            }
            isShowEdit={rowData?.isShowEdit}
          />
        ))
      ) : (
        <PageNoData />
      )}
    </div>
  );
}

export default TestSampleList;
