import React from "react";
import TableInfo from "../../CommonComponents/TableInfo";
import { getAddressFromComponent, getNameKanji } from "app/utils";

function CustomerPICDetail(props) {
  const { picInfo } = props;
  return (
    <TableInfo
      tableConfig={[
        {
          title: "項目",
          width: "25%",
          dataKey: "title",
        },
        {
          title: "詳細",
          width: "75%",
          dataKey: "value",
        },
      ]}
      tableData={[
        {
          title: "住所",
          value: getAddressFromComponent(picInfo) || "", // TODO FE-1
        },
        {
          title: "担当部署",
          value: picInfo?.department || "",
        },
        {
          title: "担当者名",
          value: getNameKanji(picInfo),
        },
        {
          title: "電話番号",
          value: picInfo?.phone1 || "",
        },
        {
          title: "FAX番号",
          value: picInfo?.fax || "",
        },
        {
          title: "メールアドレス",
          value: picInfo?.email || "",
        },
      ]}
    />
  );
}

export default CustomerPICDetail;
