import {
  invoiceStatus,
  invoiceStatusColor,
  invoiceStatusLabel,
  updateActionStatus,
  quotationStatus,
  quotationStatusColor,
  quotationStatusLabel,
  reportStatus,
  reportStatusColor,
  reportStatusLabel,
  requestFormStatus,
  requestFormStatusColor,
  requestFormStatusLabel,
  sampleStatus,
  sampleStatusColor,
  sampleStatusLabel,
  testPlanStatus,
  testPlanStatusColor,
  testPlanStatusLabel,
  DATE_TIME_FORMAT,
  fileNameType,
  DOCUMENT_TYPE,
} from "app/constants";
import {
  convertDateStringToJpFormat,
  defaultFormatNumberOfPeople,
  defaultFormatNumberOfSample,
} from "app/utils";
import React, { useCallback, useMemo, useState } from "react";
import ProjectTableInfo from "../ProjectTableInfo";
import InputText from "../../../UI/Form/Input/InputText";
import moment from "moment/moment";
import PrimaryBtn from "../../../UI/Button/PrimaryBtn";
import Popup from "../Popup";
import CaseStatusCell from "../TestScheduleTableInfo/CaseStatusCell";
import useCallApi from "../../../../pages/Project/useCallApi";
import UploadDocumentBtn from "./UploadDocumentBtn";

function TestScheduleItem({ item, scheduleNo, onUpdateSchedule }) {
  const [anchor, setAnchor] = useState(null);
  const [displayData, setDisplayData] = useState(item.caseInfos);
  const [currentPopupId, setCurrentPopupId] = useState();
  const [isOpenAgainModal, setIsOpenAgainModal] = useState(false);
  const [againComment, setAgainComment] = useState("");

  const updateData = (callback) => {
    onUpdateSchedule?.();
    setDisplayData(callback);
  };

  const {
    updateQuotation,
    updateRqForm,
    updateSampleStatus,
    updateTestPlanStatus,
    updateInvoiceStatus,
    getDownloadFile,
  } = useCallApi({
    setDisplayData: updateData,
    onClickReturn,
  });

  const handleClickPopup = useCallback(
    (event) => {
      setAnchor(anchor ? null : event.currentTarget);
    },
    [anchor]
  );

  const handleClickUpdateCell = useCallback(
    (e, id) => {
      setCurrentPopupId(id);
      e.preventDefault();
      handleClickPopup(e);
    },
    [handleClickPopup]
  );

  const onLeftClick = useCallback(
    (name, status, id, caseId, customer) => {
      const timeStamp = moment().format("YYYYMMDDHHmmss");
      const companyName = customer?.companyNameKanji || "";
      const filename = `${fileNameType[name]}_${caseId}_${companyName}_${timeStamp}`;
      if (name === "quotation") {
        if (
          status === quotationStatus.submitted ||
          status === quotationStatus.lost
        ) {
          return getDownloadFile(id, filename);
        }
      } else if (name === "requestForm") {
        if (
          status === requestFormStatus.confirmed ||
          status === requestFormStatus.rejected ||
          status === requestFormStatus.submitted
        ) {
          console.log("pdf requestForm =>>");
          return getDownloadFile(id, filename);
        }
      } else if (
        name === "testPlan" &&
        status === testPlanStatus.submitted
      ) {
        return getDownloadFile(id, filename);
      } else if (
        name === "report" &&
        status === reportStatus.submitted
      ) {
        console.log("pdf report =>>");
        return getDownloadFile(id, filename);
      }
    },
    [getDownloadFile]
  );

  const onDownloadInvoice = useCallback(
    ({ billId, deliveryId, caseId, customer }) => {
      const timeStamp = moment().format("YYYYMMDDHHmmss");
      const companyName = customer?.companyNameKanji || "";
      const filenameBill = `${fileNameType.invoice}_${caseId}_${companyName}_${timeStamp}`;
      const filenameDelivery = `${fileNameType.delivery}_${caseId}_${companyName}_${timeStamp}`;
      if (billId && billId !== "0") {
        getDownloadFile(billId, filenameBill);
      }
      if (deliveryId && deliveryId !== "0") {
        getDownloadFile(deliveryId, filenameDelivery);
      }
    },
    [getDownloadFile]
  );

  function onClickReturn() {
    setAnchor(null);
    setCurrentPopupId(null);
  }

  const tableData = useMemo(() => {
    return displayData.map((item, index) => ({
      caseId:
        item.reportInfo?.testId || item.quotationInfo?.quotationId,
      caseStatus: item.caseStatus,
      customerId: (
        <span className="px-1">
          {item?.customer?.customerId || ""}
        </span>
      ),
      quotation: (
        <div id={item?._id}>
          <button
            style={{
              color: quotationStatusColor[item.quotationStatus],
              cursor: "pointer",
            }}
            onClick={() =>
              onLeftClick(
                "quotation",
                item.quotationStatus,
                item.quotationInfo?.generatedExcel,
                item.reportInfo?.testId ||
                  item.quotationInfo?.quotationId,
                item.customer
              )
            }
            onContextMenu={(e) => {
              if (
                item.quotationStatus === quotationStatus.notStarted
              ) {
                return;
              }
              handleClickUpdateCell(e, `quotation-${item._id}`);
            }}
          >
            {quotationStatusLabel[item.quotationStatus]}
          </button>
          <Popup
            id={`quotation-${item._id}`}
            anchor={anchor}
            open={
              `quotation-${item._id}` === currentPopupId &&
              (item.quotationStatus === quotationStatus.lost ||
                item.quotationStatus === quotationStatus.submitted)
            }
          >
            {item.quotationStatus === quotationStatus.submitted && (
              <div className="flex gap-2">
                <UploadDocumentBtn
                  documentType={DOCUMENT_TYPE.QUOTATION}
                  caseInfoId={item?._id}
                  onCloseModal={onClickReturn}
                />
                <PrimaryBtn
                  text={updateActionStatus.lostOrder}
                  onClick={() =>
                    updateQuotation({
                      id: item._id,
                      index,
                      changeValue: quotationStatus.lost,
                    })
                  }
                />
                <PrimaryBtn
                  text={updateActionStatus.return}
                  onClick={onClickReturn}
                />
              </div>
            )}
            {item.quotationStatus === quotationStatus.lost && (
              <div className="flex flex-col gap-2">
                <p>
                  最終更新日:
                  {moment(
                    item.quotationInfo?.fileInfo?.updatedAt
                  ).format(DATE_TIME_FORMAT)}
                </p>
                <div className="flex gap-2">
                  <UploadDocumentBtn
                    documentType={DOCUMENT_TYPE.QUOTATION}
                    caseInfoId={item?._id}
                  />
                  <PrimaryBtn
                    text={updateActionStatus.recovery}
                    onClick={() =>
                      updateQuotation({
                        id: item._id,
                        index,
                        changeValue: quotationStatus.submitted,
                      })
                    }
                  />
                  <PrimaryBtn
                    text={updateActionStatus.return}
                    onClick={onClickReturn}
                  />
                </div>
              </div>
            )}
          </Popup>
        </div>
      ),
      requestForm: (
        <div>
          <button
            style={{
              color: requestFormStatusColor[item.requestFormStatus],
              cursor: "pointer",
            }}
            onClick={() => {
              if (!item.requestFormInfo) return;
              onLeftClick(
                "requestForm",
                item.requestFormStatus,
                item.requestFormInfo?.generatedPdf,
                item.reportInfo?.testId ||
                  item.quotationInfo?.quotationId,
                item.customer
              );
            }}
            onContextMenu={(e) => {
              if (
                [
                  requestFormStatus.confirmed,
                  requestFormStatus.rejected,
                  requestFormStatus.waiting,
                ].includes(item.requestFormStatus)
              ) {
                handleClickUpdateCell(e, `requestForm-${item._id}`);
              }
            }}
          >
            {requestFormStatusLabel[item.requestFormStatus]}
          </button>
          <Popup
            id={`requestForm-${item._id}`}
            anchor={anchor}
            open={
              `requestForm-${item._id}` === currentPopupId &&
              [
                requestFormStatus.confirmed,
                requestFormStatus.rejected,
                requestFormStatus.waiting,
              ].includes(item.requestFormStatus)
            }
          >
            {!isOpenAgainModal &&
              item.requestFormStatus ===
                requestFormStatus.confirmed && (
                <div className="flex flex-col gap-2">
                  <p>
                    最終更新日:
                    {moment(
                      item.requestFormInfo?.fileInfo?.updatedAt
                    ).format(DATE_TIME_FORMAT)}
                  </p>
                  <div className="flex gap-2">
                    <PrimaryBtn
                      text={updateActionStatus.approval}
                      onClick={() => {
                        // setCurrentPopupId(null);
                        updateRqForm({
                          id: item._id,
                          changeValue: requestFormStatus.submitted,
                          index,
                        });
                      }}
                    />
                    <PrimaryBtn
                      text={updateActionStatus.again}
                      onClick={() => {
                        setIsOpenAgainModal(true);
                      }}
                    />
                    <PrimaryBtn
                      text={updateActionStatus.waiting}
                      onClick={() => {
                        updateRqForm({
                          id: item._id,
                          changeValue: requestFormStatus.waiting,
                          data: {
                            emailAddress: item?.customer?.email,
                          },
                          index,
                        });
                      }}
                    />
                    <PrimaryBtn
                      text={updateActionStatus.return}
                      onClick={onClickReturn}
                    />
                  </div>
                </div>
              )}
            {!isOpenAgainModal &&
              item.requestFormStatus ===
                requestFormStatus.rejected && (
                <div className="flex flex-col gap-2">
                  <p>
                    最終更新日:
                    {moment(
                      item.requestFormInfo?.fileInfo?.updatedAt
                    ).format(DATE_TIME_FORMAT)}
                  </p>
                  <div className="flex gap-2">
                    <PrimaryBtn
                      text={updateActionStatus.confirmation}
                      onClick={() => {
                        updateRqForm({
                          id: item._id,
                          changeValue: requestFormStatus.confirmed,
                          index,
                        });
                      }}
                    />
                    <PrimaryBtn
                      text={updateActionStatus.waiting}
                      onClick={() => {
                        updateRqForm({
                          id: item._id,
                          changeValue: requestFormStatus.waiting,
                          data: {
                            emailAddress: item?.customer?.email,
                          },
                          index,
                        });
                      }}
                    />
                    <PrimaryBtn
                      text={updateActionStatus.return}
                      onClick={onClickReturn}
                    />
                  </div>
                </div>
              )}
            {!isOpenAgainModal &&
              item.requestFormStatus ===
                requestFormStatus.waiting && (
                <div className="flex flex-col gap-2">
                  <p>
                    最終更新日:
                    {moment(
                      item.requestFormInfo?.fileInfo?.updatedAt
                    ).format(DATE_TIME_FORMAT)}
                  </p>
                  <div className="flex gap-2">
                    <PrimaryBtn
                      text={updateActionStatus.confirmation}
                      onClick={() => {
                        updateRqForm({
                          id: item._id,
                          changeValue: requestFormStatus.confirmed,
                          index,
                        });
                      }}
                    />
                    <PrimaryBtn
                      text={updateActionStatus.again}
                      onClick={() => {
                        setIsOpenAgainModal(true);
                      }}
                    />
                    <PrimaryBtn
                      text={updateActionStatus.return}
                      onClick={onClickReturn}
                    />
                  </div>
                </div>
              )}
            {isOpenAgainModal && (
              <div>
                <div className="mb-2">再提出依頼 コメント</div>
                <InputText
                  onChange={(value) => {
                    setAgainComment(value);
                  }}
                />
                <div className="mt-2 flex justify-end gap-2">
                  <PrimaryBtn
                    text={"キャンセル"}
                    onClick={() => {
                      setIsOpenAgainModal(false);
                      setAgainComment("");
                    }}
                  />
                  <PrimaryBtn
                    text={"送信"}
                    onClick={() => {
                      setCurrentPopupId(null);
                      setIsOpenAgainModal(false);
                      setAgainComment("");
                      updateRqForm({
                        id: item._id,
                        changeValue: requestFormStatus.rejected,
                        data: {
                          comment: againComment,
                          emailAddress: item?.customer?.email,
                        },
                        index,
                      });
                    }}
                  />
                </div>
              </div>
            )}
          </Popup>
        </div>
      ),
      testPlan: (
        <div>
          <button
            style={{
              color: testPlanStatusColor[item.testPlanStatus],
              cursor: "pointer",
            }}
            onClick={() =>
              onLeftClick(
                "testPlan",
                item.testPlanStatus,
                item.testPlanInfo?.generatedWord,
                item.reportInfo?.testId ||
                  item.quotationInfo?.quotationId,
                item.customer
              )
            }
            onContextMenu={(e) => {
              handleClickUpdateCell(e, `testPlan-${item._id}`);
            }}
          >
            {testPlanStatusLabel[item.testPlanStatus]}
          </button>
          <Popup
            id={`testPlan-${item._id}`}
            anchor={anchor}
            open={currentPopupId === `testPlan-${item._id}`}
          >
            <>
              {item.testPlanStatus === testPlanStatus.submitted && (
                <div className="flex flex-col gap-2">
                  <p>
                    最終更新日:
                    {moment(
                      item.testPlanInfo?.fileInfo?.updatedAt
                    ).format(DATE_TIME_FORMAT)}
                  </p>
                  <div className="flex gap-2">
                    <UploadDocumentBtn
                      documentType={DOCUMENT_TYPE.TEST_PLAN}
                      caseInfoId={item?._id}
                      onCloseModal={onClickReturn}
                    />
                    <PrimaryBtn
                      text={updateActionStatus.clear}
                      onClick={() =>
                        updateTestPlanStatus({
                          id: item._id,
                          changeValue: testPlanStatus.none,
                          index,
                        })
                      }
                    />
                    <PrimaryBtn
                      text={updateActionStatus.return}
                      onClick={onClickReturn}
                    />
                  </div>
                </div>
              )}
              {item.testPlanStatus === testPlanStatus.notStarted && (
                <div className="flex gap-2 mx-auto">
                  <UploadDocumentBtn
                    documentType={DOCUMENT_TYPE.TEST_PLAN}
                    caseInfoId={item?._id}
                    onCloseModal={onClickReturn}
                  />
                  <PrimaryBtn
                    text={updateActionStatus.clear}
                    onClick={() =>
                      updateTestPlanStatus({
                        id: item._id,
                        changeValue: testPlanStatus.none,
                        index,
                      })
                    }
                  />
                  <PrimaryBtn
                    text={updateActionStatus.return}
                    onClick={onClickReturn}
                  />
                </div>
              )}
              {item.testPlanStatus === testPlanStatus.none && (
                <div className="flex gap-2 mx-auto">
                  <PrimaryBtn
                    text={updateActionStatus.notStarted}
                    onClick={() =>
                      updateTestPlanStatus({
                        id: item._id,
                        changeValue: testPlanStatus.notStarted,
                        index,
                      })
                    }
                  />
                  <PrimaryBtn
                    text={updateActionStatus.return}
                    onClick={onClickReturn}
                  />
                </div>
              )}
            </>
          </Popup>
        </div>
      ),
      sampleStatus: (
        <div>
          <button
            style={{
              color: sampleStatusColor[item.sampleStatus],
              cursor: "pointer",
            }}
            onContextMenu={(e) => {
              if (item.sampleStatus === sampleStatus.submitted)
                return;
              handleClickUpdateCell(e, `sampleStatus-${item._id}`);
            }}
          >
            {sampleStatusLabel[item.sampleStatus]}
          </button>
          <Popup
            id={`sampleStatus-${item._id}`}
            anchor={anchor}
            open={
              currentPopupId === `sampleStatus-${item._id}` &&
              [
                sampleStatus.confirmed,
                sampleStatus.notStarted,
                sampleStatus.rejected,
              ].includes(item.sampleStatus)
            }
          >
            {item.sampleStatus === sampleStatus.notStarted && (
              <div className="flex flex-col gap-2">
                <p>
                  最終更新日:
                  {item?.updateSampleTime
                    ? moment(item?.updateSampleTime).format(
                        DATE_TIME_FORMAT
                      )
                    : null}
                </p>
                <div className="flex gap-2 mx-auto">
                  <PrimaryBtn
                    text={updateActionStatus.confirmation}
                    onClick={() =>
                      updateSampleStatus({
                        id: item._id,
                        changeValue: sampleStatus.confirmed,
                        index,
                      })
                    }
                  />
                  <PrimaryBtn
                    text={updateActionStatus.return}
                    onClick={onClickReturn}
                  />
                </div>
              </div>
            )}
            {item.sampleStatus === sampleStatus.confirmed && (
              <div className="flex flex-col gap-2">
                <p>
                  最終更新日:{" "}
                  {moment(item?.updateSampleTime).format(
                    DATE_TIME_FORMAT
                  )}
                </p>
                <div className="flex gap-2">
                  <PrimaryBtn
                    text={updateActionStatus.approval}
                    onClick={() =>
                      updateSampleStatus({
                        id: item._id,
                        changeValue: sampleStatus.submitted,
                        index,
                      })
                    }
                  />
                  <PrimaryBtn
                    text={updateActionStatus.reject}
                    onClick={() =>
                      updateSampleStatus({
                        id: item._id,
                        changeValue: sampleStatus.rejected,
                        index,
                      })
                    }
                  />
                  <PrimaryBtn
                    text={updateActionStatus.return}
                    onClick={onClickReturn}
                  />
                </div>
              </div>
            )}
            {item.sampleStatus === sampleStatus.rejected && (
              <div className="flex flex-col gap-2">
                <p>
                  最終更新日:
                  {moment(item?.updateSampleTime).format(
                    DATE_TIME_FORMAT
                  )}
                </p>
                <div className="flex gap-2 mx-auto">
                  <PrimaryBtn
                    text={updateActionStatus.confirmation}
                    onClick={() =>
                      updateSampleStatus({
                        id: item._id,
                        changeValue: sampleStatus.confirmed,
                        index,
                      })
                    }
                  />
                  <PrimaryBtn
                    text={updateActionStatus.return}
                    onClick={onClickReturn}
                  />
                </div>
              </div>
            )}
          </Popup>
        </div>
      ),
      report: (
        <div>
          <button
            style={{
              color: reportStatusColor[item.reportStatus],
              cursor: "pointer",
            }}
            onClick={() =>
              onLeftClick(
                "report",
                item.reportStatus,
                item.reportInfo?.generatedPdf,
                item.reportInfo?.testId ||
                  item.quotationInfo?.quotationId,
                item.customer
              )
            }
            onContextMenu={(e) => {
              if (
                ![
                  reportStatus.submitted,
                  reportStatus.notStarted,
                ].includes(item.reportStatus)
              ) {
                return;
              }
              handleClickUpdateCell(e, `report-${item._id}`);
            }}
          >
            {reportStatusLabel[item.reportStatus]}
          </button>
          <Popup
            id={`report-${item._id}`}
            anchor={anchor}
            open={
              `report-${item._id}` === currentPopupId &&
              [
                reportStatus.submitted,
                reportStatus.notStarted,
              ].includes(item.reportStatus)
            }
          >
            {item.reportStatus === reportStatus.submitted && (
              <div className="flex flex-col gap-2">
                <p>
                  最終更新日:
                  {moment(
                    item.testPlanInfo?.fileInfo?.updatedAt
                  ).format(DATE_TIME_FORMAT)}
                </p>
                <div className="flex gap-2">
                  <UploadDocumentBtn
                    documentType={DOCUMENT_TYPE.REPORT}
                    caseInfoId={item?._id}
                    onCloseModal={onClickReturn}
                  />
                  <PrimaryBtn
                    text={updateActionStatus.return}
                    onClick={onClickReturn}
                  />
                </div>
              </div>
            )}
            {item.reportStatus === reportStatus.notStarted && (
              <div className="flex flex-col gap-2">
                <div className="flex gap-2">
                  <UploadDocumentBtn
                    documentType={DOCUMENT_TYPE.REPORT}
                    caseInfoId={item?._id}
                    onCloseModal={onClickReturn}
                  />
                  <PrimaryBtn
                    text={updateActionStatus.return}
                    onClick={onClickReturn}
                  />
                </div>
              </div>
            )}
          </Popup>
        </div>
      ),
      invoice: (
        <div>
          <button
            style={{
              color: invoiceStatusColor[item.invoiceStatus],
              cursor: "pointer",
            }}
            onClick={() => {
              if (item.invoiceStatus === invoiceStatus.submitted) {
                return onDownloadInvoice({
                  billId: item.invoiceInfo?.generatedInvoiceExcel,
                  deliveryId:
                    item.invoiceInfo?.generatedDeliverySlipExcel,
                  caseId:
                    item.reportInfo?.testId ||
                    item.quotationInfo?.quotationId,
                  customer: item.customer,
                });
              }
            }}
            onContextMenu={(e) => {
              handleClickUpdateCell(e, `invoice-${item._id}`);
            }}
          >
            {invoiceStatusLabel[item.invoiceStatus]}
          </button>
          <Popup
            id={`invoice-${item._id}`}
            anchor={anchor}
            open={`invoice-${item._id}` === currentPopupId}
          >
            {item.invoiceStatus === invoiceStatus.submitted && (
              <div className="flex flex-col">
                <div className="flex flex-col gap-2">
                  <p>
                    最終更新日:
                    {moment(
                      item.invoiceInfo?.fileInfo?.updatedAt
                    ).format(DATE_TIME_FORMAT)}
                  </p>
                  <div className="flex gap-2">
                    <UploadDocumentBtn
                      documentType={DOCUMENT_TYPE.INVOICE}
                      caseInfoId={item?._id}
                      onCloseModal={onClickReturn}
                      text="請求書を選択"
                    />
                    <UploadDocumentBtn
                      documentType={DOCUMENT_TYPE.INVOICE_DELIVERY}
                      caseInfoId={item?._id}
                      onCloseModal={onClickReturn}
                      text="納品書を選択"
                    />
                    <PrimaryBtn
                      text={updateActionStatus.return}
                      onClick={onClickReturn}
                    />
                  </div>
                </div>
              </div>
            )}
            {item.invoiceStatus === invoiceStatus.notStarted && (
              <div className="flex gap-2 mx-auto">
                <UploadDocumentBtn
                  documentType={DOCUMENT_TYPE.INVOICE}
                  caseInfoId={item?._id}
                  onCloseModal={onClickReturn}
                  text="請求書を選択"
                />
                <UploadDocumentBtn
                  documentType={DOCUMENT_TYPE.INVOICE_DELIVERY}
                  caseInfoId={item?._id}
                  onCloseModal={onClickReturn}
                  text="納品書を選択"
                />
                <PrimaryBtn
                  text={updateActionStatus.clear}
                  onClick={() =>
                    updateInvoiceStatus({
                      id: item._id,
                      changeValue: invoiceStatus.none,
                      index,
                    })
                  }
                />
                <PrimaryBtn
                  text={updateActionStatus.return}
                  onClick={onClickReturn}
                />
              </div>
            )}
            {item.invoiceStatus === invoiceStatus.none && (
              <div className="flex gap-2 mx-auto">
                <PrimaryBtn
                  text={updateActionStatus.notStarted}
                  onClick={() =>
                    updateInvoiceStatus({
                      id: item._id,
                      changeValue: invoiceStatus.notStarted,
                      index,
                    })
                  }
                />
                <PrimaryBtn
                  text={updateActionStatus.return}
                  onClick={onClickReturn}
                />
              </div>
            )}
          </Popup>
        </div>
      ),
      samplePeopleNumber: defaultFormatNumberOfPeople(
        item.samplePeopleNumber
      ),
      sampleNumber: defaultFormatNumberOfSample(item.sampleNumber),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    anchor,
    currentPopupId,
    displayData,
    handleClickUpdateCell,
    onDownloadInvoice,
    onLeftClick,
    updateQuotation,
    updateRqForm,
    updateSampleStatus,
  ]);

  return (
    <div className="overflow-x-auto mt-8">
      <p
        id={item.caseId}
        className="text-custom-size text-green mb-3"
      >
        スケジュール{scheduleNo}：
        {convertDateStringToJpFormat(item.testDate)}
      </p>

      <div>
        <ProjectTableInfo
          tableConfig={[
            {
              title: "案件ID",
              width: "11.74%",
              dataKey: "caseId",
              textAlign: "center",
            },
            {
              title: "見積り",
              width: "7.47%",
              dataKey: "quotation",
              textAlign: "center",
            },
            {
              title: "依頼書",
              width: "7.47%",
              dataKey: "requestForm",
              textAlign: "center",
            },
            {
              title: "計画書",
              width: "7.47%",
              dataKey: "testPlan",
              textAlign: "center",
            },
            {
              title: "サンプル",
              width: "8%",
              dataKey: "sampleStatus",
              textAlign: "center",
            },
            {
              title: "報告書",
              width: "7.47%",
              dataKey: "report",
              textAlign: "center",
            },
            {
              title: "請求書",
              width: "7.47%",
              dataKey: "invoice",
              textAlign: "center",
            },
            {
              title: "顧客ID",
              width: "22.41%",
              dataKey: "customerId",
              textAlign: "center",
            },
            {
              title: "人数",
              width: "6.4%",
              dataKey: "samplePeopleNumber",
              textAlign: "center",
            },
            {
              title: "検体数",
              width: "6.4%",
              dataKey: "sampleNumber",
              textAlign: "center",
            },
            {
              title: "状況",
              width: "7.68%",
              dataKey: "caseStatus",
              textAlign: "center",
              CustomTableCell: CaseStatusCell,
            },
          ]}
          tableData={tableData}
        />
      </div>
    </div>
  );
}

export default TestScheduleItem;
