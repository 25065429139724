import { useRef } from "react";
import style from "./style.module.scss";
import { v4 as uuidv4 } from "uuid";

export default function Select({ options, name, wrapperClassname, ...rest }) {
  const selectRef = useRef(null);

  return (
    <div
      className={`${style.wrapper} bg-background-select flex border border-[#B3B3B3] rounded-[10px] overflow-hidden ${wrapperClassname}`}
    >
      <select
        ref={selectRef}
        {...rest}
        className="bg-transparent m-[0.8125em] flex-1"
        name={name}
      >
        {options?.map(({ value, label }) => {
          return (
            <option key={value} value={value}>
              {label}
            </option>
          );
        })}
      </select>
      <div
        onClick={() => {
          selectRef?.current?.focus();
        }}
        className="border-l bg-[#6C6C6C] border-[#B3B3B3] px-2 center-flex"
      >
        <div className="h-[1.25em] text-[#D9D9D9]">&#9660;</div>
      </div>
    </div>
  );
}
