/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { socketConfig } from "app/services/socket";
import ContentLayout from "./Components/ContentLayout";
import MessageCard from "./Components/MessageCard";
import Button from "app/components/UI/Button";
import { customerServices } from "app/services/api";
import {
  ADMIN_CHAT_ID,
  DEFAULT_MSG_NUMBER_PER_PAGE,
} from "../../constants";
import moment from "moment/moment";
import ChatBox from "./Components/ChatBox";
import { useSearchParams } from "react-router-dom";
import useVerifyToken from "../../hooks/useVerifyToken";

function ChatContent({ socket }) {
  const [searchParams] = useSearchParams();
  const [msgList, setMsgList] = useState([]);
  const [selectingId, setSelectingId] = useState(
    searchParams.get("id")
  );
  const [listContact, setListContact] = useState([]);
  const textAreaRef = useRef();
  const msgListRef = useRef(null);
  // Infinite Scroll's state
  const [listCusPage, setListCusPage] = useState(1);
  const [hasMoreCustomer, setHasMoreCustomer] = useState(true);
  // Need clear before select another
  const [isLoadingMoreMsg, setIsLoadingMoreMsg] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [prevHeight, setPrevHeight] = useState(0);
  const [msgVal, setMsgVal] = useState("");

  //Function

  const scrollBottom = () => {
    const msgListEl = document.getElementById("msg-list-id");
    msgListEl.scrollTo(0, msgListEl.scrollHeight);
  };

  const scrollToKeepCurrentPosition = () => {
    const msgListEl = document.getElementById("msg-list-id");
    const scrollDistance =
      msgListRef?.current?.offsetHeight - prevHeight;
    setPrevHeight(msgListRef?.current?.offsetHeight);
    msgListEl.scrollTo(0, scrollDistance);
  };

  const clearStateWhenChangeObjId = () => {
    setIsLoadingMoreMsg(false);
    setOffset(0);
    setHasMore(false);
    setPrevHeight(0);
    setMsgVal("");
  };

  const handleSendMsg = () => {
    if (selectingId && msgVal) {
      socket?.emit("message", {
        message: msgVal.replaceAll("\n", "<br/>"),
        toId: selectingId,
        fromId: ADMIN_CHAT_ID,
      });
      textAreaRef.current.focus();
    }
  };

  const handleScrollMsgList = (e) => {
    if (e?.target.scrollTop === 0 && hasMore) {
      setOffset((prev) => prev + 1);
      setIsLoadingMoreMsg(true);
      socket?.emit("load-more-msg", {
        toId: selectingId,
        fromId: ADMIN_CHAT_ID,
        offset: offset + 1,
        perPage: DEFAULT_MSG_NUMBER_PER_PAGE,
      });
    }
  };

  // Hook
  function onConnect() {
    console.log("Socket connected");
  }
  function onDisconnect() {
    console.log("Socket disconnected");
  }
  function onInitMsgEvent(value) {
    console.log("Join Room");
    const { prevMsg, hasMore } = value;
    setHasMore(hasMore);
    const formattedList = prevMsg?.reverse().map((msgItem, index) => {
      const { message, fromId, toId, _id, createdAt } = msgItem || {};
      return {
        content: message,
        isIncomingMsg: fromId !== ADMIN_CHAT_ID,
        _id,
        createdAt,
        fromId,
        toId,
      };
    });
    setMsgList(formattedList);
  }
  function onLoadMoreMsgEvent(value) {
    const { prevMsg, hasMore } = value;
    setHasMore(hasMore);
    const formattedList = prevMsg?.reverse().map((msgItem, index) => {
      const { message, fromId, toId, _id, createdAt } = msgItem || {};
      return {
        content: message,
        isIncomingMsg: fromId !== ADMIN_CHAT_ID,
        _id,
        createdAt,
        fromId,
        toId,
      };
    });
    setMsgList((prev) => {
      return [...formattedList, ...prev];
    });
  }

  function onSendSuccessEvent(value) {
    const { message, fromId, toId, createdAt, _id } = value || {};
    const isIncomingMsg = fromId !== ADMIN_CHAT_ID;
    setMsgList((prev) => [
      ...prev,
      {
        content: message,
        isIncomingMsg,
        createdAt,
        _id,
        fromId,
        toId,
      },
    ]);
    setMsgVal("");
  }

  useEffect(() => {
    socket?.on("connect", onConnect);
    socket?.on("send-success-message", onSendSuccessEvent);
    socket?.on("load-more-msg", onLoadMoreMsgEvent);
    socket?.on("join-room", onInitMsgEvent);
    socket?.on("disconnect", onDisconnect);
    return () => {
      socket?.off("connect", onConnect);
      socket?.off("disconnect", onDisconnect);
      socket?.off("join-room", onInitMsgEvent);
      socket?.off("send-success-message", onSendSuccessEvent);
      socket?.off("load-more-msg", onLoadMoreMsgEvent);
    };
  }, [socket]);

  useEffect(() => {
    if (selectingId) {
      socket?.emit("join-room", {
        toId: selectingId,
        fromId: ADMIN_CHAT_ID,
        offset: 0,
        perPage: DEFAULT_MSG_NUMBER_PER_PAGE,
      });
    }
    clearStateWhenChangeObjId();
    if (selectingId) {
      window.history.replaceState(
        null,
        null,
        `/chat?id=${selectingId}`
      );
    } else {
      window.history.replaceState(null, null, `/chat`);
    }
  }, [selectingId]);

  useEffect(() => {
    customerServices
      .search(listCusPage)
      .then((res) => {
        const { currentPage, totalPages } = res?.data || {};
        if (currentPage >= totalPages) {
          setHasMoreCustomer(false);
        }
        setListContact((prev) => [
          ...prev,
          ...res?.data?.rows
            .filter(({ _id }) => _id !== ADMIN_CHAT_ID)
            .map(({ id: _id, companyNameKanji: firstNameKanji, customerId }) => {
              return {
                _id,
                info: {
                  firstNameKanji,
                  lastNameKanji: "",
                  customerId
                },
              };
            }),
        ]);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [listCusPage]);

  useEffect(() => {
    if (isLoadingMoreMsg) {
      scrollToKeepCurrentPosition();
    } else {
      scrollBottom();
      setPrevHeight(msgListRef?.current?.offsetHeight);
    }

    setIsLoadingMoreMsg(false);
  }, [msgList]);

  const formatedMsgList = useMemo(() => {
    // check duplicate msg and rearrange
    const result = [];
    msgList.forEach((item) => {
      const { _id, fromId, toId } = item || {};
      const isCurrentObject =
        fromId === selectingId || toId === selectingId;
      if (
        result.every(({ _id: resId }) => {
          return resId !== _id;
        }) &&
        isCurrentObject
      ) {
        result.push(item);
      }
    });
    return result.sort(({ createdAt: a }, { createdAt: b }) => {
      return moment(a).diff(moment(b), "miliseconds");
    });
  }, [msgList, selectingId]);

  const getCurrentCoName = useMemo(() => {
    const { info } =
      listContact?.find(({ _id }) => selectingId === _id) || {};

      console.log('info', info)
    if (info) {
      return info?.customerId + info?.lastNameKanji;
    }
    return;
  }, [selectingId]);

  const onLoadMoreCustomer = () => {
    setListCusPage((prev) => prev + 1);
  };

  return (
    <ContentLayout
      selectingId={selectingId}
      onSelect={setSelectingId}
      listContact={listContact}
      onLoadMore={onLoadMoreCustomer}
      hasMore={hasMoreCustomer}
    >
      <div className="h-full flex flex-col flex-1 gap-[1.3em]">
        <div className="w-full px-4 min-h-[40px] py-2 bg-[#001C4F] text-white">
          {getCurrentCoName}
        </div>
        <div
          onScroll={handleScrollMsgList}
          id="msg-list-id"
          className="flex-1 overflow-y-auto"
        >
          <div ref={msgListRef} className="flex flex-col gap-4">
            {formatedMsgList?.map(
              ({ isIncomingMsg, content, _id }) => {
                return (
                  <MessageCard
                    key={_id}
                    isIncomingMsg={isIncomingMsg}
                    content={content}
                  />
                );
              }
            )}
          </div>
        </div>
        {selectingId && (
          <div className="flex-[0_0_82px]">
            <ChatBox
              textAreaRef={textAreaRef}
              msgVal={msgVal}
              setMsgVal={setMsgVal}
              handleSendMsg={handleSendMsg}
            />
            <div className="flex justify-end gap-4 mt-2">
              <Button type="danger">クリア</Button>
              <Button onClick={handleSendMsg}>送信する</Button>
            </div>
          </div>
        )}
      </div>
    </ContentLayout>
  );
}

export default function ChatPage() {
  const [socket, setSocket] = useState();

  useVerifyToken();

  useEffect(() => {
    setSocket(socketConfig());
  }, []);
  if (socket) {
    return <ChatContent socket={socket} />;
  }
}
