import { useRef } from "react";

export default function InfiniteScroll({ list, render, offset = 0, onLoadMore, hasMore }) {
  let timeoutFnc;
  const contentRef = useRef(null);
  return (
    <div
      onScroll={(e) => {
        const { scrollTop, offsetHeight } = e?.target || {};
        if (scrollTop + offsetHeight + offset >= contentRef?.current?.offsetHeight && typeof onLoadMore === "function" && hasMore) {
          clearTimeout(timeoutFnc);
          timeoutFnc = setTimeout(() => {
            onLoadMore();
          }, 200);
        }
      }}
      className="overflow-y-auto h-full">
      <div ref={contentRef}>
        {list?.map((item) => {
          return render(item);
        })}
      </div>
    </div>
  );
}
