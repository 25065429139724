import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import PageLoading from "app/components/CommonPage/PageLoading";
import PageNoData from "app/components/CommonPage/PageNoData";
import PageError from "app/components/CommonPage/PageError";
import AllCaseTable from "app/components/Table/Dashboard/AllCaseTable";
import AllTextScheduleTable from "app/components/Table/Dashboard/AllTextScheduleTable";

import { dashboardServices } from "app/services/api";
import messages from "app/services/api/messages";
import routePaths from "app/route/routePaths";

function AllTestSchedule({ loadInitState }) {
  if (loadInitState.isLoading) {
    return <PageLoading />;
  }
  if (loadInitState.error) {
    return <PageError />;
  }
  if (
    Array.isArray(loadInitState.data?.allTestScheduleList) &&
    loadInitState.data?.allTestScheduleList.length === 0
  ) {
    return <PageNoData />;
  }
  if (
    Array.isArray(loadInitState.data?.allTestScheduleList) &&
    loadInitState.data?.allTestScheduleList.length > 0
  ) {
    const sortedList = loadInitState.data?.allTestScheduleList.sort(
      (a, b) => {
        const date1 = new Date(a.testDate);
        const date2 = new Date(b.testDate);
        return date2 - date1;
      }
    );
    return <AllTextScheduleTable list={sortedList} />;
  }
  return null;
}

function RecentCase({ loadInitState }) {
  if (loadInitState.isLoading) {
    return <PageLoading />;
  }
  if (loadInitState.error) {
    return <PageError />;
  }
  if (
    Array.isArray(loadInitState.data?.recentCaseList) &&
    loadInitState.data?.recentCaseList.length === 0
  ) {
    return <PageNoData />;
  }
  if (
    Array.isArray(loadInitState.data?.recentCaseList) &&
    loadInitState.data?.recentCaseList.length > 0
  ) {
    return <AllCaseTable list={loadInitState.data?.recentCaseList} />;
  }
  return null;
}

function NextCase({ loadInitState }) {
  if (loadInitState.isLoading) {
    return <PageLoading />;
  }
  if (loadInitState.error) {
    return <PageError />;
  }
  if (
    Array.isArray(loadInitState.data?.nextCaseList) &&
    loadInitState.data?.nextCaseList.length === 0
  ) {
    return <PageNoData />;
  }
  if (
    Array.isArray(loadInitState.data?.nextCaseList) &&
    loadInitState.data?.nextCaseList.length > 0
  ) {
    return <AllCaseTable list={loadInitState.data?.nextCaseList} />;
  }
  return null;
}

function CaseInfo({ loadInitState }) {
  return (
    <div className="w-full h-auto md:h-[56%] flex flex-wrap">
      <div className="w-full h-auto md:w-1/2 md:h-full md:pr-4">
        <div className="w-full h-full pt-6 pb-4">
          <div className="w-full h-10 pb-3 flex items-center">
            <p className="text-custom-size text-[#08978E]">
              直近の試験状況
            </p>
          </div>
          <div className="w-full h-[calc(100%-40px)]">
            <RecentCase loadInitState={loadInitState} />
          </div>
        </div>
      </div>

      <div className="w-full h-auto md:w-1/2 md:h-full">
        <div className="w-full h-full pt-6 pb-4">
          <div className="w-full h-10 pb-3 flex items-center">
            <p className="text-custom-size text-[#08978E]">
              次回の試験状況
            </p>
          </div>
          <div className="w-full h-[calc(100%-40px)]">
            <NextCase loadInitState={loadInitState} />
          </div>
        </div>
      </div>
    </div>
  );
}

function Dashboard() {
  const [loadInitState, setLoadInitState] = useState({
    isLoading: true,
    data: null,
    error: null,
  });

  const loadInit = useCallback(() => {
    setLoadInitState({ isLoading: true, data: null, error: null });
    dashboardServices
      .getAllTestScheduleList()
      .then((res) => {
        setLoadInitState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        toast.error(messages.common_error);
        setLoadInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, []);

  useEffect(() => loadInit(), [loadInit]);

  return (
    <div className="custom-container w-full h-[calc(100vh-136px)] overflow-y-auto">
      <div className="w-full h-auto md:h-[44%]">
        <div className="w-full h-full pt-10">
          <div className="w-full h-10 flex items-end justify-between pb-3">
            <p className="text-custom-size">試験スケジュール</p>

            <Link
              to={routePaths.topMenu}
              className="w-auto h-10 px-3 rounded-[10px] flex items-center bg-[#001C4F] text-white text-[13px]"
            >
              トップへ戻る
            </Link>
          </div>
          <div className="w-full h-[calc(100%-40px)] overflow-x-auto">
            <AllTestSchedule loadInitState={loadInitState} />
          </div>
        </div>
      </div>

      <CaseInfo loadInitState={loadInitState} />
    </div>
  );
}

export default Dashboard;
