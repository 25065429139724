import React, { useState, useMemo } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { useMediaQuery } from "@mui/material";
import InputText from "app/components/UI/Form/Input/InputText";
import SelectPicStaff from "app/components/UI/Form/Select/SelectPicStaff";
import PrimaryBtn from "app/components/UI/Button/PrimaryBtn";
import {
  callApiStatus,
  fileNameType,
  LABEL_WIDTH,
} from "app/constants";
import PageLoading from "app/components/CommonPage/PageLoading";
import PageError from "app/components/CommonPage/PageError";
import { customerServices, testPlanServices } from "app/services/api";
import messages from "../../../services/api/messages";
import CustomerTableInfo from "../../../components/Table/TestPlan/CustomerTableInfo";
import EditBtn from "../../../components/UI/Button/EditBtn";
import PlanTableInfo from "../../../components/Table/TestPlan/PlanTableInfo";
import Button from "../../../components/UI/Button";
import PageLoadingBackdrop from "../../../components/CommonPage/PageLoadingBackdrop";
import { getAddressFromComponent } from "../../../utils";
import useCallApi from "../useCallApi";
import tableInfo from "./tableInfo";

const { customerConfig, labConfig } = tableInfo;

function CreatePlanForm({
  labTable,
  materialTable,
  customerTable,
  onChangeCustomer,
  onChangeLab,
  onChangeMaterial,
  handleClickEditCus,
  handleClickEditLab,
  handleClickEditMaterial,
  createTestPlan,
  downloadPdf,
  confirmedData,
}) {
  const isDisabledSubmit = useMemo(
    () => !confirmedData,
    [confirmedData]
  );
  return (
    <>
      <div className="mt-[7px]">
        <p className="text-[15px] mb-[17px] tracking-[2px]">
          試験計画書の内容
        </p>

        <CustomerTableInfo
          id="customer"
          key="customer"
          tableConfig={[
            {
              title: "項目",
              width: "30%",
              textAlign: "left",
            },
            {
              title: "詳細",
              width: "67%",
              textAlign: "left",
            },
            {
              title: "",
              width: "3%",
              textAlign: "right",
              isHeaderBlank: true,
              CustomTableCell: EditBtn,
              style: { justifyContent: "right" },
              handleEdit: onChangeCustomer,
            },
          ]}
          tableData={customerTable}
          onClickEdit={handleClickEditCus}
          handleChange={onChangeCustomer}
        />
      </div>
      <div className="mt-[22px]">
        <p className="text-[15px] mb-[17px] tracking-[2px]">
          試験委託機関の名称
        </p>

        <CustomerTableInfo
          id="lab"
          key="lab"
          tableConfig={[
            {
              title: "項目",
              width: "30%",
              textAlign: "left",
            },
            {
              title: "詳細",
              width: "67%",
              textAlign: "left",
            },
            {
              title: "",
              width: "3%",
              textAlign: "right",
              isHeaderBlank: true,
              CustomTableCell: EditBtn,
              style: { justifyContent: "right" },
            },
          ]}
          tableData={labTable}
          onClickEdit={handleClickEditLab}
          handleChange={onChangeLab}
        />
      </div>

      <div className="mt-[22px]">
        <p className="text-[15px] mb-[17px] tracking-[2px]">
          被験物質の概要
        </p>

        <PlanTableInfo
          tableConfig={[
            {
              title: "名称",
              width: "30%",
              dataKey: "name",
              textAlign: "left",
            },
            {
              title: "前処理",
              width: "67%",
              dataKey: "preprocess",
              textAlign: "left",
            },
            {
              title: "",
              width: "3%",
              textAlign: "right",
              isHeaderBlank: true,
              CustomTableCell: EditBtn,
              style: { justifyContent: "center" },
            },
          ]}
          tableData={materialTable}
          handleChange={onChangeMaterial}
          onClickEdit={handleClickEditMaterial}
        />
      </div>
      <div className="ml-auto flex gap-[13px] justify-end mt-[19px]">
        <PrimaryBtn
          text="確認する"
          key="確認する"
          onClick={downloadPdf}
        />
        <Button
          disabled={isDisabledSubmit}
          key="提出する"
          onClick={createTestPlan}
          type={isDisabledSubmit ? "disabled" : "primary"}
        >
          提出する
        </Button>
      </div>
    </>
  );
}

export default function CreatePlan({
  quotationInfo,
  customer,
  schedule,
}) {
  const isMd = useMediaQuery("(max-width:768px)");

  const [loadInitState, setLoadInitState] = useState({
    loadingStatus: callApiStatus.idle,
    data: null,
    error: null,
    planInfoExist: true,
  });
  const [isCreated, setIsCreated] = useState(false);

  const [quota, setQuota] = useState(quotationInfo);

  const [customerTable, setCustomerTable] = useState();
  const [labTable, setLabTable] = useState();
  const [materialTable, setMaterialTable] = useState();
  const [confirmedData, setConfirmedData] = useState(null);

  const { create, onConfirmPDF, isLoading } = useCallApi({
    customerTable,
    labTable,
    materialTable,
    confirmedData,
    setConfirmedData,
    customerId: customer?.id,
  });

  const sendData = {
    customerId: customer.id,
    testSchedule: schedule.id,
    personInCharge: quota.picStaff,
    planId: loadInitState.data?.planId,
    quotationId: quotationInfo.id,
  };

  const doneTableEdit = () => {
    setLabTable((oldList) =>
      oldList.map((l) =>
        l.showInput ? { ...l, showInput: false } : l
      )
    );
    setCustomerTable((oldList) =>
      oldList.map((l) =>
        l.showInput ? { ...l, showInput: false } : l
      )
    );
    setMaterialTable((oldList) =>
      oldList.map((l) =>
        l.showInput ? { ...l, showInput: false } : l
      )
    );
  };

  const createPdf = async () => {
    doneTableEdit();
    const timeStamp = moment().format("YYYYMMDDHHmmss");
    const fileName = `${fileNameType.testPlan}_${
      quotationInfo.quotationId
    }_${customer?.companyNameKanji || ""}_${timeStamp}`;
    return onConfirmPDF(sendData, fileName, false);
  };

  const createTestPlan = () => {
    doneTableEdit();
    create(sendData);
    setIsCreated(true);
  };

  const handleClickEditCus = (dataKey) => {
    const newCus = customerTable.map((c) => ({
      ...c,
      showInput: c.dataKey === dataKey ? !c.showInput : c.showInput,
    }));
    setCustomerTable(newCus);
  };

  const onChangeCustomer = (name, value) => {
    const newCus = customerTable.map((c) => ({
      ...c,
      detail: c.dataKey === name ? value : c.detail,
    }));
    setCustomerTable(newCus);
  };

  const handleClickEditLab = (dataKey) => {
    const newCus = labTable.map((l) => ({
      ...l,
      showInput: l.dataKey === dataKey ? !l.showInput : l.showInput,
    }));
    setLabTable(newCus);
  };

  const onChangeLab = (name, value) => {
    const newLab = labTable.map((l) => ({
      ...l,
      detail: l.dataKey === name ? value : l.detail,
    }));
    setLabTable(newLab);
  };

  const onChangeMaterial = (index, value) => {
    const newMaterial = [
      ...materialTable.slice(0, index),
      { ...materialTable[index], preprocess: value },
      ...materialTable.slice(index + 1),
    ];
    setMaterialTable(newMaterial);
  };

  const handleClickEditMaterial = (index) => {
    const newMaterial = [
      ...materialTable.slice(0, index),
      {
        ...materialTable[index],
        showInput: !materialTable[index]["showInput"],
      },
      ...materialTable.slice(index + 1),
    ];
    setMaterialTable(newMaterial);
  };

  const getPlanSummary = async ({ customerId, quotationId }) => {
    setLoadInitState({
      loadingStatus: callApiStatus.loading,
      data: null,
      error: null,
      planInfoExist: true,
    });

    Promise.all([
      testPlanServices.getTestSummaryByQuotationId(quotationId),
      customerServices.getDetail(customerId),
      testPlanServices.getTestMaterialInfoByQuotationId(quotationId),
    ])
      .then(([testRes, cusRes, materialRes]) => {
        if (testRes.data.success) {
          const combined = {
            ...testRes.data.result,
            customerAddress: getAddressFromComponent(cusRes.data),
            customerName: cusRes.data.companyNameKanji,
            phone1: cusRes.data.phone1,
            planInfoExist: testRes.data.result?._id ? true : false,
            planId: quota?.quotationId,
            testMaterialInfos:
              materialRes.data?.result?.length > 0
                ? materialRes.data.result.map((i) => ({
                    ...i,
                    shouldEdit: true,
                  }))
                : [],
          };

          setMaterialTable(combined.testMaterialInfos);
          setCustomerTable((s) => {
            return customerConfig.map((i) => ({
              ...i,
              detail: combined[i.dataKey],
            }));
          });
          setLabTable((s) => {
            return labConfig.map((i) => ({
              ...i,
              detail: combined[i.dataKey],
            }));
          });

          setLoadInitState({
            loadingStatus: callApiStatus.success,
            data: combined,
            error: null,
            planInfoExist: combined.planInfoExist,
          });
          return;
        }
      })
      .catch((err) => {
        toast.error(messages.callApiFail);
        setLoadInitState({
          loadingStatus: callApiStatus.error,
          data: null,
          error: err,
        });
      });
  };

  if (isCreated) {
    return (
      <div className="w-full h-auto my-12 flex items-center justify-center text-xl">
        試験計画書は作成しました。修正する場合は、計画書の修正で修正していください。
      </div>
    );
  }

  return (
    <>
      <div className="w-full h-auto">
        <div className="w-full h-auto flex items-end mb-[56px] flex-wrap">
          <div className="w-full md:w-1/2 h-full pr-12">
            <InputText
              id="quotationId"
              value={quota?.quotationId || ""}
              label="計画番号"
              disabled={true}
              labelWidth={isMd ? LABEL_WIDTH.RIGHT : LABEL_WIDTH.LEFT}
            />
          </div>

          <div className="w-full md:w-1/2 h-full">
            <SelectPicStaff
              pic={{ id: quota?.picStaff }}
              setPic={(newPic) => {
                setQuota((s) => ({ ...s, picStaff: newPic?.id }));
              }}
              labelWidth={LABEL_WIDTH.RIGHT}
            />
          </div>
        </div>
        <div className="w-full h-auto flex items-center justify-end gap-3">
          <Button
            onClick={() => {
              console.log("ha");
              getPlanSummary({
                customerId: customer.id,
                quotationId: quotationInfo.id,
              });
            }}
          >
            作成する
          </Button>
        </div>
        {loadInitState.loadingStatus === callApiStatus.loading && (
          <PageLoading />
        )}
        {(loadInitState.loadingStatus === callApiStatus.error ||
          loadInitState.error) && <PageError />}
        {loadInitState.loadingStatus === callApiStatus.success &&
          loadInitState.planInfoExist && (
            <h1>{messages.planExist}</h1>
          )}

        {loadInitState.loadingStatus === callApiStatus.success &&
          !loadInitState.planInfoExist &&
          loadInitState.data && (
            <CreatePlanForm
              customerTable={customerTable}
              labTable={labTable}
              materialTable={materialTable}
              handleClickEditCus={handleClickEditCus}
              handleClickEditLab={handleClickEditLab}
              handleClickEditMaterial={handleClickEditMaterial}
              onChangeCustomer={onChangeCustomer}
              onChangeLab={onChangeLab}
              onChangeMaterial={onChangeMaterial}
              createTestPlan={createTestPlan}
              downloadPdf={createPdf}
              confirmedData={confirmedData}
            />
          )}
      </div>

      <PageLoadingBackdrop open={isLoading} />
    </>
  );
}
