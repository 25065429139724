import React from "react";
import TableInfoCell from "./TableInfoCell";

function TableInfoRow(props) {
  const { tableConfig, rowData } = props;
  return (
    <div className="w-full h-[52px] flex">
      {Array.isArray(tableConfig)
        ? tableConfig.map((cellConfig, index) => {
            return cellConfig.CustomTableCell ? (
              <cellConfig.CustomTableCell
                key={index}
                width={cellConfig.width}
                textAlign={cellConfig.textAlign}
                cellContent={
                  rowData?.[cellConfig.dataKey] !== undefined
                    ? rowData?.[cellConfig.dataKey]
                    : ""
                }
                {...cellConfig}
              />
            ) : (
              <TableInfoCell
                key={index}
                width={cellConfig.width}
                textAlign={cellConfig.textAlign}
                cellClass={cellConfig?.cellClass}
                cellContent={
                  rowData?.[cellConfig.dataKey] !== undefined
                    ? rowData?.[cellConfig.dataKey]
                    : ""
                }
              />
            );
          })
        : null}
    </div>
  );
}

export default TableInfoRow;
