import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import ReactSelectSingle from "app/components/UI/Form/Select/ReactSelectSingle";
import { customerServices } from "app/services/api";
import { getNameKanji } from "app/utils";
import messages from "../../../../../services/api/messages";

function SelectCustomerPic(props) {
  const { pic, setPic, labelWidth = 146, disabled } = props;

  const [loadInitState, setLoadInitState] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const loadInit = useCallback(() => {
    setLoadInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    customerServices
      .getAllPic()
      .then((res) => {
        setLoadInitState({
          isLoading: false,
          data: res.data,
          error: null,
        });
        if (
          Array.isArray(res.data) &&
          !res.data.find((item) => item.id === pic?.id)
        ) {
          setPic(res.data[0]);
        }
      })
      .catch((error) => {
        toast.error(messages.callApiFail);
        setLoadInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, []);

  useEffect(() => {
    loadInit();
  }, [loadInit]);

  const picOptions = useMemo(() => {
    if (!Array.isArray(loadInitState.data)) return [];

    return loadInitState.data.map((item) => ({
      value: item.id,
      label: getNameKanji(item),
    }));
  }, [loadInitState.data]);

  const allPic = useMemo(() => {
    if (!Array.isArray(loadInitState.data)) return [];

    return loadInitState.data;
  }, [loadInitState.data]);

  return (
    <div className="w-full h-auto">
      <ReactSelectSingle
        id="select-pic"
        value={pic?.id}
        onChange={(newValue) => {
          const selected = allPic.find((item) => item.id === newValue);
          setPic(selected);
        }}
        label="担当者"
        options={picOptions}
        labelWidth={labelWidth}
        isLoading={loadInitState.isLoading}
        isDisabled={disabled}
      />
    </div>
  );
}

export default SelectCustomerPic;
