import React from "react";
import TableInfoHeader from "./TableInfoHeader";
import TableInfoRow from "./TableInfoRow";
import PageNoData from "../../../CommonPage/PageNoData";

function TableInfo(props) {
  const { tableConfig, tableData, className } = props;

  return (
    <div className={`w-full h-full overflow-auto relative ${className || ""}`}>
      <TableInfoHeader tableConfig={tableConfig} />

      {Array.isArray(tableData) && tableData.length > 0 ? (
        tableData.map((rowData, index) => (
          <TableInfoRow
            key={index}
            tableConfig={tableConfig}
            rowData={rowData}
          />
        ))
      ) : (
        <PageNoData />
      )}
    </div>
  );
}

export default TableInfo;
