import React from "react";

function ProjectTableHeader(props) {
  const { tableConfig } = props;
  return (
    <div className="w-full h-8 flex sticky top-0 inset-x-0">
      {Array.isArray(tableConfig)
        ? tableConfig.map((cellConfig, index) => (
            <div
              key={index}
              className="h-full border border-[#B3B3B3] bg-[#EBEBEB] flex items-center justify-center shrink-0"
              style={{
                width: cellConfig.width,
              }}
            >
              <p className="text-custom-size truncate">{cellConfig.title}</p>
            </div>
          ))
        : null}
    </div>
  );
}

export default ProjectTableHeader;
