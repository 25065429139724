import React, { useCallback, useEffect, useMemo, useState } from "react";
import TableInfo from "../../CommonComponents/TableInfo";
import { customerServices } from "../../../../services/api";
import { toast } from "react-toastify";
import PageLoading from "../../../CommonPage/PageLoading";
import PageError from "../../../CommonPage/PageError";
import PageNoData from "../../../CommonPage/PageNoData";
import {
  billingTypeLabel,
  contractTypeLabel,
  numberOfSpecimensLabel,
  quotationPriceUnitLabel,
  testSampleNumberOptions,
} from "../../../../constants";

function CustomerDetailEstimateBasicContent({ quotationBasics }) {
  const tableData = useMemo(() => {
    return quotationBasics.map((item) => ({
      id: item.id,
      contractType: contractTypeLabel[item.contractType] || "",
      billingTiming: billingTypeLabel[item.billingTiming] || "",
      inspectionPeopleNumber: `${item.inspectionPeopleNumber}名`,
      testSampleNumber:
        testSampleNumberOptions.find(
          (option) => option.value === item.testSampleNumber
        )?.label || "",
      quotationPrice: `${item.quotationPrice}${
        quotationPriceUnitLabel[item.quotationPriceUnit] || ""
      }`,
    }));
  }, [quotationBasics]);

  return (
    <TableInfo
      tableConfig={[
        {
          title: "契約形態",
          width: "20%",
          textAlign: "center",
          dataKey: "contractType",
        },
        {
          title: "請求タイミング",
          width: "20%",
          textAlign: "center",
          dataKey: "billingTiming",
        },
        {
          title: "検査人数",
          width: "20%",
          textAlign: "center",
          dataKey: "inspectionPeopleNumber",
        },
        {
          title: "検体数",
          width: "20%",
          textAlign: "center",
          dataKey: "testSampleNumber",
        },
        {
          title: "見積金額(税抜)",
          width: "20%",
          textAlign: "center",
          dataKey: "quotationPrice",
        },
      ]}
      tableData={tableData}
    />
  );
}

function CustomerDetailEstimateBasic(props) {
  const { quotationBasics } = props;

  return (
    <CustomerDetailEstimateBasicContent
      quotationBasics={quotationBasics || []}
    />
  );
}

export default CustomerDetailEstimateBasic;
