import React from "react";
import { v4 as uuidv4 } from "uuid";

export default function InputInForm({
  value,
  onChange,
  handleBlur,
  disabled,
  placeholder,
  touched,
  error,
  otherStyle
}) {
  const inputId = uuidv4();

  const onChangeValue = (e) => {
    onChange(e.currentTarget.value);
  };

  const inputClasses = () => {
    const baseClasses = `w-full h-full pl-1 py-2.5 text-sm tracking-[.2em] border border-[#B3B3B3] !outline-none focus:border-blue-500 ${otherStyle}`;
    if (disabled) return `${baseClasses} !bg-background-select`;
    if (touched && error) return `${baseClasses} !border-[2px] border-solid border-red-500`;
    return baseClasses;
  };

  return (
    <div className="w-full h-[52px] flex items-center">
      <div
        className={`w-full h-full relative ${
          touched && error ? "shadow-[0px_0px_1px_1px_#dc2626]" : ""
        }`}
      >
        <input
          id={inputId}
          name={inputId}
          value={value}
          placeholder={placeholder}
          onChange={onChangeValue}
          onBlur={(e) =>
            typeof handleBlur === "function" && handleBlur(e)
          }
          disabled={disabled}
          className={inputClasses()}
        />
      </div>
    </div>
  );
}
