import React, { useState, useRef, useMemo } from "react";
import ContentLayout from "../../../layouts/ContentLayout";
import { useOutletContext } from "react-router-dom";
import readXlsxFile from "read-excel-file";
import { useMediaQuery } from "@mui/material";
import { SIDE_MENU } from "../constants";
import SelectTestSchedule from "../../../components/UI/Form/Select/SelectTestSchedule";
import Item from "./Item";
import InputFile from "../../../components/UI/Form/Input/InputFile";
import {
  LABEL_WIDTH,
  testResultRequiredRowName,
} from "../../../constants";
import { toast } from "react-toastify";
import messages from "../../../services/api/messages";
import Button from "app/components/UI/Button";

const BatchReportPage = () => {
  const itemRefs = useRef([]);
  const [breadcrumb] = useOutletContext();
  const isMd = useMediaQuery("(max-width:768px)");
  const [fileData, setFileData] = useState(null);
  const [file, setFile] = useState(null);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [hasAvailableData, setHasAvailableData] = useState(false);

  const handleFileChange = (e) => {
    try {
      const file = e.target.files[0];
      readXlsxFile(file, { sheet: "list" }).then((rows) => {
        const filterFileData = rows.slice(1).filter((row) => {
          const name = row?.[3] || "";
          if (testResultRequiredRowName.includes(name)) {
            return false;
          }
          return true;
        });

        setFileData(filterFileData);
        setFile(file);
      });
    } catch (error) {
      toast.error(messages.callApiFail);
    }
  };

  const cellStyle =
    "flex items-center justify-center p-2 bg-gray-200 border border-[#B3B3B3]";

  const itemDataArray = useMemo(() => {
    return selectedSchedule?.caseInfos
      .filter(
        (caseInfo) =>
          !caseInfo.report || caseInfo.reportStatus === "notStarted"
      )
      .map((item) => {
        const itemFileData = fileData
          ?.filter((row) => {
            const name = row?.[3] || "";
            if (testResultRequiredRowName.includes(name))
              return false;

            const caseId = row?.[6] || "";
            if (caseId !== item.caseId) {
              return false;
            }

            const companyNameKanji = row?.[4] || "";
            if (item.customer.companyNameKanji === companyNameKanji)
              return true;
            return false;
          })
          .map((row) => {
            return {
              name: row?.[3] || "",
              preprocess: row?.[9] || "",
              materialNumber: row?.[1] || "",
              capacity: row?.[10] || "",
            };
          });

        if (itemFileData?.length > 0) {
          setHasAvailableData(true);
        }
        return { ...item, itemFileData };
      });
  }, [selectedSchedule, fileData]);

  return (
    <ContentLayout
      sideMenuLinkOptions={SIDE_MENU}
      breadcrumb={breadcrumb}
    >
      <div className="w-full h-auto">
        <div className="w-full h-auto mb-5">
          <p className="text-custom-size mb-3">報告書一括送付</p>
        </div>
        <div className="w-full h-auto flex items-center mb-3">
          <SelectTestSchedule
            isWhite
            schedule={selectedSchedule}
            setSchedule={setSelectedSchedule}
            filterFunc={(schedules) =>
              schedules
                .filter((schedule) => schedule.caseInfos.length > 0)
                .sort((a, b) => {
                  const date1 = new Date(a.testDate);
                  const date2 = new Date(b.testDate);
                  return date2 - date1;
                })
            }
          />
          <InputFile
            id="upload-report-file"
            handleFileChange={handleFileChange}
            label="試験結果"
            labelWidth={isMd ? LABEL_WIDTH.RIGHT : LABEL_WIDTH.LEFT}
            fileName={file?.name || ""}
            acceptType=".xls, .xlsx"
          />
        </div>

        <div className="overflow-x-auto">
          <div
            className="grid grid-cols-11 p-4"
            style={{
              gridTemplateColumns:
                "110px 70px 120px 60px 70px 90px 100px 100px 90px 60px 55px",
            }}
          >
            <div className={"border-l " + cellStyle}>案件ID</div>
            <div className={cellStyle}>報告書</div>
            <div className={cellStyle}>企業名</div>
            <div className={cellStyle}>人数</div>
            <div className={cellStyle}>検体数</div>
            <div className={cellStyle}>判定医師*</div>
            <div className={cellStyle}>担当部署*</div>
            <div className={cellStyle}>担当者名</div>
            <div className={cellStyle}>電話番号</div>
            <div className={cellStyle}>状況</div>
            <div className={cellStyle}>結果</div>
            {itemDataArray?.map((item, index) => (
              <Item
                key={item._id}
                ref={(el) => (itemRefs.current[index] = el)}
                data={item}
                fileDataArray={item.itemFileData}
                file={file}
                scheduleId={selectedSchedule?._id}
              />
            ))}
          </div>
        </div>

        <div className="flex items-end justify-end mt-4">
          <Button
            disabled={!hasAvailableData}
            type={hasAvailableData ? "primary" : "disabled"}
            onClick={() => {
              try {
                itemRefs.current.forEach((ref) => {
                  if (ref) ref.triggerClick();
                });
              } catch (error) {
                toast.error("送付に失敗しました");
                window.location.reload();
              }
            }}
          >
            報告書一括送付
          </Button>
        </div>
      </div>
    </ContentLayout>
  );
};

export default BatchReportPage;
