import React, { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";
import ContentLayout from "app/layouts/ContentLayout";
import routePaths from "app/route/routePaths";
import { commonServices, contractServices } from "../../../services/api";
import { toast } from "react-toastify";
import SelectCustomerId from "../../../components/UI/Form/SelectGroup/SelectCustomerId";
import ReactSelectSingle from "app/components/UI/Form/Select/ReactSelectSingle";
import messages from "../../../services/api/messages";
import { contractCategory, contractCategoryLabel } from "../../../constants";
import InputText from "../../../components/UI/Form/Input/InputText";
import InputFile from "../../../components/UI/Form/Input/InputFile";
import Button from "../../../components/UI/Button";
import moment from "moment";

const labelWidth = 186;

const initialValues = {
  customerId: "",
  contractId: "",
  name: "",
  contractDate: moment().startOf("day").toISOString(),
  progress: "submitted",
  contractCategory: "nonDisclosureAgreement",
  picDepoartment: "",
  picPost: "",
  picName: "",

  generatedWord: "",
  generatedPdf: "",
};

function CreateContractPageContent(props) {
  const [file, setFile] = useState(null);
  const [confirmedData, setConfirmedData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [customerNameKanji, setCustomerNameKanji] = useState("");

  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    customerId: Yup.string().required(),
    contractId: Yup.string().required(),
    name: Yup.string().required(),
    contractDate: Yup.string(),
    progress: Yup.string(),
    contractCategory: Yup.string().required(),
    picDepoartment: Yup.string().required(),
    picPost: Yup.string().required(),
    picName: Yup.string().required(),
  })

  const isDisabledSubmit = !confirmedData;

  const onSubmit = async (values) => {
    if (isLoading) return;
    if (values.contractCategory === "original" && !file) {
      toast.error("ファイルを選択してください。");
      return;
    }

    const data = {
      customerId: values.customerId,
      contractId: values.contractId,
      name: values.name,
      contractDate: values.contractDate,
      progress: values.progress,
      contractCategory: values.contractCategory,
      picDepoartment: values.picDepoartment,
      picPost: values.picPost,
      picName: values.picName,
      file: file,
    };

    if (values.contractCategory === "original" && !file) {
      toast.error("ファイルが必要です!");
      return;
    }

    setIsLoading(true);
    contractServices
      .confirmContract(data)
      .then((res) => {
        setConfirmedData({
          ...data,
          generatedWord: res.data?.file?._id,
          generatedPdf: res.data?.filePdf?._id,
        });
        setIsLoading(false);

        const contractCategory = contractCategoryLabel[values.contractCategory];

        const timeStamp = moment().format("YYYYMMDDHHmmss");

        setTimeout(() => {
          commonServices
            .downloadWithFileId(
              res.data?.file?._id,
              "docx",
              `${contractCategory}_${customerNameKanji}_${timeStamp}`
            )
            .catch(() => {
              toast.error(messages.downloadFail);
            });
        }, 500);

        setTimeout(() => {
          commonServices
            .downloadPDFWithFileId(
              res.data?.filePdf?._id,
              `${contractCategory}_${customerNameKanji}_${timeStamp}`
            )
            .catch(() => {
              toast.error(messages.downloadFail);
            });
        }, 500);
      })
      .catch((e) => {
        toast.error(messages.common_error);
        setIsLoading(false);
      });
  };

  const { handleSubmit, errors, values, setFieldValue, touched, handleBlur } =
    useFormik({
      initialValues: initialValues,
      onSubmit,
      validationSchema,
      enableReinitialize: true,
    });

  const handleChangeField = (field, value) => {
    setFieldValue(field, value);
    setConfirmedData(null);
  };

  const handleFileChange = (e) => {
    try {
      const file = e.target.files[0];
      if (!file) return;

      setFile(file);
      setConfirmedData(null);
    } catch (error) {
      toast.error(messages.handleFileFail);
    }
  };

  const handleSubmitCreate = () => {
    if (isLoading || !confirmedData) return;
    const data = { ...confirmedData };
    if (values.contractCategory === "original" && !file) {
      toast.error("ファイルが必要です!");
      return;
    }
    if (values.contractCategory && file) {
      data.file = file;
    }

    setIsLoading(true);
    contractServices
      .createContract(data)
      .then((res) => {
        toast.success("成功する契約を作成する");
        navigate(routePaths.contract);
        setIsLoading(false);
      })
      .catch((e) => {
        toast.error(messages.common_error);
        setIsLoading(false);
      });
  };

  return (
    <div className="w-full h-auto">
      <div className="flex justify-between pb-4">
        <div className="flex items-end tracking-[2px]">契約書の作成</div>
      </div>

      <div className="w-full h-auto">
        <SelectCustomerId
          customer={{ id: values.customerId }}
          setCustomer={(newValue) => {
            handleChangeField("customerId", newValue.id);
            setCustomerNameKanji(newValue.companyNameKanji);
          }}
          labelWidth={labelWidth}
        />
      </div>
      {values.customerId ? (
        <div className="w-full h-auto">
          <div className="mb-8">
            <ReactSelectSingle
              id="contractCategory"
              name="contractCategory"
              value={values?.contractCategory}
              onChange={(newValue) => {
                handleChangeField("contractCategory", newValue);
              }}
              label="契約書"
              options={contractCategory}
              labelWidth={labelWidth}
            />

            <InputText
              id="contractId"
              value={values.contractId}
              onChange={(newValue) => handleChangeField("contractId", newValue)}
              label="契約書ID（フリー定義）"
              labelWidth={labelWidth}
              handleBlur={handleBlur}
              placeholder=""
              errors={errors.contractId}
              touched={touched.contractId}
              isRequired
            />

            <InputText
              id="name"
              value={values.name}
              onChange={(newValue) => handleChangeField("name", newValue)}
              label="契約書名"
              labelWidth={labelWidth}
              handleBlur={handleBlur}
              placeholder=""
              errors={errors.name}
              touched={touched.name}
              isRequired
            />

            <InputText
              id="picDepoartment"
              value={values.picDepoartment}
              onChange={(newValue) =>
                handleChangeField("picDepoartment", newValue)
              }
              label="契約担当部署"
              labelWidth={labelWidth}
              handleBlur={handleBlur}
              placeholder=""
              errors={errors.picDepoartment}
              touched={touched.picDepoartment}
              isRequired
            />
            <InputText
              id="picPost"
              value={values.picPost}
              onChange={(newValue) => handleChangeField("picPost", newValue)}
              label="契約担当者役職"
              labelWidth={labelWidth}
              handleBlur={handleBlur}
              placeholder="代表取締役"
              errors={errors.picPost}
              touched={touched.picPost}
              isRequired
            />
            <InputText
              id="picName"
              value={values.picName}
              onChange={(newValue) => handleChangeField("picName", newValue)}
              label="契約担当者"
              labelWidth={labelWidth}
              handleBlur={handleBlur}
              placeholder="本田　稔"
              errors={errors.picName}
              touched={touched.picName}
              isRequired
            />
          </div>

          <div className="h-[52px] flex items-center ms-[146px] justify-between">
            <div className="w-auto">
              <InputFile
                placeholder="ファイルを選択"
                id="upload-report-file"
                handleFileChange={handleFileChange}
                fileName={file?.name || ""}
              />
            </div>

            <div className="flex items-center gap-3 ms-auto">
              <div className="w-auto h-auto flex items-center gap-3">
                <Button onClick={handleSubmit}>
                  <span className="break-keep">確認する</span>
                </Button>

                <Button
                  disabled={isDisabledSubmit}
                  onClick={handleSubmitCreate}
                  type={isDisabledSubmit ? "disabled" : "primary"}
                >
                  <span className="break-keep">提出する</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

function CreateContractPage() {
  const [breadcrumb] = useOutletContext();
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);

  return (
    <ContentLayout
      sideMenuLinkOptions={[
        { linkTo: routePaths.contract, linkTitle: "契約書締結状況" },
        {
          linkTo: routePaths.createContract,
          linkTitle: "契約書の作成",
        },
        { linkTo: routePaths.topMenu, linkTitle: "トップへ戻る" },
      ]}
      breadcrumb={breadcrumb}
    >
      <div className="w-full h-full grow flex flex-col pt-2.5">
        <CreateContractPageContent
          selectedCustomerId={selectedCustomerId}
          setSelectedCustomerId={setSelectedCustomerId}
        />
      </div>
    </ContentLayout>
  );
}

export default CreateContractPage;
