import React, { useMemo } from "react";
import TableInfo from "../CommonComponents/TableInfo";
import { Link } from "react-router-dom";
import routePaths from "app/route/routePaths";
import TrashSVG from "app/assets/svg/trash.svg";
import Support from "app/assets/svg/3p.svg";
import { convertDateStringToJpFormat } from "../../../utils";

function StatusCell(props) {
  const { cellContent, width, textAlign } = props;
  const getBgColor = () => {
    if (cellContent === "submitted") {
      return "#ECDAAC";
    } else if (cellContent === "completed") return "#6C6C6C";
    return "[#fff]";
  };

  const bgColor = getBgColor(cellContent);

  return (
    <div
      className={`h-full border border-[#B3B3B3] px-4 py-4`}
      style={{
        width: width,
        textAlign: textAlign || "start",
        backgroundColor: bgColor,
      }}
    >
      <p className="text-sm leading-[18px] tracking-[.2em] truncate">
        {cellContent === "submitted" ? "提出中" : "締結済"}
      </p>
    </div>
  );
}

function contractCategory(props) {
  const { cellContent, width, textAlign } = props;

  function getCellContentJp() {
    if (cellContent === "nonDisclosureAgreement") {
      return "秘密保持契約書";
    } else if (cellContent === "outsourcingContract") {
      return "業務委託契約書";
    } else {
      return "オリジナル";
    }
  }

  const cellContentJp = getCellContentJp();

  return (
    <div
      className={`h-full border border-[#B3B3B3] px-4 py-4`}
      style={{
        width: width,
        textAlign: textAlign || "start",
      }}
    >
      <p className="text-sm leading-[18px] tracking-[.2em] truncate">
        {cellContentJp}
      </p>
    </div>
  );
}

function ActionCell(props) {
  const { cellContent } = props;
  const { allowEdit, id, iconSvg } = cellContent || {};
  if (!allowEdit) {
    return <></>;
  }
  return (
    <div className="h-full px-3 py-4" style={{ width: "5%" }}>
      <Link to={routePaths.chat} className="text-[#08978E]">
        <img
          className="w-[1.25em] h-[1.25em] cursor-pointer"
          alt="delete-icon"
          src={iconSvg}
        />
      </Link>
    </div>
  );
}

function ActionDeleteCell(props) {
  const { cellContent, clickDelete } = props;
  console.log("🚀 ~ ActionDeleteCell ~ props=>", props);
  const { allowEdit, id, iconSvg } = cellContent || {};
  if (!allowEdit) {
    return <></>;
  }
  return (
    <div className="h-full px-3 py-4" style={{ width: "5%" }}>
      <button
        type="button"
        onClick={() => clickDelete(cellContent.id)}
        className=""
      >
        <img
          className="w-[1.25em] h-[1.25em] cursor-pointer"
          alt="delete-icon"
          src={iconSvg}
        />
      </button>
    </div>
  );
}

function ContractList(props) {
  const { contractList, downloadFile, deleteContract } = props;
  const tableData = useMemo(() => {
    return contractList.map((item) => ({
      contract_id: (
        <div
          onClick={() => downloadFile(item)}
          className="text-[#08978E] cursor-pointer"
        >
          {item.contractId}
        </div>
      ),
      company_name: item.customer?.companyNameKanji || "",
      contract_category: item.contractCategory,
      execution_date: convertDateStringToJpFormat(item.contractDate),
      progress: item.progress,
      action_support: {
        allowEdit: true,
        id: item.contractId,
        iconSvg: Support,
      },
      action_delete: {
        allowEdit: true,
        id: item._id,
        iconSvg: TrashSVG,
      },
    }));
  }, [contractList, downloadFile]);

  return (
    <TableInfo
      tableConfig={[
        {
          title: "契約書ID",
          width: "15%",
          textAlign: "center",
          dataKey: "contract_id",
        },
        {
          title: "企業名",
          width: "25%",
          textAlign: "center",
          dataKey: "company_name",
        },
        {
          title: "契約書",
          width: "20%",
          textAlign: "center",
          dataKey: "contract_category",
          CustomTableCell: contractCategory,
        },
        {
          title: "締結日",
          width: "20%",
          textAlign: "center",
          dataKey: "execution_date",
        },
        {
          title: "進捗",
          width: "10%",
          textAlign: "center",
          dataKey: "progress",
          CustomTableCell: StatusCell,
        },
        {
          title: "Action Support",
          width: "5%",
          dataKey: "action_support",
          CustomTableHeaderCell: () => {
            return <div style={{ width: "5%" }}></div>;
          },
          CustomTableCell: ActionCell,
        },
        {
          title: "Action Delete",
          width: "5%",
          dataKey: "action_delete",
          CustomTableHeaderCell: () => {
            return <div style={{ width: "5%" }}></div>;
          },
          CustomTableCell: ActionDeleteCell,
          clickDelete: (id) => deleteContract(id),
        },
      ]}
      tableData={tableData}
      className="min-w-[900px]"
    />
  );
}

export default ContractList;
