import React, { useMemo } from "react";
import TableInfo from "../../CommonComponents/TableInfo";
import { Link } from "react-router-dom";
import routePaths from "app/route/routePaths";
import { getNameKana, getNameKanji } from "../../../../utils";

function UserSearchResult(props) {
  const { userList } = props;
  const tableData = useMemo(() => {
    return userList.map((item) => ({
      nameKanji: (
        <Link
          to={routePaths.systemSettingsUserDetail(item.id)}
          className="text-[#08978E]"
        >
          {getNameKanji(item)}
        </Link>
      ),
      nameKana: getNameKana(item),
      userId: item.userId,
    }));
  }, [userList]);

  return (
    <TableInfo
      tableConfig={[
        {
          title: "ユーザー名",
          width: "38%",
          dataKey: "nameKanji",
        },
        {
          title: "フリガナ",
          width: "38%",
          dataKey: "nameKana",
        },
        {
          title: "ユーザーID",
          width: "24%",
          dataKey: "userId",
        },
      ]}
      tableData={tableData}
      className="custom-scrollbar pr-1.5"
    />
  );
}

export default UserSearchResult;
