import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import ReactSelectSingle from "app/components/UI/Form/Select/ReactSelectSingle";
import PageLoading from "app/components/CommonPage/PageLoading";
import PageNoData from "app/components/CommonPage/PageNoData";
import PageError from "app/components/CommonPage/PageError";
import { customerServices } from "app/services/api";
import messages from "../../../../../services/api/messages";

function SelectCustomerId(props) {
  const { customer, setCustomer, labelWidth = 146 } = props;

  const [loadInitState, setLoadInitState] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const loadInit = useCallback(() => {
    setLoadInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    customerServices
      .getAllCustomerId()
      .then((res) => {
        setLoadInitState({
          isLoading: false,
          data: res.data,
          error: null,
        });
        if (
          Array.isArray(res.data) &&
          !res.data.find((item) => item.id === customer?.id)
        ) {
          setCustomer(res.data[0]);
        }
      })
      .catch((error) => {
        toast.error(messages.callApiFail);
        setLoadInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, []);

  useEffect(() => {
    loadInit();
  }, [loadInit]);

  const customerIdList = useMemo(() => {
    if (!Array.isArray(loadInitState.data)) return [];

    return loadInitState.data.map((item) => ({
      value: item.id,
      label: item.customerId,
    }));
  }, [loadInitState.data]);

  const companyNameKanjiList = useMemo(() => {
    if (!Array.isArray(loadInitState.data)) return [];

    return loadInitState.data.map((item) => ({
      value: item.id,
      label: item.companyNameKanji,
    }));
  }, [loadInitState.data]);

  const companyNameKanaList = useMemo(() => {
    if (!Array.isArray(loadInitState.data)) return [];

    return loadInitState.data.map((item) => ({
      value: item.id,
      label: item.companyNameKana,
    }));
  }, [loadInitState.data]);

  const allCustomer = useMemo(() => {
    if (!Array.isArray(loadInitState.data)) return [];

    return loadInitState.data.map((item) => ({
      id: item.id,
      companyNameKanji: item.companyNameKanji,
      companyNameKana: item.companyNameKana,
      customerId: item.customerId,
    }));
  }, [loadInitState.data]);

  if (loadInitState.isLoading) return <PageLoading />;
  if (loadInitState.error) return <PageError />;

  if (Array.isArray(loadInitState.data) && loadInitState.data.length > 0)
    return (
      <div className="w-full h-auto">
        <ReactSelectSingle
          id="select-customer-id"
          value={customer?.id}
          onChange={(newValue) => {
            const customer = allCustomer.find((item) => item.id === newValue);
            setCustomer(customer);
          }}
          label="お客様ID"
          options={customerIdList}
          labelWidth={labelWidth}
        />
        <ReactSelectSingle
          id="select-customer-id"
          value={customer?.id}
          onChange={(newValue) => {
            const customer = allCustomer.find((item) => item.id === newValue);
            setCustomer(customer);
          }}
          label="会社名"
          options={companyNameKanjiList}
          labelWidth={labelWidth}
        />
        <ReactSelectSingle
          id="select-customer-id"
          value={customer?.id}
          onChange={(newValue) => {
            const customer = allCustomer.find((item) => item.id === newValue);
            setCustomer(customer);
          }}
          label="フリガナ"
          options={companyNameKanaList}
          labelWidth={labelWidth}
        />
      </div>
    );

  if (Array.isArray(loadInitState.data) && loadInitState.data.length === 0)
    <div className="w-full h-auto">
      <PageNoData noDataMessage="顧客IDはまだありません。 最初に顧客IDを作成してください。" />
    </div>;

  return null;
}

export default SelectCustomerId;
