// FE-4 : https://www.figma.com/file/rjvTEGNOs42Sv2L7TEA5Ef/DRC_%E6%A5%AD%E5%8B%99%E7%AE%A1%E7%90%86%E3%82%B7%E3%82%B9%E3%83%86%E3%83%A0_%E7%AE%A1%E7%90%86%E8%80%85%E7%94%A8?type=design&node-id=114-142&mode=design&t=2R9y6hQCpN4l2bnM-0
import React, { useCallback, useEffect, useState } from "react";
import ContentLayout from "app/layouts/ContentLayout";
import routePaths from "app/route/routePaths";
import { commonServices, contractServices } from "../../services/api";
import { toast } from "react-toastify";
import PageLoading from "app/components/CommonPage/PageLoading";
import PageError from "app/components/CommonPage/PageError";
import PageNoData from "app/components/CommonPage/PageNoData";
import ContractList from "../../components/Table/Contract";
import { useOutletContext } from "react-router-dom";
import PageLoadingBackdrop from "../../components/CommonPage/PageLoadingBackdrop";
import messages from "../../services/api/messages";
import moment from "moment";
import { contractCategoryLabel } from "../../constants";

function ContractPage() {
  const [breadcrumb] = useOutletContext();
  const [loadInitState, setLoadInitState] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const [downloading, setDownloading] = useState(false);
  const downloadFile = (contract) => {
    const fileId = contract.generatedWord;

    setDownloading(true);
    const contractCategory =
      contractCategoryLabel[contract.contractCategory] || "";
    const customerNameKanji = contract?.customer?.companyNameKanji || "";
    const timeStamp = moment().format("YYYYMMDDHHmmss");

    commonServices
      .downloadPDFWithFileId(
        fileId,
        `${contractCategory}_${customerNameKanji}_${timeStamp}`
      )
      .then(() => {
        setDownloading(false);
      })
      .catch((err) => {
        setDownloading(false);
        toast.error(messages.downloadFail);
      });
  };

  const deleteContact = (id) => {
    return contractServices
      .deleteContract(id)
      .then((res) => {
        toast.success(messages.delete(true));
        loadInit();
      })
      .catch((e) => {
        toast.error(messages.delete(false));
      });
  };

  const loadInit = useCallback((searchParams) => {
    setLoadInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    contractServices
      .getContractList()
      .then((res) => {
        setLoadInitState({
          isLoading: false,
          data: res.data.result,
          error: null,
        });
      })
      .catch((error) => {
        toast.error(messages.callApiFail);
        setLoadInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, []);

  useEffect(() => {
    loadInit(1);
  }, [loadInit]);

  return (
    <ContentLayout
      sideMenuLinkOptions={[
        { linkTo: routePaths.contract, linkTitle: "契約書締結状況" },
        {
          linkTo: routePaths.createContract,
          linkTitle: "契約書の作成",
        },
        { linkTo: routePaths.topMenu, linkTitle: "トップへ戻る" },
      ]}
      breadcrumb={breadcrumb}
    >
      <div className="flex items-end mb-3">契約書締結状況</div>
      {loadInitState.isLoading ? <PageLoading /> : null}

      {!loadInitState.isLoading && loadInitState.error ? <PageError /> : null}

      {!loadInitState.isLoading &&
      !loadInitState.error &&
      Array.isArray(loadInitState.data) ? (
        loadInitState.data.length > 0 ? (
          <ContractList
            contractList={loadInitState.data}
            downloadFile={downloadFile}
            deleteContract={deleteContact}
          />
        ) : (
          <PageNoData noDataMessage="データなし!" noDataAction />
        )
      ) : null}

      <PageLoadingBackdrop open={downloading} />
    </ContentLayout>
  );
}

export default ContractPage;
