import React, { useEffect, useMemo, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import Button from "app/components/UI/Button";
import InputText from "app/components/UI/Form/Input/InputText";
import AsyncCheckExistedInput from "app/components/UI/Form/Input/AsyncCheckExistedInput";

import { checkExistedStatus } from "app/constants";
import TrashBinSVG from "app/assets/svg/trashbin.svg";
import { systemSettingsServices } from "../../../services/api";
import { toast } from "react-toastify";
import isEmail from "validator/lib/isEmail";
import "app/utils/yup-custom";
import validateMessages from "../../../validateMessages";

export default function CreateAndUpdateCustomerForm({
  handleSubmitForm,
  customerIdToUpdate,
  handleShowConfirmDelete,
}) {
  const isUpdateMode = customerIdToUpdate !== undefined;
  const checkCustomerIdRef = useRef();
  const checkCompanyNameKanjiRef = useRef();
  const checkCompanyNameKanaRef = useRef();
  const checkLoginNameRef = useRef();

  const [checkCustomerIdStatus, setCheckCustomerIdStatus] = useState(
    checkExistedStatus.noChange
  );

  const [
    checkCompanyNameKanjiStatus,
    setCheckCompanyNameKanjiStatus,
  ] = useState(checkExistedStatus.noChange);

  const [checkCompanyNameKanaStatus, setCheckCompanyNameKanaStatus] =
    useState(checkExistedStatus.noChange);

  const [checkLoginNameStatus, setCheckLoginNameStatus] = useState(
    checkExistedStatus.noChange
  );

  const validationSchema = Yup.object().shape({
    companyNameKanji: Yup.string().required(
      validateMessages.required("会社名")
    ),
    companyNameKana: Yup.string().required(
      validateMessages.required("フリガナ")
    ),
    customerId: Yup.string().required(
      validateMessages.required("お客様ID")
    ),
    email: Yup.string()
    .required(validateMessages.required("メールアドレス"))
    .email(validateMessages.emailWrongFormat),
    accounts: Yup.array().of(
      Yup.object().shape({
        email: Yup.string()
          .required(validateMessages.required("メールアドレス"))
          .email(validateMessages.emailWrongFormat),
        password: isUpdateMode
          ? Yup.string()
          : Yup.string()
              .required(validateMessages.required("パスワード"))
              .min(8, validateMessages.minLength("パスワード", 8))
              .max(20, validateMessages.maxLength("パスワード", 20)),
      })
    ),
  });

  const onSubmit = async (values) => {
    if (
      checkCustomerIdStatus === checkExistedStatus.isChecking ||
      checkCustomerIdStatus === checkExistedStatus.existed ||
      checkLoginNameStatus === checkExistedStatus.isChecking ||
      checkLoginNameStatus === checkExistedStatus.existed
    )
      return;
    handleSubmitForm(values);
  };

  const initialValues = useMemo(
    () =>
      customerIdToUpdate?.id
        ? {
            companyNameKanji: customerIdToUpdate.companyNameKanji,
            companyNameKana: customerIdToUpdate.companyNameKana,
            customerId: customerIdToUpdate.customerId,
            email: customerIdToUpdate.email,
            accounts: customerIdToUpdate.accountInfos.map(
              (account) => ({
                email: account.loginName,
                password: "",
              })
            ),
          }
        : {
            companyNameKanji: "",
            companyNameKana: "",
            email: "",
            accounts: [
              {
                email: "",
                password: "",
              },
            ],
          },
    [customerIdToUpdate]
  );

  const {
    handleSubmit,
    errors,
    values,
    setFieldValue,
    touched,
    handleBlur,
    validateForm,
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (checkCustomerIdRef.current) {
      clearTimeout(checkCustomerIdRef.current);
    }

    const check = (valueToCheck) => {
      if (initialValues.customerId === valueToCheck) {
        setCheckCustomerIdStatus(checkExistedStatus.noChange);
        return;
      }
      setCheckCustomerIdStatus(checkExistedStatus.isChecking);
      systemSettingsServices
        .checkCustomerIdExisted(valueToCheck?.trim())
        .then((isExisted) => {
          if (values.customerId === valueToCheck)
            if (!isExisted) {
              setCheckCustomerIdStatus(checkExistedStatus.available);
            } else {
              setCheckCustomerIdStatus(checkExistedStatus.existed);
            }
        });
    };

    checkCustomerIdRef.current = setTimeout(
      () => check(values.customerId),
      500
    );
  }, [initialValues.customerId, values.customerId]);

  useEffect(() => {
    if (checkCompanyNameKanjiRef.current) {
      clearTimeout(checkCompanyNameKanjiRef.current);
    }

    const check = (valueToCheck) => {
      if (initialValues.companyNameKanji === valueToCheck) {
        setCheckCompanyNameKanjiStatus(checkExistedStatus.noChange);

        return;
      }

      setCheckCompanyNameKanjiStatus(checkExistedStatus.isChecking);

      systemSettingsServices
        .checkCompanyNameKanjiExisted(valueToCheck?.trim())
        .then((isExisted) => {
          if (values.companyNameKanji === valueToCheck)
            if (!isExisted) {
              setCheckCompanyNameKanjiStatus(
                checkExistedStatus.available
              );
            } else {
              setCheckCompanyNameKanjiStatus(
                checkExistedStatus.existed
              );
            }
        });
    };

    checkCompanyNameKanjiRef.current = setTimeout(
      () => check(values.companyNameKanji),
      500
    );
  }, [initialValues.companyNameKanji, values.companyNameKanji]);

  useEffect(() => {
    if (checkCompanyNameKanaRef.current) {
      clearTimeout(checkCompanyNameKanaRef.current);
    }

    const check = (valueToCheck) => {
      if (initialValues.companyNameKana === valueToCheck) {
        setCheckCompanyNameKanaStatus(checkExistedStatus.noChange);

        return;
      }

      setCheckCompanyNameKanaStatus(checkExistedStatus.isChecking);
      systemSettingsServices
        .checkCompanyNameKanaExisted(valueToCheck?.trim())
        .then((isExisted) => {
          if (values.companyNameKana === valueToCheck)
            if (!isExisted) {
              setCheckCompanyNameKanaStatus(
                checkExistedStatus.available
              );
            } else {
              setCheckCompanyNameKanaStatus(
                checkExistedStatus.existed
              );
            }
        });
    };

    checkCompanyNameKanaRef.current = setTimeout(
      () => check(values.companyNameKana),
      500
    );
  }, [initialValues.companyNameKana, values.companyNameKana]);

  const checkLoginEmail = (valueToCheck) => {
    if (checkLoginNameRef.current) {
      clearTimeout(checkLoginNameRef.current);
    }
    const check = () => {
      if (
        typeof valueToCheck !== "string" ||
        !isEmail(valueToCheck)
      ) {
        return;
      }
      setCheckLoginNameStatus(checkExistedStatus.isChecking);
      systemSettingsServices
        .checkLoginNameExisted(valueToCheck?.trim())
        .then((isExisted) => {
          setCheckLoginNameStatus(
            isExisted
              ? checkExistedStatus.existed
              : checkExistedStatus.available
          );
        });
    };

    checkLoginNameRef.current = setTimeout(
      () => check(values.email),
      500
    );
  };

  return (
    <div className="p-4">
      <div className="flex justify-between pb-4">
        <div className="flex items-end">顧客情報</div>
        <div className="flex gap-4">
          <Button
            onClick={() => {
              validateForm(values).then((res) => {
                if (Object.keys(res).length > 0) {
                  const errorList = Object.values(res);
                  toast.error(
                    <div>
                      {errorList.map((item) => {
                        return (
                          typeof item === "string" && (
                            <p className="texts-sm">{item}</p>
                          )
                        );
                      })}
                    </div>
                  );
                }

                handleSubmit();
              });
            }}
          >
            {customerIdToUpdate?.id ? "変更登録" : "新規登録"}
          </Button>

          {customerIdToUpdate?.id ? (
            <button
              type="button"
              onClick={handleShowConfirmDelete}
              className="center-flex"
            >
              <img
                className="w-[1.25em] h-[1.25em] cursor-pointer"
                alt=""
                src={TrashBinSVG}
              />
            </button>
          ) : null}
        </div>
      </div>
      <div className="w-full">
        <AsyncCheckExistedInput
          id="customerId"
          value={values.customerId}
          onChange={(newValue) =>
            setFieldValue("customerId", newValue)
          }
          label="お客様ID"
          labelWidth={175}
          handleBlur={handleBlur}
          disabled={false}
          placeholder="ABCDE12345"
          checkStatus={checkCustomerIdStatus}
          errors={errors.customerId}
          touched={touched.customerId}
          requiredUnique
          requiredUniqueError="お客様IDはすでに存在します!"
          isRequired
        />
        <AsyncCheckExistedInput
          id="companyNameKanji"
          value={values.companyNameKanji}
          onChange={(newValue) =>
            setFieldValue("companyNameKanji", newValue)
          }
          label="会社名"
          labelWidth={175}
          handleBlur={handleBlur}
          disabled={false}
          placeholder="株式会社システムデバイステクノロジー"
          checkStatus={checkCompanyNameKanjiStatus}
          errors={errors.companyNameKanji}
          touched={touched.companyNameKanji}
          requiredUniqueError="会社名はすでに存在します!"
          isRequired
        />
        <AsyncCheckExistedInput
          id="companyNameKana"
          value={values.companyNameKana}
          onChange={(newValue) =>
            setFieldValue("companyNameKana", newValue)
          }
          label="フリガナ"
          labelWidth={175}
          handleBlur={handleBlur}
          disabled={false}
          placeholder="システムデバイステクノロジー"
          checkStatus={checkCompanyNameKanaStatus}
          errors={errors.companyNameKana}
          touched={touched.companyNameKana}
          requiredUniqueError="フリガナはすでに存在します!"
          isRequired
        />
        <InputText
          id="email"
          value={values.email}
          onChange={(newValue) => {
            setFieldValue("email", newValue);
          }}
          label="会社メールアドレス"
          labelWidth={175}
          handleBlur={handleBlur}
          disabled={false}
          placeholder="t.yamamoto@sysdt.com"
          checkStatus={checkLoginNameStatus}
          errors={errors.email}
          touched={touched.email}
          isRequired
        />
      </div>
      {!isUpdateMode && (
        <div className="mt-8">
          <div className="flex pb-4">ユーザー情報</div>
          <div className="w-full flex-col gap-2">
            <AsyncCheckExistedInput
              id={`accounts[0]email`}
              value={values.accounts[0].email}
              onChange={(newValue) => {
                setFieldValue("accounts[0].email", newValue);
                checkLoginEmail(newValue);
              }}
              label="メールアドレス"
              labelWidth={175}
              handleBlur={handleBlur}
              disabled={false}
              placeholder="t.yamamoto@sysdt.com"
              checkStatus={checkLoginNameStatus}
              errors={errors?.accounts?.[0]?.email}
              touched={touched?.accounts?.[0]?.email}
              requiredUnique
              requiredUniqueError={
                <div className="flex flex-col">
                  <p>このメールはすでに使用されています。</p>
                  <p>別のメールを選択してください。</p>
                </div>
              }
              isRequired
            />
            <InputText
              id={`accounts[0]password`}
              value={values.accounts[0].password}
              onChange={(newValue) =>
                setFieldValue("accounts[0].password", newValue)
              }
              label="パスワード"
              labelWidth={175}
              handleBlur={handleBlur}
              disabled={false}
              placeholder="SystemD123@"
              errors={errors?.accounts?.[0]?.password}
              touched={touched?.accounts?.[0]?.password}
              isRequired
            />
          </div>
        </div>
      )}
    </div>
  );
}
