import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import React, { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

export default function InputText({
  id,
  value,
  onChange,
  label,
  labelWidth,
  handleBlur,
  disabled,
  placeholder,
  touched,
  errors,
  hideError,
  isRequired = false,
}) {
  const inputId = useMemo(() => {
    if (!id) {
      return uuidv4();
    }
    return id;
  }, [id]);

  const onChangeValue = (e) => {
    onChange(e.currentTarget.value);
  };

  const inputClasses = () => {
    const baseClasses = `w-full h-full pl-4 py-2.5 text-sm tracking-[.2em] rounded-[10px] border border-[#B3B3B3] !outline-none focus:border-blue-500 ${
      hideError ? "pr-2.5" : "pr-12"
    }`;

    if (disabled) return `${baseClasses} !bg-background-select`;
    if (touched && errors) return `${baseClasses} !border-red-500`;

    return baseClasses;
  };

  return (
    <div className="w-full h-[52px] flex items-center">
      {label ? (
        <label
          htmlFor={inputId}
          className="h-full pr-4 flex items-center"
          style={{
            width: `${labelWidth || 0}px`,
          }}
        >
          <span
            className="w-full text-sm leading-[22px] tracking-[.2em] whitespace-nowrap"
            style={{
              direction: "rtl",
            }}
          >
            {label}
          </span>
          {isRequired && <span style={{ color: "red" }}>*</span>}
        </label>
      ) : null}

      <div
        className={`h-full relative rounded-[10px] ${
          touched && errors ? "shadow-[0px_0px_1px_1px_#dc2626]" : ""
        }`}
        style={{
          width: `calc(100% - ${labelWidth}px)`,
        }}
      >
        <Tooltip arrow title={errors}>
          <input
            id={inputId}
            name={inputId}
            value={value}
            placeholder={placeholder}
            onChange={onChangeValue}
            onBlur={(e) =>
              typeof handleBlur === "function" && handleBlur(e)
            }
            disabled={disabled}
            className={inputClasses()}
          />
        </Tooltip>
        {!hideError ? (
          <div className="w-8 h-8 absolute top-[9px] right-1 flex items-center justify-center">
            {touched && errors ? (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                size="lg"
                className="text-lg text-red-500"
              />
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}
