// FE-1
import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Dialog, Popover } from "@mui/material";

import { logout, selectCurrentUser } from "app/redux/authReducer";
import useServiceWorker from "app/hooks/useServiceWorker";

import questionMarkIcon from "app/assets/svg/question-mark.svg";
import logo from "app/assets/images/logo.png";
import userIcon from "app/assets/svg/user.svg";
import userBlueIcon from "app/assets/svg/user-blue.svg";
import bellIcon from "app/assets/svg/bell.svg";
import NotiDropdown from "app/components/UI/Dropdown";
import notificationServices from "app/services/api/notificationServices";
import { getCurrentUserId } from "app/services/socket";
import routePaths from "app/route/routePaths";

function TopBreadcrumb(props) {
  const { breadcrumb } = props;
  return (
    <div className="w-auto h-full grow text-custom-size py-3.5 mr-3 overflow-x-auto custom-scrollbar-none">
      <p className="whitespace-nowrap">
        {Array.isArray(breadcrumb) && breadcrumb.length > 0
          ? breadcrumb.map((item, index) => (
              <React.Fragment key={index}>
                {index !== 0 ? <span> \ </span> : null}

                <button className="inline text-gray-300">{item.label}</button>
              </React.Fragment>
            ))
          : null}
      </p>
    </div>
  );
}

function TopRightLogedInMenu({ notificationList, handleReadNotifiction, onLogout }) {
  const [openUserInfoEl, setOpenUserInfoEl] = useState(null);
  const [showOperatingInstructions, setShowOperatingInstructions] =
    useState(false);
  const dispatch = useDispatch();
  
  const handleLogout = () => {
    typeof onLogout === "function" && onLogout()
    dispatch(logout());
  };

  const userInfo = useSelector(selectCurrentUser);

  return (
    <>
      <div className="w-auto h-full flex items-center gap-4 md:gap-[25px]">
        <NotiDropdown
          icon={bellIcon}
          list={notificationList?.reverse()}
          onClickRead={handleReadNotifiction}
        />
        <button
          onClick={() => setShowOperatingInstructions(true)}
          type="button"
          className="w-[30px] h-[30px]"
        >
          <img alt="bell" className="w-full h-full" src={questionMarkIcon} />
        </button>

        <button
          type="button"
          onClick={(e) => setOpenUserInfoEl(e.currentTarget)}
          className="w-[30px] h-[30px]"
        >
          <img alt="user" className="w-full h-full" src={userIcon} />
        </button>
      </div>

      <Popover
        open={Boolean(openUserInfoEl)}
        anchorEl={openUserInfoEl}
        onClose={(e) => setOpenUserInfoEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="w-[240px] h-auto px-4 py-2 flex flex-col">
          <div className="w-full py-3 flex items-center">
            <div className="w-7 h-7">
              <img alt="user" className="w-full h-full" src={userBlueIcon} />
            </div>
            <p className="w-[calc(100%-28px)] pl-2 h-auto text-custom-size truncate">
              {userInfo?.loginName || ""}
            </p>
          </div>
          <div className="w-full h-[1px] bg-gray-300"></div>
          <div className="w-full">
            <button
              onClick={handleLogout}
              className="w-full h-auto text-start py-3 text-custom-size"
            >
              ログアウト
            </button>
          </div>
        </div>
      </Popover>

      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={showOperatingInstructions}
        onClose={() => setShowOperatingInstructions(false)}
      >
        <div className="w-full h-[80vh]">
          <embed src="/docs.pdf" className="w-full h-full" />
        </div>
      </Dialog>
    </>
  );
}

export const NotificationContext = createContext();

function MainLayout(props) {
  const { children, breadcrumb } = props;
  const [notificationList, setNotificationList] = useState([]);

  const { msgPayload, register, unregister: unregisterSW } = useServiceWorker();

  const fetchNoti = () => {
    notificationServices.getALlNotification(getCurrentUserId()).then((res) => {
      if (res?.data?.result?.length) {
        setNotificationList(res?.data?.result);
      }
    });
  };
  
  useEffect(() => {
    fetchNoti();
  }, [msgPayload]);

  const handleReadNotifiction = (id) => {
    notificationServices.markReadNotification(id).then(() => {
      fetchNoti();
    });
  };

  return (
    <>
      <div className="w-full h-screen relative">
        {/* Top Logo */}
        {/* Top Logo */}
        <div className="w-full h-[90px] bg-white">
          <div className="custom-container h-full">
            <div className="w-full h-full flex items-center">
              <Link to={routePaths.topMenu}>
                <img
                  alt="logo"
                  className="w-[200px] h-[38px] object-contain"
                  src={logo}
                />
              </Link>
            </div>
          </div>
        </div>

        {/* Top Breadcrumb & Loged in Menu */}
        <div className="w-full h-[46px] bg-white">
          <div className="custom-container h-full">
            <div className="w-full h-full bg-[#001C4F] text-white pl-3 pr-3 md:pr-7 flex items-center justify-between">
              <div className="w-auto h-full grow mr-3 overflow-x-auto custom-scrollbar-none">
                <TopBreadcrumb breadcrumb={breadcrumb} />
              </div>

              <div className="w-auto h-full shrink-0">
                <TopRightLogedInMenu
                  notificationList={notificationList}
                  handleReadNotifiction={handleReadNotifiction}
                  onLogout={unregisterSW}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-auto min-h-content">
          <NotificationContext.Provider value={{ msgPayload }}>
            {children}
          </NotificationContext.Provider>
        </div>
      </div>

      <ToastContainer
        position="bottom-left"
        autoClose={1500}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        limit={3}
      />
    </>
  );
}

export default MainLayout;
