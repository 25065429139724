export const DEFAULT_QUOTATION_BASICS = [
  {
    contractType: "eachTime",
    billingTiming: "nextMonth",
    inspectionPeopleNumber: 20,
    testSampleNumber: "to5",
    quotationPrice: 70000,
    quotationPriceUnit: "perInspection",
  },
  {
    contractType: "eachTime",
    billingTiming: "nextMonth",
    inspectionPeopleNumber: 20,
    testSampleNumber: "to10",
    quotationPrice: 68000,
    quotationPriceUnit: "perInspection",
  },
  {
    contractType: "eachTime",
    billingTiming: "nextMonth",
    inspectionPeopleNumber: 20,
    testSampleNumber: "to20",
    quotationPrice: 66000,
    quotationPriceUnit: "perInspection",
  },
  {
    contractType: "eachTime",
    billingTiming: "nextMonth",
    inspectionPeopleNumber: 20,
    testSampleNumber: "to31",
    quotationPrice: 64000,
    quotationPriceUnit: "perInspection",
  },
  {
    contractType: "annually",
    billingTiming: "nextMonth",
    inspectionPeopleNumber: 20,
    testSampleNumber: "to20PerYear",
    quotationPrice: 60000,
    quotationPriceUnit: "perInspection",
  },
  {
    contractType: "annually",
    billingTiming: "nextMonth",
    inspectionPeopleNumber: 20,
    testSampleNumber: "to30PerYear",
    quotationPrice: 58000,
    quotationPriceUnit: "perInspection",
  },
  {
    contractType: "annually",
    billingTiming: "nextMonth",
    inspectionPeopleNumber: 20,
    testSampleNumber: "to40PerYear",
    quotationPrice: 55000,
    quotationPriceUnit: "perInspection",
  },
  {
    contractType: "annually",
    billingTiming: "nextMonth",
    inspectionPeopleNumber: 20,
    testSampleNumber: "to50PerYear",
    quotationPrice: 53000,
    quotationPriceUnit: "perInspection",
  },
  {
    contractType: "annually",
    billingTiming: "nextMonth",
    inspectionPeopleNumber: 20,
    testSampleNumber: "to60PerYear",
    quotationPrice: 51000,
    quotationPriceUnit: "perInspection",
  },
  {
    contractType: "annually",
    billingTiming: "nextMonth",
    inspectionPeopleNumber: 20,
    testSampleNumber: "to70PerYear",
    quotationPrice: 49000,
    quotationPriceUnit: "perInspection",
  },
  {
    contractType: "annually",
    billingTiming: "nextMonth",
    inspectionPeopleNumber: 20,
    testSampleNumber: "to80PerYear",
    quotationPrice: 47000,
    quotationPriceUnit: "perInspection",
  },
  {
    contractType: "annually",
    billingTiming: "nextMonth",
    inspectionPeopleNumber: 20,
    testSampleNumber: "to90PerYear",
    quotationPrice: 46000,
    quotationPriceUnit: "perInspection",
  },
  {
    contractType: "annually",
    billingTiming: "nextMonth",
    inspectionPeopleNumber: 20,
    testSampleNumber: "to100PerYear",
    quotationPrice: 45000,
    quotationPriceUnit: "perInspection",
  },
];
