import React, { useEffect, useMemo, useState } from "react";
import TableInfoHeader from "../../../components/Table/CommonComponents/TableInfo/TableInfoHeader";
import PageNoData from "../../../components/CommonPage/PageNoData";
import {
  contractTypeLabel,
  contractTypeOptions,
  inspectionPeopleNumberOptions,
  testSampleNumberOptions,
} from "../../../constants";

import { NumericFormat } from "react-number-format";
import SelectPopoverSmall from "../../../components/UI/Form/Select/SelectPopover/SelectPopoverSmall";
import Button from "../../../components/UI/Button";
import PencilSVG from "app/assets/svg/pencil.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const tableConfig = [
  {
    title: "No.",
    width: "6.38%",
  },
  {
    title: "項目",
    width: "23.6%",
  },
  {
    title: "契約形態",
    width: "12.34%",
  },
  {
    title: "ボリューム",
    width: "12.34%",
  },
  {
    title: "試験人数",
    width: "12.34%",
  },
  {
    title: "検体数",
    width: "8.58%",
  },
  {
    title: "単価",
    width: "10.73%",
  },
  {
    title: "金額",
    width: "13.73%",
  },
];

const volumnOptions = [
  {
    value: "1-5検体",
    label: "1-5検体",
  },
];

function InvoiceItemInfoInput(props) {
  const { value, onChange } = props;

  return (
    <div className="w-full h-full">
      <input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="w-full h-10 border border-[#B3B3B3] bg-white rounded-[10px] !outline-none text-custom-size py-2.5 px-3"
      />
    </div>
  );
}

function InvoiceItemInfoInputNumber(props) {
  const { value, onChange } = props;

  return (
    <div className="w-full h-10">
      <NumericFormat
        value={value}
        onValueChange={(values) => {
          if (typeof values.floatValue === "number")
            onChange(values.floatValue);
          else onChange(0);
        }}
        allowNegative={false}
        decimalScale={0}
        valueIsNumericString={true}
        className="w-full h-full px-4 py-1 input-border-bg-colo text-center border border-[#B3B3B3] bg-white rounded-[10px] !outline-none"
        min={0}
      />
    </div>
  );
}

function InvoiceItemInfoSelect(props) {
  const { value, onChange, options } = props;

  return (
    <div className="w-full h-full">
      <SelectPopoverSmall
        selectedValue={value}
        setSelectedValue={onChange}
        options={options}
      />
    </div>
  );
}

function InvoiceItemInfoReadonlyCell({ value, className }) {
  return (
    <div
      className={`w-full h-full border border-[#B3B3B3] bg-[#e0e4e5] flex items-center justify-center ${
        className || ""
      }`}
    >
      <span>{value}</span>
    </div>
  );
}

function InvoiceItemInfosInfoRow(props) {
  const { index, rowData, handleChangeRow } = props;
  const handleChangeField = (field, newValue) => {
    handleChangeRow({ ...rowData, [field]: newValue });
  };
  return (
    <div className="w-full h-10 flex items-center pr-8 relative">
      <div className="w-[6.38%] h-full">
        <InvoiceItemInfoReadonlyCell value={index + 1} />
      </div>
      <div className="w-[23.6%] h-full">
        {rowData?.isShowEdit ? (
          <InvoiceItemInfoInput
            value={rowData?.name}
            onChange={(newValue) => handleChangeField("name", newValue)}
          />
        ) : (
          <div className="w-full h-full border border-[#B3B3B3] bg-[#e0e4e5] px-2 py-2.5 text-center">
            <p className="text-sm leading-[18px] tracking-[.2em] truncate">
              {rowData?.name}
            </p>
          </div>
        )}
      </div>
      <div className="w-[12.34%] h-full">
        {rowData?.isShowEdit ? (
          <InvoiceItemInfoSelect
            value={rowData?.contractType}
            onChange={(newValue) => handleChangeField("contractType", newValue)}
            options={contractTypeOptions}
          />
        ) : (
          <div className="w-full h-full border border-[#B3B3B3] bg-[#e0e4e5] px-2 py-2.5 text-center">
            <p className="text-sm leading-[18px] tracking-[.2em] truncate">
              {contractTypeLabel?.[rowData?.contractType] || ""}
            </p>
          </div>
        )}
      </div>
      <div className="w-[12.34%] h-full">
        {rowData?.isShowEdit ? (
          <InvoiceItemInfoSelect
            value={rowData?.volumn}
            onChange={(newValue) => handleChangeField("volumn", newValue)}
            options={testSampleNumberOptions}
          />
        ) : (
          <div className="w-full h-full border border-[#B3B3B3] bg-[#e0e4e5] px-2 py-2.5 text-center">
            <p className="text-sm leading-[18px] tracking-[.2em] truncate">
              {testSampleNumberOptions.find(
                (item) => item.value === rowData?.volumn
              )?.label || ""}
            </p>
          </div>
        )}
      </div>
      <div className="w-[12.34%] h-full">
        {rowData?.isShowEdit ? (
          <InvoiceItemInfoSelect
            value={rowData?.testPeopleNumber}
            onChange={(newValue) =>
              handleChangeField("testPeopleNumber", newValue)
            }
            options={inspectionPeopleNumberOptions}
          />
        ) : (
          <div className="w-full h-full border border-[#B3B3B3] bg-[#e0e4e5] px-2 py-2.5 text-center">
            <p className="text-sm leading-[18px] tracking-[.2em] truncate">
              {inspectionPeopleNumberOptions.find(
                (item) => item.value === rowData?.testPeopleNumber
              )?.label || ""}
            </p>
          </div>
        )}
      </div>
      <div className="w-[8.58%] h-full">
        {rowData?.isShowEdit ? (
          <InvoiceItemInfoInputNumber
            value={rowData?.testSampleNumber}
            onChange={(newValue) =>
              handleChangeField("testSampleNumber", newValue)
            }
          />
        ) : (
          <div className="w-full h-full border border-[#B3B3B3] bg-[#e0e4e5] px-2 py-2.5 text-center">
            <p className="text-sm leading-[18px] tracking-[.2em] truncate">
              {rowData?.testSampleNumber}
            </p>
          </div>
        )}
      </div>
      <div className="w-[10.73%] h-full">
        {rowData?.isShowEdit ? (
          <InvoiceItemInfoInputNumber
            value={rowData?.unitPrice}
            onChange={(newValue) => handleChangeField("unitPrice", newValue)}
          />
        ) : (
          <div className="w-full h-full border border-[#B3B3B3] bg-[#e0e4e5] px-2 py-2.5 text-center">
            <p className="text-sm leading-[18px] tracking-[.2em] truncate">
              {rowData?.unitPrice}
            </p>
          </div>
        )}
      </div>
      <div className="w-[13.73%] h-full">
        <InvoiceItemInfoReadonlyCell
          value={rowData?.totalPrice}
          className="bg-[#e0e4e5]"
        />
      </div>

      <button
        type="button"
        onClick={() => handleChangeField("isShowEdit", !rowData?.isShowEdit)}
        className="absolute top-1 right-0 w-8 h-8 flex items-center justify-center"
      >
        {rowData?.isShowEdit ? (
          <FontAwesomeIcon icon={faCheck} size="lg" />
        ) : (
          <img src={PencilSVG} className="" alt="" />
        )}
      </button>
    </div>
  );
}

function InvoiceItemInfosTable(props) {
  const { invoiceItemInfos, setInvoiceItemInfos } = props;

  const handleChangeRow = (index, newRowData) => {
    const newData = [...invoiceItemInfos];
    newData[index] = newRowData;
    setInvoiceItemInfos(newData);
  };

  const totalInvoiceItemInfo = useMemo(() => {
    if (!Array.isArray(invoiceItemInfos)) return 0;
    return invoiceItemInfos.reduce(
      (a, b) => a + (Number(b?.totalPrice) || 0),
      0
    );
  }, [invoiceItemInfos]);

  const handleRecalculation = () => {
    return setInvoiceItemInfos(
      invoiceItemInfos.map((item) => ({
        ...item,
        isShowEdit: false,
        totalPrice:
          (Number(item?.unitPrice) || 0) *
          (Number(item?.testSampleNumber) || 0),
      }))
    );
  };

  return (
    <>
      <div className="w-full h-auto flex justify-end mb-3">
        <Button onClick={handleRecalculation}>再計算</Button>
      </div>

      <div className={`w-full h-full overflow-auto relative min-w-[900px]`}>
        <div className="w-full h-auto pr-8">
          <TableInfoHeader tableConfig={tableConfig} />
        </div>

        {Array.isArray(invoiceItemInfos) && invoiceItemInfos.length > 0 ? (
          <>
            {invoiceItemInfos.map((rowData, index) => (
              <InvoiceItemInfosInfoRow
                key={index}
                index={index}
                tableConfig={tableConfig}
                rowData={rowData}
                handleChangeRow={(newValue) => handleChangeRow(index, newValue)}
                isShowEdit={rowData?.isShowEdit}
              />
            ))}

            <div className="w-full h-10 flex pr-8">
              <div className="w-[86.27%] h-full px-5 flex items-center justify-end border border-[#B3B3B3] bg-[#ebebeb]">
                <span>合計(税抜)</span>
              </div>
              <div className="w-[13.73%] h-full flex items-center justify-center border border-[#B3B3B3] bg-[#e0e4e5]">
                {totalInvoiceItemInfo}
              </div>
            </div>
          </>
        ) : (
          <PageNoData />
        )}
      </div>
    </>
  );
}

export default InvoiceItemInfosTable;
