const routePaths = {
  login: "/login",
  register: "/register",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-assword",

  topMenu: "/top-menu",

  /* -------------------------------------------- */
  /*****     Dashboard (1. ダッシュボード)    *****/
  dashboard: "/dashboard",

  /* -------------------------------------------- */
  /*****     Customer (2. 顧客情報の管理)    *****/
  customer: "/customer",
  createCustomer: "/customer/create",
  detailCustomer: (id) =>
    id !== undefined
      ? `/customer/detail/${id}`
      : "/customer/detail/:id",
  updateCustomer: (id) =>
    id !== undefined
      ? `/customer/update/${id}`
      : "/customer/update/:id",

  /* -------------------------------------------- */
  /*****     Project (3. 案件情報の管理)    *****/
  project: "/project",
  projectTestSchedule: "/project/test-schedule",

  /* -------------------------------------------- */
  /*****     Quotation (4. 見積書の管理)    *****/
  quotation: "/quotation",
  createQuotation: "/quotation-create",
  updateQuotation: "/quotation-update",

  /* -------------------------------------------- */
  /*****     Purchase Order (5. 発注書の管理)    *****/

  /* -------------------------------------------- */
  /*****     Test Plan (6. 試験計画書の管理)    *****/
  testPlan: "/test-plan",
  createTestPlan: "/test-plan-create",
  updateTestPlan: "/test-plan-update",

  /* -------------------------------------------- */
  /*****     Test Sample Shipping (7. 試験サンプル発送管理)    *****/

  /* -------------------------------------------- */
  /*****     Report (8. 報告書の管理)    *****/
  report: "/report",
  createReport: "/report-create",
  updateReport: "/report-update",
  testFormGeneration: "/report/test-form-generation",
  batchReport: "/report-batch",

  /* -------------------------------------------- */
  /*****     Invoice (9. 請求書の管理)    *****/
  invoice: "/invoice",
  createInvoice: "/invoice-create",
  updateInvoice: "/invoice-update",
  batchInvoice: "/invoice-batch",

  /* -------------------------------------------- */
  /*****     Contract (10. 契約書の管理)    *****/
  contract: "/contract",
  createContract: "/contract-create",

  /* -------------------------------------------- */
  /*****     System Settings (11. システム設定)    *****/
  systemSettings: "/system-settings",
  systemSettingsCustomer: "/system-settings/customer",
  systemSettingsCustomerDetail: (id) =>
    id !== undefined
      ? `/system-settings/customer/${id}`
      : "/system-settings/customer/:id",
  systemSettingsCustomerUpdate: (id) =>
    id !== undefined
      ? `/system-settings/customer-update/${id}`
      : "/system-settings/customer-update/:id",

  systemSettingsCreateCustomer: "/system-settings/customer-create",

  systemSettingsUser: "/system-settings/user",
  systemSettingsUserDetail: (id) =>
    id !== undefined
      ? `/system-settings/user/${id}`
      : "/system-settings/user/:id",
  systemSettingsUserUpdate: (id) =>
    id !== undefined
      ? `/system-settings/user-update/${id}`
      : "/system-settings/user-update/:id",
  systemSettingsCreateUser: "/system-settings/user-create",
  chat: "/chat",
};

export default routePaths;
