import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { useMediaQuery } from "@mui/material";
import Button from "app/components/UI/Button";
import PageLoading from "app/components/CommonPage/PageLoading";
import PageError from "app/components/CommonPage/PageError";
import InputText from "app/components/UI/Form/Input/InputText";
import { invoiceServices, commonServices } from "app/services/api";
import {
  callApiStatus,
  fileNameType,
  LABEL_WIDTH,
} from "app/constants";
import PageLoadingBackdrop from "../../../components/CommonPage/PageLoadingBackdrop";
import SelectCustomerPic from "../../../components/UI/Form/Select/SelectCustomerPic";
import messages from "../../../services/api/messages";
import InputDate from "../../../components/UI/Form/Input/InputDate";
import InvoiceItemInfosTable from "./InvoiceItemInfosTable";

const CreateInvoiceForm = ({
  caseInfo,
  initInvoice,
  customerId,
  scheduleId,
  customerName,
}) => {
  const isMd = useMediaQuery("(max-width:768px)");
  const [invoiceInfo, setInvoiceInfo] = useState(initInvoice);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreated, setIsCreated] = useState(false);

  const getFormData = (rawData) => {
    return {
      caseInfoId: caseInfo._id,
      customerId,
      testScheduleId: scheduleId,
      personInCharge: rawData.customerPic,
      memo: rawData.memo,
      paymentDeadline: rawData.paymentDeadline,
      invoiceItemInfos: Array.isArray(rawData.invoiceItemInfos)
        ? rawData.invoiceItemInfos.map((item) => ({
            name: item.name,
            contractType: item.contractType,
            volumn: item.volumn,
            testPeopleNumber: item.testPeopleNumber,
            testSampleNumber: item.testSampleNumber,
            unitPrice: item.unitPrice,
            totalPrice:
              Number(item.unitPrice) * Number(item.testSampleNumber),
          }))
        : [],
    };
  };

  const isDisabledSubmit = useMemo(() => {
    return (
      !invoiceInfo?.generatedInvoiceExcel ||
      !invoiceInfo?.generatedDeliverySlipExcel
    );
  }, [
    invoiceInfo?.generatedDeliverySlipExcel,
    invoiceInfo?.generatedInvoiceExcel,
  ]);

  const handleChangeCreateInvoiceInfo = (field, value) => {
    setInvoiceInfo({
      ...invoiceInfo,
      [field]: value,
      generatedInvoicePdf: "",
      generatedInvoiceExcel: "",
      generatedDeliverySlipPdf: "",
      generatedDeliverySlipExcel: "",
    });
  };

  const handleConfirmCreate = () => {
    if (!invoiceInfo.paymentDeadline) {
      toast.error(messages.required("振込期限"));
      return;
    }
    const data = getFormData(invoiceInfo);
    invoiceServices
      .createFileDownload(data)
      .then((res) => {
        setInvoiceInfo((oldState) => ({
          ...oldState,
          generatedInvoiceExcel: res.data.fileBill._id,
          generatedDeliverySlipExcel: res.data.fileDeliverly._id,
        }));

        const caseId = caseInfo.caseId || "";
        const invoiceFileType = fileNameType["invoice"];
        const deliveryFileType = fileNameType["delivery"];
        const timeStamp = moment().format("YYYYMMDDHHmmss");

        setTimeout(() => {
          commonServices
            .downloadWithFileId(
              res.data.fileBill._id,
              "xlsx",
              `${invoiceFileType}_${caseId}_${customerName}_${timeStamp}`
            )
            .catch(() => toast.error(messages.downloadFail));
        }, 1000);

        setTimeout(() => {
          commonServices
            .downloadPDFWithFileId(
              res.data.fileBill._id,
              `${invoiceFileType}_${caseId}_${customerName}_${timeStamp}`
            )
            .catch(() => toast.error(messages.downloadFail));
        }, 1000);

        setTimeout(() => {
          commonServices
            .downloadWithFileId(
              res.data.fileDeliverly._id,
              "xlsx",
              `${deliveryFileType}_${caseId}_${customerName}_${timeStamp}`
            )
            .catch(() => toast.error(messages.downloadFail));
        }, 1500);

        setTimeout(() => {
          commonServices
            .downloadPDFWithFileId(
              res.data.fileDeliverly._id,
              `${deliveryFileType}_${caseId}_${customerName}_${timeStamp}`
            )
            .catch(() => toast.error(messages.downloadFail));
        }, 1500);

        toast.success(messages.confirm(true));
        setIsLoading(false);
      })
      .catch(() => {
        toast.error(messages.confirm(false));
        setIsLoading(false);
      });
  };

  const handleSubmitCreate = () => {
    if (isDisabledSubmit) return;
    if (!invoiceInfo.paymentDeadline) {
      toast.error(messages.required("振込期限"));
      return;
    }
    const data = getFormData(invoiceInfo);
    invoiceServices
      .createInvoice({
        ...data,
        generatedInvoiceExcel: invoiceInfo.generatedInvoiceExcel,
        generatedDeliverySlipExcel:
          invoiceInfo.generatedDeliverySlipExcel,
      })
      .then((res) => {
        toast.success(messages.create(true));
        setIsLoading(false);
        setIsCreated(true);
      })
      .catch((error) => {
        toast.error(messages.create(false));
        setIsLoading(false);
      });
  };

  if (isCreated)
    return (
      <div className="w-full h-auto my-12 flex items-center justify-center text-xl">
        請求書は作成しました。修正する場合は、請求書の修正で修正していください。
      </div>
    );

  return (
    <>
      <div className="w-full h-auto">
        <div className="w-full h-auto flex items-center justify-center mb-3 flex-wrap">
          <div className="w-full mb-3 md:mb-0 md:w-1/2 h-full md:pr-12">
            <InputText
              id="quotationId"
              value={invoiceInfo?.quotationId || ""}
              label="見積番号"
              disabled={true}
              labelWidth={isMd ? LABEL_WIDTH.RIGHT : LABEL_WIDTH.LEFT}
            />
          </div>

          <div className="w-full md:w-1/2 h-full">
            <SelectCustomerPic
              pic={{ id: invoiceInfo.customerPic }}
              setPic={(newPic) => {
                handleChangeCreateInvoiceInfo(
                  "customerPic",
                  newPic?.id
                );
              }}
              labelWidth={LABEL_WIDTH.RIGHT}
              disabled={true}
            />
          </div>
        </div>

        <div className="w-full h-auto flex items-center justify-center mb-3">
          <div className="w-1/2 h-[52px] pr-12">
            <InputDate
              id="paymentDeadline"
              value={invoiceInfo.paymentDeadline}
              onChange={(newValue) => {
                handleChangeCreateInvoiceInfo(
                  "paymentDeadline",
                  newValue
                );
              }}
              label="振込期限"
              labelWidth={isMd ? LABEL_WIDTH.RIGHT : LABEL_WIDTH.LEFT}
              isRequired
            />
          </div>

          <div className="w-1/2 h-full"></div>
        </div>

        <div className="w-full h-auto flex items-center justify-center mb-8">
          <InputText
            value={invoiceInfo.memo}
            onChange={(newValue) => {
              handleChangeCreateInvoiceInfo("memo", newValue);
            }}
            label="備考"
            labelWidth={isMd ? LABEL_WIDTH.RIGHT : LABEL_WIDTH.LEFT}
          />
        </div>

        <div className="mb-[14px]">
          <InvoiceItemInfosTable
            invoiceItemInfos={invoiceInfo.invoiceItemInfos}
            setInvoiceItemInfos={(newValue) => {
              handleChangeCreateInvoiceInfo(
                "invoiceItemInfos",
                newValue
              );
            }}
          />
        </div>

        <div className="w-full h-auto flex items-center justify-end gap-3">
          <Button onClick={handleConfirmCreate}>確認する</Button>

          <Button
            disabled={isDisabledSubmit}
            onClick={handleSubmitCreate}
            type={isDisabledSubmit ? "disabled" : "primary"}
          >
            提出する
          </Button>
        </div>
      </div>

      <PageLoadingBackdrop open={isLoading} />
    </>
  );
};

const CreateInvoice = ({ caseInfo, customer, schedule }) => {
  const [loadInitState, setLoadInitState] = useState({
    loadingStatus: callApiStatus.idle,
    data: null,
    error: null,
  });

  const loadInitInvoice = useCallback(() => {
    if (!caseInfo?._id) return;

    setLoadInitState({
      loadingStatus: callApiStatus.loading,
      data: null,
      error: null,
    });

    invoiceServices
      .getInitNewInvoice(customer.id, caseInfo.quotation)
      .then((initInvoice) => {
        setLoadInitState({
          loadingStatus: callApiStatus.success,
          data: initInvoice,
          error: null,
        });
      })
      .catch((error) => {
        toast.error(messages.callApiFail);
        setLoadInitState({
          loadingStatus: callApiStatus.error,
          data: null,
          error: error,
        });
      });
  }, [customer?.id, caseInfo]);

  useEffect(() => {
    loadInitInvoice();
  }, [loadInitInvoice]);

  if (loadInitState.loadingStatus === callApiStatus.loading) {
    return <PageLoading />;
  }

  if (
    loadInitState.loadingStatus === callApiStatus.error ||
    loadInitState.error
  ) {
    return <PageError />;
  }

  if (
    loadInitState.loadingStatus === callApiStatus.success &&
    loadInitState.data
  ) {
    return (
      <CreateInvoiceForm
        initInvoice={loadInitState.data}
        customerId={customer?.id}
        scheduleId={schedule?.id}
        caseInfo={caseInfo}
        customerName={customer?.companyNameKanji || ""}
      />
    );
  }
  return null;
};

export default CreateInvoice;
