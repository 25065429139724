import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Button from "app/components/UI/Button";
import PageError from "app/components/CommonPage/PageError";
import PageLoading from "app/components/CommonPage/PageLoading";
import TableInfo from "app/components/Table/CommonComponents/TableInfo";
import { systemSettingsServices } from "app/services/api";
import { getNameKana, getNameKanji } from "app/utils";
import { userRolesLabel } from "app/constants";
import routePaths from "app/route/routePaths";

import SystemSettingContentLayout from "../../Components/ContentLayout";
import messages from "../../../../services/api/messages";

function SystemSettingContentLayoutContent(props) {
  const { id, userInfo } = props;
  return (
    <div className="p-4">
      <div className="flex justify-between pb-4">
        <div className="flex items-end">ユーザー情報</div>
        <Link to={routePaths.systemSettingsUserUpdate(id)}>
          <Button>変更する</Button>
        </Link>
      </div>

      <TableInfo
        tableConfig={[
          {
            title: "項目",
            width: "30%",
            dataKey: "title",
          },
          {
            title: "詳細",
            width: "70%",
            dataKey: "value",
          },
        ]}
        tableData={[
          {
            title: "ユーザーID",
            value: userInfo?.userId || "",
          },
          {
            title: "ユーザー名",
            value: getNameKanji(userInfo),
          },
          {
            title: "フリガナ",
            value: getNameKana(userInfo),
          },
          {
            title: "メールアドレス",
            value: userInfo?.email || "",
          },
          {
            title: "権限",
            value: userRolesLabel[userInfo?.accountType] || "",
          },
        ]}
      />
    </div>
  );
}

export default function SystemSettingsUserDetailPage() {
  const { id } = useParams();

  const [loadInitState, setLoadInitState] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const loadInit = useCallback(() => {
    setLoadInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    systemSettingsServices
      .getUserDetail(id)
      .then((res) => {
        setLoadInitState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        toast.error(messages.callApiFail);
        setLoadInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [id]);

  useEffect(() => {
    loadInit();
  }, [loadInit]);

  return (
    <SystemSettingContentLayout>
      <div className="w-full grow flex flex-col pt-2.5">
        {loadInitState.isLoading ? <PageLoading /> : null}

        {!loadInitState.isLoading && loadInitState.error ? <PageError /> : null}

        {!loadInitState.isLoading &&
        !loadInitState.error &&
        loadInitState.data ? (
          <SystemSettingContentLayoutContent
            id={id}
            userInfo={loadInitState.data}
          />
        ) : null}
      </div>
    </SystemSettingContentLayout>
  );
}
