import routePaths from "../../route/routePaths";

const SIDE_MENU = [
  {
    linkTo: routePaths.createReport,
    linkTitle: "報告書の作成",
  },
  {
    linkTo: routePaths.updateReport,
    linkTitle: "報告書の修正",
  },
  {
    linkTo: routePaths.batchReport,
    linkTitle: "報告書一括送付",
  },
  {
    linkTo: routePaths.testFormGeneration,
    linkTitle: "試験フォーム生成",
  },
  { linkTo: routePaths.topMenu, linkTitle: "トップへ戻る" },
];

export { SIDE_MENU };
