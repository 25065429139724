import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import routePaths from "app/route/routePaths";
import { caseStatusLabel, caseStatusBgColor } from "app/constants";
import TableInfo from "../../CommonComponents/TableInfo";

function CaseStatusCell(props) {
  const { cellContent, width, textAlign } = props;

  const bgColor = caseStatusBgColor[cellContent] || caseStatusBgColor.default;

  return (
    <div
      className={`h-full border border-[#B3B3B3] px-4 py-4`}
      style={{
        width: width,
        textAlign: textAlign || "start",
        backgroundColor: bgColor,
      }}
    >
      <p className="text-sm leading-[18px] tracking-[.2em] truncate">
        {caseStatusLabel[cellContent]}
      </p>
    </div>
  );
}

function ProjectIdCell(props) {
  const { cellContent, width, textAlign } = props;

  return (
    <div
      className="h-full border border-[#B3B3B3] px-2 py-4 shrink-0h-full shrink-0"
      style={{
        width: width,
        textAlign: textAlign || "start",
      }}
    >
      <Link
        className="block w-full text-sm leading-[18px] tracking-[.2em] truncate"
        to={`${routePaths.project}#${cellContent?.id || ""}`}
      >
        {cellContent?.project_id || ""}
      </Link>
    </div>
  );
}

function AllCaseTable(props) {
  const { list } = props;
  const tableData = useMemo(() => {
    return list.map((item) => ({
      project_id: {
        id: item?._id,
        project_id: item?.quotationInfo?.quotationId || "",
      },
      company_name: item.customer?.companyNameKana,
      requested_sample_quantity: item.sampleNumber,
      status: item.caseStatus,
    }));
  }, [list]);

  return (
    <div className="w-full h-auto overflow-x-auto">
      <TableInfo
        tableConfig={[
          {
            title: "案件ID",
            width: "28%",
            textAlign: "center",
            dataKey: "project_id",
            CustomTableCell: ProjectIdCell,
          },
          {
            title: "企業名",
            width: "36%",
            textAlign: "center",
            dataKey: "company_name",
          },
          {
            title: "検体数",
            width: "17%",
            textAlign: "center",
            dataKey: "requested_sample_quantity",
          },
          {
            title: "状況",
            width: "19%",
            textAlign: "center",
            dataKey: "status",
            CustomTableCell: CaseStatusCell,
          },
        ]}
        tableData={tableData}
        className="custom-scrollbar pr-1.5 max-h-[292px] min-w-[560px] md:min-w-max"
      />
    </div>
  );
}

export default AllCaseTable;
