import React, { useMemo } from "react";
import {
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_JP_DATE_FORMAT,
} from "app/constants";
import { Tooltip } from "@mui/material";

export default function InputDate({
  id,
  value,
  onChange,
  label,
  handleBlur,
  touched,
  errors,
  labelWidth,
  isRequired = false,
  isFormStyle,
  ...rest
}) {
  const inputId = useMemo(() => {
    if (!id) {
      return uuidv4();
    }
    return id;
  }, [id]);

  const valueDate = useMemo(() => {
    if (!value) return null;

    return moment(value, DEFAULT_DATE_FORMAT);
  }, [value]);

  return (
    <div className={`w-full h-auto ${!isFormStyle ? "mb-3" : null}`}>
      <div className="w-full h-[52px] flex items-center">
        {label ? (
          <label
            htmlFor={inputId}
            className="h-full pr-4 flex items-center"
            style={{
              width: `${labelWidth || 0}px`,
            }}
          >
            <span
              className="w-full text-sm leading-[22px] tracking-[.2em] whitespace-nowrap"
              style={{
                direction: "rtl",
              }}
            >
              {label}
            </span>
            {isRequired && <span style={{ color: "red" }}>*</span>}
          </label>
        ) : null}

        <div
          className={`h-full relative rounded-[10px] ${
            touched && errors
              ? "shadow-[0px_0px_1px_1px_#dc2626]"
              : ""
          }`}
          style={{
            width: `calc(100% - ${labelWidth || 0}px)`,
          }}
        >
          <Tooltip arrow title={errors}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id={inputId}
                name={inputId}
                label=""
                value={valueDate}
                onChange={(newDate) => {
                  if (!newDate) onChange("");
                  else onChange(newDate.format(DEFAULT_DATE_FORMAT));
                }}
                format={DEFAULT_JP_DATE_FORMAT}
                slotProps={{
                  textField: {
                    sx: {
                      "& .MuiOutlinedInput-root": {
                        height: "52px",
                        "& input": {
                          color: "#000",
                          paddingRight: "20px",
                          fontFamily:
                            '"Noto Sans JP", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                        },
                        "& fieldset": {
                          border:
                            touched && errors
                              ? "2px solid #ef4444"
                              : "1px solid #9ca2af",
                          borderRadius: isFormStyle ? "0px" : "10px",
                        },
                        "&:hover fieldset": {
                          border:
                            touched && errors
                              ? "2px solid #ef4444"
                              : "1px solid #9ca2af",
                        },
                        "&.Mui-focused fieldset": {
                          border:
                            touched && errors
                              ? "2px solid #ef4444"
                              : "1px solid #9ca2af",
                        },
                      },
                    },
                    className: "!font-bai-jamjuree",
                    onBlur: handleBlur,
                    id: inputId,
                    name: inputId,
                  },
                }}
                className="w-full !font-bai-jamjuree"
                {...rest}
              />
            </LocalizationProvider>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}
