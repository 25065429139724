import React, { useMemo } from "react";
import TableInfo from "../../CommonComponents/TableInfo";
import { Link } from "react-router-dom";
import routePaths from "app/route/routePaths";

function CustomerSearchResult(props) {
  const { customerList } = props;
  const tableData = useMemo(() => {
    return customerList.map((item) => ({
      companyNameKanji: (
        <Link
          to={routePaths.systemSettingsCustomerDetail(item.id)}
          className="text-[#08978E]"
        >
          {item.companyNameKanji}
        </Link>
      ),
      companyNameKana: item.companyNameKana,
      customerId: item.customerId,
    }));
  }, [customerList]);

  return (
    <TableInfo
      tableConfig={[
        {
          title: "会社名",
          width: "38%",
          dataKey: "companyNameKanji",
        },
        {
          title: "フリガナ",
          width: "38%",
          dataKey: "companyNameKana",
        },
        {
          title: "お客様ID",
          width: "24%",
          dataKey: "customerId",
        },
      ]}
      tableData={tableData}
      className="custom-scrollbar pr-1.5"
    />
  );
}

export default CustomerSearchResult;
