// FE-1
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Drawer } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";

function ContentLayout({
  children,
  sideMenuLinkOptions,
  breadcrumb,
}) {
  const [showSideMenuMobile, setShowSideMenuMobile] = useState(false);
  return (
    <>
      <div className="custom-container h-full min-h-content">
        <div className="w-full h-full relative md:pl-[200px]">
          {/* Side Menu Content */}
          <div className="hidden md:block absolute inset-y-0 left-0 w-[215px] h-full pt-10 pb-14">
            <div className="w-full h-full px-[26px] py-5 bg-[#001C4F]">
              {Array.isArray(sideMenuLinkOptions)
                ? sideMenuLinkOptions.map((item) =>
                    item?.isLabel ? (
                      <p className="w-full h-6 flex items-center !outline-none mb-6">
                        {item.linkTitle}
                      </p>
                    ) : (
                      <Link
                        key={item.linkTo}
                        to={item.linkTo}
                        className="w-full h-6 flex items-center !outline-none mb-6"
                      >
                        <p className="text-white custom-size">
                          {item.linkTitle}
                        </p>
                      </Link>
                    )
                  )
                : null}
            </div>
          </div>
          {/* Main Content */}
          <div className="w-full h-full min-h-content md:pl-[36px] pt-12 md:pt-10 pb-14 flex flex-col relative">
            <div className="md:hidden w-full h-8 absolute top-2 inset-x-0 flex items-center">
              <button
                type="button"
                className="w-8 h-8 flex items-center justify-center bg-[#001C4F] rounded shrink-0"
                onClick={() => setShowSideMenuMobile(true)}
              >
                <FontAwesomeIcon
                  icon={faBars}
                  size="lg"
                  className="text-white"
                />
              </button>

              <div className="w-auto h-full grow text-custom-size leading-5 py-1.5 ml-3 overflow-x-auto custom-scrollbar-none">
                <p className="whitespace-nowrap font-medium">
                  {Array.isArray(breadcrumb) && breadcrumb.length > 0
                    ? breadcrumb.map((item, index) => (
                        <React.Fragment key={index}>
                          {index !== 0 ? <span> \ </span> : null}

                          <button className="inline text-gray-800">
                            {item.label}
                          </button>
                        </React.Fragment>
                      ))
                    : null}
                </p>
              </div>
            </div>

            {children}
          </div>
        </div>
      </div>

      <Drawer
        anchor="left"
        open={showSideMenuMobile}
        onClose={() => {
          setShowSideMenuMobile(false);
        }}
      >
        <div className="w-[215px] h-full relative">
          <button
            type="button"
            onClick={() => setShowSideMenuMobile(false)}
            className="w-8 h-8 absolute top-2 right-2 flex items-center justify-center"
          >
            <FontAwesomeIcon
              icon={faXmark}
              size="xl"
              className="text-white"
            />
          </button>
          <div className="w-full h-full px-[26px] py-12 bg-[#001C4F]">
            {Array.isArray(sideMenuLinkOptions)
              ? sideMenuLinkOptions.map((item) => (
                  <Link
                    key={item.linkTo}
                    to={item.linkTo}
                    className="w-full h-6 flex items-center !outline-none mb-6"
                  >
                    <p className="text-white custom-size">
                      {item.linkTitle}
                    </p>
                  </Link>
                ))
              : null}
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default ContentLayout;
