import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { DOCUMENT_TYPE } from "../../../../constants";
import PropTypes from "prop-types";
import { projectServices } from "../../../../services/api";
import Button from "../../../UI/Button";
import messages from "../../../../services/api/messages";

const UploadDocumentBtn = ({
  caseInfoId,
  documentType,
  onCloseModal,
  text,
}) => {
  const [file, setFile] = useState(null);
  const fileName = file?.name;
  const inputRef = useRef();
  const inputId = uuidv4();

  const handleChangeFile = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
    }
  };

  const handleUpload = () => {
    projectServices
      .uploadDocument(caseInfoId, documentType, file)
      .then((res) => {
        if (res) {
          toast.success(messages.update(true));
          onCloseModal();
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div
        className="relative cursor-pointer"
        onClick={() => {
          inputRef.current && inputRef.current.click();
        }}
      >
        <div className="p-2.5 text-sm border rounded-xl border-[#B3B3B3] !outline-none bg-[#ebebeb]">
          {fileName ? (
            <p className="max-w-[90px] overflow-hidden truncate text-xs">
              {fileName}
            </p>
          ) : (
            <p className="max-w-[90px] text-xs">
              {text || "ファイルを選択"}
            </p>
          )}
        </div>
        <input
          ref={inputRef}
          id={inputId}
          name={inputId}
          type="file"
          onChange={handleChangeFile}
          className="hidden"
        />
      </div>
      {
        <Button
          onClick={handleUpload}
          disabled={!fileName}
          type={!fileName ? "disabled" : "primary"}
        >
          UPLOAD
        </Button>
      }
    </>
  );
};

UploadDocumentBtn.propTypes = {
  caseInfoId: PropTypes.string.isRequired,
  documentType: PropTypes.oneOf(Object.values(DOCUMENT_TYPE))
    .isRequired,
  onCloseModal: PropTypes.func.isRequired,
  text: PropTypes.string,
};

export default UploadDocumentBtn;
