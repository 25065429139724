import client from "./client";
import Apis from "./Path";

const create = async (data) => {
  return client.post(Apis.createTestPlan, data);
};

const update = async (id, data) => {
  return client.put(`${Apis.updateTestPlan}?id=${id}`, data);
};

const getTestSummaryByQuotationId = async (quotationId) => {
  return client.get(Apis.getTestSummary, { params: { quotationId } });
};

const getWordorPDF = async (data) => {
  return client.post(Apis.downloadPlan, data);
};

const getTestMaterialInfoByQuotationId = async (quotationId) => {
  return client.get(Apis.getTestMaterial, {
    params: { quotationId },
  });
};

const testPlanServices = {
  create,
  update,
  getTestSummaryByQuotationId,
  getWordorPDF,
  getTestMaterialInfoByQuotationId,
};

export default testPlanServices;
