import moment from "moment";
import client from "./client";
import { DEFAULT_DATE_FORMAT } from "../../constants";

const getAllTestScheduleList = () => {
  return client.get("/test-schedule-info/all").then((res) => {
    if (res.data?.success && Array.isArray(res.data?.result)) {
      const allTestScheduleList = res.data?.result;
      let recentCaseList = [];
      let nextCaseList = [];

      const currentDate = moment(
        moment().format(DEFAULT_DATE_FORMAT),
        DEFAULT_DATE_FORMAT
      );

      const recentAndNextSchedule = res.data?.result
        .filter((item) => {
          const testDate = moment(
            moment(item?.testDate).format(DEFAULT_DATE_FORMAT),
            DEFAULT_DATE_FORMAT
          );
          const endDate = moment(
            moment(item?.reportDeliveryDate).format(DEFAULT_DATE_FORMAT),
            DEFAULT_DATE_FORMAT
          );
          if (currentDate.diff(testDate) > 0 && currentDate.diff(endDate) > 0)
            return false;
          return true;
        })
        .sort((a, b) => moment(a).diff(moment(b)));

      if (
        recentAndNextSchedule.length > 0 &&
        Array.isArray(recentAndNextSchedule[0].caseInfos)
      ) {
        recentCaseList = recentAndNextSchedule[0].caseInfos;
      }

      if (
        recentAndNextSchedule.length > 1 &&
        Array.isArray(recentAndNextSchedule[1].caseInfos)
      ) {
        nextCaseList = recentAndNextSchedule[1].caseInfos;
      }

      return {
        data: {
          allTestScheduleList,
          recentCaseList,
          nextCaseList,
        },
      };
    }
    throw new Error("Malformed data!");
  });
};

const dashboardServices = {
  getAllTestScheduleList,
};
export default dashboardServices;
