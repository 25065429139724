import React from "react";
import { DEFAULT_ITEM_PER_PAGE } from "app/constants";

function CustomPagination(props) {
  const { currentPage, totalItems, handleChangePage } = props;

  const handleClickPreviousPage = () => {
    handleChangePage(currentPage - 1);
  };

  const handleClickNextPage = () => {
    handleChangePage(currentPage + 1);
  };

  return (
    <div className="w-full h-auto flex items-center justify-end gap-2 pt-3 text-custom-size">
      <p>
        <span>{totalItems}</span>件中{" "}
        <span>{(currentPage - 1) * DEFAULT_ITEM_PER_PAGE + 1}</span>~
        <span>{Math.min(currentPage * DEFAULT_ITEM_PER_PAGE, totalItems)}</span>
        件
      </p>

      {currentPage > 1 ? (
        <button
          type="button"
          onClick={handleClickPreviousPage}
          className="w-[22px] h-[22px] bg-[#001C4F] flex items-center justify-center text-white leading-4 pb-0.5"
        >
          <span>{"<"}</span>
        </button>
      ) : null}

      {currentPage * DEFAULT_ITEM_PER_PAGE < totalItems ? (
        <button
          type="button"
          onClick={handleClickNextPage}
          className="w-[22px] h-[22px] bg-[#001C4F] flex items-center justify-center text-white leading-4 pb-0.5"
        >
          <span>{">"}</span>
        </button>
      ) : null}
    </div>
  );
}

export default CustomPagination;
