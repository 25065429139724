import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import moment from "moment";
import { toast } from "react-toastify";
import readXlsxFile from "read-excel-file";
import { useMediaQuery } from "@mui/material";
import Button from "app/components/UI/Button";
import PageLoading from "app/components/CommonPage/PageLoading";
import PageError from "app/components/CommonPage/PageError";
import InputText from "app/components/UI/Form/Input/InputText";
import SelectPicStaff from "app/components/UI/Form/Select/SelectPicStaff";
import InputFile from "app/components/UI/Form/Input/InputFile";
import {
  reportServices,
  customerServices,
  commonServices,
} from "app/services/api";
import {
  callApiStatus,
  fileNameType,
  testResultRequiredRowName,
  LABEL_WIDTH,
} from "app/constants";
import { getAddressFromComponent } from "../../../utils";
import ReportMaterialInfosTable from "./ReportMaterialInfosTable";
import PageLoadingBackdrop from "../../../components/CommonPage/PageLoadingBackdrop";
import messages from "../../../services/api/messages";
import ReportPicTable from "./ReportPicTable";

const UpdateReportForm = ({
  initUpdateReport,
  customerId,
  scheduleId,
  caseInfo,
  customerName,
}) => {
  const isMd = useMediaQuery("(max-width:768px)");
  const [updateReport, setUpdateReport] = useState(initUpdateReport);
  const [customerInfo, setCustomerInfo] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const loadCustomerInfo = useCallback(() => {
    setCustomerInfo(null);
    if (customerId) {
      customerServices
        .getDetail(customerId)
        .then((res) => {
          setCustomerInfo(res.data);
        })
        .catch((error) => {
          toast.error(messages.loadCustomerFail);
        });
    }
  }, [customerId]);

  useEffect(() => {
    loadCustomerInfo();
  }, [loadCustomerInfo]);

  const handleFileChange = (e) => {
    try {
      const file = e.target.files[0];
      readXlsxFile(file, { sheet: "list" }).then((rows) => {
        const filterFileData = rows
          .slice(1)
          .filter((row) => {
            const name = row?.[3] || "";
            if (testResultRequiredRowName.includes(name))
              return false;

            const caseId = row?.[6] || "";
            if (caseId !== caseInfo.caseId) {
              return false;
            }

            const companyNameKanji = row?.[4] || "";
            if (customerName === companyNameKanji) return true;
            return false;
          })
          .map((row) => ({
            name: row?.[3] || "",
            preprocess: row?.[9] || "",
            materialNumber: row?.[1] || "",
            capacity: row?.[10] || "",
          }));

        setFileData(filterFileData);
        setFile(file);

        // Remove confirm generatedPdf and generatedWord file.
        setUpdateReport((oldState) => ({
          ...oldState,
          generatedPdf: null,
          generatedWord: null,
        }));
      });
    } catch (error) {
      toast.error(messages.callApiFail);
    }
  };

  const handleClickCreate = () => {
    if (Array.isArray(fileData) && fileData.length > 0)
      setUpdateReport({
        ...updateReport,
        reportTestMaterialInfos: fileData,
      });
  };

  const companyNameKanji = useMemo(
    () => (customerInfo ? customerInfo?.companyNameKanji : ""),
    [customerInfo]
  );

  const companyAddress = useMemo(
    () => (customerInfo ? getAddressFromComponent(customerInfo) : ""),
    [customerInfo]
  );

  const isDisabledSubmitUpdate = useMemo(() => {
    return (
      !file ||
      !Array.isArray(updateReport.reportTestMaterialInfos) ||
      updateReport.reportTestMaterialInfos.length === 0 ||
      !updateReport?.generatedPdf ||
      !updateReport?.generatedWord
    );
  }, [file, updateReport]);

  const isDisabledConfirm = useMemo(() => {
    return (
      !file ||
      !Array.isArray(updateReport.reportTestMaterialInfos) ||
      updateReport.reportTestMaterialInfos.length === 0
    );
  }, [file, updateReport]);

  const handleConfirmReport = () => {
    if (!updateReport.doctor) {
      toast.error(messages.required("判定医師"));
    }
    if (!updateReport?.reportCustomerPIC?.department) {
      toast.error(messages.required("担当部署"));
    }
    if (
      !updateReport.doctor ||
      !updateReport?.reportCustomerPIC?.department
    ) {
      return;
    }

    setIsLoading(true);
    const data = {
      customerId,
      testScheduleId: scheduleId,
      testId: updateReport.testId,
      doctor: updateReport.doctor,
      hasTestPlan: !!caseInfo?.testPlanInfo,
      department: updateReport?.reportCustomerPIC?.department || "",
      reportTestMaterialInfos: updateReport.reportTestMaterialInfos,
      file,
    };
    reportServices
      .createFileDownload(data)
      .then((res) => {
        const caseId = caseInfo?.caseId || "";
        const fileType = fileNameType["report"];
        const timeStamp = moment().format("YYYYMMDDHHmmss");

        if (res.data?.success) {
          setUpdateReport((oldState) => ({
            ...oldState,
            fileResult: res.data?.result?.fileResult?._id,
            generatedPdf: res.data?.result?.generatedWord?._id,
            generatedWord: res.data?.result?.generatedWord?._id,
          }));

          setTimeout(() => {
            commonServices
              .downloadWithFileId(
                res.data?.result?.generatedWord?._id,
                "docx",
                `${fileType}_${caseId}_${customerName}_${timeStamp}`
              )
              .catch(() => toast.error(messages.downloadFail));
          }, 500);

          // setTimeout(() => {
          //   commonServices
          //     .downloadPDFWithFileId(
          //       res.data?.result?.generatedWord?._id,
          //       `${fileType}_${caseId}_${customerName}_${timeStamp}`
          //     )
          //     .catch(() => toast.error(messages.downloadFail));
          // }, 500);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(messages.callApiFail);
        setIsLoading(false);
      });
  };

  const handleUpdateReport = () => {
    if (!updateReport.doctor) {
      toast.error(messages.required("判定医師"));
    }
    if (!updateReport?.reportCustomerPIC?.department) {
      toast.error(messages.required("担当部署"));
    }
    if (
      !updateReport.doctor ||
      !updateReport?.reportCustomerPIC?.department
    ) {
      return;
    }
    setIsLoading(true);
    const data = {
      customerId: customerId,
      testScheduleId: scheduleId,
      caseInfoId: caseInfo._id,
      testId: updateReport.testId,
      doctor: updateReport.doctor,
      department: updateReport?.reportCustomerPIC?.department || "",
      reportCustomerPIC: updateReport?.reportCustomerPIC,
      reportTestMaterialInfos: Array.isArray(
        updateReport.reportTestMaterialInfos
      )
        ? updateReport.reportTestMaterialInfos.map((item) => ({
            name: item.name,
            preprocess: item.preprocess,
            materialNumber: item.materialNumber,
            capacity: item.capacity,
          }))
        : [],
      testResult: updateReport.testResult,
      generatedPdf: updateReport.generatedPdf,
      generatedWord: updateReport.generatedWord,
    };

    reportServices
      .updateReport(updateReport.id, data)
      .then(() => {
        toast.success(messages.update(true));
        setIsLoading(false);
      })
      .catch(() => {
        toast.error(messages.update(false));
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="w-full h-auto">
        <div className="w-full h-auto flex items-center justify-center mb-3 flex-wrap">
          <div className="w-full mb-3 md:mb-0 md:w-1/2 h-full md:pr-12">
            <InputText
              id="testId"
              value={updateReport.testId}
              label="試験番号"
              disabled={true}
              labelWidth={isMd ? LABEL_WIDTH.RIGHT : LABEL_WIDTH.LEFT}
            />
          </div>

          <div className="w-full md:w-1/2 h-full">
            <SelectPicStaff
              pic={{ id: updateReport.picStaff }}
              setPic={(newPic) => {
                setUpdateReport({
                  ...updateReport,
                  picStaff: newPic?.id,
                });
              }}
              labelWidth={LABEL_WIDTH.RIGHT}
            />
          </div>
        </div>

        <div className="w-full h-auto flex items-center justify-center mb-3 flex-wrap">
          <div className="w-full mb-3 md:mb-0 md:w-1/2 h-full md:pr-12">
            <InputFile
              id="upload-report-file"
              handleFileChange={handleFileChange}
              label="試験結果"
              labelWidth={isMd ? LABEL_WIDTH.RIGHT : LABEL_WIDTH.LEFT}
              fileName={file?.name || ""}
            />
          </div>

          <div className="w-full md:w-1/2 h-full">
            <InputText
              id="doctor"
              value={updateReport.doctor}
              onChange={(newValue) => {
                setUpdateReport({
                  ...updateReport,
                  doctor: newValue,
                });
              }}
              label="判定医師"
              labelWidth={LABEL_WIDTH.RIGHT}
              isRequired
            />
          </div>
        </div>

        <div className="w-full h-auto flex items-center justify-end mb-4">
          <Button
            type={
              Array.isArray(fileData) && fileData.length > 0
                ? "primary"
                : "disabled"
            }
            onClick={handleClickCreate}
          >
            報告書呼出
          </Button>
        </div>

        <div className="mb-[14px]">
          <div className="w-full h-auto mb-5 overflow-x-auto">
            <p className="text-custom-size mb-3">報告書の内容</p>
            <ReportPicTable
              companyNameKanji={companyNameKanji}
              companyAddress={companyAddress}
              reportPICInfo={updateReport.reportCustomerPIC}
              setReportPICInfo={(newValue) => {
                setUpdateReport({
                  ...updateReport,
                  reportCustomerPIC: newValue,
                });
              }}
            />
          </div>

          <div className="w-full h-auto mb-5 overflow-x-auto">
            <p className="text-custom-size mb-3">被験物質の概要</p>
            <ReportMaterialInfosTable
              reportTestMaterialInfos={
                updateReport.reportTestMaterialInfos
              }
              setReportTestMaterialInfos={(newValue) => {
                setUpdateReport({
                  ...updateReport,
                  reportTestMaterialInfos: newValue,
                });
              }}
            />
          </div>
        </div>

        <div className="w-full h-auto flex items-center justify-end gap-3">
          <Button
            type={isDisabledConfirm ? "disabled" : "primary"}
            disabled={isDisabledConfirm}
            onClick={handleConfirmReport}
          >
            確認する
          </Button>

          <Button
            type={isDisabledSubmitUpdate ? "disabled" : "primary"}
            disabled={isDisabledSubmitUpdate}
            onClick={handleUpdateReport}
          >
            修正する
          </Button>
        </div>
      </div>
      <PageLoadingBackdrop open={isLoading} />
    </>
  );
};

const UpdateReport = ({ caseInfo, customer, schedule }) => {
  const [loadInitState, setLoadInitState] = useState({
    loadingStatus: callApiStatus.idle,
    data: null,
    error: null,
  });

  const loadInitReport = useCallback(() => {
    if (!customer?.id || !schedule?.id || !caseInfo?._id) {
      return;
    }

    setLoadInitState({
      loadingStatus: callApiStatus.loading,
      data: null,
      error: null,
    });

    reportServices
      .getByCaseInfoId(caseInfo._id)
      .then((res) => {
        setLoadInitState({
          loadingStatus: callApiStatus.success,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        toast.error(messages.callApiFail);
        setLoadInitState({
          loadingStatus: callApiStatus.error,
          data: null,
          error: error,
        });
      });
  }, [customer?.id, schedule?.id, caseInfo]);

  useEffect(() => {
    loadInitReport();
  }, [loadInitReport]);

  if (loadInitState.loadingStatus === callApiStatus.loading)
    return <PageLoading />;

  if (
    loadInitState.loadingStatus === callApiStatus.error ||
    loadInitState.error
  )
    return <PageError />;
  if (
    loadInitState.loadingStatus === callApiStatus.success &&
    loadInitState.data
  )
    return (
      <UpdateReportForm
        initUpdateReport={loadInitState.data}
        customerId={customer?.id}
        scheduleId={schedule?.id}
        caseInfo={caseInfo}
        customerName={customer?.companyNameKanji || ""}
      />
    );

  return null;
};

export default UpdateReport;
