import axios from "axios";
import store from "app/redux/store";
import { logout } from "app/redux/authReducer";

const clientFormData = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
  headers: {
    "Content-Type": "multipart/form-data",
    // Accept: "application/json",
    // "Access-Control-Allow-Origin": "*",
  },
});

clientFormData.interceptors.request.use(
  (config) => {
    const token = store.getState()?.auth.token?.accessToken || "";
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        // Accept: "application/json",
        // "Access-Control-Allow-Origin": "*",
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

clientFormData.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config;
    if (err.response?.status === 400) {
    }
    if (originalConfig?.url !== "/api/login" && err.response) {
      if (err.response.status === 401 || err.response.status === 403) {
        store.dispatch(logout());
      }
    }
    return Promise.reject(err);
  }
);

export default clientFormData;
