const Apis = {
  //common
  verifyToken:"verify-token",
  getAllPIC: "/pic-info/all",
  getFileById: (id) => `/download/download-with-file-id?fileId=${id}`,
  allUserInfo: "/user/info/all-user-info",
  downloadFileByPath: "/download/buffer",
  //customer
  getCaseListByCustomerId: "/case-info/get-by-customer-id",
  //project
  testScheduleList: "/test-schedule-info/all",
  updateTestSchedule: "/test-schedule-info/create-many",
  updateStatus: (id) => `/case-info/update-status?id=${id}`,
  //test plan
  createTestPlan: "/test-plan-info/create",
  updateTestPlan: "/test-plan-info/update",
  getTestPlan: "/test-plan-info/query-with-data",
  getTestSummary: "/test-plan-info/get-by-quotation-id",
  downloadPlan: "/test-plan-info/create-file-download",
  downloadPlanSummary: "/test-plan-info/download",
  getQuotationByCustomerAndSchedule:
    "/quotation-info/get-by-customer-id-and-test-schedule",
  //test material
  getTestMaterial: "/test-material-info/get-by-quotation-id",
  // contract
  contractList: "/contract-info/all",
  createContract: "/contract-info/create",
  confirmContact: "/contract-info/create-file-download",
  deleteContract: "/contract-info/delete",
  // file
  uploadDocument: "/file/document-file",
};

export default Apis;
