import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { toast } from "react-toastify";
import { LABEL_WIDTH } from "../../../../../constants";
import PageLoading from "app/components/CommonPage/PageLoading";
import PageNoData from "app/components/CommonPage/PageNoData";
import PageError from "app/components/CommonPage/PageError";
import {
  caseServices,
  customerServices,
  scheduleServices,
} from "app/services/api";
import { convertDateStringToJpFormat } from "app/utils";
import { useMediaQuery } from "@mui/material";
import SelectPopover from "../../Select/SelectPopover";
import messages from "../../../../../services/api/messages";

function ReactSelectSingle({
  value,
  onChange,
  label,
  options,
  labelWidth,
}) {
  return (
    <div className="w-full h-[52px] flex items-center">
      {label ? (
        <label
          className="h-full pr-4 flex items-center"
          style={{
            width: `${labelWidth || 0}px`,
          }}
        >
          <span
            className="w-full text-sm leading-[22px] tracking-[.2em] whitespace-nowrap"
            style={{
              direction: "rtl",
            }}
          >
            {label}
          </span>
        </label>
      ) : null}

      <div
        className="h-full relative"
        style={{
          width: `calc(100% - ${labelWidth || 0}px)`,
        }}
      >
        <SelectPopover
          options={options}
          selectedValue={value}
          setSelectedValue={onChange}
          customerSelectedLabelStyle={{
            width: "100%",
            textAlign: "start",
          }}
        />
      </div>
    </div>
  );
}

function SelectCustomerIdAndTestSchedule({
  customer,
  setCustomer,
  schedule,
  setSchedule,
  caseInfo,
  setCaseInfo = () => {},
  labelWidthLeft = LABEL_WIDTH.LEFT,
  labelWidthRight = LABEL_WIDTH.RIGHT,
  isSelectCase = true,
}) {
  const isMd = useMediaQuery("(max-width:768px)");
  const [loadInitState, setLoadInitState] = useState({
    isLoading: true,
    data: null,
    error: null,
  });

  const loadInit = useCallback(() => {
    setLoadInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    Promise.all([
      customerServices.getAllCustomerId(),
      scheduleServices.getAll(),
    ])

      .then((res) => {
        setLoadInitState({
          isLoading: false,
          data: {
            allCustomer: res[0].data,
            allTestSchedule: res[1].data,
          },
          error: null,
        });
        const allCustomer = res[0].data;
        const allTestSchedule = res[1].data;
        if (
          Array.isArray(allCustomer) &&
          !allCustomer.find((item) => item.id === customer?.id)
        ) {
          setCustomer(allCustomer[0]);
        }
        if (
          Array.isArray(allTestSchedule) &&
          !allTestSchedule.find((item) => item.id === schedule?.id)
        ) {
          // setSchedule(allTestSchedule[0]);
        }
      })
      .catch((error) => {
        toast.error(messages.callApiFail);
        setLoadInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadInit();
  }, [loadInit]);

  useEffect(() => {
    if (isSelectCase && customer?.id && schedule?.id) {
      caseServices
        .searchByCustomerAndSchedule(customer?.id, schedule?.id)
        .then((data) => {
          setLoadInitState({
            isLoading: false,
            data: {
              ...loadInitState.data,
              allCaseInfos: data || [],
            },
            error: null,
          });
        })
        .catch(() => {
          toast.error(messages.callApiFail);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, schedule, isSelectCase]);

  const customerIdList = useMemo(() => {
    if (!Array.isArray(loadInitState.data?.allCustomer)) return [];

    return loadInitState.data?.allCustomer.map((item) => ({
      value: item.id,
      label: item.customerId,
    }));
  }, [loadInitState.data?.allCustomer]);

  const companyNameKanjiList = useMemo(() => {
    if (!Array.isArray(loadInitState.data?.allCustomer)) return [];

    return loadInitState.data?.allCustomer.map((item) => ({
      value: item.id,
      label: item.companyNameKanji,
    }));
  }, [loadInitState.data?.allCustomer]);

  const companyNameKanaList = useMemo(() => {
    if (!Array.isArray(loadInitState.data?.allCustomer)) return [];

    return loadInitState.data?.allCustomer.map((item) => ({
      value: item.id,
      label: item.companyNameKana,
    }));
  }, [loadInitState.data?.allCustomer]);

  const allCustomer = useMemo(() => {
    if (!Array.isArray(loadInitState.data?.allCustomer)) return [];

    return loadInitState.data?.allCustomer.map((item) => ({
      id: item.id,
      companyNameKanji: item.companyNameKanji,
      companyNameKana: item.companyNameKana,
      customerId: item.customerId,
    }));
  }, [loadInitState.data?.allCustomer]);

  const allTestScheduleOptions = useMemo(() => {
    if (!Array.isArray(loadInitState.data?.allTestSchedule))
      return [];

    return loadInitState.data?.allTestSchedule.map((item) => ({
      value: item.id,
      label: convertDateStringToJpFormat(item.testDate),
    }));
  }, [loadInitState.data?.allTestSchedule]);

  const allTestSchedule = useMemo(() => {
    if (!Array.isArray(loadInitState.data?.allTestSchedule))
      return [];

    return loadInitState.data?.allTestSchedule;
  }, [loadInitState.data?.allTestSchedule]);

  const allCaseInfosOptions = useMemo(() => {
    if (!isSelectCase) {
      return [];
    }
    if (!Array.isArray(loadInitState.data?.allCaseInfos)) return [];

    return loadInitState.data?.allCaseInfos?.map((item) => ({
      value: item._id,
      label: item.caseId,
    }));
  }, [isSelectCase, loadInitState.data?.allCaseInfos]);

  if (loadInitState.isLoading) return <PageLoading />;
  if (loadInitState.error) return <PageError />;

  if (
    Array.isArray(loadInitState.data?.allCustomer) &&
    loadInitState.data?.allCustomer.length > 0 &&
    Array.isArray(loadInitState.data?.allTestSchedule) &&
    loadInitState.data?.allTestSchedule.length > 0
  ) {
    return (
      <div className="w-full h-auto">
        <div className="w-full h-auto flex items-center justify-center mb-3 flex-wrap">
          <div className="w-full mb-3 md:mb-0 md:w-1/2 h-full md:pr-12">
            <ReactSelectSingle
              id="select-customer-id"
              value={customer?.id}
              onChange={(newValue) => {
                const customer = allCustomer.find(
                  (item) => item.id === newValue
                );
                setCustomer(customer);
              }}
              label="お客様ID"
              options={customerIdList}
              labelWidth={isMd ? labelWidthRight : labelWidthLeft}
            />
          </div>

          <div className="w-full md:w-1/2 h-full">
            <ReactSelectSingle
              id="selectt-name-kanji"
              value={customer?.id}
              onChange={(newValue) => {
                const customer = allCustomer.find(
                  (item) => item.id === newValue
                );
                setCustomer(customer);
              }}
              label="会社名"
              options={companyNameKanjiList}
              labelWidth={labelWidthRight}
            />
          </div>
        </div>

        <div className="w-full h-auto flex items-center justify-center mb-3 flex-wrap">
          <div className="w-full mb-3 md:mb-0 md:w-1/2 h-full md:pr-12">
            <ReactSelectSingle
              id="select-name-kana"
              value={customer?.id}
              onChange={(newValue) => {
                const customer = allCustomer.find(
                  (item) => item.id === newValue
                );
                setCustomer(customer);
              }}
              label="フリガナ"
              options={companyNameKanaList}
              labelWidth={isMd ? labelWidthRight : labelWidthLeft}
            />
          </div>

          <div className="w-full md:w-1/2 h-full">
            <ReactSelectSingle
              id="select-test-schedule"
              value={schedule?.id}
              onChange={(newValue) => {
                const schedule = allTestSchedule.find(
                  (item) => item.id === newValue
                );
                setSchedule(schedule);
              }}
              label="試験スケジュール"
              options={allTestScheduleOptions}
              labelWidth={labelWidthRight}
            />
          </div>
        </div>

        {isSelectCase && allCaseInfosOptions.length > 0 && (
          <div className="w-full h-auto flex items-center justify-center mb-3 flex-wrap">
            <div className="w-full mb-3 md:mb-0 md:w-1/2 h-full md:pr-12">
              <ReactSelectSingle
                id="select-case-info"
                value={caseInfo?._id}
                onChange={(newValue) => {
                  const newCaseInfo =
                    loadInitState.data?.allCaseInfos?.find(
                      (item) => item._id === newValue
                    );
                  setCaseInfo(newCaseInfo);
                }}
                label="案件ID"
                options={allCaseInfosOptions}
                labelWidth={isMd ? labelWidthRight : labelWidthLeft}
              />
            </div>
            <div className="w-full md:w-1/2 h-full"></div>
          </div>
        )}
        {isSelectCase &&
          customer &&
          schedule &&
          allCaseInfosOptions.length === 0 && (
            <PageNoData noDataMessage="案件IDはまだありません。 見積書を作成してください。" />
          )}
      </div>
    );
  }

  return (
    <div className="w-full h-auto">
      <PageNoData />
    </div>
  );
}

export default SelectCustomerIdAndTestSchedule;
