import React from "react";
import { Link } from "react-router-dom";
import routePaths from "../../route/routePaths";

function MainMenuIem(props) {
  const { linkTo, title, description } = props;

  return (
    <Link
      to={linkTo}
      className="w-full h-[110px] rounded-l-full bg-[#001C4F] text-white pl-32 pr-5 py-[19px] relative"
    >
      <div className="absolute w-[86px] h-[86px] top-3 left-[13px] rounded-full bg-white z-10" />

      <div className="w-full h-auto">
        <p className="text-custom-size leading-[15px] tracking-[.35em]">
          {title}
        </p>
        <div className="w-full h-[1px] bg-white mt-[14px] mb-[10px]" />
        <p className="text-[13px] leading-[16px] tracking-[.1em]">
          {description}
        </p>
      </div>
    </Link>
  );
}

function TopMenu() {
  return (
    <div className="custom-container h-auto py-10">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-[57px] gap-y-[26px] ">
        <MainMenuIem
          linkTo={routePaths.dashboard}
          title="ダッシュボード"
          description="各試験に対する案件の状況を確認できます"
        />
        <MainMenuIem
          linkTo={routePaths.customer}
          title="顧客情報の管理"
          description="社名、住所、担当者など顧客に関する情報を管理できます"
        />
        <MainMenuIem
          linkTo={routePaths.project}
          title="案件情報の管理"
          description="案件内容を管理できます"
        />

        <MainMenuIem
          linkTo={routePaths.quotation}
          title="見積書の管理"
          description="見積書の作成および提出などの管理ができます"
        />
        <MainMenuIem
          linkTo={routePaths.project}
          title="発注書の管理"
          description="顧客からの発注書提出状況を確認できます"
        />
        <MainMenuIem
          linkTo={routePaths.testPlan}
          title="試験計画書の管理"
          description="試験計画書の作成および提出が管理できます"
        />

        <MainMenuIem
          linkTo={routePaths.project}
          title="試験サンプル発送管理"
          description="試験サンプルの発送状況の確認と到着登録ができます"
        />
        <MainMenuIem
          linkTo={routePaths.report}
          title="報告書の管理"
          description="試験報告書の作成および提出が管理できます"
        />
        <MainMenuIem
          linkTo={routePaths.invoice}
          title="請求書の管理"
          description="請求書の作成および提出などの管理ができます"
        />

        <MainMenuIem
          linkTo={routePaths.contract}
          title="契約書の管理"
          description="契約書の作成および締結などの管理ができます"
        />
        <MainMenuIem
          linkTo={routePaths.systemSettings}
          title="システム設定"
          description="ユーザー追加/削除、アクセス権付与などを設定できます"
        />
         <MainMenuIem
          linkTo={routePaths.chat}
          title="顧客に連絡"
          description="顧客にチャットにて連絡することができます"
        />
      </div>
    </div>
  );
}

export default TopMenu;
