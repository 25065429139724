import routePaths from "app/route/routePaths";
import ContentLayout from "app/layouts/ContentLayout";
import { useOutletContext } from "react-router-dom";

export default function SystemSettingContentLayout({ children }) {
  const [breadcrumb] = useOutletContext();
  return (
    <ContentLayout
      sideMenuLinkOptions={[
        {
          linkTo: routePaths.systemSettingsCustomer,
          linkTitle: "お客様IDの検索",
        },
        {
          linkTo: routePaths.systemSettingsCreateCustomer,
          linkTitle: "お客様IDの登録",
        },
        {
          linkTo: routePaths.systemSettingsUser,
          linkTitle: "ユーザーの検索",
        },
        {
          linkTo: routePaths.systemSettingsCreateUser,
          linkTitle: "ユーザーの登録",
        },
        { linkTo: routePaths.topMenu, linkTitle: "トップへ戻る" },
      ]}
      breadcrumb={breadcrumb}
      >
      {children}
    </ContentLayout>
  );
}
