import { toast } from "react-toastify";
import { useState } from "react";
import messages from "app/services/api/messages";

import { testPlanServices, commonServices } from "app/services/api";

const useCallApi = ({
  customerTable,
  labTable,
  materialTable,
  confirmedData,
  setConfirmedData,
  customerId,
}) => {

  const [isLoading, setIsLoading] = useState(false);
  const createSendData = (data) => {
    const cusData = customerTable.reduce((acc, current) => {
      return Object.assign(acc, {
        [current.dataKey]: current.detail,
      });
    }, {});
    const labData = labTable.reduce((acc, current) => {
      return Object.assign(acc, {
        [current.dataKey]: current.detail,
      });
    }, {});

    const sendData = {
      ...data,
      customerName: cusData.customerName,
      customerAddress: cusData.customerAddress,
      department: cusData.department,
      picNameKanji: cusData.picNameKanji,
      // progressStatus: "progressStatus",
      phone1: cusData.phone1,
      labCompanyNameKanji: labData.labCompanyNameKanji,
      labAddress: labData.labAddress,
      labPicNameKanji: labData.labPicNameKanji,
      labPhone: labData.labPhone,
      testMaterialInfos: materialTable ? materialTable : [],
      generatedWord: confirmedData?.generatedWord,
      generatedPdf: confirmedData?.generatedPdf,
      customerId: customerId,
    };
    return sendData;
  };

  const isPayloadValid = (data) => {
    const required = ["department", "phone1", "picNameKanji"];
    return required.every(function (x) {
      return data[x]?.trim() ? true : false;
    });
  };

  const create = async (data) => {
    if (!confirmedData) return;

    const obj = createSendData(data);

    if (!isPayloadValid(obj)) {
      toast.error(
        "フィールド 担当部署、担当者名、電話番号 に記入してください"
      );
      return;
    }
    return testPlanServices
      .create(obj)
      .then((res) => {
        toast.success(messages.create(true));
      })
      .catch((err) => {
        toast.error(messages.create(false));
      });
  };

  const update = async (data) => {
    if (!confirmedData) return;

    const body = createSendData(data);
    if (!isPayloadValid(body)) {
      toast.error(
        "フィールド 担当部署、担当者名、電話番号 に記入してください"
      );
      return;
    }
    return testPlanServices
      .update(body.planId, body)
      .then((res) => {
        toast.success(messages.update(true));
      })
      .catch((err) => {
        toast.error(messages.update(false));
      });
  };

  const onConfirmPDF = async (data, fileName, isUpdate = false) => {
    const body = createSendData(data);
    if (isPayloadValid(body) === false) {
      toast.error(
        "フィールド 担当部署、担当者名、電話番号 に記入してください"
      );
      return;
    }

    if (body.generatedWord) {
      delete body.generatedWord;
    }
    setIsLoading(true);
    return testPlanServices
      .getWordorPDF(body)
      .then(async (res) => {
        setConfirmedData({
          generatedWord: res.data?.result?.generatedWord?._id,
          generatedPdf: res.data?.result?.generatedPdf?._id,
        });
        await new Promise((resolve) =>
          setTimeout(() => resolve(), 3000)
        );
        return {
          pdfId: res.data?.result?.generatedPdf?._id,
          wordId: res.data?.result?.generatedWord?._id,
        };
      })
      .then(({ pdfId, wordId }) => {
        setIsLoading(false);
        commonServices.downloadWithFileId(pdfId, "pdf", fileName);
        if (!isUpdate) {
          commonServices.downloadWithFileId(wordId, "docx", fileName);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(messages.downloadFail);
      });
  };

  return {
    onConfirmPDF,
    create,
    update,
    isLoading,
  };
};

export default useCallApi;
