import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  Outlet,
  useLocation,
  matchPath,
  useMatches,
} from "react-router-dom";
import MainLayout from "app/layouts/MainLayout";

import routePaths from "../routePaths";
import { selectCurrentToken } from "../../redux/authReducer";

const routeBreadcrumb = [
  { path: "/", breadcrumb: [] },
  {
    path: routePaths.topMenu,
    breadcrumb: [{ label: "業務管理システム" }, { label: "トップ" }],
  },
  // 1. Dashboard
  {
    path: routePaths.dashboard,
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "ダッシュボード" },
    ],
  },

  // 2. Customer
  {
    path: routePaths.customer,
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "顧客情報の管理" },
      { label: "顧客情報の検索" },
    ],
  },
  {
    path: routePaths.createCustomer,
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "顧客情報の管理" },
      { label: "顧客情報の登録" },
    ],
  },
  {
    path: routePaths.detailCustomer(),
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "顧客情報の管理" },
      { label: "顧客情報" },
    ],
  },
  {
    path: routePaths.updateCustomer(),
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "顧客情報の管理" },
      { label: "顧客情報の変更" },
    ],
  },

  // 3. Project
  {
    path: routePaths.project,
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "案件情報の管理" },
      { label: "試験スケジュール" },
    ],
  },
  {
    path: routePaths.projectTestSchedule,
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "案件情報の管理" },
      { label: "試験スケジュールの登録" },
    ],
  },

  // 4. Quotation
  {
    path: routePaths.createQuotation,
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "見積書の管理" },
      { label: "見積書の作成" },
    ],
  },
  {
    path: routePaths.updateQuotation,
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "見積書の管理" },
      { label: "見積書の修正" },
    ],
  },

  // 5. Purchase Order

  // 6. Test Plan
  {
    path: routePaths.createTestPlan,
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "試験計画書の管理" },
      { label: "試験計画書の作成" },
    ],
  },
  {
    path: routePaths.updateTestPlan,
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "試験計画書の管理" },
      { label: "試験計画書の修正" },
    ],
  },

  // 7. Test Sample Shipping

  // 8. Report

  {
    path: routePaths.createReport,
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "報告書の管理" },
      { label: "報告書の作成" },
    ],
  },
  {
    path: routePaths.updateReport,
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "報告書の管理" },
      { label: "報告書の修正" },
    ],
  },
  {
    path: routePaths.testFormGeneration,
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "報告書の管理" },
      { label: "試験フォーム生成" },
    ],
  },
  {
    path: routePaths.batchReport,
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "報告書の管理" },
      { label: "報告書一括送付" },
    ],
  },

  // 9. Invoice
  {
    path: routePaths.createInvoice,
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "請求書の管理" },
      { label: "請求書の作成" },
    ],
  },
  {
    path: routePaths.updateInvoice,
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "請求書の管理" },
      { label: "請求書の修正" },
    ],
  },
  {
    path: routePaths.batchInvoice,
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "請求書の管理" },
      { label: "請求書一括発行・送付" },
    ],
  },

  // 10. Contract
  {
    path: routePaths.contract,
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "顧客情報の管理" },
      { label: "契約書締結状況" },
    ],
  },
  {
    path: routePaths.createContract,
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "契約書の管理" },
      { label: "契約書の作成" },
    ],
  },

  // 11. System Settings
  {
    path: routePaths.systemSettingsCustomer,
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "システム設定" },
      { label: "お客様IDの検索" },
    ],
  },
  {
    path: routePaths.systemSettingsCustomerDetail(),
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "システム設定" },
      { label: "お客様ID" },
    ],
  },
  {
    path: routePaths.systemSettingsCustomerUpdate(),
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "システム設定" },
      { label: "お客様IDの変更" },
    ],
  },
  {
    path: routePaths.systemSettingsCreateCustomer,
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "システム設定" },
      { label: "お客様IDの登録" },
    ],
  },

  {
    path: routePaths.systemSettingsUser,
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "システム設定" },
      { label: "ユーザーの検索" },
    ],
  },
  {
    path: routePaths.systemSettingsUserDetail(),
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "システム設定" },
      { label: "ユーザー情報" },
    ],
  },
  {
    path: routePaths.systemSettingsUserUpdate(),
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "システム設定" },
      { label: "ユーザー情報の変更" },
    ],
  },
  {
    path: routePaths.systemSettingsCreateUser,
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "システム設定" },
      { label: "ユーザーの登録" },
    ],
  },
  {
    path: routePaths.chat,
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "チャット" },
    ],
  },
];

export default function PrivateRoute(props) {
  const location = useLocation();

  const token = useSelector(selectCurrentToken);

  const breadcrumb = useMemo(() => {
    if (!token) return [];

    const breadcrumbObj = routeBreadcrumb.find((item) =>
      matchPath(item.path, location.pathname)
    );

    if (breadcrumbObj && Array.isArray(breadcrumbObj.breadcrumb))
      return breadcrumbObj.breadcrumb;
    return [];
  }, [location.pathname, token]);

  if (!token) {
    return (
      <Navigate
        to={routePaths.login}
        state={{ from: location }}
        replace
      />
    );
  }

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <Outlet context={[breadcrumb]} />
    </MainLayout>
  );
}
