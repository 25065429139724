import React from "react";
import InputText from "../Input/InputText";

function PhoneAndFaxFieldGroup(props) {
  const {
    errors,
    values,
    setFieldValue,
    touched,
    handleBlur,
    fieldSuffix = "",
    labelWidth = 136,
    displaysReplacementValueSuffix,
    hiddenFax = false,
    placeholder,
  } = props;

  return (
    <div className="w-full h-auto">
      <InputText
        id={`phone1${fieldSuffix}`}
        value={
          displaysReplacementValueSuffix
            ? values[`phone1${displaysReplacementValueSuffix}`]
            : values[`phone1${fieldSuffix}`]
        }
        onChange={(newValue) => setFieldValue(`phone1${fieldSuffix}`, newValue)}
        label="代表番号"
        labelWidth={labelWidth}
        handleBlur={handleBlur}
        disabled={displaysReplacementValueSuffix}
        placeholder={placeholder || "06-6578-5600"}
        errors={errors[`phone1${fieldSuffix}`]}
        touched={touched[`phone1${fieldSuffix}`]}
      />

      <InputText
        id={`phone2${fieldSuffix}`}
        value={
          displaysReplacementValueSuffix
            ? values[`phone2${displaysReplacementValueSuffix}`]
            : values[`phone2${fieldSuffix}`]
        }
        onChange={(newValue) => setFieldValue(`phone2${fieldSuffix}`, newValue)}
        label="電話番号(予備用)"
        labelWidth={labelWidth}
        handleBlur={handleBlur}
        disabled={displaysReplacementValueSuffix}
        placeholder={placeholder || "06-6578-5600"}
        errors={errors[`phone2${fieldSuffix}`]}
        touched={touched[`phone2${fieldSuffix}`]}
      />
      {!hiddenFax ? (
        <InputText
          id={`fax${fieldSuffix}`}
          value={
            displaysReplacementValueSuffix
              ? values[`fax${displaysReplacementValueSuffix}`]
              : values[`fax${fieldSuffix}`]
          }
          onChange={(newValue) => setFieldValue(`fax${fieldSuffix}`, newValue)}
          label="FAX番号"
          labelWidth={labelWidth}
          handleBlur={handleBlur}
          disabled={displaysReplacementValueSuffix}
          placeholder="06-6578-0888"
          errors={errors[`fax${fieldSuffix}`]}
          touched={touched[`fax${fieldSuffix}`]}
        />
      ) : null}
    </div>
  );
}

export default PhoneAndFaxFieldGroup;
