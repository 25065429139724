// FE-1
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";

import ContentLayout from "app/layouts/ContentLayout";
import PageLoading from "app/components/CommonPage/PageLoading";
import PageError from "app/components/CommonPage/PageError";
import SelectCustomerId from "app/components/UI/Form/SelectGroup/SelectCustomerId";
import Button from "app/components/UI/Button";
import routePaths from "app/route/routePaths";
import { customerServices } from "app/services/api";
import { callApiStatus } from "app/constants";
import { DEFAULT_QUOTATION_BASICS } from "./constants";

import CustomerInfoForm from "../CustomerInfoForm";
import messages from "../../../services/api/messages";

function CreateCustomerPageContent(props) {
  const { selectedCustomerId, setSelectedCustomerId } = props;

  const [loadInitState, setLoadInitState] = useState({
    isLoading: false,
    data: null,
    error: null,
  });
  const [updateStatus, setUpdateStatus] = useState(
    callApiStatus.idle
  );

  const loadInit = useCallback(() => {
    if (!selectedCustomerId?.id) return;

    setLoadInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    customerServices
      .getDetail(selectedCustomerId?.id)
      .then((res) => {
        const customerInfo = res.data;
        const isApplyDefaultQuotationBasic =
          customerInfo.quotationBasics.length <= 0;

        setLoadInitState({
          isLoading: false,
          data: {
            id: customerInfo.id,
            companyNameKanji: customerInfo.companyNameKanji,
            companyNameKana: customerInfo.companyNameKana,
            customerId: customerInfo.customerId,
            postalCode: customerInfo.postalCode,
            prefecture: customerInfo.prefecture,
            address1: customerInfo.address1,
            address2: customerInfo.address2,
            address3: customerInfo.address3,
            phone1: customerInfo.phone1,
            phone2: customerInfo.phone2,
            email: customerInfo.email,
            billing: customerInfo.billing,
            pic: customerInfo.pic,
            quotationBasics: isApplyDefaultQuotationBasic
              ? DEFAULT_QUOTATION_BASICS
              : customerInfo.quotationBasics,
          },
          error: null,
        });
      })
      .catch((error) => {
        toast.error(messages.callApiFail);
        setLoadInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [selectedCustomerId?.id]);

  useEffect(() => {
    loadInit();
  }, [loadInit]);

  const navigate = useNavigate();
  const formDataRef = useRef();
  const clickSubmit = () => {
    if (
      formDataRef.current &&
      typeof formDataRef.current.clickSubmit === "function"
    )
      formDataRef.current.clickSubmit();
  };

  const handleSubmitForm = (formData) => {
    const data = {
      customerId: selectedCustomerId.customerId,
      ...formData,
    };

    if (updateStatus === callApiStatus.loading) return;
    customerServices
      .updateInfo(data)
      .then((res) => {
        setUpdateStatus(callApiStatus.idle);
        navigate(routePaths.detailCustomer(res.data.id));
      })

      .catch((error) => {
        toast.error(messages.update(false));
        setUpdateStatus(callApiStatus.error);
      });
  };

  return (
    <>
      <div className="w-full h-auto">
        <div className="flex justify-between pb-4">
          <div className="flex items-end">顧客情報</div>
          <div className="flex gap-4">
            <Button
              disabled={
                loadInitState.isLoading || loadInitState.error
              }
              onClick={clickSubmit}
            >
              新規登録
            </Button>
          </div>
        </div>

        <div className="w-full h-auto">
          <SelectCustomerId
            customer={selectedCustomerId}
            setCustomer={setSelectedCustomerId}
          />
        </div>

        {loadInitState.isLoading ? <PageLoading /> : null}

        {!loadInitState.isLoading && loadInitState.error ? (
          <PageError />
        ) : null}

        {!loadInitState.isLoading &&
        !loadInitState.error &&
        loadInitState.data ? (
          <CustomerInfoForm
            ref={formDataRef}
            handleSubmitForm={handleSubmitForm}
            customerBaseInfo={loadInitState.data}
          />
        ) : null}
      </div>
    </>
  );
}

function CreateCustomerPage(props) {
  const [breadcrumb] = useOutletContext();
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);

  return (
    <ContentLayout
      sideMenuLinkOptions={[
        { linkTo: "/customer", linkTitle: "顧客情報の検索" },
        { linkTo: "/customer/create", linkTitle: "顧客情報の登録" },
        { linkTo: routePaths.topMenu, linkTitle: "トップへ戻る" },
      ]}
      breadcrumb={breadcrumb}
    >
      <div className="w-full h-full grow flex flex-col pt-2.5">
        <CreateCustomerPageContent
          selectedCustomerId={selectedCustomerId}
          setSelectedCustomerId={setSelectedCustomerId}
        />
      </div>
    </ContentLayout>
  );
}

export default CreateCustomerPage;
