import React, { useEffect, useMemo, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "app/components/UI/Button";
import Select from "app/components/UI/Form/Input/Select";
import InputText from "app/components/UI/Form/Input/InputText";
import AsyncCheckExistedInput from "app/components/UI/Form/Input/AsyncCheckExistedInput";

import {
  checkExistedStatus,
  userRoles,
  userRolesLabel,
} from "app/constants";
import TrashBinSVG from "app/assets/svg/trashbin.svg";
import { extractFirstLastFromFullName } from "../../../utils";
import { systemSettingsServices } from "../../../services/api";
import { toast } from "react-toastify";
import isEmail from "validator/lib/isEmail";
import "app/utils/yup-custom";
import validateMessages from "../../../validateMessages";

const userRoleOptions = [
  {
    value: userRoles.admin,
    label: userRolesLabel[userRoles.admin],
  },
  {
    value: userRoles.staff,
    label: userRolesLabel[userRoles.staff],
  },
];

const initUserId = {
  userId: "",
  nameKanji: "",
  nameKana: "",
  email: "",
  password: "",
  accountType: userRoles.admin,
};

function SelectRole({
  label,
  selectId,
  labelWidth,
  value,
  onChange,
}) {
  return (
    <div className="w-full h-[52px] flex items-center">
      {label ? (
        <label
          htmlFor={selectId}
          className="h-full pr-4 flex items-center"
          style={{
            width: `${labelWidth || 0}px`,
          }}
        >
          <span className="w-full text-sm leading-[22px] tracking-[.2em] text-end">
            {label}
          </span>
        </label>
      ) : null}

      <div
        className="h-full relative"
        style={{
          width: `calc(100% - ${labelWidth}px)`,
        }}
      >
        <Select
          id={selectId}
          name="select-accountType"
          wrapperClassname="bg-transparent"
          value={value}
          onChange={(e) => {
            console.log(e?.target?.value);
            onChange(e?.target?.value);
          }}
          options={userRoleOptions}
        />
      </div>
    </div>
  );
}

export default function CreateAndUpdateUserForm({
  handleSubmitForm,
  userIdToUpdate,
  handleShowConfirmDelete,
}) {
  const checkUserIdRef = useRef();
  const checkNameKanjiRef = useRef();
  const checkNameKanaRef = useRef();
  const checkLoginNameRef = useRef();

  const [checkUserIdStatus, setCheckUserIdStatus] = useState(
    checkExistedStatus.noChange
  );
  const [checkNameKanjiStatus, setCheckNameKanjiStatus] = useState(
    checkExistedStatus.noChange
  );
  const [checkNameKanaStatus, setCheckNameKanaStatus] = useState(
    checkExistedStatus.noChange
  );
  const [checkLoginNameStatus, setCheckLoginNameStatus] = useState(
    checkExistedStatus.noChange
  );

  const validationSchema = Yup.object().shape({
    nameKanji: Yup.string().required(
      validateMessages.required("山本　太郎")
    ),
    nameKana: Yup.string().required(
      validateMessages.required("フリガナ")
    ),
    userId: Yup.string().required(
      validateMessages.required("ユーザーID")
    ),
    email: Yup.string()
      .required(validateMessages.required("メールアドレス"))
      .email(),
    password: Yup.string()
      .required(validateMessages.required("パスワード"))
      .min(8, validateMessages.minLength("パスワード", 8))
      .max(20, validateMessages.maxLength("パスワード", 20)),
    accountType: Yup.string().required(
      validateMessages.required("権限")
    ),
  });

  const onSubmit = async (values) => {
    console.log("values", values);
    if (
      checkUserIdStatus === checkExistedStatus.isChecking ||
      checkUserIdStatus === checkExistedStatus.existed ||
      checkLoginNameStatus === checkExistedStatus.isChecking ||
      checkLoginNameStatus === checkExistedStatus.existed
    )
      return;

    const nameKanjiObj = extractFirstLastFromFullName(
      values.nameKanji
    );
    const nameKanaObj = extractFirstLastFromFullName(values.nameKana);

    handleSubmitForm({
      userId: values.userId,
      firstNameKanji: nameKanjiObj.firstName,
      lastNameKanji: nameKanjiObj.lastName,
      firstNameKana: nameKanaObj.firstName,
      lastNameKana: nameKanaObj.lastName,
      email: values.email,
      password: values.password,
      accountType: values.accountType,
    });
  };

  const initialValues = useMemo(
    () =>
      userIdToUpdate?.id
        ? {
            nameKanji: `${userIdToUpdate.firstNameKanji}　${userIdToUpdate.lastNameKanji}`,
            nameKana: `${userIdToUpdate.firstNameKana}　${userIdToUpdate.lastNameKana}`,
            userId: userIdToUpdate.userId,
            email: userIdToUpdate.email,
            password: "",
            accountType:
              userIdToUpdate.accountType || userRoles.admin,
          }
        : initUserId,
    [userIdToUpdate]
  );

  const {
    handleSubmit,
    errors,
    values,
    setFieldValue,
    // resetForm,
    touched,
    handleBlur,
    validateForm,
    // setFieldTouched,
  } = useFormik({
    initialValues: initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (checkUserIdRef.current) {
      clearTimeout(checkUserIdRef.current);
    }

    const check = (valueToCheck) => {
      if (initialValues.userId === valueToCheck) {
        setCheckUserIdStatus(checkExistedStatus.noChange);

        return;
      }

      setCheckUserIdStatus(checkExistedStatus.isChecking);
      systemSettingsServices
        .checkUserIdExisted(valueToCheck?.trim())
        .then((isExisted) => {
          if (values.userId === valueToCheck)
            if (!isExisted) {
              setCheckUserIdStatus(checkExistedStatus.available);
            } else {
              setCheckUserIdStatus(checkExistedStatus.existed);
            }
        });
    };

    checkUserIdRef.current = setTimeout(
      () => check(values.userId),
      500
    );
  }, [initialValues.userId, values.userId]);

  useEffect(() => {
    if (checkNameKanjiRef.current) {
      clearTimeout(checkNameKanjiRef.current);
    }

    const check = (valueToCheck) => {
      if (initialValues.nameKanji === valueToCheck) {
        setCheckNameKanjiStatus(checkExistedStatus.noChange);

        return;
      }

      setCheckNameKanjiStatus(checkExistedStatus.isChecking);
      systemSettingsServices
        .checkUserNameKanjiExisted(valueToCheck?.trim())
        .then((isExisted) => {
          if (values.nameKanji === valueToCheck)
            if (!isExisted) {
              setCheckNameKanjiStatus(checkExistedStatus.available);
            } else {
              setCheckNameKanjiStatus(checkExistedStatus.existed);
            }
        });
    };

    checkNameKanjiRef.current = setTimeout(
      () => check(values.nameKanji),
      500
    );
  }, [initialValues.nameKanji, values.nameKanji]);

  useEffect(() => {
    if (checkNameKanaRef.current) {
      clearTimeout(checkNameKanaRef.current);
    }

    const check = (valueToCheck) => {
      if (initialValues.nameKana === valueToCheck) {
        setCheckNameKanaStatus(checkExistedStatus.noChange);

        return;
      }

      setCheckNameKanaStatus(checkExistedStatus.isChecking);
      systemSettingsServices
        .checkUserNameKanaExisted(valueToCheck?.trim())
        .then((isExisted) => {
          if (values.nameKana === valueToCheck)
            if (!isExisted) {
              setCheckNameKanaStatus(checkExistedStatus.available);
            } else {
              setCheckNameKanaStatus(checkExistedStatus.existed);
            }
        });
    };

    checkNameKanaRef.current = setTimeout(
      () => check(values.nameKana),
      500
    );
  }, [initialValues.nameKana, values.nameKana]);

  useEffect(() => {
    if (checkLoginNameRef.current) {
      clearTimeout(checkLoginNameRef.current);
    }

    const check = (valueToCheck) => {
      if (typeof valueToCheck !== "string" || !isEmail(valueToCheck))
        return;
      if (initialValues.email === valueToCheck) {
        setCheckLoginNameStatus(checkExistedStatus.noChange);

        return;
      }

      setCheckLoginNameStatus(checkExistedStatus.isChecking);
      systemSettingsServices
        .checkLoginNameExisted(valueToCheck?.trim())
        .then((isExisted) => {
          if (values.email === valueToCheck)
            if (!isExisted) {
              setCheckLoginNameStatus(checkExistedStatus.available);
            } else {
              setCheckLoginNameStatus(checkExistedStatus.existed);
            }
        });
    };

    checkLoginNameRef.current = setTimeout(
      () => check(values.email),
      500
    );
  }, [initialValues.email, values.email]);

  return (
    <div className="p-4">
      <div className="flex justify-between pb-4">
        <div className="flex items-end">
          {userIdToUpdate?.id
            ? "ユーザー情報の変更"
            : "ユーザーの登録"}
        </div>
        <div className="flex gap-4">
          <Button
            onClick={() => {
              validateForm(values).then((res) => {
                if (Object.keys(res).length > 0) {
                  const errorList = Object.values(res);
                  toast.error(
                    <div>
                      {errorList.map((item) => (
                        <p className="texts-sm">{item}</p>
                      ))}
                    </div>
                  );
                }

                handleSubmit();
              });
            }}
          >
            {userIdToUpdate?.id ? "変更する" : "新規登録"}
          </Button>
          {userIdToUpdate?.id ? (
            <button
              onClick={handleShowConfirmDelete}
              type="button"
              className="center-flex"
            >
              <img
                className="w-[1.25em] h-[1.25em] cursor-pointer"
                alt=""
                src={TrashBinSVG}
              />
            </button>
          ) : null}
        </div>
      </div>
      <div className="w-full">
        <AsyncCheckExistedInput
          id="userId"
          value={values.userId}
          onChange={(newValue) => setFieldValue("userId", newValue)}
          label="ユーザーID"
          labelWidth={145}
          handleBlur={handleBlur}
          disabled={false}
          placeholder="ABCDE12345"
          checkStatus={checkUserIdStatus}
          errors={errors.userId}
          touched={touched.userId}
          requiredUnique
          requiredUniqueError="お客様IDはすでに存在します!"
          isRequired
        />
        <AsyncCheckExistedInput
          id="nameKanji"
          value={values.nameKanji}
          onChange={(newValue) =>
            setFieldValue("nameKanji", newValue)
          }
          label="ユーザー名"
          labelWidth={145}
          handleBlur={handleBlur}
          disabled={false}
          placeholder="山本　太郎"
          checkStatus={checkNameKanjiStatus}
          errors={errors.nameKanji}
          touched={touched.nameKanji}
          requiredUniqueError="会社名はすでに存在します!"
          isRequired
        />
        <AsyncCheckExistedInput
          id="nameKana"
          value={values.nameKana}
          onChange={(newValue) => setFieldValue("nameKana", newValue)}
          label="フリガナ"
          labelWidth={145}
          handleBlur={handleBlur}
          disabled={false}
          placeholder="ヤマモト　タロウ"
          checkStatus={checkNameKanaStatus}
          errors={errors.nameKana}
          touched={touched.nameKana}
          requiredUniqueError="フリガナはすでに存在します!"
          isRequired
        />
        <AsyncCheckExistedInput
          id="email"
          value={values.email}
          onChange={(newValue) => setFieldValue("email", newValue)}
          label="メールアドレス"
          labelWidth={145}
          handleBlur={handleBlur}
          disabled={false}
          placeholder="t.yamamoto@faith.com"
          checkStatus={checkLoginNameStatus}
          errors={errors.email}
          touched={touched.email}
          requiredUnique
          requiredUniqueError={
            <div className="flex flex-col">
              <p>このメールはすでに使用されています。</p>
              <p>別のメールを選択してください。</p>
            </div>
          }
          isRequired
        />

        <InputText
          id="password"
          value={values.password}
          onChange={(newValue) => setFieldValue("password", newValue)}
          label="パスワード"
          labelWidth={145}
          handleBlur={handleBlur}
          disabled={false}
          placeholder="**********"
          errors={errors.password}
          touched={touched.password}
          isRequired
        />

        <SelectRole
          id="accountType"
          value={values.accountType}
          onChange={(newValue) =>
            setFieldValue("accountType", newValue)
          }
          label="権限"
          labelWidth={145}
        />
      </div>
    </div>
  );
}
