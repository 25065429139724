const customerConfig = [
  {
    title: "会社名",
    dataKey: "customerName",
  },
  {
    title: "住所",
    shouldEdit: false,
    showInput: false,
    dataKey: "customerAddress",
  },
  {
    title: "担当部署",
    shouldEdit: true,
    showInput: false,
    dataKey: "department",
    isRequired: true,
  },
  {
    title: "担当者名",
    shouldEdit: true,
    showInput: false,
    dataKey: "picNameKanji",
    isRequired: true,
  },
  {
    title: "電話番号",
    dataKey: "phone1",
    shouldEdit: true,
    showInput: false,
    isRequired: true,
  },
];

const labConfig = [
  {
    title: "会社名",
    dataKey: "labCompanyNameKanji",
    shouldEdit: true,
    showInput: false,
  },
  {
    title: "住所",
    shouldEdit: true,
    dataKey: "labAddress",
    showInput: false,
  },
  {
    title: "担当者名",
    shouldEdit: true,
    dataKey: "labPicNameKanji",
    showInput: false,
  },
  {
    title: "電話番号",
    dataKey: "labPhone",
    shouldEdit: true,
    showInput: false,
  },
];

const materialConfig = [
  {
    title: "名称",
    dataKey: "name",
    shouldEdit: false,
    showInput: false,
  },
  {
    title: "前処理",
    dataKey: "preprocess",
    shouldEdit: true,
    showInput: false,
  },
]

const tableInfo = { customerConfig, labConfig, materialConfig };

export default tableInfo;
