import client from "./client";
import { DEFAULT_ITEM_PER_PAGE } from "app/constants";
import { extractFirstLastFromFullName } from "app/utils";

// CustomerId
const searchCustomerId = (page, searchParams) => {
  return client
    .get("/customer-info/all", {
      params: {
        page: page,
        perPage: DEFAULT_ITEM_PER_PAGE,
        customerId: searchParams?.customerId,
        companyNameKana: searchParams?.companyNameKana,
        companyNameKanji: searchParams?.companyNameKanji,
      },
    })
    .then((res) => {
      if (
        res.data?.success &&
        res.data?.result &&
        Array.isArray(res.data?.result.rows)
      )
        return {
          data: {
            rows: res.data?.result.rows.map((item) => ({
              id: item._id,
              companyNameKanji: item.companyNameKanji,
              companyNameKana: item.companyNameKana,
              customerId: item.customerId,
              email: item.email,
            })),
            currentPage: Number(res.data.result?.page),
            totalItems: Number(res.data.result?.total),
            totalPages: Number(res.data.result?.totalPages),
          },
        };
      throw new Error("Malformed data!");
    });
};

const getCustomerIdDetail = (id) => {
  return client.get(`/customer-info/get?id=${id}`).then((res) => {
    if (res.data?.success && res.data?.result)
      return {
        data: {
          id: res.data.result?._id,
          companyNameKanji: res.data.result?.companyNameKanji,
          companyNameKana: res.data?.result?.companyNameKana,
          email: res.data?.result?.email,
          customerId: res.data.result?.customerId,
          accountInfos: res.data.result?.accountInfos,
        },
      };
    throw new Error("Malformed data!");
  });
};

const registerCustomerId = (data) => {
  return client
    .post("/customer-info/system/create", data)
    .then((res) => {
      if (res.data?.success && res.data?.result)
        return {
          data: {
            id: res.data.result?._id,
          },
        };
      throw new Error("Malformed data!");
    });
};

const registerUserForCustomer = (id, data) => {
  return client
    .post(`/customer-info/system/user?id=${id}`, {
      ...data,
      accountType: "customer",
    })
    .then((res) => {
      if (res.data?.success && res.data?.result)
        return {
          data: {
            id: res.data.result?._id,
          },
        };
      throw new Error("Malformed data!");
    });
};

const updateCustomerId = (id, data) => {
  return client
    .put(`/customer-info/system/update?id=${id}`, data)
    .then((res) => {
      if (res.data?.success && res.data?.result)
        return {
          data: {
            id: res.data.result?._id,
          },
        };
      throw new Error("Malformed data!");
    });
};

const deleteCustomerId = (id) => {
  return client.delete(`/customer-info/delete?id=${id}`);
};

// User
const searchUser = (page, searchParams) => {
  const nameKanjiObj = extractFirstLastFromFullName(
    searchParams.nameKanji
  );
  const nameKanaObj = extractFirstLastFromFullName(
    searchParams.nameKana
  );

  return client
    .get("/user/account/all", {
      params: {
        page: page,
        perPage: DEFAULT_ITEM_PER_PAGE,
        userId: searchParams?.userId,
        lastNameKanji: nameKanjiObj?.lastName,
        firstNameKanji: nameKanjiObj?.firstName,
        lastNameKana: nameKanaObj?.lastName,
        firstNameKana: nameKanaObj?.firstName,
      },
    })
    .then((res) => {
      if (
        res.data?.success &&
        res.data?.result &&
        Array.isArray(res.data?.result.rows)
      )
        return {
          data: {
            rows: res.data?.result.rows.map((item) => ({
              id: item._id,
              userId: item.info?.userId || "",
              lastNameKanji: item.info?.lastNameKanji || "",
              firstNameKanji: item.info?.firstNameKanji || "",
              lastNameKana: item.info?.lastNameKana || "",
              firstNameKana: item.info?.firstNameKana || "",
              email: item.info?.email || "",
              accountType: item.accountType,
            })),
            currentPage: Number(res.data.result?.page),
            totalItems: Number(res.data.result?.total),
            totalPages: Number(res.data.result?.totalPages),
          },
        };
      throw new Error("Malformed data!");
    });
};

const getUserDetail = (id) => {
  return client.get(`/user/account/get?id=${id}`).then((res) => {
    if (res.data?.success && res.data?.result) {
      const accountInfo = res.data?.result;
      return {
        data: {
          id: accountInfo._id,
          userId: accountInfo.info?.userId || "",
          lastNameKanji: accountInfo.info?.lastNameKanji || "",
          firstNameKanji: accountInfo.info?.firstNameKanji || "",
          lastNameKana: accountInfo.info?.lastNameKana || "",
          firstNameKana: accountInfo.info?.firstNameKana || "",
          email: accountInfo?.loginName || "",
          accountType: accountInfo.accountType,
          password: accountInfo.password,
          userInfoId: accountInfo.info?._id || "",
        },
      };
    }
    throw new Error("Malformed data!");
  });
};

const registerUser = (data) => {
  return client.post("/user/account/create", data).then((res) => {
    if (res.data?.success && res.data?.result)
      return {
        data: {
          id: res.data.result?._id,
        },
      };
    throw new Error("Malformed data!");
  });
};

const updateUser = (id, data) => {
  return client.put(`/user/account/update?id=${id}`, data);
};

export const getAllUserId = () => {
  return client.get("/user/info/all-user-info").then((res) => {
    if (res.data?.success && Array.isArray(res.data?.result))
      return {
        data: res.data.result.map((item) => ({
          id: item?._id,
          lastNameKanji: item?.lastNameKanji,
          firstNameKanji: item?.firstNameKanji,
        })),
      };
    throw new Error("Malformed data!");
  });
};

export const getAllAccountList = () => {
  return client.get("/user/account/all");
};

export const getAccountListByListId = (listId) => {
  return client.get("/user/account/query-with-ids", {
    params: { listId },
  });
};

const deleteUser = (id) => {
  return client.delete(`/user/info/delete?id=${id}`);
};

// Utils
const checkCustomerIdExisted = (customerId) => {
  return client
    .get(
      `/customer-info/query-with-customer-id?customerId=${customerId}`
    )
    .then((res) => {
      if (res.data?.success && res.data?.result) return true;
      return false;
    });
};

const checkCompanyNameKanjiExisted = (companyNameKanji) => {
  return client
    .get(
      `/customer-info/query-with-name?companyNameKanji=${companyNameKanji}`
    )
    .then((res) => {
      if (res.data?.success && res.data?.result) return true;
      return false;
    });
};

const checkCompanyNameKanaExisted = (companyNameKana) => {
  return client
    .get(
      `/customer-info/query-with-name?companyNameKana=${companyNameKana}`
    )
    .then((res) => {
      if (res.data?.success && res.data?.result) return true;
      return false;
    });
};

const checkUserIdExisted = (userId) => {
  return client
    .get(`/user/info/get-user-id?user-id=${userId}`)
    .then((res) => {
      if (res.data?.success && res.data?.result) return true;
      return false;
    });
};

const checkUserNameKanjiExisted = (nameKanjiStr) => {
  const nameKanji = extractFirstLastFromFullName(nameKanjiStr);
  return client
    .get(
      `/user/account/query-with-name-of-user?firstNameKanji=${nameKanji.firstName}&lastNameKanji=${nameKanji.lastName}`
    )
    .then((res) => {
      if (
        res.data?.success &&
        Array.isArray(res.data?.result) &&
        res.data?.result.length > 0
      )
        return true;
      return false;
    });
};

const checkUserNameKanaExisted = (nameKanaStr) => {
  const nameKana = extractFirstLastFromFullName(nameKanaStr);
  return client
    .get(
      `/user/account/query-with-name-of-user?firstNameKana=${nameKana.firstName}&lastNameKana=${nameKana.lastName}`
    )
    .then((res) => {
      if (
        res.data?.success &&
        Array.isArray(res.data?.result) &&
        res.data?.result.length > 0
      )
        return true;
      return false;
    });
};

const checkLoginNameExisted = (loginName) => {
  return client
    .get(`/user/account/query-with-login-name?loginName=${loginName}`)
    .then((res) => {
      if (res.data?.success && res.data?.result) return true;
      return false;
    });
};

const systemSettingsServices = {
  searchCustomerId,
  registerCustomerId,
  registerUserForCustomer,
  getCustomerIdDetail,
  updateCustomerId,
  deleteCustomerId,

  searchUser,
  getAccountListByListId,
  getAllAccountList,
  registerUser,
  getUserDetail,
  updateUser,
  deleteUser,
  getAllUserId,

  checkCustomerIdExisted,
  checkCompanyNameKanjiExisted,
  checkCompanyNameKanaExisted,
  checkUserIdExisted,
  checkUserNameKanjiExisted,
  checkUserNameKanaExisted,
  checkLoginNameExisted,
};
export default systemSettingsServices;
