import moment from "moment";
import { getAddressFromComponent, getNameKanji } from "../../utils";
import client from "./client";
import clientFormData from "./clientFormData";

const getByCaseInfoId = (caseInfoId) => {
  return client
    .get("/report-info/get-by-caseinfo-id", {
      params: {
        caseInfoId,
      },
    })
    .then((res) => {
      if (res.data?.success) {
        const result = res.data.result;
        return {
          data: result
            ? {
                id: result._id,
                testId: result.testId,
                doctor: result.doctor,
                department: result.department,

                reportCustomerPIC: {
                  department: result?.pic?.department || "",
                  nameKanji: result?.pic?.nameKanji || "",
                  phone: result?.pic?.phone || "",
                },
                reportTestMaterialInfos: Array.isArray(
                  result.testMaterials
                )
                  ? result.testMaterials.map((item) => ({
                      name: item?.name || "",
                      preprocess: item?.preprocess || "",
                      materialNumber: item?.materialNumber || "",
                      capacity: item?.capacity || "",
                    }))
                  : [],

                testResult: result.testResult,
                generatedPdf: result.generatedPdf,
                generatedWord: result.generatedWord,
                createdAt: result.createdAt,
              }
            : null,
        };
      }
      throw new Error("Malformed data!");
    });
};

const getCustomer = (id) => {
  return client.get(`/customer-info/get?id=${id}`).then((res) => {
    if (res.data?.success && res.data?.result)
      return {
        data: {
          id: res.data.result?._id,
          companyNameKanji: res.data.result?.companyNameKanji,

          postalCode: res.data.result?.postalCode,
          prefecture: res.data.result?.prefecture,
          address1: res.data.result?.address1,
          address2: res.data.result?.address2,
          address3: res.data.result?.address3,

          pic: {
            department: res.data.result?.pic?.department || "",
            lastNameKanji: res.data.result?.pic?.lastNameKanji || "",
            firstNameKanji:
              res.data.result?.pic?.firstNameKanji || "",
            phone1: res.data.result?.pic?.phone1 || "",
          },
        },
      };
    throw new Error("Malformed data!");
  });
};

const getQuotation = (id) => {
  return client
    .get("/quotation-info/get-by-id", {
      params: { id },
    })
    .then((res) => {
      if (res.data?.success && res.data.result) {
        const quotation = res.data.result;
        return {
          picStaff: quotation?.picStaff,
          quotationId: quotation?.quotationId,
        };
      }
      throw new Error("Malformed data!");
    });
};

const getInitNewReport = (customerId, quotationInfoId) => {
  return Promise.all([
    getQuotation(quotationInfoId),
    getCustomer(customerId),
  ]).then((res) => {
    const quotationData = res[0];
    const customerData = res[1];
    return {
      picStaff: quotationData?.picStaff,
      testId: quotationData?.quotationId,
      doctor: "",
      department: "",
      companyNameKanji: customerData?.data?.companyNameKanji,
      companyAddress: getAddressFromComponent(customerData?.data),
      reportCustomerPIC: {
        department: customerData?.data?.pic?.department,
        nameKanji: getNameKanji({
          lastNameKanji: customerData?.data?.pic?.lastNameKanji || "",
          firstNameKanji:
            customerData?.data?.pic?.firstNameKanji || "",
        }),
        phone: customerData?.data?.pic?.phone1,
      },
      reportTestMaterialInfos: [],
      createdAt: moment().toISOString(),
    };
  });
};

const createFileDownload = (data) => {
  return clientFormData.post(
    "/report-info/create-file-download",
    data
  );
};

const createReport = (formData) => {
  return client
    .post("/report-info/create", {
      customerId: formData.customerId,
      testScheduleId: formData.testScheduleId,
      caseInfoId: formData.caseInfoId,
      testId: formData.testId,
      doctor: formData.doctor,
      department: formData.reportCustomerPIC?.department || "",
      reportCustomerPIC: {
        department: formData.reportCustomerPIC?.department || "",
        nameKanji: formData.reportCustomerPIC?.nameKanji || "",
        phone: formData.reportCustomerPIC?.phone || "",
      },
      reportTestMaterialInfos: Array.isArray(
        formData.reportTestMaterialInfos
      )
        ? formData.reportTestMaterialInfos.map((item) => ({
            name: item.name,
            preprocess: item.preprocess,
            materialNumber: item.materialNumber,
            capacity: item.capacity,
          }))
        : [],
      testResult: formData.testResult,
      generatedPdf: formData.generatedPdf,
      generatedWord: formData.generatedWord,
    })
    .then((res) => {
      if (res.data?.success)
        return {
          data: res.data.result ? { id: res.data.result?._id } : null,
        };
      throw new Error("Malformed data!");
    });
};

const updateReport = (id, formData) => {
  return client
    .put(`/report-info/update?id=${id}`, {
      customerId: formData.customerId,
      testScheduleId: formData.testScheduleId,
      testId: formData.testId,
      doctor: formData.doctor,
      caseInfoId: formData.caseInfoId,
      department: formData.reportCustomerPIC?.department || "",
      reportCustomerPIC: {
        department: formData.reportCustomerPIC?.department || "",
        nameKanji: formData.reportCustomerPIC?.nameKanji || "",
        phone: formData.reportCustomerPIC?.phone || "",
      },
      reportTestMaterialInfos: Array.isArray(
        formData.reportTestMaterialInfos
      )
        ? formData.reportTestMaterialInfos.map((item) => ({
            name: item.name,
            preprocess: item.preprocess,
            materialNumber: item.materialNumber,
            capacity: item.capacity,
          }))
        : [],
      testResult: formData.testResult,
      generatedPdf: formData.generatedPdf,
      generatedWord: formData.generatedWord,
    })
    .then((res) => {
      if (res.data?.success)
        return {
          data: res.data.result ? { id: res.data.result?._id } : null,
        };
      throw new Error("Malformed data!");
    });
};

const reportServices = {
  getByCaseInfoId,
  getInitNewReport,
  createReport,
  updateReport,
  createFileDownload,
};

export default reportServices;
