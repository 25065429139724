import { useState } from "react";
import { toast } from "react-toastify";
import SelectCustomerIdAndTestSchedule from "../../../components/UI/Form/SelectGroup/SelectCustomerIdAndTestSchedule";
import Button from "../../../components/UI/Button";
import ContentLayout from "../../../layouts/ContentLayout";
import routePaths from "../../../route/routePaths";
import { callApiStatus } from "../../../constants";
import PageLoading from "../../../components/CommonPage/PageLoading";
import PageError from "../../../components/CommonPage/PageError";
import CreatePlan from "../CreateTestPlan/CreatePlan";
import UpdatePlan from "../UpdateTestPlan/UpdatePlan";
import { useOutletContext } from "react-router-dom";
import messages from "../../../services/api/messages";
import { quotationServices } from "../../../services/api";

function CreateAndUpdate({
  isUpdate,
  quotationInfo,
  customer,
  schedule,
}) {
  return !isUpdate ? (
    <CreatePlan
      quotationInfo={quotationInfo}
      customer={customer}
      schedule={schedule}
    />
  ) : (
    <UpdatePlan
      quotationInfo={quotationInfo}
      customer={customer}
      schedule={schedule}
    />
  );
}

function CreateAndUpdateFormWrapper({
  isUpdate,
  customer,
  schedule,
  loadInitState,
}) {
  if (loadInitState.loadingStatus === callApiStatus.loading)
    return <PageLoading />;
  if (loadInitState.loadingStatus === callApiStatus.error)
    return <PageError />;

  if (
    loadInitState.loadingStatus === callApiStatus.success &&
    loadInitState.data?.id
  )
    return (
      <CreateAndUpdate
        isUpdate={isUpdate}
        customer={customer}
        schedule={schedule}
        quotationInfo={loadInitState.data}
      />
    );

  if (
    loadInitState.loadingStatus === callApiStatus.success &&
    !loadInitState.data?.id
  )
    return (
      <div className="w-full h-auto my-12 flex items-center justify-center text-xl">
        {messages.quotationExist(false)}
      </div>
    );

  return null;
}

export default function CreateUpdateForm({ isUpdate }) {
  const [breadcrumb] = useOutletContext();
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [selectedCaseInfo, setSelectedCaseInfo] = useState(null);

  const [loadInitState, setLoadInitState] = useState({
    loadingStatus: callApiStatus.idle,
    data: null,
    error: null,
  });

  const reset = () => {
    setSelectedCaseInfo(null);
    setLoadInitState({
      loadingStatus: callApiStatus.idle,
      data: null,
      error: null,
    });
  };

  const handleCalloutQuotation = () => {
    if (!selectedCaseInfo?.id) {
      return;
    }
    setLoadInitState({
      loadingStatus: callApiStatus.loading,
      data: null,
      error: null,
    });
    quotationServices
      .getQuotationById(selectedCaseInfo.quotation)
      .then((res) => {
        setLoadInitState({
          loadingStatus: callApiStatus.success,
          data: { ...res.data, id: res.data._id },
          error: null,
        });
      })
      .catch((error) => {
        toast.error(messages.callApiFail);
        setLoadInitState({
          loadingStatus: callApiStatus.error,
          data: null,
          error: error,
        });
      });
  };

  return (
    <ContentLayout
      sideMenuLinkOptions={[
        {
          linkTo: routePaths.createTestPlan,
          linkTitle: "計画書の作成",
        },
        {
          linkTo: routePaths.updateTestPlan,
          linkTitle: "計画書の修正",
        },
        { linkTo: routePaths.topMenu, linkTitle: "トップへ戻る" },
      ]}
      breadcrumb={breadcrumb}
    >
      <div className="w-full h-auto">
        <div className="w-full h-auto mb-5">
          <p className="text-custom-size mb-3">
            {isUpdate ? "試験計画書の修正" : "試験計画書の作成"}
          </p>
          <SelectCustomerIdAndTestSchedule
            customer={selectedCustomer}
            setCustomer={(newCustomer) => {
              setSelectedCustomer(newCustomer);
              reset();
            }}
            schedule={selectedSchedule}
            setSchedule={(newSchedule) => {
              setSelectedSchedule(newSchedule);
              reset();
            }}
            caseInfo={selectedCaseInfo}
            setCaseInfo={(newCaseInfo) => {
              setLoadInitState({
                loadingStatus: callApiStatus.idle,
                data: null,
                error: null,
              });
              setSelectedCaseInfo({
                ...newCaseInfo,
                id: newCaseInfo._id,
              });
            }}
          />
          <div className="w-full h-auto flex items-center justify-end mt-5">
            <Button
              onClick={handleCalloutQuotation}
              disabled={!selectedCaseInfo}
              type={!selectedCaseInfo ? "disabled" : "primary"}
            >
              呼び出す
            </Button>
          </div>
        </div>

        <CreateAndUpdateFormWrapper
          isUpdate={isUpdate}
          customer={selectedCustomer}
          schedule={selectedSchedule}
          loadInitState={loadInitState}
        />
      </div>
    </ContentLayout>
  );
}
