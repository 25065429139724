/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { ToastContainer, toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import {
  setCredentials,
  saveLoginInfo,
  selectLoginInfo,
} from "app/redux/authReducer";
import { callApiStatus } from "app/constants";
import { authServices } from "app/services/api";
import logo from "app/assets/images/logo.png";

function Login() {
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [isSaveLoginInfo, setIsSaveLoginInfo] = useState(false);

  const [loginStatus, setLoginStatus] = useState(callApiStatus.idle);

  const loginPageInfo = useSelector(selectLoginInfo);

  useEffect(() => {
    setLoginData({
      email: loginPageInfo?.email || "",
      password: loginPageInfo?.password || "",
    });
    if (loginPageInfo?.email || loginPageInfo?.password) {
      setIsSaveLoginInfo(true);
    } else {
      setIsSaveLoginInfo(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const handleLogin = () => {
    if (loginStatus === callApiStatus.loading) return;

    const email = loginData.email;
    const password = loginData.password;

    setLoginStatus(callApiStatus.loading);
    authServices
      .login(email, password)
      .then((res) => {
        const userData = jwtDecode(res.data.result.accessToken);
        const token = {
          accessToken: res.data.result.accessToken,
          refreshToken: "",
        };

        dispatch(setCredentials({ user: userData, token: token }));
        setLoginStatus(callApiStatus.success);

        if (isSaveLoginInfo) {
          dispatch(
            saveLoginInfo({
              email,
              password,
            })
          );
        } else {
          dispatch(
            saveLoginInfo({
              email: "",
              password: "",
            })
          );
        }
      })
      .catch((error) => {
        console.log("error: ", error);

        if (!isSaveLoginInfo)
          dispatch(
            saveLoginInfo({
              email: "",
              password: "",
            })
          );
        setLoginStatus(callApiStatus.error);
        toast.error(
          <div className="flex flex-col">
            <p>メールアドレスもしくはパスワードが異なります</p>
          </div>
        );
      });
  };

  return (
    <>
      <div className="h-screen w-full relative">
        <div className="absolute inset-x-0 top-0 w-full h-[90px] flex justify-center">
          <div className="px-5 w-full max-w-[1180px] h-full flex items-center">
            <img alt="logo" className="w-[200px] h-[38px]" src={logo} />
          </div>
        </div>

        <div className="w-full h-full flex flex-col items-center justify-center">
          <div className="w-full max-w-[698px] h-auto flex flex-col items-center justify-center px-5 lg:px-0">
            <h3 className="text-custom-size leading-[19px] font-normal text-[#001C4F]">
              管理者用
            </h3>
            <h1 className="text-[32px] leading-10 text-[#001C4F] mb-10">
              Login
            </h1>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleLogin();
              }}
              className="w-full flex flex-col"
            >
              <div className="w-full h-auto flex flex-col mb-10">
                <label
                  htmlFor="email"
                  className="w-full text-custom-size leading-4 mb-4 text-[#001C4F]"
                >
                  メールアドレス
                </label>
                <div className="relative">
                  <input
                    id="email"
                    type="email"
                    name="email"
                    className="w-full h-[38px] placeholder-[#1E1E1E59] rounded-lg border border-[#1E1E1EDE] focus:border-blue-400 !outline-none px-3 py-1.5 text-base"
                    placeholder="faith@faith-research.co.jp"
                    value={loginData.email}
                    onChange={(e) => {
                      setLoginData((oldState) => ({
                        ...oldState,
                        email: e.target.value,
                      }));
                    }}
                    required
                  />
                </div>
              </div>

              <div className="w-full h-auto flex flex-col mb-[15px]">
                <label
                  htmlFor="password"
                  className="w-full text-custom-size leading-4 mb-4 text-[#001C4F]"
                >
                  パスワード
                </label>
                <div className="relative">
                  <input
                    id="password"
                    type="password"
                    name="password"
                    className="w-full h-[38px] placeholder-[#1E1E1E59] rounded-lg border border-[#1E1E1EDE] focus:border-blue-400 !outline-none px-3 py-1.5 text-base"
                    placeholder="**********"
                    value={loginData.password}
                    onChange={(e) => {
                      setLoginData((oldState) => ({
                        ...oldState,
                        password: e.target.value,
                      }));
                    }}
                    required
                  />
                </div>
              </div>

              <div className="w-full h-auto flex items-center mb-[54px]">
                <button
                  type="button"
                  onClick={() => setIsSaveLoginInfo(!isSaveLoginInfo)}
                  className="flex items-center gap-2"
                >
                  <div className="w-6 h-6 border border-black rounded flex items-center justify-center">
                    {isSaveLoginInfo ? (
                      <FontAwesomeIcon icon={faCheck} />
                    ) : null}
                  </div>

                  <p className="text-custom-size leading-6 text-[#001C4F]">
                    パスワードを保存する
                  </p>
                </button>
              </div>

              <button
                type="submit"
                disabled={loginStatus === callApiStatus.loading}
                className="w-full h-[52px] flex items-center justify-center bg-[#001C4F] "
                style={{
                  cursor:
                    loginStatus === callApiStatus.loading ? "wait" : "pointer",
                }}
              >
                {loginStatus === callApiStatus.loading ? (
                  <CircularProgress size={24} className="text-white mr-2" />
                ) : null}
                <span className="text-custom-size font-base text-white">
                  ログイン
                </span>
              </button>
            </form>
          </div>
        </div>
      </div>

      <ToastContainer
        position="bottom-left"
        autoClose={1500}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        limit={3}
      />
    </>
  );
}

export default Login;
