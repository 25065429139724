import client from "./client";

const searchByCustomerAndSchedule = (customerId, scheduleId) => {
  return client
    .get("/case-info/get-by-customer-id-and-test-schedule", {
      params: {
        "customer-id": customerId,
        "test-schedule-id": scheduleId,
      },
    })
    .then((res) => {
      if (res.data?.success) {
        return res.data.result;
      }
      throw new Error("Malformed data!");
    });
};

const caseServices = {
  searchByCustomerAndSchedule,
};

export default caseServices;
