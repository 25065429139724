import React, { useMemo, useState } from "react";
import moment from "moment";
import * as Yup from "yup";
import { useOutletContext } from "react-router-dom";
import { useFormik } from "formik";
import { useMediaQuery } from "@mui/material";
import { toast } from "react-toastify";
import ContentLayout from "app/layouts/ContentLayout";
import routePaths from "app/route/routePaths";
import SelectCustomerIdAndTestSchedule from "app/components/UI/Form/SelectGroup/SelectCustomerIdAndTestSchedule";
import InputText from "app/components/UI/Form/Input/InputText";
import SelectPicStaff from "app/components/UI/Form/Select/SelectPicStaff";
import InputDate from "app/components/UI/Form/Input/InputDate";
import ReactSelect from "app/components/UI/Form/Select/ReactSelectSingle";
import Button from "app/components/UI/Button";
import ClearRedBtn from "app/components/UI/Button/ClearRedBtn";
import PrimaryBtn from "app/components/UI/Button/PrimaryBtn";
import {
  DEFAULT_DATE_FORMAT,
  LABEL_WIDTH,
  billingTypeOptions,
  callApiStatus,
  fileNameType,
} from "app/constants";
import { commonServices, quotationServices } from "app/services/api";
import QuotationTaskTable from "../CreateQuotation/QuotationTaskTable";
import PageLoadingBackdrop from "../../../components/CommonPage/PageLoadingBackdrop";
import messages from "../../../services/api/messages";
import validateMessages from "../../../validateMessages";
import TextArea from "../../../components/UI/Form/Input/TextArea";

function UpdateQuotationForm( { initQuotation, customer }) {
  const [confirmedData, setConfirmedData] = useState(null);
  const isMd = useMediaQuery("(max-width:768px)");

  const validationSchema = Yup.object().shape({
    paymentMethod: Yup.string().required(
      validateMessages.required("支払方法")
    ),
    deliveryDate: Yup.string(),
    deliveryPlace: Yup.string(),
    expiryDateStatement: Yup.string().required(
      validateMessages.required("有効期間")
    ),
    requirements: Yup.string().required(
      validateMessages.required("要件")
    ),
    memo: Yup.string().required(validateMessages.required("備考")),
  });

  const onSubmit = (validValues) => {
    const firstTaskItem = validValues.quotationTasks?.[0];
    let totalSamplePeopleNumber = Number(
      firstTaskItem?.inspectionPeopleNumber || 0
    );
    let totalSampleNumber = 0;
    let totalQuotationPrice = 0;

    validValues.quotationTasks.forEach((item) => {
      totalSampleNumber += Number(item.testSampleNumber);
      totalQuotationPrice +=
        Number(item.testSampleNumber) * Number(item.quotationPrice);
    });

    const dataToCallApi = {
      ...genDataToCallApi(validValues),
      totalSamplePeopleNumber,
      totalSampleNumber,
      totalQuotationPrice,
    };

    quotationServices
      .createFileDownload(dataToCallApi)
      .then((res) => {
        setConfirmedData({
          ...validValues,
          generatedExcel: res.data._id,
        });

        const fileType = fileNameType["quotation"];
        const caseId = values?.quotationId || "";
        const companyName = customer?.companyNameKanji || "";
        const timeStamp = moment().format("YYYYMMDDHHmmss");

        setTimeout(() => {
          commonServices
            .downloadPDFWithFileId(
              res.data._id,
              `${fileType}_${caseId}_${companyName}_${timeStamp}`
            )
            .catch(() => toast.error(messages.downloadFail));
        }, 500);

        toast.success(messages.create(true));
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(messages.create(false));
        setIsLoading(false);
      });
  };

  const {
    handleSubmit,
    errors,
    values,
    setFieldValue,
    resetForm,
    touched,
    // handleBlur,
    // setFieldTouched,
  } = useFormik({
    initialValues: initQuotation,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const [isLoading, setIsLoading] = useState(false);

  const isDisabledSubmit = useMemo(
    () => !confirmedData,
    [confirmedData]
  );

  const changeQuotationInfo = (field, value) => {
    setFieldValue(field, value);
    setConfirmedData(null);
  };

  const convertDateToISO = (dateStr) => {
    return dateStr
      ? moment(dateStr, DEFAULT_DATE_FORMAT).toISOString()
      : "";
  };

  const genDataToCallApi = (quotationData) => {
    const data = {
      customerId: quotationData.customerId,
      testScheduleId: quotationData.testScheduleId,
      quotationId: quotationData.quotationId,
      deliveryPlace: quotationData.deliveryPlace,
      paymentMethod: quotationData.paymentMethod,
      expiryDateStatement: quotationData.expiryDateStatement,
      requirements: quotationData.requirements,
      memo: quotationData.memo,
      picStaff: quotationData.picStaff,
      paymentDeadline: [
        convertDateToISO(quotationData.paymentDeadline1),
        convertDateToISO(quotationData.paymentDeadline2),
        convertDateToISO(quotationData.paymentDeadline3),
        convertDateToISO(quotationData.paymentDeadline4),
        convertDateToISO(quotationData.paymentDeadline5),
        convertDateToISO(quotationData.paymentDeadline6),
        convertDateToISO(quotationData.paymentDeadline7),
        convertDateToISO(quotationData.paymentDeadline8),
      ],
      quotationTasks: Array.isArray(quotationData.quotationTasks)
        ? quotationData.quotationTasks.map((item) => ({
            name: item.name,
            contractType: item.contractType,
            billingTiming: item.billingTiming,
            volumn: item.volumn,
            inspectionPeopleNumber: item.inspectionPeopleNumber,
            testSampleNumber: item.testSampleNumber,
            quotationPrice: item.quotationPrice,
            quotationPriceUnit: item.quotationPriceUnit,
            memo: item.memo,
          }))
        : [],
    };

    if (quotationData.deliveryDate) {
      data.deliveryDate = quotationData.deliveryDate;
    }
    return data;
  };

  const handleConfirm = () => {
    handleSubmit();
  };

  const handleSave = () => {
    if (!confirmedData || isLoading) return;

    const firstTaskItem = confirmedData.quotationTasks?.[0];
    let totalSamplePeopleNumber = Number(
      firstTaskItem?.inspectionPeopleNumber || 0
    );
    let totalSampleNumber = 0;
    let totalQuotationPrice = 0;

    confirmedData.quotationTasks.forEach((item) => {
      totalSampleNumber += Number(item.testSampleNumber);
      totalQuotationPrice +=
        Number(item.testSampleNumber) * Number(item.quotationPrice);
    });

    const dataToCallApi = {
      ...genDataToCallApi(confirmedData),
      generatedExcel: confirmedData.generatedExcel,
      totalSamplePeopleNumber,
      totalSampleNumber,
      totalQuotationPrice,
    };
    setIsLoading(true);

    quotationServices
      .updateQuotation(initQuotation.id, dataToCallApi)
      .then((res) => {
        toast.success(messages.update(true));
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(messages.update(false));
        setIsLoading(false);
      });
  };

  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto flex items-center justify-center mb-3 flex-wrap">
        <div className="w-full mb-3 md:mb-0 md:w-1/2 h-full md:pr-12">
          <InputText
            id="quotationId"
            value={values.quotationId}
            label="見積番号"
            disabled={true}
            labelWidth={isMd ? LABEL_WIDTH.RIGHT : LABEL_WIDTH.LEFT}
          />
        </div>

        <div className="w-full md:w-1/2 h-full">
          <SelectPicStaff
            pic={{ id: values.picStaff }}
            setPic={(newPic) => {
              changeQuotationInfo("picStaff", newPic?.id);
            }}
            labelWidth={LABEL_WIDTH.RIGHT}
            touched={touched.picStaff}
            errors={errors.picStaff}
            isRequired
          />
        </div>
      </div>
      <div className="w-full h-auto flex items-center justify-center mb-3 flex-wrap">
        <div className="w-full md:w-1/2 h-full md:pr-12">
          <InputDate
            id="deliveryDate"
            value={values.deliveryDate}
            onChange={(newValue) => {
              changeQuotationInfo("deliveryDate", newValue);
            }}
            label="納入期日"
            labelWidth={isMd ? LABEL_WIDTH.RIGHT : LABEL_WIDTH.LEFT}
            touched={touched.deliveryDate}
            errors={errors.deliveryDate}
          />
        </div>

        <div className="w-full md:w-1/2 h-full">
          <InputText
            id="deliveryPlace"
            value={values.deliveryPlace}
            onChange={(newValue) => {
              changeQuotationInfo("deliveryPlace", newValue);
            }}
            label="納入場所"
            labelWidth={LABEL_WIDTH.RIGHT}
            touched={touched.deliveryPlace}
            errors={errors.deliveryPlace}
          />
        </div>
      </div>

      <div className="w-full h-auto flex items-center justify-center mb-3 flex-wrap">
        <div className="w-full md:w-1/2 h-[52px] md:pr-12 mb-3 md:mb-0">
          <ReactSelect
            value={values.paymentMethod}
            onChange={(newValue) => {
              changeQuotationInfo("paymentMethod", newValue);
            }}
            label="支払方法"
            options={billingTypeOptions}
            labelWidth={isMd ? LABEL_WIDTH.RIGHT : LABEL_WIDTH.LEFT}
            touched={touched.paymentMethod}
            errors={errors.paymentMethod}
            isRequired
          />
        </div>

        <div className="w-full md:w-1/2 h-full">
          <InputText
            id="expiryDateStatement"
            value={values.expiryDateStatement}
            onChange={(newValue) => {
              changeQuotationInfo("expiryDateStatement", newValue);
            }}
            label="有効期間"
            labelWidth={LABEL_WIDTH.RIGHT}
            touched={touched.expiryDateStatement}
            errors={errors.expiryDateStatement}
            isRequired
          />
        </div>
      </div>
      <div className="w-full h-auto flex items-center justify-center mb-3 flex-wrap">
        <div className="w-full md:w-1/2 h-full md:pr-12">
          <InputText
            id="requirements"
            value={values.requirements}
            onChange={(newValue) => {
              changeQuotationInfo("requirements", newValue);
            }}
            label="要件"
            labelWidth={isMd ? LABEL_WIDTH.RIGHT : LABEL_WIDTH.LEFT}
            touched={touched.requirements}
            errors={errors.requirements}
            isRequired
          />
        </div>

        <div className="w-1/2 h-full"></div>
      </div>

      <div className="w-full h-auto flex items-center justify-center mb-3">
        <div className="w-full h-full">
          <TextArea
            id="memo"
            value={values.memo}
            onChange={(newValue) => {
              changeQuotationInfo("memo", newValue);
            }}
            label="備考"
            labelWidth={isMd ? LABEL_WIDTH.RIGHT : LABEL_WIDTH.LEFT}
            touched={touched.memo}
            errors={errors.memo}
            isRequired
          />
        </div>
      </div>

      <div className="w-full h-auto mb-7">
        <div className="w-full h-auto flex items-start">
          <label
            className="h-[52px] pr-4 flex items-center shrink-0"
            style={{
              width: `${
                isMd ? LABEL_WIDTH.RIGHT : LABEL_WIDTH.LEFT
              }px`,
            }}
          >
            <span
              className="w-full text-sm leading-[22px] tracking-[.2em] whitespace-nowrap"
              style={{
                direction: "rtl",
              }}
            >
              振込期限
            </span>
          </label>

          <div
            className="h-auto"
            style={{
              width: `calc(100% - ${LABEL_WIDTH.LEFT}px)`,
            }}
          >
            <div className="w-[calc(100%+16px)] h-auto flex items-center mb-0 md:mb-3 flex-wrap -mx-2">
              <div className="w-full sm:w-1/2 md:w-1/4 h-[52px] px-2">
                <InputDate
                  id="paymentDeadline1"
                  value={values.paymentDeadline1}
                  onChange={(newValue) => {
                    changeQuotationInfo("paymentDeadline1", newValue);
                  }}
                  errors={errors.paymentDeadline1}
                />
              </div>
              <div className="w-full sm:w-1/2 md:w-1/4 h-[52px] px-2">
                <InputDate
                  id="paymentDeadline2"
                  value={values.paymentDeadline2}
                  onChange={(newValue) => {
                    changeQuotationInfo("paymentDeadline2", newValue);
                  }}
                />
              </div>
              <div className="w-full sm:w-1/2 md:w-1/4 h-[52px] px-2">
                <InputDate
                  id="paymentDeadline3"
                  value={values.paymentDeadline3}
                  onChange={(newValue) => {
                    changeQuotationInfo("paymentDeadline3", newValue);
                  }}
                />
              </div>
              <div className="w-full sm:w-1/2 md:w-1/4 h-[52px] px-2">
                <InputDate
                  id="paymentDeadline4"
                  value={values.paymentDeadline4}
                  onChange={(newValue) => {
                    changeQuotationInfo("paymentDeadline4", newValue);
                  }}
                />
              </div>
            </div>
            <div className="w-[calc(100%+16px)] h-auto flex items-center mb-3 flex-wrap -mx-2">
              <div className="w-full sm:w-1/2 md:w-1/4 h-[52px] px-2">
                <InputDate
                  id="paymentDeadline5"
                  value={values.paymentDeadline5}
                  onChange={(newValue) => {
                    changeQuotationInfo("paymentDeadline5", newValue);
                  }}
                />
              </div>
              <div className="w-full sm:w-1/2 md:w-1/4 h-[52px] px-2">
                <InputDate
                  id="paymentDeadline6"
                  value={values.paymentDeadline6}
                  onChange={(newValue) => {
                    changeQuotationInfo("paymentDeadline6", newValue);
                  }}
                />
              </div>
              <div className="w-full sm:w-1/2 md:w-1/4 h-[52px] px-2">
                <InputDate
                  id="paymentDeadline7"
                  value={values.paymentDeadline7}
                  onChange={(newValue) => {
                    changeQuotationInfo("paymentDeadline7", newValue);
                  }}
                />
              </div>
              <div className="w-full sm:w-1/2 md:w-1/4 h-[52px] px-2">
                <InputDate
                  id="paymentDeadline8"
                  value={values.paymentDeadline8}
                  onChange={(newValue) => {
                    changeQuotationInfo("paymentDeadline8", newValue);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full overflow-x-auto">
        <QuotationTaskTable
          quotationTasks={values.quotationTasks}
          setQuotationTasks={(newValue) =>
            changeQuotationInfo("quotationTasks", newValue)
          }
          showResultInit={true}
        />
      </div>

      <div className="ml-auto flex gap-[13px] justify-end mt-[14px]">
        <ClearRedBtn
          onClick={() => resetForm(initQuotation)}
          text="クリア"
        />
        <PrimaryBtn onClick={handleConfirm} text="確認する" />
        <Button
          disabled={isDisabledSubmit}
          type={isDisabledSubmit ? "disabled" : "primary"}
          onClick={handleSave}
        >
          修正する
        </Button>
      </div>

      <PageLoadingBackdrop open={isLoading} />
    </div>
  );
}

function UpdateQuotation({
  customer,
  testSchedule,
  quotationToUpdate,
}) {
  const convertDateToDefaultFormat = (dateStr) => {
    return dateStr ? moment(dateStr).format(DEFAULT_DATE_FORMAT) : "";
  };

  const initQuotation = useMemo(() => {
    return {
      id: quotationToUpdate._id,
      customerId: customer.id,
      testScheduleId: testSchedule.id,
      deliveryDate: convertDateToDefaultFormat(
        quotationToUpdate.deliveryDate
      ),
      quotationId: quotationToUpdate.quotationId,
      deliveryPlace: quotationToUpdate.deliveryPlace,
      paymentMethod: quotationToUpdate.paymentMethod,
      expiryDateStatement: quotationToUpdate.expiryDateStatement,
      requirements: quotationToUpdate.requirements,
      memo: quotationToUpdate.memo,
      picStaff: quotationToUpdate.picStaff,
      paymentDeadline1: Array.isArray(
        quotationToUpdate.paymentDeadline
      )
        ? convertDateToDefaultFormat(
            quotationToUpdate.paymentDeadline?.[0]
          )
        : "",
      paymentDeadline2: Array.isArray(
        quotationToUpdate.paymentDeadline
      )
        ? convertDateToDefaultFormat(
            quotationToUpdate.paymentDeadline?.[1]
          )
        : "",
      paymentDeadline3: Array.isArray(
        quotationToUpdate.paymentDeadline
      )
        ? convertDateToDefaultFormat(
            quotationToUpdate.paymentDeadline?.[2]
          )
        : "",
      paymentDeadline4: Array.isArray(
        quotationToUpdate.paymentDeadline
      )
        ? convertDateToDefaultFormat(
            quotationToUpdate.paymentDeadline?.[3]
          )
        : "",
      paymentDeadline5: Array.isArray(
        quotationToUpdate.paymentDeadline
      )
        ? convertDateToDefaultFormat(
            quotationToUpdate.paymentDeadline?.[4]
          )
        : "",
      paymentDeadline6: Array.isArray(
        quotationToUpdate.paymentDeadline
      )
        ? convertDateToDefaultFormat(
            quotationToUpdate.paymentDeadline?.[5]
          )
        : "",
      paymentDeadline7: Array.isArray(
        quotationToUpdate.paymentDeadline
      )
        ? convertDateToDefaultFormat(
            quotationToUpdate.paymentDeadline?.[6]
          )
        : "",
      paymentDeadline8: Array.isArray(
        quotationToUpdate.paymentDeadline
      )
        ? convertDateToDefaultFormat(
            quotationToUpdate.paymentDeadline?.[7]
          )
        : "",
      quotationTasks: Array.isArray(quotationToUpdate.quotation_tasks)
        ? quotationToUpdate.quotation_tasks.map((item) => ({
            id: item.id,
            name: item.name,
            contractType: item.contractType,
            billingTiming: item.billingTiming,
            volumn: item.volumn,
            inspectionPeopleNumber: item.inspectionPeopleNumber,
            testSampleNumber: item.testSampleNumber,
            quotationPrice: item.quotationPrice,
            quotationPriceUnit: item.quotationPriceUnit,
            memo: item.memo,
          }))
        : [],

      generatedExcel: quotationToUpdate.generatedExcel || undefined,
    };
  }, [customer.id, quotationToUpdate, testSchedule.id]);

  return (
    <UpdateQuotationForm
      initQuotation={initQuotation}
      customer={customer}
    />
  );
}

function UpdateQuotationPage() {
  const [breadcrumb] = useOutletContext();
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [selectedCaseInfo, setSelectedCaseInfo] = useState(null);

  const [loadInitState, setLoadInitState] = useState({
    loadingStatus: callApiStatus.idle,
    data: null,
    error: null,
  });

  const reset = () => {
    setSelectedCaseInfo(null);
    setLoadInitState({
      loadingStatus: callApiStatus.idle,
      data: null,
      error: null,
    });
  };

  const handleCalloutQuotation = () => {
    if (!selectedCaseInfo?._id) {
      return;
    }
    setLoadInitState({
      loadingStatus: callApiStatus.loading,
      data: null,
      error: null,
    });
    quotationServices
      .getQuotationById(selectedCaseInfo.quotation)
      .then((res) => {
        setLoadInitState({
          loadingStatus: callApiStatus.success,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        toast.error(messages.callApiFail);
        setLoadInitState({
          loadingStatus: callApiStatus.error,
          data: null,
          error: error,
        });
      });
  };

  return (
    <ContentLayout
      sideMenuLinkOptions={[
        {
          linkTo: routePaths.createQuotation,
          linkTitle: "見積書の作成",
        },
        {
          linkTo: routePaths.updateQuotation,
          linkTitle: "見積書の修正",
        },
        { linkTo: routePaths.topMenu, linkTitle: "トップへ戻る" },
      ]}
      breadcrumb={breadcrumb}
    >
      <div className="w-full h-auto">
        <div className="w-full h-auto mb-5">
          <p className="text-custom-size mb-3">見積書の修正</p>
          <SelectCustomerIdAndTestSchedule
            customer={selectedCustomer}
            setCustomer={(newCustomer) => {
              setSelectedCustomer(newCustomer);
              reset();
            }}
            schedule={selectedSchedule}
            setSchedule={(newSchedule) => {
              setSelectedSchedule(newSchedule);
              reset();
            }}
            caseInfo={selectedCaseInfo}
            setCaseInfo={(newCaseInfo) => {
              setLoadInitState({
                loadingStatus: callApiStatus.loading,
                data: null,
                error: null,
              });
              setSelectedCaseInfo(newCaseInfo);
            }}
          />
          <div className="w-full h-auto flex items-center justify-end mt-5">
            <Button
              disabled={!selectedCaseInfo}
              type={!selectedCaseInfo ? "disabled" : "primary"}
              onClick={handleCalloutQuotation}
            >
              呼び出す
            </Button>
          </div>
        </div>
        {loadInitState.data && (
          <UpdateQuotation
            quotationToUpdate={loadInitState.data}
            customer={selectedCustomer}
            testSchedule={selectedSchedule}
          />
        )}
      </div>
    </ContentLayout>
  );
}

export default UpdateQuotationPage;
