import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "app/components/UI/Button";
import PageError from "app/components/CommonPage/PageError";
import PageLoading from "app/components/CommonPage/PageLoading";
import TableInfo from "app/components/Table/CommonComponents/TableInfo";
import { customerServices } from "app/services/api";
import routePaths from "app/route/routePaths";
import SystemSettingContentLayout from "../../Components/ContentLayout";
import messages from "../../../../services/api/messages";
import UpsertCustomerUserModal from "./UpdateCustomerUserModal.";
import AddBtn from "app/components/UI/Button/AddBtn";

function SystemSettingsCustomerDetailPageContent({
  id,
  customerIdInfo,
}) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  return (
    <div className="p-4">
      <div className="flex justify-between pb-4">
        <div className="flex items-end">顧客情報</div>
        <Link to={routePaths.systemSettingsCustomerUpdate(id)}>
          <Button>変更する</Button>
        </Link>
      </div>
      <TableInfo
        tableConfig={[
          {
            title: "項目",
            width: "30%",
            dataKey: "title",
          },
          {
            title: "詳細",
            width: "70%",
            dataKey: "value",
          },
        ]}
        tableData={[
          {
            title: "お客様ID",
            value: customerIdInfo?.customerId || "",
          },
          {
            title: "会社名",
            value: customerIdInfo?.companyNameKanji || "",
          },
          {
            title: "フリガナ",
            value: customerIdInfo?.companyNameKana || "",
          },
          {
            title: "メールアドレス",
            value: customerIdInfo?.email || "",
          },
        ]}
      />
      <div>
        <div className="my-4">ユーザー</div>
        <TableInfo
          tableConfig={[
            {
              title: "メールアドレス",
              width: "45%",
              dataKey: "title",
            },
            {
              title: "パスワード",
              width: "30%",
              dataKey: "value",
            },
            {
              title: "変更",
              width: "25%",
              dataKey: "button",
            },
          ]}
          tableData={customerIdInfo.accountInfos.map((account) => ({
            title: account.loginName,
            value: "********",
            button: (
              <div className="w-full flex justify-center">
                <button
                  onClick={() => setIsOpenModal(true)}
                  className="bg-[#001C4F] text-white rounded-[10px] px-[1.375em] p-1 text-[0.8125em] "
                >
                  更新する
                </button>
                <UpsertCustomerUserModal
                  id={account._id}
                  email={account.loginName}
                  password={account.password}
                  isOpen={isOpenModal}
                  handleCloseModal={() => {
                    setIsOpenModal(false);
                  }}
                />
              </div>
            ),
          }))}
        />
        <div className="w-full h-auto flex items-center justify-end">
          <AddBtn
            className="mt-[6px] ml-auto"
            onClick={() => setIsOpenCreateModal(true)}
          />
          <UpsertCustomerUserModal
            customerInfoId={customerIdInfo.id}
            isOpen={isOpenCreateModal}
            handleCloseModal={() => {
              setIsOpenCreateModal(false);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default function SystemSettingsCustomerDetailPage() {
  const { id } = useParams();

  const [loadInitState, setLoadInitState] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const loadInit = useCallback(() => {
    setLoadInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    customerServices
      .getDetail(id)
      .then((res) => {
        setLoadInitState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        toast.error(messages.callApiFail);
        setLoadInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [id]);

  useEffect(() => {
    loadInit();
  }, [loadInit]);

  return (
    <SystemSettingContentLayout>
      <div className="w-full grow flex flex-col pt-2.5">
        {loadInitState.isLoading ? <PageLoading /> : null}

        {!loadInitState.isLoading && loadInitState.error ? (
          <PageError />
        ) : null}

        {!loadInitState.isLoading &&
        !loadInitState.error &&
        loadInitState.data ? (
          <SystemSettingsCustomerDetailPageContent
            id={id}
            customerIdInfo={loadInitState.data}
          />
        ) : null}
      </div>
    </SystemSettingContentLayout>
  );
}
