import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import ContentLayout from "../../../layouts/ContentLayout";
import { useOutletContext } from "react-router-dom";
import * as Yup from "yup";
import { SIDE_MENU } from "../constants";
import SelectTestSchedule from "../../../components/UI/Form/Select/SelectTestSchedule";
import Item from "./Item";
import Button from "../../../components/UI/Button";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import validateMessages from "../../../validateMessages";
import {
  commonServices,
  invoiceServices,
} from "../../../services/api";
import messages from "../../../services/api/messages";
import { fileNameType } from "../../../constants";
import moment from "moment";

const BatchInvoicePage = () => {
  const [breadcrumb] = useOutletContext();
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [initInvoices, setInitInvoices] = useState([]);

  const itemDataArray = useMemo(() => {
    return (
      selectedSchedule?.caseInfos.filter(
        (caseInfo) =>
          !caseInfo.invoice || caseInfo.invoiceStatus === "notStarted"
      ) || []
    );
  }, [selectedSchedule]);

  const loadInitInvoices = useCallback(() => {
    if (!itemDataArray?.length === 0) {
      return;
    }
    itemDataArray.forEach(async (itemData) => {
      try {
        const data = await invoiceServices.getInitNewInvoice(
          itemData.customer._id,
          itemData.quotation
        );
        setInitInvoices((prevStates) => {
          return [...prevStates, data];
        });
      } catch (error) {
        toast.error(messages.callApiFail);
      }
    });
  }, [itemDataArray]);

  useEffect(() => {
    loadInitInvoices();
  }, [loadInitInvoices]);

  const cellStyle =
    "flex items-center justify-center p-2 bg-gray-200 border border-[#B3B3B3]";

  const formik = useFormik({
    initialValues: itemDataArray?.map((item) => {
      return {
        memo: "",
        paymentDeadline: "",
      };
    }),
    validationSchema: Yup.array().of(
      Yup.object({
        memo: Yup.string(),
        paymentDeadline: Yup.string().required(
          validateMessages.required("振込期限")
        ),
      })
    ),
    enableReinitialize: true,
  });

  const { validateForm } = formik;

  const handleConfirm = async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      const combinedDataArray = itemDataArray.map((item, index) => {
        return {
          ...item,
          ...initInvoices[index],
          ...formik.values[index],
        };
      });
      try {
        const promises = combinedDataArray.map((item, index) => {
          const createFileData = {
            caseInfoId: item._id,
            customerId: item.customer._id,
            testScheduleId: selectedSchedule._id,
            personInCharge: item.customer._id,
            memo: item.memo,
            paymentDeadline: item.paymentDeadline,
            invoiceItemInfos: Array.isArray(item.invoiceItemInfos)
              ? item.invoiceItemInfos.map((childItem) => ({
                  name: childItem.name,
                  contractType: childItem.contractType,
                  volumn: childItem.volumn,
                  testPeopleNumber: childItem.testPeopleNumber,
                  testSampleNumber: childItem.testSampleNumber,
                  unitPrice: childItem.unitPrice,
                  totalPrice:
                    Number(childItem.unitPrice) *
                    Number(childItem.testSampleNumber),
                }))
              : [],
          };

          return invoiceServices
            .createFileDownload(createFileData)
            .then((res) => {
              const caseId = item.caseId || "";
              const invoiceFileType = fileNameType["invoice"];
              const deliveryFileType = fileNameType["delivery"];
              const timeStamp = moment().format("YYYYMMDDHHmmss");
              const customerName = item.customer.companyNameKanji;

              setInitInvoices((prevInvoices) => {
                const newInvoices = [...prevInvoices];
                newInvoices[index] = {
                  ...newInvoices[index],
                  generatedInvoiceExcel: res.data.fileBill._id,
                  generatedDeliverySlipExcel:
                    res.data.fileDeliverly._id,
                };

                return newInvoices;
              });

              setTimeout(() => {
                commonServices
                  .downloadWithFileId(
                    res.data.fileBill._id,
                    "xlsx",
                    `${invoiceFileType}_${caseId}_${customerName}_${timeStamp}`
                  )
                  .catch(() => toast.error(messages.downloadFail));
              }, 1000);

              setTimeout(() => {
                commonServices
                  .downloadPDFWithFileId(
                    res.data.fileBill._id,
                    `${invoiceFileType}_${caseId}_${customerName}_${timeStamp}`
                  )
                  .catch(() => toast.error(messages.downloadFail));
              }, 1000);

              setTimeout(() => {
                commonServices
                  .downloadWithFileId(
                    res.data.fileDeliverly._id,
                    "xlsx",
                    `${deliveryFileType}_${caseId}_${customerName}_${timeStamp}`
                  )
                  .catch(() => toast.error(messages.downloadFail));
              }, 1500);

              setTimeout(() => {
                commonServices
                  .downloadPDFWithFileId(
                    res.data.fileDeliverly._id,
                    `${deliveryFileType}_${caseId}_${customerName}_${timeStamp}`
                  )
                  .catch(() => toast.error(messages.downloadFail));
              }, 1500);

              return "success";
            })
            .catch(() => {
              toast.error(messages.confirm(false));
            });
        });
        const results = await Promise.all(promises);
        console.log("results", results);
        toast.success("請求書一括発行が完了しました。");
      } catch (error) {
        toast.error(messages.callApiFail);
      }
    } else {
      toast.error(validateMessages.required("振込期限"));
    }
  };

  const handleCreate = async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      const combinedDataArray = itemDataArray.map((item, index) => {
        return {
          ...item,
          ...initInvoices[index],
          ...formik.values[index],
        };
      });
      try {
        const promises = combinedDataArray.map((item) => {
          const createData = {
            caseInfoId: item._id,
            customerId: item.customer._id,
            testScheduleId: selectedSchedule._id,
            personInCharge: item.customer._id,
            memo: item.memo,
            paymentDeadline: item.paymentDeadline,
            invoiceItemInfos: Array.isArray(item.invoiceItemInfos)
              ? item.invoiceItemInfos.map((childItem) => ({
                  name: childItem.name,
                  contractType: childItem.contractType,
                  volumn: childItem.volumn,
                  testPeopleNumber: childItem.testPeopleNumber,
                  testSampleNumber: childItem.testSampleNumber,
                  unitPrice: childItem.unitPrice,
                  totalPrice:
                    Number(childItem.unitPrice) *
                    Number(childItem.testSampleNumber),
                }))
              : [],
            generatedInvoiceExcel: item.generatedInvoiceExcel,
            generatedDeliverySlipExcel:
              item.generatedDeliverySlipExcel,
          };
          return invoiceServices.createInvoice(createData);
        });
        const results = await Promise.all(promises);
        console.log("results", results);
        toast.success(messages.create(true));
      } catch (error) {
        toast.error(messages.create(false));
      }
    } else {
      toast.error(validateMessages.required("振込期限"));
    }
  };

  return (
    <ContentLayout
      sideMenuLinkOptions={SIDE_MENU}
      breadcrumb={breadcrumb}
    >
      <div className="w-full h-auto">
        <div className="w-full h-auto mb-5">
          <p className="text-custom-size mb-3">
            請求書一括発行・送付
          </p>
        </div>

        <SelectTestSchedule
          isWhite
          schedule={selectedSchedule}
          setSchedule={setSelectedSchedule}
          filterFunc={(schedules) =>
            schedules
              .filter((schedule) => schedule.caseInfos.length > 0)
              .sort((a, b) => {
                const date1 = new Date(a.testDate);
                const date2 = new Date(b.testDate);
                return date2 - date1;
              })
          }
        />

        {itemDataArray?.length > 0 && (
          <>
            <div className="overflow-x-auto">
              <div
                className="grid grid-cols-11 p-4"
                style={{
                  gridTemplateColumns:
                    "160px 70px 160px 210px 250px 70px",
                }}
              >
                <div className={"border-l " + cellStyle}>案件ID</div>
                <div className={cellStyle}>請求書</div>
                <div className={cellStyle}>企業名</div>
                <div className={cellStyle}>振込期限</div>
                <div className={cellStyle}>備考</div>
                <div className={cellStyle}>状況</div>
                {itemDataArray?.map((item, index) => (
                  <Item
                    key={item._id}
                    index={index}
                    data={item}
                    formik={formik}
                  />
                ))}
              </div>
            </div>

            <div className="flex items-end justify-end mt-4 gap-6">
              <Button
                // disabled={}
                // type={hasAvailableData ? "primary" : "disabled"}
                onClick={handleConfirm}
              >
                請求書一括発行
              </Button>
              <Button
                // disabled={}
                // type={hasAvailableData ? "primary" : "disabled"}
                onClick={handleCreate}
              >
                請求書一括送付
              </Button>
            </div>
          </>
        )}
      </div>
    </ContentLayout>
  );
};

export default BatchInvoicePage;
