import React, { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { CircularProgress, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCircleExclamation,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { checkExistedStatus } from "app/constants";

function AsyncCheckExistedInput({
  id,
  value,
  onChange,
  label,
  labelWidth,
  handleBlur,
  disabled,
  placeholder,
  checkStatus,
  requiredUnique,
  duplicateErrorMessage,
  touched,
  errors,
  requiredUniqueError,
  isRequired,
}) {
  const inputId = useMemo(() => {
    if (!id) {
      return uuidv4();
    }
    return id;
  }, [id]);

  const onChangeValue = (e) => {
    onChange(e.currentTarget.value);
  };

  const inputClasses = () => {
    const baseClasses =
      "w-full h-full pl-4 pr-12 py-2.5 text-sm tracking-[.2em] rounded-[10px] border border-[#B3B3B3] !outline-none focus:border-blue-500";

    if (disabled) return `${baseClasses} !bg-background-select`;
    if (
      (touched && errors) ||
      (checkStatus === checkExistedStatus.existed && requiredUnique)
    )
      return `${baseClasses} !border-red-500`;

    if (checkStatus === checkExistedStatus.existed)
      return `${baseClasses} !border-orange-500`;

    return baseClasses;
  };

  const tooltipMessage = useMemo(() => {
    if (errors && touched) return errors;
    if (checkStatus === checkExistedStatus.existed)
      return duplicateErrorMessage;
    return "";
  }, [checkStatus, duplicateErrorMessage, errors, touched]);

  return (
    <div className="w-full h-[52px] flex items-center">
      {label ? (
        <label
          htmlFor={inputId}
          className="h-full pr-4 flex items-center"
          style={{
            width: `${labelWidth || 0}px`,
          }}
        >
          <span className="w-full text-sm leading-[22px] tracking-[.2em] text-end">
            {label}
          </span>
          {isRequired && <span style={{ color: "red" }}>*</span>}
        </label>
      ) : null}

      <div
        className="h-full relative"
        style={{
          width: `calc(100% - ${labelWidth}px)`,
        }}
      >
        <Tooltip arrow title={tooltipMessage || ""}>
          <>
            <input
              id={inputId}
              name={inputId}
              value={value}
              placeholder={placeholder}
              onChange={onChangeValue}
              onBlur={(e) =>
                typeof handleBlur === "function" && handleBlur(e)
              }
              disabled={disabled}
              className={inputClasses()}
            />

            <div className="w-8 h-8 absolute top-[9px] right-1 flex items-center justify-center">
              {touched && errors ? (
                <Tooltip arrow title={errors}>
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    size="lg"
                    className="text-lg text-red-500"
                  />
                </Tooltip>
              ) : (
                <>
                  {checkStatus === checkExistedStatus.isChecking ? (
                    <CircularProgress size={24} />
                  ) : null}

                  {checkStatus === checkExistedStatus.existed ? (
                    requiredUnique ? (
                      <Tooltip arrow title={requiredUniqueError}>
                        <FontAwesomeIcon
                          icon={faCircleExclamation}
                          size="lg"
                          className="text-lg text-red-500"
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip arrow title={requiredUniqueError}>
                        <FontAwesomeIcon
                          icon={faTriangleExclamation}
                          size="lg"
                          className="text-lg text-orange-500"
                        />
                      </Tooltip>
                    )
                  ) : null}

                  {checkStatus === checkExistedStatus.available ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      size="lg"
                      className="text-lg text-green-500"
                    />
                  ) : null}
                </>
              )}
            </div>
          </>
        </Tooltip>
      </div>
    </div>
  );
}

export default AsyncCheckExistedInput;
