import React from "react";
import { v4 as uuidv4 } from "uuid";
import { NumericFormat } from "react-number-format";
import {
  billingTypeOptions,
  contractTypeOptions,
  inspectionPeopleNumberOptions,
  quotationPriceUnitOptions,
  testSampleNumberOptions,
} from "app/constants";
import TableInfoHeader from "../../CommonComponents/TableInfo/TableInfoHeader";
import SelectPopover from "../../../UI/Form/Select/SelectPopover";
import TrashSVG from "app/assets/svg/trash.svg";

const initEstimateItem = {
  contractType: contractTypeOptions[0].value,
  billingTiming: billingTypeOptions[0].value,
  inspectionPeopleNumber: inspectionPeopleNumberOptions[0].value,
  testSampleNumber: testSampleNumberOptions[0].value,
  quotationPrice: 0,
  quotationPriceUnit: quotationPriceUnitOptions[0].value,
};

function InputNumber(props) {
  const { value, onChange } = props;

  return (
    <div className="w-full h-[52px]">
      <NumericFormat
        value={value}
        onValueChange={(values) => {
          if (typeof values.floatValue === "number")
            onChange(values.floatValue);
          else onChange(0);
        }}
        allowNegative={false}
        decimalScale={0}
        valueIsNumericString={true}
        className="w-full h-full px-4 py-1 input-border-bg-colo text-center border border-[#B3B3B3] bg-white rounded-[10px] !outline-none"
        min={0}
        thousandSeparator=","
      />
    </div>
  );
}

function EstimateBasicInformationItem(props) {
  const { estimateItem, handleChange, handleDelete } = props;

  const handleChangeField = (fieldName, newValue) => {
    handleChange({ ...estimateItem, [fieldName]: newValue });
  };

  return (
    <div className="w-full h-[52px] pr-10 relative mb-[1px]">
      <div className="w-full h-full flex">
        <div className="h-full" style={{ width: "15.9%" }}>
          <SelectPopover
            options={contractTypeOptions}
            selectedValue={estimateItem.contractType}
            setSelectedValue={(newValue) =>
              handleChangeField("contractType", newValue)
            }
          />
        </div>
        <div className="h-full" style={{ width: "31.9%" }}>
          <SelectPopover
            options={billingTypeOptions}
            selectedValue={estimateItem.billingTiming}
            setSelectedValue={(newValue) =>
              handleChangeField("billingTiming", newValue)
            }
          />
        </div>
        <div className="h-full" style={{ width: "13.4%" }}>
          <SelectPopover
            options={inspectionPeopleNumberOptions}
            selectedValue={estimateItem.inspectionPeopleNumber}
            setSelectedValue={(newValue) =>
              handleChangeField("inspectionPeopleNumber", newValue)
            }
          />
        </div>
        <div className="h-full" style={{ width: "15.4%" }}>
          <SelectPopover
            options={testSampleNumberOptions}
            selectedValue={estimateItem.testSampleNumber}
            setSelectedValue={(newValue) =>
              handleChangeField("testSampleNumber", newValue)
            }
          />
        </div>
        <div className="h-full" style={{ width: "9.6%" }}>
          <InputNumber
            value={estimateItem.quotationPrice}
            onChange={(newValue) =>
              handleChangeField("quotationPrice", newValue)
            }
          />
        </div>
        <div className="h-full" style={{ width: "13.8%" }}>
          <SelectPopover
            options={quotationPriceUnitOptions}
            selectedValue={estimateItem.quotationPriceUnit}
            setSelectedValue={(newValue) =>
              handleChangeField("quotationPriceUnit", newValue)
            }
          />
        </div>
      </div>

      <button
        type="button"
        onClick={handleDelete}
        className="w-8 h-8 flex items-center justify-center absolute right-0 top-[9px]"
      >
        <img alt="trash" src={TrashSVG} className="w-6 h-6" />
      </button>
    </div>
  );
}

function EstimateBasicInformation(props) {
  const { quotationBasics, setQuotationBasics } = props;

  const handleAddeEstimateItem = () => {
    setQuotationBasics((oldList) => [
      ...oldList,
      { tempId: uuidv4(), ...initEstimateItem },
    ]);
  };

  const handleChangeEstimateItem = (tempId, newValue) => {
    const itemIndex = quotationBasics.findIndex(
      (item) => item.tempId === tempId
    );
    if (tempId < 0) return;

    const newEstimateList = [...quotationBasics];
    newEstimateList[itemIndex] = newValue;

    setQuotationBasics(newEstimateList);
  };

  const handleDeleteEstimateItem = (tempId) => {
    setQuotationBasics((oldList) =>
      oldList.filter((item) => item.tempId !== tempId)
    );
  };

  return (
    <div className="w-full h-auto">
      <h3 className="text-custom-size mb-4">見積り基本情報</h3>

      <div className="w-full h-auto overflow-x-auto">
        <div className="w-full h-auto min-w-[912px]">
          {/* Header */}
          <div className="w-full h-8 relative pr-10">
            <TableInfoHeader
              tableConfig={[
                {
                  title: "契約形態",
                  width: "15.9%",
                },
                {
                  title: "請求タイミング",
                  width: "31.9%",
                },
                {
                  title: "検査人数",
                  width: "13.4%",
                },
                {
                  title: "検体数",
                  width: "15.4%",
                },
                {
                  title: "見積金額(税抜)",
                  width: "23.4%",
                },
              ]}
            />
          </div>

          {Array.isArray(quotationBasics) && quotationBasics.length > 0
            ? quotationBasics.map((estimateItem, index) => (
                <EstimateBasicInformationItem
                  key={index}
                  estimateItem={estimateItem}
                  handleChange={(newValue) => {
                    handleChangeEstimateItem(estimateItem.tempId, newValue);
                  }}
                  handleDelete={() => {
                    handleDeleteEstimateItem(estimateItem.tempId);
                  }}
                />
              ))
            : null}
        </div>
      </div>

      <div className="w-full h-auto flex items-center justify-end mt-2">
        <button
          type="button"
          onClick={handleAddeEstimateItem}
          className="w-9 h-9 border border-[#6C6C6C] flex items-center justify-center font-thin"
        >
          <span className="text-2xl">+</span>
        </button>
      </div>
    </div>
  );
}

export default EstimateBasicInformation;
