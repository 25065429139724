// FE-2 : https://www.figma.com/file/rjvTEGNOs42Sv2L7TEA5Ef/DRC_%E6%A5%AD%E5%8B%99%E7%AE%A1%E7%90%86%E3%82%B7%E3%82%B9%E3%83%86%E3%83%A0_%E7%AE%A1%E7%90%86%E8%80%85%E7%94%A8?type=design&node-id=164-866&mode=design&t=TnHyoUr5aMtVZd2h-0
import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import ContentLayout from "app/layouts/ContentLayout";
import { commonServices, testFormServices } from "app/services/api";
import { toast } from "react-toastify";
import SelectTestSchedule from "app/components/UI/Form/Select/SelectTestSchedule";
import InputFile from "app/components/UI/Form/Input/InputFile";
import PageLoading from "app/components/CommonPage/PageLoading";
import PageError from "app/components/CommonPage/PageError";
import Button from "app/components/UI/Button";
import InputText from "app/components/UI/Form/Input/InputText";
import TestSampleList from "./TestSampleList";
import TestMonitorList from "./TestMonitorList";
import readXlsxFile from "read-excel-file";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_JP_DATE_FORMAT,
  fileNameType,
  gender,
} from "app/constants";
import PageLoadingBackdrop from "../../../components/CommonPage/PageLoadingBackdrop";
import messages from "../../../services/api/messages";
import moment from "moment";
import { getJsDateFromExcel } from "excel-date-to-js";
import { SIDE_MENU } from "../constants";
const labelWidthRight = 160;

function TestFormCreate({
  initTestForm,
  testDate,
  createTestFormSuccess,
  caseInfos,
  idTestSchedule,
}) {
  const [testForminfo, setTestForminfo] = useState(initTestForm);
  const [fileData, setFileData] = useState(null);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (e) => {
    try {
      const file = e.target.files[0];
      if (!file) return;

      readXlsxFile(file, {
        sheet: "monitor",
        dateFormat: "mm/dd/yyyy",
      }).then((res) => {
        const monitorFileData = res;

        let sampleData = [];
        let monitorData = [];

        const now = moment();
        const getAge = (dateOfBirthExcel) => {
          const dateOfBirth = moment(
            getJsDateFromExcel(dateOfBirthExcel)?.toISOString() || ""
          );
          if (dateOfBirth)
            return Math.ceil(now.diff(dateOfBirth, "years"));
          return 1;
        };

        if (Array.isArray(monitorFileData)) {
          monitorData = monitorFileData.slice(1).map((row) => ({
            monitorId: row?.[0] || "",
            gender:
              row?.[1] === "M"
                ? gender.male
                : row?.[1] === "F"
                ? gender.female
                : gender.unknown,
            dob: moment(
              getJsDateFromExcel(row?.[2])?.toISOString() || ""
            ).format(DEFAULT_JP_DATE_FORMAT),
            age: getAge(row?.[2]),
          }));
        }

        const newFileData = {
          sampleData,
          monitorData,
        };

        setFileData(newFileData);
        setFile(file);
      });
    } catch (error) {
      toast.error(messages.callApiFail);
    }
  };

  const handleClickCreate = () => {
    if (Array.isArray(fileData?.monitorData)) {
      setTestForminfo((oldState) => ({
        ...oldState,
        totalTestPeople: fileData?.monitorData?.length,
        monitors: fileData.monitorData,
        caseInfos,
      }));
    }
  };

  const handleClickSubmit = () => {
    if (isLoading) return;

    setIsLoading(true);
    const data = {
      ...testForminfo,
      testDate,
      totalTestPeople: testForminfo.monitors,
      testSchedule: idTestSchedule,
      file: file,
    };

    testFormServices
      .createTestForm(data)
      .then((res) => {
        if (res.data?.generatedTestFormExcel) {
          setTimeout(() => {
            if (typeof createTestFormSuccess === "function")
              createTestFormSuccess();

            const timeStamp = moment().format("YYYYMMDDHHmmss");
            const fileType = fileNameType["testForm"];
            commonServices
              .downloadWithFileId(
                res.data?.generatedTestFormExcel,
                "xlsx",
                `${fileType}_${timeStamp}`
              )
              .then(() => {
                setIsLoading(false);
                toast.success(messages.create(true));
              })
              .catch(() => toast.error(messages.downloadFail));
          }, 2000);
        } else {
          throw Error("Create file fail!");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(messages.create(false));
        console.log("error: ", error);
      });
  };

  return (
    <>
      <div className="w-full md:w-1/2 h-full">
        <InputFile
          id="upload-report-file"
          handleFileChange={handleFileChange}
          label="モニターファイル"
          labelWidth={labelWidthRight}
          fileName={file?.name || ""}
        />
      </div>
      <div className="w-full h-auto pl-[92px] mt-5">
        <div className="w-full pb-4 flex flex-wrap">
          <div className="w-[164px] flex items-center mb-4">
            <div className="w-[68px]">
              <div className="flex justify-end items-center h-full px-2">
                観察1
              </div>
            </div>
            <div className="w-[96px]">
              <InputText
                labelWidth={0}
                value={testForminfo.monitor1}
                onChange={(newValue) => {
                  setTestForminfo({
                    ...testForminfo,
                    monitor1: newValue,
                  });
                }}
                hideError
              />
            </div>
          </div>
          <div className="w-[164px] flex items-center mb-4">
            <div className="w-[68px]">
              <div className="flex justify-end items-center h-full px-2">
                観察2
              </div>
            </div>
            <div className="w-[96px]">
              <InputText
                labelWidth={0}
                value={testForminfo.monitor2}
                onChange={(newValue) => {
                  setTestForminfo({
                    ...testForminfo,
                    monitor2: newValue,
                  });
                }}
                hideError
              />
            </div>
          </div>
          <div className="w-[164px] flex items-center mb-4">
            <div className="w-[68px]">
              <div className="flex justify-end items-center h-full px-2">
                観察3
              </div>
            </div>
            <div className="w-[96px]">
              <InputText
                labelWidth={0}
                value={testForminfo.monitor3}
                onChange={(newValue) => {
                  setTestForminfo({
                    ...testForminfo,
                    monitor3: newValue,
                  });
                }}
                hideError
              />
            </div>
          </div>
          <div className="w-[164px] flex items-center mb-4">
            <div className="w-[68px]">
              <div className="flex justify-end items-center h-full px-2">
                観察4
              </div>
            </div>
            <div className="w-[96px]">
              <InputText
                labelWidth={0}
                value={testForminfo.monitor4}
                onChange={(newValue) => {
                  setTestForminfo({
                    ...testForminfo,
                    monitor4: newValue,
                  });
                }}
                hideError
              />
            </div>
          </div>
          <div className="w-[164px] flex items-center mb-4">
            <div className="w-[68px]">
              <div className="flex justify-end items-center h-full px-2">
                観察5
              </div>
            </div>
            <div className="w-[96px]">
              <InputText
                labelWidth={0}
                value={testForminfo.monitor5}
                onChange={(newValue) => {
                  setTestForminfo({
                    ...testForminfo,
                    monitor5: newValue,
                  });
                }}
                hideError
              />
            </div>
          </div>

          <div className="w-[164px] flex items-center mb-4">
            <div className="w-[68px]">
              <div className="flex justify-end items-center h-full px-2">
                観察6
              </div>
            </div>
            <div className="w-[96px]">
              <InputText
                labelWidth={0}
                value={testForminfo.monitor6}
                onChange={(newValue) => {
                  setTestForminfo({
                    ...testForminfo,
                    monitor6: newValue,
                  });
                }}
                hideError
              />
            </div>
          </div>

          <div className="w-[164px] flex items-center mb-4">
            <div className="w-[68px]">
              <div className="flex justify-end items-center h-full px-2">
                観察7
              </div>
            </div>
            <div className="w-[96px]">
              <InputText
                labelWidth={0}
                value={testForminfo.monitor7}
                onChange={(newValue) => {
                  setTestForminfo({
                    ...testForminfo,
                    monitor7: newValue,
                  });
                }}
                hideError
              />
            </div>
          </div>

          <div className="w-[164px] flex items-center mb-4">
            <div className="w-[68px]">
              <div className="flex justify-end items-center h-full px-2">
                観察8
              </div>
            </div>
            <div className="w-[96px]">
              <InputText
                labelWidth={0}
                value={testForminfo.monitor8}
                onChange={(newValue) => {
                  setTestForminfo({
                    ...testForminfo,
                    monitor8: newValue,
                  });
                }}
                hideError
              />
            </div>
          </div>

          <div className="w-[164px] flex items-center mb-4">
            <div className="w-[68px]">
              <div className="flex justify-end items-center h-full px-2">
                観察9
              </div>
            </div>
            <div className="w-[96px]">
              <InputText
                labelWidth={0}
                value={testForminfo.monitor9}
                onChange={(newValue) => {
                  setTestForminfo({
                    ...testForminfo,
                    monitor9: newValue,
                  });
                }}
                hideError
              />
            </div>
          </div>

          <div className="w-[164px] flex items-center mb-4">
            <div className="w-[68px]">
              <div className="flex justify-end items-center h-full px-2">
                観察10
              </div>
            </div>
            <div className="w-[96px]">
              <InputText
                labelWidth={0}
                value={testForminfo.monitor10}
                onChange={(newValue) => {
                  setTestForminfo({
                    ...testForminfo,
                    monitor10: newValue,
                  });
                }}
                hideError
              />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-auto flex items-center justify-end mb-4">
        <Button
          disabled={!file}
          type={
            Array.isArray(fileData?.monitorData)
              ? "primary"
              : "disabled"
          }
          onClick={handleClickCreate}
        >
          呼び出す
        </Button>
      </div>

      <div className="w-full h-auto">
        {/* Test Sample List */}
        <TestSampleList
          testSampleList={testForminfo.testSamples}
          setTestSampleList={(newValue) => {
            setTestForminfo({
              ...testForminfo,
              testSamples: newValue,
            });
          }}
        />
      </div>

      <div className="w-full h-auto">
        {/* Test Sample List */}
        <TestMonitorList monitorList={testForminfo.monitors} />
      </div>

      <div className="w-full h-auto flex justify-end">
        <Button
          disabled={
            !file ||
            testForminfo.testSamples.length === 0 ||
            testForminfo.monitors.length === 0
          }
          type={
            !file ||
            testForminfo.testSamples.length === 0 ||
            testForminfo.monitors.length === 0
              ? "disabled"
              : "primary"
          }
          onClick={handleClickSubmit}
        >
          作成する
        </Button>
      </div>

      <PageLoadingBackdrop open={isLoading} />
    </>
  );
}

function TestFormUpdate({
  initTestForm,
  testDate,
  caseInfos,
  updateTestFormSuccess,
  idTestSchedule,
}) {
  const [testForminfo, setTestForminfo] = useState(initTestForm);
  const [fileData, setFileData] = useState(null);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  console.log("caseInfos", caseInfos);
  const handleFileChange = (e) => {
    try {
      const file = e.target.files[0];

      readXlsxFile(file, {
        sheet: "monitor",
        dateFormat: DEFAULT_DATE_FORMAT,
      }).then((res) => {
        const monitorFileData = res;
        let sampleData = [];
        let monitorData = [];

        const now = moment();
        const getAge = (dateOfBirthExcel) => {
          const dateOfBirth = moment(
            getJsDateFromExcel(dateOfBirthExcel)?.toISOString() || ""
          );
          if (dateOfBirth)
            return Math.ceil(now.diff(dateOfBirth, "years"));
          return 1;
        };

        if (Array.isArray(monitorFileData)) {
          monitorData = monitorFileData.slice(1).map((row) => ({
            monitorId: row?.[0] || "",
            gender:
              row?.[1] === "M"
                ? gender.male
                : row?.[1] === "F"
                ? gender.female
                : gender.unknown,
            dob: moment(
              getJsDateFromExcel(row?.[2])?.toISOString() || ""
            ).format(DEFAULT_JP_DATE_FORMAT),
            age: getAge(row?.[2]),
          }));
        }

        const newFileData = {
          sampleData,
          monitorData,
        };

        setFileData(newFileData);
        setFile(file);
      });
    } catch (error) {
      toast.error(messages.callApiFail);
    }
  };

  const handleClickCreate = () => {
    if (Array.isArray(fileData?.monitorData)) {
      setTestForminfo((oldState) => ({
        ...oldState,
        monitors: fileData.monitorData,
      }));
    }
  };

  const handleClickSubmit = () => {
    if (isLoading) return;
    setIsLoading(true);
    const data = {
      ...testForminfo,
      testDate,
      totalTestPeople: testForminfo.monitors.length,
      testSchedule: idTestSchedule,
      caseInfos,
      file: file,
    };

    testFormServices
      .updateTestForm(data)
      .then((res) => {
        if (res.data?.generatedTestFormExcel) {
          setTimeout(() => {
            if (typeof updateTestFormSuccess === "function")
              updateTestFormSuccess();
            const timeStamp = moment().format("YYYYMMDDHHmmss");
            const fileType = fileNameType["testForm"];
            commonServices
              .downloadWithFileId(
                res.data?.generatedTestFormExcel,
                "xlsx",
                `${fileType}_${timeStamp}`
              )
              .then(() => {
                setIsLoading(false);
                toast.success(messages.create(true));
              })
              .catch(() => toast.error(messages.downloadFail));
          }, 2000);
        } else {
          throw Error("Create file fail!");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(messages.update(false));
        console.log("error: ", error);
      });
  };

  return (
    <>
      <div className="w-full md:w-1/2 h-full mb-5">
        <InputFile
          id="upload-report-file"
          handleFileChange={handleFileChange}
          label="モニターファイル"
          labelWidth={labelWidthRight}
          fileName={file?.name || ""}
        />
      </div>
      <div className="hidden md:block">
        <div className="flex pb-4">
          <div className="hidden md:block w-[18%]">
            <div className="flex justify-end items-center h-full pr-2">
              観察1
            </div>
          </div>
          <div className="w-full flex md:w-[82%]">
            <div className="block md:hidden w-[11%]">
              <div className="flex justify-end items-center h-full px-2">
                観察1
              </div>
            </div>
            <div className="w-[96px]">
              <InputText
                labelWidth={0}
                value={testForminfo.monitor1}
                onChange={(newValue) => {
                  setTestForminfo({
                    ...testForminfo,
                    monitor1: newValue,
                  });
                }}
                hideError
              />
            </div>
            <div className="w-[68px]">
              <div className="flex justify-end items-center h-full px-2">
                観察2
              </div>
            </div>
            <div className="w-[96px]">
              <InputText
                labelWidth={0}
                value={testForminfo.monitor2}
                onChange={(newValue) => {
                  setTestForminfo({
                    ...testForminfo,
                    monitor2: newValue,
                  });
                }}
                hideError
              />
            </div>
            <div className="w-[68px]">
              <div className="flex justify-end items-center h-full px-2">
                観察3
              </div>
            </div>
            <div className="w-[96px]">
              <InputText
                labelWidth={0}
                value={testForminfo.monitor3}
                onChange={(newValue) => {
                  setTestForminfo({
                    ...testForminfo,
                    monitor3: newValue,
                  });
                }}
                hideError
              />
            </div>
            <div className="w-[68px]">
              <div className="flex justify-end items-center h-full px-2">
                観察4
              </div>
            </div>
            <div className="w-[96px]">
              <InputText
                labelWidth={0}
                value={testForminfo.monitor4}
                onChange={(newValue) => {
                  setTestForminfo({
                    ...testForminfo,
                    monitor4: newValue,
                  });
                }}
                hideError
              />
            </div>
            <div className="w-[68px]">
              <div className="flex justify-end items-center h-full px-2">
                観察5
              </div>
            </div>
            <div className="w-[96px]">
              <InputText
                labelWidth={0}
                value={testForminfo.monitor5}
                onChange={(newValue) => {
                  setTestForminfo({
                    ...testForminfo,
                    monitor5: newValue,
                  });
                }}
                hideError
              />
            </div>
          </div>
        </div>
        <div className="flex pb-4">
          <div className="hidden md:block w-[18%]">
            <div className="flex justify-end items-center h-full pr-2">
              観察6
            </div>
          </div>
          <div className="w-full flex md:w-[82%]">
            <div className="block md:hidden w-[11%]">
              <div className="flex justify-end items-center h-full px-2">
                観察6
              </div>
            </div>
            <div className="w-[96px]">
              <InputText
                labelWidth={0}
                value={testForminfo.monitor6}
                onChange={(newValue) => {
                  setTestForminfo({
                    ...testForminfo,
                    monitor6: newValue,
                  });
                }}
                hideError
              />
            </div>
            <div className="w-[68px]">
              <div className="flex justify-end items-center h-full px-2">
                観察7
              </div>
            </div>
            <div className="w-[96px]">
              <InputText
                labelWidth={0}
                value={testForminfo.monitor7}
                onChange={(newValue) => {
                  setTestForminfo({
                    ...testForminfo,
                    monitor7: newValue,
                  });
                }}
                hideError
              />
            </div>
            <div className="w-[68px]">
              <div className="flex justify-end items-center h-full px-2">
                観察8
              </div>
            </div>
            <div className="w-[96px]">
              <InputText
                labelWidth={0}
                value={testForminfo.monitor8}
                onChange={(newValue) => {
                  setTestForminfo({
                    ...testForminfo,
                    monitor8: newValue,
                  });
                }}
                hideError
              />
            </div>
            <div className="w-[68px]">
              <div className="flex justify-end items-center h-full px-2">
                観察9
              </div>
            </div>
            <div className="w-[96px]">
              <InputText
                labelWidth={0}
                value={testForminfo.monitor9}
                onChange={(newValue) => {
                  setTestForminfo({
                    ...testForminfo,
                    monitor9: newValue,
                  });
                }}
                hideError
              />
            </div>
            <div className="w-[68px]">
              <div className="flex justify-end items-center h-full px-2">
                観察10
              </div>
            </div>
            <div className="w-[96px]">
              <InputText
                labelWidth={0}
                value={testForminfo.monitor10}
                onChange={(newValue) => {
                  setTestForminfo({
                    ...testForminfo,
                    monitor10: newValue,
                  });
                }}
                hideError
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-auto flex items-center justify-end mb-4">
        <Button
          disabled={!file}
          type={
            Array.isArray(fileData?.monitorData)
              ? "primary"
              : "disabled"
          }
          onClick={handleClickCreate}
        >
          呼び出す
        </Button>
      </div>

      <div className="w-full h-auto">
        {/* Test Sample List */}
        <TestSampleList
          testSampleList={testForminfo.testSamples}
          setTestSampleList={(newValue) => {
            setTestForminfo({
              ...testForminfo,
              testSamples: newValue,
            });
          }}
        />
      </div>

      <div className="w-full h-auto">
        {/* Test Sample List */}
        <TestMonitorList monitorList={testForminfo.monitors} />
      </div>

      <div className="w-full h-auto flex justify-end">
        <Button
          disabled={
            testForminfo.testSamples.length === 0 ||
            testForminfo.monitors.length === 0
          }
          type={
            testForminfo.testSamples.length === 0 ||
            testForminfo.monitors.length === 0
              ? "disabled"
              : "primary"
          }
          onClick={handleClickSubmit}
        >
          作成する
        </Button>
      </div>

      <PageLoadingBackdrop open={isLoading} />
    </>
  );
}

function TestFormGenerationPageContent({
  testSchedule,
  setTestSchedule,
}) {
  const [loadInitState, setLoadInitState] = useState({
    isLoading: true,
    data: null,
    error: null,
  });

  const caseInfos = useMemo(() => {
    return testSchedule?.caseInfos?.map((item) => {
      const caseInfo = {
        caseId: item.caseId,
        hasTestPlan: !!item.testPlan,
        customerId: item.customer.customerId,
        companyName: item.customer.companyNameKanji,
        customerAddress: `${item.customer.address1}${item.customer.address2}${item.customer.address3}`,
        picPhone: item.customer.pic?.phone1,
        picFax: item.customer.pic?.fax,
        picName: `${item.customer.pic?.lastNameKanji || ""}　${
          item.customer.pic?.firstNameKanji || ""
        }`,
      };
      return caseInfo;
    });
  }, [testSchedule]);

  const loadInit = useCallback(() => {
    if (!testSchedule?.id) return;
    setLoadInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    testFormServices
      .getInitTestFormByTestSchedule(testSchedule.id)
      .then((searchResult) => {
        const arrangedData = {
          ...searchResult.data,
          testSamples: searchResult.data.testSamples.map((item) => ({
            ...item,
            capacity: 15,
          })),
        };
        setLoadInitState({
          isLoading: false,
          data: arrangedData,
          error: null,
        });
      })
      .catch((error) => {
        toast.error(messages.callApiFail);
        setLoadInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [testSchedule]);

  useEffect(() => {
    loadInit();
  }, [loadInit]);

  return (
    <section className="p-4">
      <h5 className="mb-[1em]">試験フォーム生成</h5>

      <div className="w-full h-auto flex flex-wrap">
        <div className="w-full mb-3 md:mb-0 md:w-1/2 h-full md:pr-12">
          <SelectTestSchedule
            schedule={testSchedule}
            setSchedule={setTestSchedule}
            labelWidth={160}
          />
        </div>
        {loadInitState.isLoading ? <PageLoading /> : null}

        {!loadInitState.isLoading && loadInitState.error ? (
          <PageError />
        ) : null}

        {!loadInitState.isLoading &&
        !loadInitState.error &&
        loadInitState.data ? (
          loadInitState.data.id ? (
            <TestFormUpdate
              idTestSchedule={testSchedule.id}
              testDate={testSchedule.testDate}
              initTestForm={loadInitState.data}
              caseInfos={caseInfos}
              updateTestFormSuccess={loadInit}
            />
          ) : (
            <TestFormCreate
              idTestSchedule={testSchedule.id}
              testDate={testSchedule.testDate}
              caseInfos={caseInfos}
              initTestForm={loadInitState.data}
              createTestFormSuccess={loadInit}
            />
          )
        ) : null}
      </div>
    </section>
  );
}

function TestFormGenerationPage() {
  const [testSchedule, setTestSchedule] = useState(null);

  return (
    <ContentLayout
      sideMenuLinkOptions={SIDE_MENU}
    >
      <TestFormGenerationPageContent
        testSchedule={testSchedule}
        setTestSchedule={setTestSchedule}
      />
    </ContentLayout>
  );
}

export default TestFormGenerationPage;
