// FE-2 : https://www.figma.com/file/rjvTEGNOs42Sv2L7TEA5Ef/DRC_%E6%A5%AD%E5%8B%99%E7%AE%A1%E7%90%86%E3%82%B7%E3%82%B9%E3%83%86%E3%83%A0_%E7%AE%A1%E7%90%86%E8%80%85%E7%94%A8?type=design&node-id=77-248&mode=design&t=TnHyoUr5aMtVZd2h-0
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import PageLoadingBackdrop from "app/components/CommonPage/PageLoadingBackdrop";
import { callApiStatus } from "app/constants";
import routePaths from "app/route/routePaths";
import { systemSettingsServices } from "app/services/api";

import SystemSettingContentLayout from "../../Components/ContentLayout";
import CreateAndUpdateUserForm from "../../Components/CreateAndUpdateUserForm";
import messages from "../../../../services/api/messages";

export default function SystemSettingsCreateUserPage() {
  const navigate = useNavigate();
  const [createStatus, setCreateStatus] = useState(callApiStatus.idle);

  const handleSubmitForm = async (formData) => {
    if (createStatus === callApiStatus.loading) return;

    setCreateStatus(callApiStatus.loading);
    systemSettingsServices
      .registerUser(formData)
      .then((res) => {
        setCreateStatus(callApiStatus.idle);
        navigate(routePaths.systemSettingsUser);
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          <div className="flex flex-col">
            <p className="font-bold mb-1">{messages.userRegisterFail}</p>
          </div>
        );
        setCreateStatus(callApiStatus.idle);
      });
  };

  return (
    <SystemSettingContentLayout>
      <CreateAndUpdateUserForm handleSubmitForm={handleSubmitForm} />

      <PageLoadingBackdrop open={createStatus === callApiStatus.loading} />
    </SystemSettingContentLayout>
  );
}
