import routePaths from "../../route/routePaths";

const SIDE_MENU = [
  {
    linkTo: routePaths.createInvoice,
    linkTitle: "請求書の作成",
  },
  {
    linkTo: routePaths.updateInvoice,
    linkTitle: "請求書の修正",
  },
  {
    linkTo: routePaths.batchInvoice,
    linkTitle: "請求書一括発行・送付",
  },
  { linkTo: routePaths.topMenu, linkTitle: "トップへ戻る" },
];

export { SIDE_MENU };
