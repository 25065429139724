import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import TableInfoHeader from "app/components/Table/CommonComponents/TableInfo/TableInfoHeader";
import PencilSVG from "app/assets/svg/pencil.svg";

const tableConfig = [
  {
    title: "項目",
    width: "25.55%",
  },
  {
    title: "詳細",
    width: "74.45%",
  },
];

function InputTextCell(props) {
  const { value, onChange } = props;

  return (
    <div className="w-full h-full">
      <input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="w-full h-10 border border-[#B3B3B3] bg-white rounded-[10px] !outline-none text-custom-size py-2.5 px-3"
      />
    </div>
  );
}

function ReadonlyCell({ value, className, isRequired = false }) {
  return (
    <div
      className={`w-full h-full border border-[#b3b3b3] bg-[#e0e4e5] flex items-center justify-center ${
        className || ""
      }`}
    >
      <span className="text-custom-size">
        {value}
        {isRequired && <span style={{ color: "red" }}>*</span>}
      </span>
    </div>
  );
}

function ReportPICInfoFieldRow({
  label,
  value,
  onChange,
  showEditOption,
  isRequired,
}) {
  const [isShowEdit, setIsShowEdit] = useState(false);
  return (
    <div className="w-full h-10 flex items-center pr-8 relative">
      <div className="w-[25.5%] h-full">
        <ReadonlyCell value={label} isRequired={isRequired} />
      </div>
      <div className="w-[74.5%] h-full">
        {isShowEdit ? (
          <InputTextCell value={value} onChange={onChange} />
        ) : (
          <div className="w-full h-full border border-[#B3B3B3] bg-[#e0e4e5] px-2 py-2.5 text-start">
            <p className="text-sm leading-[18px] tracking-[.2em] truncate">
              {value}
            </p>
          </div>
        )}
      </div>
      {showEditOption ? (
        <button
          type="button"
          onClick={() => setIsShowEdit(!isShowEdit)}
          className="absolute top-1 right-0 w-8 h-8 flex items-center justify-center"
        >
          {isShowEdit ? (
            <FontAwesomeIcon icon={faCheck} size="lg" />
          ) : (
            <img src={PencilSVG} className="" alt="" />
          )}
        </button>
      ) : null}
    </div>
  );
}

function ReportPicTable(props) {
  const {
    reportPICInfo,
    setReportPICInfo,
    companyNameKanji,
    companyAddress,
  } = props;

  const handleChangeField = (field, newValue) => {
    setReportPICInfo({ ...reportPICInfo, [field]: newValue });
  };

  return (
    <div className={`w-full h-full overflow-auto relative`}>
      <div className="w-full h-auto pr-8">
        <TableInfoHeader tableConfig={tableConfig} />
      </div>

      <ReportPICInfoFieldRow
        label="会社名"
        value={companyNameKanji}
        showEditOption={false}
      />

      <ReportPICInfoFieldRow
        label="住所"
        value={companyAddress}
        showEditOption={false}
      />

      <ReportPICInfoFieldRow
        label="担当部署"
        value={reportPICInfo?.department}
        onChange={(newValue) =>
          handleChangeField("department", newValue)
        }
        showEditOption={true}
        isRequired
      />

      <ReportPICInfoFieldRow
        label="担当者名"
        value={reportPICInfo?.nameKanji}
        onChange={(newValue) =>
          handleChangeField("nameKanji", newValue)
        }
        showEditOption={true}
      />

      <ReportPICInfoFieldRow
        label="電話番号"
        value={reportPICInfo?.phone}
        onChange={(newValue) => handleChangeField("phone", newValue)}
        showEditOption={true}
      />
    </div>
  );
}

export default ReportPicTable;
