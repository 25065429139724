export const PUBLIC_VAPID_KEY =
  "BOal1Ys6hA8_qdxXCK5AMh5BHk-LbK8bdI5yK3dIhUje7w1SsI-2rWBD7Lu3c1yhNgVuPqwesdRUcgRnh8Qs-Uc";
export const DEFAULT_ITEM_PER_PAGE = 10;
export const DEFAULT_JP_DATE_FORMAT = "YYYY年MM月DD日";
export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
export const DATE_TIME_FORMAT = "YYYY/MM/DD HH:mm:ss";

export const clickEventType = {
  left: 0,
  right: 2,
};

export const gender = {
  male: "male",
  female: "female",
  unknown: "unknown",
};

// non action used in test schedule table
export const updateActionStatus = {
  notStarted: "未に変更",
  return: "戻る",
  recovery: "復帰",
  approval: "承認",
  reject: "差し戻し",
  again: "再提出依頼",
  waiting: "可否連絡待ち",
  confirmation: "確認",
  lostOrder: "失注",
  clear: "無に変更",
};

export const callApiStatus = {
  idle: "idle",
  loading: "loading",
  success: "success",
  error: "error",
};

export const checkExistedStatus = {
  noChange: "noChange",
  isChecking: "isChecking",
  existed: "existed",
  available: "available",
};

// Billing type
export const billingType = {
  nextMonth: "nextMonth",
  nextNextMonth: "nextNextMonth",
  nextNext10: "nextNext10",
  nextNext15: "nextNext15",
  nextNext20: "nextNext20",
  nextNext25: "nextNext25",
  before: "before",
  other: "other",
};

export const billingTypeLabel = {
  nextMonth: "月末締め翌月末払い",
  nextNextMonth: "月末締め翌々月末払い",
  nextNext10: "月末締め翌々月１０日払い",
  nextNext15: "月末締め翌々月１５日払い",
  nextNext20: "月末締め翌々月２０日払い",
  nextNext25: "月末締め翌々月２５日払い",
  before: "先払い",
  other: "その他(詳細を確認する場合は見積書で確認することとする)",
};

export const billingTypeOptions = [
  {
    value: billingType.nextMonth,
    label: billingTypeLabel[billingType.nextMonth],
  },
  {
    value: billingType.nextNextMonth,
    label: billingTypeLabel[billingType.nextNextMonth],
  },
  {
    value: billingType.nextNext10,
    label: billingTypeLabel[billingType.nextNext10],
  },
  {
    value: billingType.nextNext15,
    label: billingTypeLabel[billingType.nextNext15],
  },
  {
    value: billingType.nextNext20,
    label: billingTypeLabel[billingType.nextNext20],
  },
  {
    value: billingType.nextNext25,
    label: billingTypeLabel[billingType.nextNext25],
  },
  {
    value: billingType.before,
    label: billingTypeLabel[billingType.before],
  },
  {
    value: billingType.other,
    label: billingTypeLabel[billingType.other],
  },
];

export const numberOfSpecimensLabel = {
  to5: "1-5 個",
  to10: "6-10 個",
  to20: "11-20 個",
  to31: "21-31 個",
  pivateRental: "貸し切り",
  to20PerYear: "年間 20 個",
  to30PerYear: "年間 30 個",
  to40PerYear: "年間 40 個",
  to50PerYear: "年間 50 個",
  to60PerYear: "年間 60 個",
  to70PerYear: "年間 70 個",
  to80PerYear: "年間 80 個",
  to90PerYear: "年間 90 個",
  to100PerYear: "年間 100 個",
  other: "そのほか",
};

export const caseProgressTypeLabel = {
  quotation: "見積り",
  request: "依頼書",
  plan: "計画書",
  testSampleDeliver: "サンプル",
  testing: "試験中",
  report: "報告書",
  acceptance: "検収OK",
  invoice: "請求書",
  completed: "完了",
};

export const caseProgressTypeBgCellColor = {
  quotation: "#6C6C6C",
  request: "#6C6C6C",
  plan: "#6C6C6C",
  testSampleDeliver: "#6C6C6C",
  testing: "#92D2F5",
  report: "#6C6C6C",
  acceptance: "#6C6C6C",
  invoice: "#6C6C6C",
  completed: "#6C6C6C",
};

// Contract type
export const contractType = {
  eachTime: "eachTime",
  annually: "annually",
};

export const contractTypeLabel = {
  [contractType.eachTime]: "都度契約",
  [contractType.annually]: "年間契約",
};

export const contractTypeOptions = [
  {
    value: contractType.eachTime,
    label: contractTypeLabel[contractType.eachTime],
  },
  {
    value: contractType.annually,
    label: contractTypeLabel[contractType.annually],
  },
];

// Quotation Price Unit
export const quotationPriceUnit = {
  perInspection: "perInspection",
  perTimes: "perTimes",
};

export const quotationPriceUnitLabel = {
  perInspection: "円/検体",
  perTimes: "円/回",
};

export const quotationPriceUnitOptions = [
  {
    value: quotationPriceUnit.perInspection,
    label: quotationPriceUnitLabel[quotationPriceUnit.perInspection],
  },
  {
    value: quotationPriceUnit.perTimes,
    label: quotationPriceUnitLabel[quotationPriceUnit.perTimes],
  },
];

export const contractProgressTypeLabel = {
  completed: "締結完了",
  submitted: "提出中",
};

// quotationStatus
export const quotationStatus = {
  notStarted: "notStarted",
  submitted: "submitted",
  lost: "lost",
};
export const quotationStatusLabel = {
  notStarted: "未",
  submitted: "済",
  lost: "失",
};
export const quotationStatusColor = {
  notStarted: "#6C6C6C",
  submitted: "#08978E",
  lost: "#8A06B8",
};

// requestFormStatus
export const requestFormStatus = {
  notStarted: "notStarted",
  confirmed: "confirmed",
  submitted: "submitted",
  rejected: "rejected",
  waiting: "waiting",
};
export const requestFormStatusLabel = {
  notStarted: "未",
  confirmed: "確",
  submitted: "済",
  rejected: "再",
  waiting: "待",
};
export const requestFormStatusColor = {
  notStarted: "#6C6C6C",
  confirmed: "#080E97",
  submitted: "#08978E",
  rejected: "#D32929",
};

// testPlanStatus
export const testPlanStatus = {
  notStarted: "notStarted",
  submitted: "submitted",
  none: "none",
};

export const testPlanStatusLabel = {
  notStarted: "未",
  submitted: "済",
  none: "無",
};
export const testPlanStatusColor = {
  notStarted: "#6C6C6C",
  submitted: "#08978E",
  none: "#000000",
};

// reportStatus
export const reportStatus = {
  notStarted: "notStarted",
  submitted: "submitted",
  confirmed: "confirmed",
  rejected: "rejected",
};
export const reportStatusLabel = {
  notStarted: "未",
  submitted: "済",
  rejected: "差",
  confirmed: "確",
};
export const reportStatusColor = {
  notStarted: "#6C6C6C",
  confirmed: "#080E97",
  submitted: "#08978E",
  rejected: "#D32929",
};

// invoiceStatus
export const invoiceStatus = {
  notStarted: "notStarted",
  submitted: "submitted",
  none: "none",
};
export const invoiceStatusLabel = {
  notStarted: "未",
  submitted: "済",
  none: "無",
};
export const invoiceStatusColor = {
  notStarted: "#6C6C6C",
  submitted: "#08978E",
};

// caseStatus
export const caseStatus = {
  quotation: "quotation",
  lost: "lost",
  requestForm: "requestForm",
  testPlan: "testPlan",
  sample: "sample",
  test: "test",
  report: "report",
  accepted: "accepted",
  invoice: "invoice",
  completed: "completed",
};
export const caseStatusLabel = {
  quotation: "見積り",
  lost: "失注",
  requestForm: "依頼書",
  testPlan: "計画書",
  sample: "サンプル",
  test: "試験中",
  report: "報告書",
  accepted: "検収OK",
  invoice: "請求書",
  completed: "完了",
};
export const caseStatusBgColor = {
  quotation: "#EAE8D7",
  lost: "rgba(108, 125, 132, 0.21)",
  requestForm: "#ADAB8F",
  testPlan: "",
  sample: "#8EC5E5",
  test: "#92D2F5",
  report: "#6C6C6C",
  accepted: "",
  invoice: "",
  completed: "#6C6C6C",
};

// sampleStatus
export const sampleStatus = {
  notStarted: "notStarted",
  confirmed: "confirmed",
  submitted: "submitted",
  rejected: "rejected",
};
export const sampleStatusLabel = {
  notStarted: "未",
  confirmed: "確",
  submitted: "済",
  rejected: "差",
};
export const sampleStatusColor = {
  notStarted: "#6C6C6C",
  confirmed: "#080E97",
  submitted: "#08978E",
  rejected: "#D32929",
};

export const userRoles = {
  admin: "admin",
  staff: "staff",
  customer: "customer",
};

export const userRolesLabel = {
  [userRoles.admin]: "管理者",
  [userRoles.staff]: "一般ユーザー",
  [userRoles.customer]: "お客様",
};

export const contractCategory = [
  {
    value: "nonDisclosureAgreement",
    label: "秘密保持契約書",
  },
  {
    value: "outsourcingContract",
    label: "業務委託契約書",
  },
  {
    value: "original",
    label: "オリジナル",
  },
];

export const contractCategoryLabel = {
  nonDisclosureAgreement: "秘密保持契約書",
  outsourcingContract: "業務委託契約書",
  original: "オリジナル",
};

export const DEFAULT_MSG_NUMBER_PER_PAGE = 15;

export const inspectionPeopleNumberOptions = [
  {
    value: 20,
    label: "20 名",
  },
  {
    value: 30,
    label: "30 名",
  },
  {
    value: 40,
    label: "40 名",
  },
];

export const testSampleNumberOptions = [
  {
    value: "to5",
    label: numberOfSpecimensLabel.to5,
  },
  {
    value: "to10",
    label: numberOfSpecimensLabel.to10,
  },
  {
    value: "to20",
    label: numberOfSpecimensLabel.to20,
  },
  {
    value: "to31",
    label: numberOfSpecimensLabel.to31,
  },
  {
    value: "pivateRental",
    label: numberOfSpecimensLabel.pivateRental,
  },
  {
    value: "to20PerYear",
    label: numberOfSpecimensLabel.to20PerYear,
  },
  {
    value: "to30PerYear",
    label: numberOfSpecimensLabel.to30PerYear,
  },
  {
    value: "to40PerYear",
    label: numberOfSpecimensLabel.to40PerYear,
  },
  {
    value: "to50PerYear",
    label: numberOfSpecimensLabel.to50PerYear,
  },
  {
    value: "to60PerYear",
    label: numberOfSpecimensLabel.to60PerYear,
  },
  {
    value: "to70PerYear",
    label: numberOfSpecimensLabel.to70PerYear,
  },
  {
    value: "to80PerYear",
    label: numberOfSpecimensLabel.to80PerYear,
  },
  {
    value: "to90PerYear",
    label: numberOfSpecimensLabel.to90PerYear,
  },
  {
    value: "to100PerYear",
    label: numberOfSpecimensLabel.to100PerYear,
  },
  {
    value: "other",
    label: numberOfSpecimensLabel.other,
  },
];

export const ADMIN_CHAT_ID = "11111111117d87862c616e87";

export const fileNameType = {
  report: "報告書",
  quotation: "見積書",
  testPlan: "試験計画書",
  invoice: "請求書",
  requestForm: "発注書",
  contract: "契約",
  delivery: "納品書",
  testForm: "試験フォーム生成",
};

export const testResultRequiredRowName = [
  "白色ワセリン",
  "生理食塩水",
  "注射用蒸留水",
];

export const LABEL_WIDTH = {
  LEFT: 103,
  RIGHT: 146,
};


export const DOCUMENT_TYPE = {
  QUOTATION : 'quotation',
  REQUEST_FORM : 'request',
  TEST_PLAN : 'testPlan',
  REPORT : 'report',
  INVOICE : 'invoice',
  INVOICE_DELIVERY : 'invoiceDelivery',
}
