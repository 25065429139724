import React from "react";
import ProjectTableCell from "./ProjectTableCell";

function ProjectTableRow(props) {
  const { tableConfig, rowData } = props;
  return (
    <div className="w-full h-[32px] flex">
      {Array.isArray(tableConfig)
        ? tableConfig.map((cellConfig, index) =>
            cellConfig.CustomTableCell ? (
              <cellConfig.CustomTableCell
                key={index}
                width={cellConfig.width}
                textAlign={cellConfig.textAlign}
                cellContent={
                  rowData?.[cellConfig.dataKey] !== undefined
                    ? rowData?.[cellConfig.dataKey]
                    : ""
                }
                rowData={cellConfig.dataKey === "caseStatus" ? rowData : null}
                caseId={rowData.id}
              />
            ) : (
              <ProjectTableCell
                key={index}
                width={cellConfig.width}
                textAlign={cellConfig.textAlign}
                cellContent={
                  rowData?.[cellConfig.dataKey] !== undefined
                    ? rowData?.[cellConfig.dataKey]
                    : ""
                }
              />
            )
          )
        : null}
    </div>
  );
}

export default ProjectTableRow;
