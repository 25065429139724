import React, { useEffect, useMemo, useState } from "react";
import TableInfoHeader from "../../../components/Table/CommonComponents/TableInfo/TableInfoHeader";
import PageNoData from "../../../components/CommonPage/PageNoData";
import {
  contractTypeOptions,
  inspectionPeopleNumberOptions,
  testSampleNumberOptions,
} from "../../../constants";

import { NumericFormat } from "react-number-format";
import SelectPopoverSmall from "../../../components/UI/Form/Select/SelectPopover/SelectPopoverSmall";
import Button from "../../../components/UI/Button";

const tableConfig = [
  {
    title: "No.",
    width: "5.38%",
  },
  {
    title: "項目",
    width: "17.6%",
  },
  {
    title: "契約形態",
    width: "11.84%",
  },
  {
    title: "ボリューム",
    width: "11.84%",
  },
  {
    title: "試験人数",
    width: "11.84%",
  },
  {
    title: "備考",
    width: "12.5%",
  },
  {
    title: "検体数",
    width: "7.58%",
  },
  {
    title: "単価",
    width: "8.73%",
  },
  {
    title: "金額",
    width: "12.73%",
  },
];

function QuotationInput(props) {
  const { value, onChange } = props;

  return (
    <div className="w-full h-full">
      <input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="w-full h-10 border border-[#B3B3B3] bg-white rounded-[10px] !outline-none text-custom-size py-2.5 px-3"
      />
    </div>
  );
}

function QuotationInputNumber(props) {
  const { value, onChange } = props;

  return (
    <div className="w-full h-10">
      <NumericFormat
        value={value}
        onValueChange={(values) => {
          if (typeof values.floatValue === "number")
            onChange(values.floatValue);
          else onChange(0);
        }}
        allowNegative={false}
        decimalScale={0}
        valueIsNumericString={true}
        className="w-full h-full px-4 py-1 input-border-bg-colo text-center border border-[#B3B3B3] bg-white rounded-[10px] !outline-none"
        min={0}
      />
    </div>
  );
}

function QuotationSelect(props) {
  const { value, onChange, options } = props;

  return (
    <div className="w-full h-full">
      <SelectPopoverSmall
        selectedValue={value}
        setSelectedValue={onChange}
        options={options}
      />
    </div>
  );
}

function QuotationReadonlyCell({ value, className }) {
  return (
    <div
      className={`w-full h-full border border-[#B3B3B3] bg-[#ebebeb] flex items-center justify-center ${
        className || ""
      }`}
    >
      <span>{value}</span>
    </div>
  );
}

function QuotationTaskInfoRow(props) {
  const { index, rowData, handleChangeRow, showResult } = props;
  const handleChangeField = (field, newValue) => {
    handleChangeRow({ ...rowData, [field]: newValue });
  };
  return (
    <div className="w-full h-10 flex items-center">
      <div className="w-[5.38%] h-full">
        <QuotationReadonlyCell value={index + 1} />
      </div>
      <div className="w-[17.6%] h-full">
        <QuotationInput
          value={rowData?.name}
          onChange={(newValue) => handleChangeField("name", newValue)}
        />
      </div>
      <div className="w-[11.84%] h-full">
        <QuotationSelect
          value={rowData?.contractType}
          onChange={(newValue) => handleChangeField("contractType", newValue)}
          options={contractTypeOptions}
        />
      </div>
      <div className="w-[11.84%] h-full">
        <QuotationSelect
          value={rowData?.volumn}
          onChange={(newValue) => handleChangeField("volumn", newValue)}
          options={testSampleNumberOptions}
        />
      </div>
      <div className="w-[11.84%] h-full">
        <QuotationSelect
          value={rowData?.inspectionPeopleNumber}
          onChange={(newValue) =>
            handleChangeField("inspectionPeopleNumber", newValue)
          }
          options={inspectionPeopleNumberOptions}
        />
      </div>
      <div className="w-[12.5%] h-full">
        <QuotationInput
          value={rowData?.memo}
          onChange={(newValue) => handleChangeField("memo", newValue)}
        />
      </div>
      <div className="w-[7.58%] h-full">
        <QuotationInputNumber
          value={rowData?.testSampleNumber}
          onChange={(newValue) =>
            handleChangeField("testSampleNumber", newValue)
          }
        />
      </div>
      <div className="w-[8.73%] h-full">
        <QuotationInputNumber
          value={rowData?.quotationPrice}
          onChange={(newValue) => handleChangeField("quotationPrice", newValue)}
        />
      </div>
      <div className="w-[12.73%] h-full">
        <QuotationReadonlyCell
          value={
            showResult
              ? Number(rowData?.testSampleNumber) *
                Number(rowData?.quotationPrice)
              : ""
          }
          className="bg-[#e0e4e5]"
        />
      </div>
    </div>
  );
}

function QuotationTaskTable({ quotationTasks, setQuotationTasks, showResultInit}) {
  const [showResult, setShowResult] = useState(showResultInit);

  const handleChangeRow = (index, newRowData) => {
    const newData = [...quotationTasks];
    newData[index] = newRowData;
    setQuotationTasks(newData);
    setShowResult(false);
  };

  useEffect(() => {
  }, [quotationTasks]);

  const totalQuotation = useMemo(() => {
    if (!Array.isArray(quotationTasks)) return 0;
    return quotationTasks.reduce(
      (a, b) => a + Number(b?.testSampleNumber) * Number(b?.quotationPrice),
      0
    );
  }, [quotationTasks]);

  return (
    <>
      <div className="w-full h-auto flex justify-end mb-2">
        <Button onClick={() => setShowResult(true)}>計算する</Button>
      </div>

      <div className={`w-full h-full overflow-auto relative min-w-[944px]`}>
        <TableInfoHeader tableConfig={tableConfig} />

        {Array.isArray(quotationTasks) && quotationTasks.length > 0 ? (
          <>
            {quotationTasks.map((rowData, index) => (
              <QuotationTaskInfoRow
                key={index}
                index={index}
                tableConfig={tableConfig}
                rowData={rowData}
                handleChangeRow={(newValue) => handleChangeRow(index, newValue)}
                showResult={showResult}
              />
            ))}

            <div className="w-full h-10 flex">
              <div className="w-[87.27%] h-full px-5 flex items-center justify-end border border-[#B3B3B3] bg-[#ebebeb]">
                <span>合計(税抜)</span>
              </div>
              <div className="w-[12.73%] h-full flex items-center justify-center border border-[#B3B3B3] bg-[#e0e4e5]">
                {showResult ? totalQuotation : ""}
              </div>
            </div>
          </>
        ) : (
          <PageNoData />
        )}
      </div>
    </>
  );
}

export default QuotationTaskTable;
