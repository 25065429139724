import React, { useRef, useState, useMemo } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import validateMessages from "../../../../validateMessages";
import isEmail from "validator/lib/isEmail";
import { Box, Modal } from "@mui/material";
import AsyncCheckExistedInput from "../../../../components/UI/Form/Input/AsyncCheckExistedInput";
import InputText from "../../../../components/UI/Form/Input/InputText";
import { useFormik } from "formik";
import { checkExistedStatus } from "../../../../constants";
import { systemSettingsServices } from "../../../../services/api";
import Button from "../../../../components/UI/Button";
import messages from "../../../../services/api/messages";

const UpsertCustomerUserModal = ({
  id,
  customerInfoId,
  email,
  // password,
  isOpen,
  handleCloseModal,
}) => {
  const checkLoginNameRef = useRef();
  const [checkLoginNameStatus, setCheckLoginNameStatus] = useState(
    checkExistedStatus.noChange
  );

  const onSubmit = async (values) => {
    if (
      checkLoginNameStatus === checkExistedStatus.isChecking ||
      checkLoginNameStatus === checkExistedStatus.existed
    ) {
      return;
    }

    if (id) {
      systemSettingsServices
        .updateUser(id, {
          email: values.email,
          password: values.password,
          accountType: "customer",
        })
        .then((res) => {
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
          toast.error(
            <div className="flex flex-col">
              <p className="font-bold mb-1">
                {messages.userUpdateFail}
              </p>
            </div>
          );
        });
    } else {
      systemSettingsServices
        .registerUserForCustomer(customerInfoId, {
          email: values.email,
          password: values.password,
        })
        .then((res) => {
          window.location.reload();
          
        })
        .catch((error) => {
          console.log(error);
          toast.error(
            <div className="flex flex-col">
              <p className="font-bold mb-1">
                {messages.userRegisterFail}
              </p>
            </div>
          );
        });
    }
  };

  const initialValues = useMemo(
    () =>
      id
        ? {
            email,
            password: "",
          }
        : {
            email: "",
            password: "",
          },
    [id, email]
  );

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(validateMessages.required("メールアドレス"))
      .email(validateMessages.emailWrongFormat),
    password: Yup.string()
      .required(validateMessages.required("パスワード"))
      .min(8, validateMessages.minLength("パスワード", 8))
      .max(20, validateMessages.maxLength("パスワード", 20)),
  });

  const {
    handleSubmit,
    errors,
    values,
    setValues,
    setTouched,
    setErrors,
    setFieldValue,
    touched,
    handleBlur,
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const checkLoginEmail = (valueToCheck) => {
    if (checkLoginNameRef.current) {
      clearTimeout(checkLoginNameRef.current);
    }

    //
    const check = () => {
      if (
        typeof valueToCheck !== "string" ||
        !isEmail(valueToCheck)
      ) {
        return;
      }

      if (initialValues.email === valueToCheck) {
        return;
      }
      setCheckLoginNameStatus(checkExistedStatus.isChecking);
      systemSettingsServices
        .checkLoginNameExisted(valueToCheck?.trim())
        .then((isExisted) => {
          setCheckLoginNameStatus(
            isExisted
              ? checkExistedStatus.existed
              : checkExistedStatus.available
          );
        });
    };

    checkLoginNameRef.current = setTimeout(
      () => check(values.email),
      500
    );
  };

  const resetModal = () => {
    setValues(initialValues);
    setErrors({});
    setTouched({});
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        resetModal();
        handleCloseModal();
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "550px",
          bgcolor: "white",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
        className="bg-white"
      >
        <div className="flex items-center justify-center mb-6">
          <h2>ユーザー {id ? "更新" : "登録"}</h2>
        </div>
        <AsyncCheckExistedInput
          id="email"
          value={values.email}
          onChange={(newValue) => {
            setFieldValue("email", newValue);
            checkLoginEmail(newValue);
          }}
          label="メールアドレス"
          labelWidth={150}
          handleBlur={handleBlur}
          disabled={false}
          placeholder="t.yamamoto@sysdt.com"
          checkStatus={checkLoginNameStatus}
          errors={errors?.accounts?.[0]?.email}
          touched={touched?.accounts?.[0]?.email}
          requiredUnique
          requiredUniqueError={
            <div className="flex flex-col">
              <p>このメールはすでに使用されています。</p>
              <p>別のメールを選択してください。</p>
            </div>
          }
          isRequired
        />
        <div className="my-4">
          <InputText
            id="password"
            value={values.password}
            onChange={(newValue) =>
              setFieldValue("password", newValue)
            }
            label="パスワード"
            labelWidth={150}
            handleBlur={handleBlur}
            disabled={false}
            placeholder="SystemD123@"
            errors={errors?.password}
            touched={touched?.password}
            isRequired
          />
        </div>
        <div className="mt-6 flex justify-end">
          <Button
            disabled={Object.entries(errors).length > 0}
            type={
              Object.entries(errors).length > 0
                ? "disabled"
                : "primary"
            }
            onClick={handleSubmit}
          >
            {id ? "更新する" : "登録する"}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default UpsertCustomerUserModal;
