import React from "react";

import CustomerTableHeader from "./CustomerTableHeader";
import CustomerTableRow from "./CustomerTableRow";
import PageNoData from "../../../CommonPage/PageNoData";

function CustomerTableInfo(props) {
  const { tableConfig, tableData, onClickEdit, handleChange } = props;

  return (
    <div className="w-full h-full overflow-auto relative">
      <CustomerTableHeader tableConfig={tableConfig} />

      {Array.isArray(tableData) && tableData.length > 0 ? (
        tableData.map((rowData, index) => (
          <CustomerTableRow
            key={index}
            tableConfig={tableConfig}
            rowData={rowData}
            indexNum={index}
            onClickEdit={onClickEdit}
            handleChange={handleChange}
          />
        ))
      ) : (
        <div>
          <PageNoData noDataMessage="データなし!" noDataAction />
        </div>
      )}
    </div>
  );
}

export default CustomerTableInfo;
