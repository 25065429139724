import React, { useMemo, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

export default function InputFile(props) {
  const { id, handleFileChange, label, labelWidth, fileName, placeholder, acceptType } =
    props;

  const inputRef = useRef();

  const inputId = useMemo(() => {
    if (!id) {
      return uuidv4();
    }
    return id;
  }, [id]);

  return (
    <div className="w-full h-[52px] flex items-center">
      {label ? (
        <label
          htmlFor={inputId}
          className="h-full pr-4 flex items-center"
          style={{
            width: `${labelWidth || 0}px`,
          }}
        >
          <span
            className="w-full text-sm leading-[22px] tracking-[.2em] whitespace-nowrap"
            style={{
              direction: "rtl",
            }}
          >
            {label}
          </span>
        </label>
      ) : null}

      <div
        className="h-full relative cursor-pointer"
        style={{
          width: `calc(100% - ${labelWidth}px)`,
        }}
        onClick={() => inputRef.current && inputRef.current.click()}
      >
        <div className="w-full h-full px-4 py-2.5 text-sm tracking-[.2em] border border-[#B3B3B3] !outline-none focus:border-blue-500 bg-[#ebebeb]">
          {fileName ? (
            <p className="max-w-[280px] leading-[30px] w-full overflow-hidden truncate">
              {fileName}
            </p>
          ) : (
            <p className="max-w-[280px] leading-[30px] w-full overflow-hidden truncate">
              {placeholder || "ファイルを選択"}
            </p>
          )}
        </div>
        <input
          ref={inputRef}
          id={inputId}
          name={inputId}
          type="file"
          placeholder={placeholder || "ファイルを選択"}
          onChange={handleFileChange}
          className="hidden"
          accept={acceptType ? acceptType : "*"}
        />
      </div>
    </div>
  );
}
