import React from "react";

import { ReactComponent as AddSvg } from "app/assets/svg/add.svg";

function AddBtn(props) {
  const { onClick, className = "" } = props;
  return (
    <button
      className={`h-6 w-6 border-[0.5px] border-solid border-[#000000] flex justify-center items-center ${className}`}
      onClick={onClick}
    >
      {" "}
      <AddSvg />
    </button>
  );
}

export default AddBtn;
