import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, useRoutes } from "react-router-dom";
import routePaths from "./routePaths";
import AuthRoute from "./AuthRoute";
import PrivateRoute from "./PrivateRoute";
import { selectCurrentToken } from "../redux/authReducer";

// Auth Page
import Login from "../pages/AuthPages/Login";
import Register from "../pages/AuthPages/Register";
import ForgotPassword from "../pages/AuthPages/ForgotPassword";
import ResetPassword from "../pages/AuthPages/ResetPassword";

// Common Page
import NotFound from "../pages/NotFound";

// Private Page
import TopMenu from "../pages/TopMenu";
import Dashboard from "../pages/Dashboard";

import CustomerPage from "../pages/Customer";
import DetailCustomerPage from "../pages/Customer/DetailCustomer";
import CreateCustomerPage from "../pages/Customer/CreateCustomer";
import UpdateCustomerPage from "../pages/Customer/UpdateCustomer";
import ProjectPage from "../pages/Project";
import TestFormGenerationPage from "../pages/Report/TestFormGeneration";
import CreateQuotationPage from "../pages/Quotation/CreateQuotation";
import UpdateQuotationPage from "../pages/Quotation/UpdateQuotation";
import CreateTestPlanPage from "../pages/TestPlan/CreateTestPlan";
import UpdateTestPlanPage from "../pages/TestPlan/UpdateTestPlan";
import CreateReportPage from "../pages/Report/CreateReport";
import UpdateReportPage from "../pages/Report/UpdateReport";
import ProjectTestSchedulePage from "../pages/Project/TestSchedule";
import CreateInvoicePage from "../pages/Invoice/CreateInvoice";
import UpdateInvoicePage from "../pages/Invoice/UpdateInvoice";
import ContractPage from "../pages/Contract";
import CreateContractPage from "../pages/Contract/CreateContract";
import SystemSettingsUserPage from "../pages/SystemSetting/User";
import SystemSettingsCustomerPage from "../pages/SystemSetting/Customer";
import SystemSettingsCustomerDetailPage from "../pages/SystemSetting/Customer/DetailCustomer";
import SystemSettingsUpdateCustomerPage from "../pages/SystemSetting/Customer/UpdateCustomer";
import SystemSettingsCreateCustomerPage from "../pages/SystemSetting/Customer/CreateCustomer";
import SystemSettingsUserDetailPage from "../pages/SystemSetting/User/DetailUser";
import SystemSettingsUpdateUserPage from "../pages/SystemSetting/User/UpdateUser";
import SystemSettingsCreateUserPage from "../pages/SystemSetting/User/CreateUser";
import ChatPage from "../pages/Chat";
import BatchReportPage from "../pages/Report/BatchReportPage";
import BatchInvoicePage from "../pages/Invoice/BatchInvoicePage";

function Router() {
  const element = useRoutes([
    {
      path: "/",
      element: <AuthRoute />,
      children: [
        {
          path: "/",
          element: <Navigate to={routePaths.login} replace />,
        },
        { path: routePaths.login, element: <Login /> },
        { path: routePaths.register, element: <Register /> },
        {
          path: routePaths.forgotPassword,
          element: <ForgotPassword />,
        },
        {
          path: routePaths.resetPassword,
          element: <ResetPassword />,
        },
      ],
    },

    {
      path: "/",
      element: <PrivateRoute />,
      children: [
        {
          path: "/",
          element: <Navigate to={routePaths.topMenu} replace />,
        },
        { path: routePaths.topMenu, element: <TopMenu /> },
        // 1. Dashboard
        { path: routePaths.dashboard, element: <Dashboard /> },

        // 2. Customer
        { path: routePaths.customer, element: <CustomerPage /> },
        {
          path: routePaths.createCustomer,
          element: <CreateCustomerPage />,
        },
        {
          path: routePaths.detailCustomer(),
          element: <DetailCustomerPage />,
        },
        {
          path: routePaths.updateCustomer(),
          element: <UpdateCustomerPage />,
        },

        // 3. Project
        { path: routePaths.project, element: <ProjectPage /> },
        {
          path: routePaths.projectTestSchedule,
          element: <ProjectTestSchedulePage />,
        },

        // 4. Quotation
        {
          path: routePaths.quotation,
          element: (
            <Navigate to={routePaths.createQuotation} replace />
          ),
        },
        {
          path: routePaths.createQuotation,
          element: <CreateQuotationPage />,
        },
        {
          path: routePaths.updateQuotation,
          element: <UpdateQuotationPage />,
        },

        // 5. Purchase Order

        // 6. Test Plan
        {
          path: routePaths.testPlan,
          element: (
            <Navigate to={routePaths.createTestPlan} replace />
          ),
        },
        {
          path: routePaths.createTestPlan,
          element: <CreateTestPlanPage />,
        },
        {
          path: routePaths.updateTestPlan,
          element: <UpdateTestPlanPage />,
        },

        // 7. Test Sample Shipping

        // 8. Report
        {
          path: routePaths.report,
          element: <Navigate to={routePaths.createReport} replace />,
        },
        {
          path: routePaths.createReport,
          element: <CreateReportPage />,
        },
        {
          path: routePaths.updateReport,
          element: <UpdateReportPage />,
        },
        {
          path: routePaths.batchReport,
          element: <BatchReportPage />,
        },
        {
          path: routePaths.testFormGeneration,
          element: <TestFormGenerationPage />,
        },

        // 9. Invoice
        {
          path: routePaths.invoice,
          element: <Navigate to={routePaths.createInvoice} replace />,
        },
        {
          path: routePaths.createInvoice,
          element: <CreateInvoicePage />,
        },
        {
          path: routePaths.updateInvoice,
          element: <UpdateInvoicePage />,
        },
        {
          path: routePaths.batchInvoice,
          element: <BatchInvoicePage />,
        },

        // 10. Contract
        { path: routePaths.contract, element: <ContractPage /> },
        {
          path: routePaths.createContract,
          element: <CreateContractPage />,
        },

        // 11. System Settings
        {
          path: routePaths.systemSettings,
          element: (
            <Navigate
              to={routePaths.systemSettingsCustomer}
              replace
            />
          ),
        },
        {
          path: routePaths.systemSettingsCustomer,
          element: <SystemSettingsCustomerPage />,
        },
        {
          path: routePaths.systemSettingsCustomerDetail(),
          element: <SystemSettingsCustomerDetailPage />,
        },
        {
          path: routePaths.systemSettingsCustomerUpdate(),
          element: <SystemSettingsUpdateCustomerPage />,
        },
        {
          path: routePaths.systemSettingsCreateCustomer,
          element: <SystemSettingsCreateCustomerPage />,
        },

        {
          path: routePaths.systemSettingsUser,
          element: <SystemSettingsUserPage />,
        },
        {
          path: routePaths.systemSettingsUserDetail(),
          element: <SystemSettingsUserDetailPage />,
        },
        {
          path: routePaths.systemSettingsUserUpdate(),
          element: <SystemSettingsUpdateUserPage />,
        },
        {
          path: routePaths.systemSettingsCreateUser,
          element: <SystemSettingsCreateUserPage />,
        },
        {
          path: routePaths.chat,
          element: <ChatPage />,
        },
      ],
    },

    {
      path: "*",
      element: <NotFound />,
    },
  ]);
  return element;
}

export default function AppRoute() {
  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );
}
