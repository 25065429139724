import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import routePaths from "../routePaths";
import { selectCurrentToken } from "../../redux/authReducer";

export default function AuthRoute() {
  console.log("AuthRoute");
  const token = useSelector(selectCurrentToken);
  const location = useLocation();

  if (!token) return <Outlet />;

  return (
    <Navigate to={routePaths.topMenu} state={{ from: location }} replace />
  );
}
