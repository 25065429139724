import React, { useMemo } from "react";
import TableInfo from "../../CommonComponents/TableInfo";
import { Link } from "react-router-dom";
import routePaths from "app/route/routePaths";
import { convertDateStringToJpFormat } from "../../../../utils";

function AllTextScheduleTable(props) {
  const { list } = props;
  const tableData = useMemo(() => {
    return list.map((item) => ({
      test_date: (
        <Link
          to={`${routePaths.project}#${item?._id}`}
          className="text-[#08978E]"
        >
          {convertDateStringToJpFormat(item.testDate)}
        </Link>
      ),
      report_submission_day: convertDateStringToJpFormat(
        item.reportDeliveryDate
      ),
      sample_payment_deadline: convertDateStringToJpFormat(
        item.samplePaymentDeadline
      ),
      application_deadline: convertDateStringToJpFormat(
        item.applicationDeadline
      ),
      possible_number_of_applications: item.possibleApplicationNumber,
      arrived_number_of_bodies: item.arrivedSampleNumber,
      untitled_number: item.undeliveredSampleNumber,
    }));
  }, [list]);

  return (
    <TableInfo
      tableConfig={[
        {
          title: "試験日",
          width: "16.67%",
          textAlign: "center",
          dataKey: "test_date",
        },
        {
          title: "報告書納品日",
          width: "16.67%",
          textAlign: "center",
          dataKey: "report_submission_day",
        },
        {
          title: "検体納付期限",
          width: "16.67%",
          textAlign: "center",
          dataKey: "sample_payment_deadline",
        },
        {
          title: "申込期限",
          width: "16.67%",
          textAlign: "center",
          dataKey: "application_deadline",
        },
        {
          title: "申込可能数",
          width: "11.11%",
          textAlign: "center",
          dataKey: "possible_number_of_applications",
        },
        {
          title: "到着検体数",
          width: "11.11%",
          textAlign: "center",
          dataKey: "arrived_number_of_bodies",
        },
        {
          title: "未着検体数",
          width: "11.11%",
          textAlign: "center",
          dataKey: "untitled_number",
        },
      ]}
      tableData={tableData}
      className="custom-scrollbar pr-1.5 max-h-[240px] min-w-[950px]"
    />
  );
}

export default AllTextScheduleTable;
