import React, { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import TableInfoHeader from "app/components/Table/CommonComponents/TableInfo/TableInfoHeader";
import PageNoData from "app/components/CommonPage/PageNoData";
import PencilSVG from "app/assets/svg/pencil.svg";

const tableConfig = [
  {
    title: "名称",
    width: "25.44%",
  },
  {
    title: "No.",
    width: "9.44%",
  },
  {
    title: "容量",
    width: "9.44%",
  },
  {
    title: "前処理",
    width: "55.66%",
  },
];

function InputTextCell(props) {
  const { value, onChange } = props;

  return (
    <div className="w-full h-full">
      <input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="w-full h-10 border border-[#B3B3B3] bg-white rounded-[10px] !outline-none text-custom-size py-2.5 px-3"
      />
    </div>
  );
}

function ReportMaterialInfosRow(props) {
  const { rowData, handleChangeRow } = props;
  const handleChangeField = (field, newValue) => {
    handleChangeRow({ ...rowData, [field]: newValue });
  };
  return (
    <div className="w-full h-10 flex items-center pr-8 relative">
      <div className="w-[25.44%] h-full">
        <div className="w-full h-full border border-[#B3B3B3] bg-[#e0e4e5] px-2 py-2.5 text-center">
          <p className="text-sm leading-[18px] tracking-[.2em] truncate">
            {rowData?.name}
          </p>
        </div>
      </div>
      <div className="w-[9.44%] h-full">
        <div className="w-full h-full border border-[#B3B3B3] bg-[#e0e4e5] px-2 py-2.5 text-center">
          <p className="text-sm leading-[18px] tracking-[.2em] truncate">
            {rowData?.materialNumber}
          </p>
        </div>
      </div>
      <div className="w-[9.44%] h-full">
        <div className="w-full h-full border border-[#B3B3B3] bg-[#e0e4e5] px-2 py-2.5 text-center">
          <p className="text-sm leading-[18px] tracking-[.2em] truncate">
            {rowData?.capacity}
          </p>
        </div>
      </div>
      <div className="w-[55.66%] h-full">
        {rowData?.isShowEdit ? (
          <InputTextCell
            value={rowData?.preprocess}
            onChange={(newValue) => handleChangeField("preprocess", newValue)}
          />
        ) : (
          <div className="w-full h-full border border-[#B3B3B3] bg-[#e0e4e5] px-2 py-2.5 text-center">
            <p className="text-sm leading-[18px] tracking-[.2em] truncate">
              {rowData?.preprocess}
            </p>
          </div>
        )}
      </div>
      <button
        type="button"
        onClick={() => handleChangeField("isShowEdit", !rowData?.isShowEdit)}
        className="absolute top-1 right-0 w-8 h-8 flex items-center justify-center"
      >
        {rowData?.isShowEdit ? (
          <FontAwesomeIcon icon={faCheck} size="lg" />
        ) : (
          <img src={PencilSVG} className="" alt="" />
        )}
      </button>
    </div>
  );
}

function ReportMaterialInfosTable(props) {
  const { reportTestMaterialInfos, setReportTestMaterialInfos } = props;

  const handleChangeRow = (index, newRowData) => {
    const newData = [...reportTestMaterialInfos];
    newData[index] = newRowData;
    setReportTestMaterialInfos(newData);
  };

  return (
    <div className={`w-full h-full overflow-auto relative min-w-[768px]`}>
      <div className="w-full h-auto pr-8">
        <TableInfoHeader tableConfig={tableConfig} />
      </div>

      {Array.isArray(reportTestMaterialInfos) &&
      reportTestMaterialInfos.length > 0 ? (
        reportTestMaterialInfos.map((rowData, index) => (
          <ReportMaterialInfosRow
            key={index}
            index={index}
            tableConfig={tableConfig}
            rowData={rowData}
            handleChangeRow={(newValue) => handleChangeRow(index, newValue)}
            isShowEdit={rowData?.isShowEdit}
          />
        ))
      ) : (
        <PageNoData />
      )}
    </div>
  );
}

export default ReportMaterialInfosTable;
