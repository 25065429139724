import React, { useMemo } from "react";
import TableInfo from "../../CommonComponents/TableInfo";
import { getAddressFromComponent, getNameKanji } from "app/utils";
import { billingTypeOptions } from "../../../../constants";

function CustomerBillingInfoDetail(props) {
  const { billingInfo } = props;

  const paymentTermsLabel = useMemo(() => {
    return (
      billingTypeOptions?.find(
        (item) => item.value === billingInfo?.paymentTerms
      )?.label || ""
    );
  }, [billingInfo]);

  return (
    <TableInfo
      tableConfig={[
        {
          title: "項目",
          width: "25%",
          dataKey: "title",
        },
        {
          title: "詳細",
          width: "75%",
          dataKey: "value",
        },
      ]}
      tableData={[
        {
          title: "住所",
          value: getAddressFromComponent(billingInfo),
        },
        {
          title: "担当部署",
          value: billingInfo?.department || "",
        },
        {
          title: "担当者名",
          value: getNameKanji(billingInfo),
        },
        {
          title: "電話番号",
          value: billingInfo?.phone1 || "",
        },
        {
          title: "FAX番号",
          value: billingInfo?.fax || "",
        },
        {
          title: "メールアドレス",
          value: billingInfo?.email || "",
        },
        {
          title: "支払い条件",
          value: paymentTermsLabel,
        },
      ]}
    />
  );
}

export default CustomerBillingInfoDetail;
