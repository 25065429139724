import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { toast } from "react-toastify";
import ReactSelectSingle from "app/components/UI/Form/Select/ReactSelectSingle";
import PageLoading from "app/components/CommonPage/PageLoading";
import PageNoData from "app/components/CommonPage/PageNoData";
import PageError from "app/components/CommonPage/PageError";
import { scheduleServices } from "app/services/api";
import { convertDateStringToJpFormat } from "app/utils";
import messages from "../../../../../services/api/messages";

function SelectTestSchedule({
  schedule,
  setSchedule,
  labelWidth = 146,
  filterFunc,
  isWhite,
}) {
  const [loadInitState, setLoadInitState] = useState({
    isLoading: true,
    data: null,
    error: null,
  });

  const loadInit = useCallback(() => {
    setLoadInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    scheduleServices
      .getAll()
      .then((res) => {
        let data = res.data;
        if (filterFunc) {
          data = filterFunc(res.data);
        }
        setLoadInitState({
          isLoading: false,
          data,
          error: null,
        });
        const allTestSchedule = data;

        if (
          Array.isArray(allTestSchedule) &&
          !allTestSchedule.find((item) => item.id === schedule?.id)
        ) {
          setSchedule(allTestSchedule[0]);
        }
      })
      .catch((error) => {
        toast.error(messages.callApiFail);
        setLoadInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadInit();
  }, [loadInit]);

  const allTestScheduleOptions = useMemo(() => {
    if (!Array.isArray(loadInitState.data)) return [];

    return loadInitState.data.map((item) => ({
      value: item.id,
      label: convertDateStringToJpFormat(item.testDate),
    }));
  }, [loadInitState.data]);

  const allTestSchedule = useMemo(() => {
    if (!Array.isArray(loadInitState.data)) return [];

    return loadInitState.data;
  }, [loadInitState.data]);

  if (loadInitState.isLoading) return <PageLoading />;
  if (loadInitState.error) return <PageError />;

  if (
    Array.isArray(loadInitState.data) &&
    loadInitState.data.length > 0
  )
    return (
      <div className="w-full h-auto">
        <ReactSelectSingle
          id="select-test-schedule"
          value={schedule?.id}
          onChange={(newValue) => {
            const schedule = allTestSchedule.find(
              (item) => item.id === newValue
            );
            setSchedule(schedule);
          }}
          label="試験スケジュール"
          options={allTestScheduleOptions}
          labelWidth={labelWidth}
          isWhite={isWhite}
        />
      </div>
    );

  return (
    <div className="w-full h-auto">
      <PageNoData />
    </div>
  );
}

export default SelectTestSchedule;
