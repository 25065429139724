import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { commonServices } from "../services/api";
import routePaths from "../route/routePaths";

export default function useVerifyToken() {
  const navigate = useNavigate();
  useEffect(() => {
    commonServices.verifyToken(() => {
      navigate(routePaths.login);
    });
  }, []);
}
