import client from "./client";
import clientFormData from "./clientFormData";
import Apis from "./Path";

const getContractList = () => {
  return client.get(Apis.contractList);
};

const createContract = (data) => {
  return client.post(Apis.createContract, data);
};

const confirmContract = (data) => {
  return clientFormData.post(Apis.confirmContact, data).then((res) => {
    if (res.data?.success) {
      return { data: res.data?.result };
    }
    throw new Error("Malformed data!");
  });
};

const deleteContract = (contractId) => {
  return client.delete(`${Apis.deleteContract}?id=${contractId}`);
};

const contractServices = {
  getContractList,
  createContract,
  confirmContract,
  deleteContract,
};
export default contractServices;
