// FE-2 : https://www.figma.com/file/rjvTEGNOs42Sv2L7TEA5Ef/DRC_%E6%A5%AD%E5%8B%99%E7%AE%A1%E7%90%86%E3%82%B7%E3%82%B9%E3%83%86%E3%83%A0_%E7%AE%A1%E7%90%86%E8%80%85%E7%94%A8?type=design&node-id=77-209&mode=design&t=TnHyoUr5aMtVZd2h-0
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { systemSettingsServices } from "app/services/api";
import { toast } from "react-toastify";

import PageError from "app/components/CommonPage/PageError";
import PageLoading from "app/components/CommonPage/PageLoading";
import PageLoadingBackdrop from "app/components/CommonPage/PageLoadingBackdrop";
import { callApiStatus } from "app/constants";
import routePaths from "app/route/routePaths";

import SystemSettingContentLayout from "../../Components/ContentLayout";
import CreateAndUpdateCustomerForm from "../../Components/CreateAndUpdateCustomerForm";
import ConfirmDeleteDialog from "../../../../components/Dialog/ConfirmDeleteDialog";
import messages from "../../../../services/api/messages";

function SystemSettingsUpdateCustomerPageContent({ id, customerIdInfo }) {

  const navigate = useNavigate();
  const [updateStatus, setUpdateStatus] = useState(callApiStatus.idle);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const handleSubmitForm = async (formData) => {
    if (updateStatus === callApiStatus.loading) return;

    setUpdateStatus(callApiStatus.loading);
    systemSettingsServices
      .updateCustomerId(id, formData)
      .then((res) => {
        setUpdateStatus(callApiStatus.idle);
        navigate(routePaths.systemSettingsCustomerDetail(id));
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          <div className="flex flex-col">
            <p className="font-bold mb-1">{messages.custUpdateFail}</p>
          </div>
        );
        setUpdateStatus(callApiStatus.idle);
      });
  };

  const handleDeleteCustomerId = async () => {
    return await systemSettingsServices.deleteCustomerId(id);
  };

  return (
    <>
      <CreateAndUpdateCustomerForm
        customerIdToUpdate={customerIdInfo}
        handleSubmitForm={handleSubmitForm}
        handleShowConfirmDelete={() => setShowConfirmDelete(true)}
      />

      <ConfirmDeleteDialog
        open={showConfirmDelete}
        handleClose={() => setShowConfirmDelete(false)}
        confirmTitle="お客様の削除の確認"
        confirmMessage={
          <div className="flex flex-col items-center">
            <p>
              {customerIdInfo.companyNameKanji}（ID: {customerIdInfo.customerId}
              ）を削除をしますか？
            </p>
            <p>（削除したら復旧できないので、ご注意ください。）</p>
          </div>
        }
        deleteCompleteTitle="顧客が正常に削除されましたId"
        deleteCompleteBtnLabel="顧客IDの検索に戻る"
        deleteCompleteAction={() => navigate(routePaths.systemSettingsCustomer)}
        deleteAction={handleDeleteCustomerId}
        handleDeleteSuccess={() => {
          navigate(routePaths.systemSettingsCustomer);
          toast.success(messages.delete(true));
        }}
        handleDeleteError={() => {
          toast.error(messages.delete(false));
        }}
      />

      <PageLoadingBackdrop open={updateStatus === callApiStatus.loading} />
    </>
  );
}

export default function SystemSettingsUpdateCustomerPage() {
  const { id } = useParams();

  const [loadInitState, setLoadInitState] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const loadInit = useCallback(() => {
    setLoadInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    systemSettingsServices
      .getCustomerIdDetail(id)
      .then((res) => {
        setLoadInitState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        toast.error(messages.callApiFail);
        setLoadInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [id]);

  useEffect(() => {
    loadInit();
  }, [loadInit]);

  return (
    <SystemSettingContentLayout>
      <div className="w-full grow flex flex-col pt-2.5">
        {loadInitState.isLoading ? <PageLoading /> : null}

        {!loadInitState.isLoading && loadInitState.error ? <PageError /> : null}

        {!loadInitState.isLoading &&
        !loadInitState.error &&
        loadInitState.data ? (
          <SystemSettingsUpdateCustomerPageContent
            id={id}
            customerIdInfo={loadInitState.data}
          />
        ) : null}
      </div>
    </SystemSettingContentLayout>
  );
}
