import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useOutletContext } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useMediaQuery } from "@mui/material";
import ContentLayout from "app/layouts/ContentLayout";
import routePaths from "app/route/routePaths";
import SelectCustomerIdAndTestSchedule from "app/components/UI/Form/SelectGroup/SelectCustomerIdAndTestSchedule";
import PageLoading from "app/components/CommonPage/PageLoading";
import PageError from "app/components/CommonPage/PageError";
import InputText from "app/components/UI/Form/Input/InputText";
import SelectPicStaff from "app/components/UI/Form/Select/SelectPicStaff";
import InputDate from "app/components/UI/Form/Input/InputDate";
import ReactSelect from "app/components/UI/Form/Select/ReactSelectSingle";
import Button from "app/components/UI/Button";
import ClearRedBtn from "app/components/UI/Button/ClearRedBtn";
import PrimaryBtn from "app/components/UI/Button/PrimaryBtn";
import {
  DEFAULT_DATE_FORMAT,
  LABEL_WIDTH,
  billingTypeOptions,
  callApiStatus,
  fileNameType,
} from "app/constants";
import { commonServices, quotationServices } from "app/services/api";
import QuotationTaskTable from "./QuotationTaskTable";
import moment from "moment";
import PageLoadingBackdrop from "../../../components/CommonPage/PageLoadingBackdrop";
import messages from "../../../services/api/messages";
import PageNoData from "../../../components/CommonPage/PageNoData";
import validateMessages from "../../../validateMessages";
import TextArea from "../../../components/UI/Form/Input/TextArea";

function CreateQuotationForm({ initQuotation, customer }) {
  const [confirmedData, setConfirmedData] = useState(null);
  const [createdId, setCreatedId] = useState();
  const isMd = useMediaQuery("(max-width:768px)");

  const validationSchema = Yup.object().shape({
    picStaff: Yup.string().required(
      validateMessages.required("担当者")
    ),
    paymentMethod: Yup.string().required(
      validateMessages.required("支払方法")
    ),
    deliveryDate: Yup.string(),
    deliveryPlace: Yup.string(),
    expiryDateStatement: Yup.string().required(
      validateMessages.required("有効期間")
    ),
    requirements: Yup.string().required(
      validateMessages.required("要件")
    ),
    memo: Yup.string().required(validateMessages.required("備考")),
  });

  const onSubmit = (validValues) => {
    const firstTaskItem = validValues.quotationTasks?.[0];
    let totalSamplePeopleNumber = Number(
      firstTaskItem?.inspectionPeopleNumber || 0
    );
    let totalSampleNumber = 0;
    let totalQuotationPrice = 0;

    validValues.quotationTasks.forEach((item) => {
      totalSampleNumber += Number(item.testSampleNumber);
      totalQuotationPrice +=
        Number(item.testSampleNumber) * Number(item.quotationPrice);
    });

    const dataToCallApi = {
      ...genDataToCallApi(validValues),
      totalSamplePeopleNumber,
      totalSampleNumber,
      totalQuotationPrice,
    };

    quotationServices
      .createFileDownload(dataToCallApi)
      .then((res) => {
        setConfirmedData({
          ...validValues,
          generatedExcel: res.data._id,
        });
        const fileType = fileNameType["quotation"];
        const caseId = values?.quotationId || "";
        const companyName = customer?.companyNameKanji || "";
        const timeStamp = moment().format("YYYYMMDDHHmmss");

        setTimeout(() => {
          commonServices.downloadWithFileId(
            res.data._id,
            "xlsx",
            `${fileType}_${caseId}_${companyName}_${timeStamp}`
          );
        }, 500);
        setTimeout(() => {
          commonServices.downloadPDFWithFileId(
            res.data._id,
            `${fileType}_${caseId}_${companyName}_${timeStamp}`
          );
        }, 500);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(messages.callApiFail);
        setIsLoading(false);
      });
  };

  const {
    handleSubmit,
    errors,
    values,
    setFieldValue,
    resetForm,
    touched,
    // handleBlur,
    // setFieldTouched,
  } = useFormik({
    initialValues: initQuotation,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const [isLoading, setIsLoading] = useState(false);

  const isDisabledSubmit = useMemo(
    () => !confirmedData,
    [confirmedData]
  );

  const changeQuotationInfo = (field, value) => {
    setFieldValue(field, value);
    setConfirmedData(null);
  };

  const convertDateToISO = (dateStr) => {
    return dateStr
      ? moment(dateStr, DEFAULT_DATE_FORMAT).toISOString()
      : "";
  };

  const genDataToCallApi = (quotationData) => {
    const data = {
      customerId: quotationData.customerId,
      testScheduleId: quotationData.testScheduleId,
      quotationId: quotationData.quotationId,
      deliveryPlace: quotationData.deliveryPlace,
      paymentMethod: quotationData.paymentMethod,
      expiryDateStatement: quotationData.expiryDateStatement,
      requirements: quotationData.requirements,
      memo: quotationData.memo,
      picStaff: quotationData.picStaff,
      paymentDeadline: [
        convertDateToISO(quotationData.paymentDeadline1),
        convertDateToISO(quotationData.paymentDeadline2),
        convertDateToISO(quotationData.paymentDeadline3),
        convertDateToISO(quotationData.paymentDeadline4),
        convertDateToISO(quotationData.paymentDeadline5),
        convertDateToISO(quotationData.paymentDeadline6),
        convertDateToISO(quotationData.paymentDeadline7),
        convertDateToISO(quotationData.paymentDeadline8),
      ],
      quotationTasks: Array.isArray(quotationData.quotationTasks)
        ? quotationData.quotationTasks.map((item) => ({
            name: item.name,
            contractType: item.contractType,
            billingTiming: item.billingTiming,
            volumn: item.volumn,
            inspectionPeopleNumber: item.inspectionPeopleNumber,
            testSampleNumber: item.testSampleNumber,
            quotationPrice: item.quotationPrice,
            quotationPriceUnit: item.quotationPriceUnit,
            memo: item.memo,
          }))
        : [],
    };

    if (quotationData.deliveryDate) {
      data.deliveryDate = quotationData.deliveryDate;
    }
    return data;
  };

  const handleConfirm = () => {
    handleSubmit();
  };

  const handleSave = () => {
    if (!confirmedData || isLoading) return;
    const firstTaskItem = confirmedData.quotationTasks?.[0];
    let totalSamplePeopleNumber = Number(
      firstTaskItem?.inspectionPeopleNumber || 0
    );
    let totalSampleNumber = 0;
    let totalQuotationPrice = 0;

    confirmedData.quotationTasks.forEach((item) => {
      totalSampleNumber += Number(item.testSampleNumber);
      totalQuotationPrice +=
        Number(item.testSampleNumber) * Number(item.quotationPrice);
    });

    const dataToCallApi = {
      ...genDataToCallApi(confirmedData),
      generatedExcel: confirmedData.generatedExcel,
      totalSamplePeopleNumber,
      totalSampleNumber,
      totalQuotationPrice,
    };

    setIsLoading(true);

    quotationServices
      .createQuotation(dataToCallApi)
      .then((data) => {
        if (data?.id) {
          setCreatedId(data?.id);
        }
        toast.success(messages.create(true));
        setIsLoading(false);
      })
      .catch((err) => {
        if ((err = "001")) {
          toast.error(messages.createMaxReach);
        } else {
          toast.error(messages.create(false));
        }
        setIsLoading(false);
      });
  };

  if (createdId)
    return (
      <div className="w-full h-auto my-12 flex items-center justify-center text-xl">
        見積書は作成しました。修正する場合は、見積書の修正で修正していください。
      </div>
    );

  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto flex items-center justify-center mb-3 flex-wrap">
        <div className="w-full mb-3 md:mb-0 md:w-1/2 h-full md:pr-12">
          <InputText
            id="quotationId"
            value={values.quotationId}
            label="見積番号"
            disabled={true}
            labelWidth={isMd ? LABEL_WIDTH.RIGHT : LABEL_WIDTH.LEFT}
          />
        </div>

        <div className="w-full md:w-1/2 h-full">
          <SelectPicStaff
            pic={{ id: values.picStaff }}
            setPic={(newPic) => {
              changeQuotationInfo("picStaff", newPic?.id);
            }}
            labelWidth={LABEL_WIDTH.RIGHT}
            touched={touched.picStaff}
            errors={errors.picStaff}
            isRequired
            preventAutoSetPic
          />
        </div>
      </div>
      <div className="w-full h-auto flex items-center justify-center mb-3 flex-wrap">
        <div className="w-full md:w-1/2 h-full md:pr-12">
          <InputDate
            id="deliveryDate"
            value={values.deliveryDate}
            onChange={(newValue) => {
              changeQuotationInfo("deliveryDate", newValue);
            }}
            label="納入期日"
            labelWidth={isMd ? LABEL_WIDTH.RIGHT : LABEL_WIDTH.LEFT}
            touched={touched.deliveryDate}
            errors={errors.deliveryDate}
          />
        </div>

        <div className="w-full md:w-1/2 h-full">
          <InputText
            id="deliveryPlace"
            value={values.deliveryPlace}
            onChange={(newValue) => {
              changeQuotationInfo("deliveryPlace", newValue);
            }}
            label="納入場所"
            labelWidth={LABEL_WIDTH.RIGHT}
            touched={touched.deliveryPlace}
            errors={errors.deliveryPlace}
          />
        </div>
      </div>

      <div className="w-full h-auto flex items-center justify-center mb-3 flex-wrap">
        <div className="w-full md:w-1/2 h-auto md:pr-12 mb-3 md:mb-0">
          <ReactSelect
            value={values.paymentMethod}
            onChange={(newValue) => {
              changeQuotationInfo("paymentMethod", newValue);
            }}
            label="支払方法"
            options={billingTypeOptions}
            labelWidth={isMd ? LABEL_WIDTH.RIGHT : LABEL_WIDTH.LEFT}
            touched={touched.paymentMethod}
            errors={errors.paymentMethod}
            placeholder="支払方法を選択"
            isRequired
          />
        </div>

        <div className="w-full md:w-1/2 h-full">
          <InputText
            id="expiryDateStatement"
            value={values.expiryDateStatement}
            onChange={(newValue) => {
              changeQuotationInfo("expiryDateStatement", newValue);
            }}
            label="有効期間"
            labelWidth={LABEL_WIDTH.RIGHT}
            touched={touched.expiryDateStatement}
            errors={errors.expiryDateStatement}
            isRequired
          />
        </div>
      </div>
      <div className="w-full h-auto flex items-center justify-center mb-3 flex-wrap">
        <div className="w-full md:w-1/2 h-full md:pr-12">
          <InputText
            id="requirements"
            value={values.requirements}
            onChange={(newValue) => {
              changeQuotationInfo("requirements", newValue);
            }}
            label="要件"
            labelWidth={isMd ? LABEL_WIDTH.RIGHT : LABEL_WIDTH.LEFT}
            touched={touched.requirements}
            errors={errors.requirements}
            isRequired
          />
        </div>

        <div className="w-1/2 h-full"></div>
      </div>

      <div className="w-full h-auto flex items-center justify-center mb-3">
        <div className="w-full h-full">
          <TextArea
            id="memo"
            value={values.memo}
            onChange={(newValue) => {
              changeQuotationInfo("memo", newValue);
            }}
            label="備考"
            labelWidth={isMd ? LABEL_WIDTH.RIGHT : LABEL_WIDTH.LEFT}
            touched={touched.memo}
            errors={errors.memo}
            isRequired
          />
        </div>
      </div>

      <div className="w-full h-auto mb-7">
        <div className="w-full h-auto flex items-start">
          <label
            className="h-[52px] pr-4 flex items-center shrink-0"
            style={{
              width: `${
                isMd ? LABEL_WIDTH.RIGHT : LABEL_WIDTH.LEFT
              }px`,
            }}
          >
            <span
              className="w-full text-sm leading-[22px] tracking-[.2em] whitespace-nowrap"
              style={{
                direction: "rtl",
              }}
            >
              振込期限
            </span>
          </label>

          <div
            className="h-auto"
            style={{
              width: `calc(100% - ${LABEL_WIDTH.LEFT}px)`,
            }}
          >
            <div className="w-[calc(100%+16px)] h-auto flex items-center mb-0 md:mb-3 flex-wrap -mx-2">
              <div className="w-full sm:w-1/2 md:w-1/4 h-[52px] px-2">
                <InputDate
                  id="paymentDeadline1"
                  value={values.paymentDeadline1}
                  onChange={(newValue) => {
                    changeQuotationInfo("paymentDeadline1", newValue);
                  }}
                  errors={errors.paymentDeadline1}
                  touched={touched.paymentDeadline1}
                />
              </div>
              <div className="w-full sm:w-1/2 md:w-1/4 h-[52px] px-2">
                <InputDate
                  id="paymentDeadline2"
                  value={values.paymentDeadline2}
                  onChange={(newValue) => {
                    changeQuotationInfo("paymentDeadline2", newValue);
                  }}
                />
              </div>
              <div className="w-full sm:w-1/2 md:w-1/4 h-[52px] px-2">
                <InputDate
                  id="paymentDeadline3"
                  value={values.paymentDeadline3}
                  onChange={(newValue) => {
                    changeQuotationInfo("paymentDeadline3", newValue);
                  }}
                />
              </div>
              <div className="w-full sm:w-1/2 md:w-1/4 h-[52px] px-2">
                <InputDate
                  id="paymentDeadline4"
                  value={values.paymentDeadline4}
                  onChange={(newValue) => {
                    changeQuotationInfo("paymentDeadline4", newValue);
                  }}
                />
              </div>
            </div>
            <div className="w-[calc(100%+16px)] h-auto flex items-center mb-3 flex-wrap -mx-2">
              <div className="w-full sm:w-1/2 md:w-1/4 h-[52px] px-2">
                <InputDate
                  id="paymentDeadline5"
                  value={values.paymentDeadline5}
                  onChange={(newValue) => {
                    changeQuotationInfo("paymentDeadline5", newValue);
                  }}
                />
              </div>
              <div className="w-full sm:w-1/2 md:w-1/4 h-[52px] px-2">
                <InputDate
                  id="paymentDeadline6"
                  value={values.paymentDeadline6}
                  onChange={(newValue) => {
                    changeQuotationInfo("paymentDeadline6", newValue);
                  }}
                />
              </div>
              <div className="w-full sm:w-1/2 md:w-1/4 h-[52px] px-2">
                <InputDate
                  id="paymentDeadline7"
                  value={values.paymentDeadline7}
                  onChange={(newValue) => {
                    changeQuotationInfo("paymentDeadline7", newValue);
                  }}
                />
              </div>
              <div className="w-full sm:w-1/2 md:w-1/4 h-[52px] px-2">
                <InputDate
                  id="paymentDeadline8"
                  value={values.paymentDeadline8}
                  onChange={(newValue) => {
                    changeQuotationInfo("paymentDeadline8", newValue);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {Array.isArray(values.quotationTasks) &&
      values.quotationTasks.length > 0 ? (
        <>
          <div className="w-full overflow-x-auto">
            <QuotationTaskTable
              quotationTasks={values.quotationTasks}
              setQuotationTasks={(newValue) =>
                changeQuotationInfo("quotationTasks", newValue)
              }
            />
          </div>

          <div className="ml-auto flex gap-[13px] justify-end mt-[14px]">
            <ClearRedBtn
              onClick={() => resetForm(initQuotation)}
              text="クリア"
            />
            <PrimaryBtn onClick={handleConfirm} text="確認する" />
            <Button
              disabled={isDisabledSubmit}
              type={isDisabledSubmit ? "disabled" : "primary"}
              onClick={handleSave}
            >
              提出する
            </Button>
          </div>
        </>
      ) : (
        <PageNoData
          noDataMessage={
            <div className="w-full h-auto flex flex-col items-center">
              <p>ユーザーはまだ基本見積を作成していません。</p>
              <p>
                まずは誰かのために基本的な見積もりを作成してください。
              </p>
            </div>
          }
        />
      )}

      <PageLoadingBackdrop open={isLoading} />
    </div>
  );
}

function CreateQuotation({ customer, testSchedule }) {
  const [loadInitState, setLoadInitState] = useState({
    loadingStatus: callApiStatus.idle,
    data: null,
    error: null,
  });

  const DEFAULT_MEMO =
    "報告書・請求書・納品書はPDF納品となります。 \n判定写真は撮影しておりません。48時間後判定で”+“以上の判定の場合には消失確認を行います。";

  const loadInitQuotation = useCallback(() => {
    if (!customer?.id || !testSchedule?.id) return;

    setLoadInitState({
      loadingStatus: callApiStatus.loading,
      data: null,
      error: null,
    });

    quotationServices
      .getInitNewQuotation(customer.id, testSchedule.id)
      .then((res) => {
        setLoadInitState({
          loadingStatus: callApiStatus.success,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        toast.error(messages.callApiFail);
        setLoadInitState({
          loadingStatus: callApiStatus.error,
          data: null,
          error: error,
        });
      });
  }, [customer?.id, testSchedule?.id]);

  useEffect(() => {
    loadInitQuotation();
  }, [loadInitQuotation]);

  if (loadInitState.loadingStatus === callApiStatus.loading) {
    return <PageLoading />;
  }

  if (
    loadInitState.loadingStatus === callApiStatus.error ||
    loadInitState.error
  ) {
    return <PageError />;
  }

  if (
    loadInitState.loadingStatus === callApiStatus.success &&
    loadInitState.data
  ) {
    return (
      <CreateQuotationForm
        initQuotation={{
          ...loadInitState.data,
          memo: DEFAULT_MEMO,
        }}
        customer={customer}
      />
    );
  }
  return null;
}

function CreateQuotationPageWrapper({
  selectedCustomerId,
  setSelectedCustomerId,
  selectedSchedule,
  setSelectedSchedule,
}) {
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto mb-5">
        <p className="text-custom-size mb-3">見積書の作成</p>
        <SelectCustomerIdAndTestSchedule
          customer={selectedCustomerId}
          setCustomer={setSelectedCustomerId}
          schedule={selectedSchedule}
          setSchedule={setSelectedSchedule}
          isSelectCase={false}
        />
      </div>
      <CreateQuotation
        customer={selectedCustomerId}
        testSchedule={selectedSchedule}
      />
    </div>
  );
}

function CreateQuotationPage() {
  const [breadcrumb] = useOutletContext();
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [selectedSchedule, setSelectedSchedule] = useState(null);

  return (
    <ContentLayout
      sideMenuLinkOptions={[
        {
          linkTo: routePaths.createQuotation,
          linkTitle: "見積書の作成",
        },
        {
          linkTo: routePaths.updateQuotation,
          linkTitle: "見積書の修正",
        },
        { linkTo: routePaths.topMenu, linkTitle: "トップへ戻る" },
      ]}
      breadcrumb={breadcrumb}
    >
      <CreateQuotationPageWrapper
        selectedCustomerId={selectedCustomerId}
        setSelectedCustomerId={setSelectedCustomerId}
        selectedSchedule={selectedSchedule}
        setSelectedSchedule={setSelectedSchedule}
      />
    </ContentLayout>
  );
}

export default CreateQuotationPage;
