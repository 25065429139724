import React from "react";

function TailComponent(props) {
  const { fillColor, ...rest } = props || {};
  return (
    <svg {...rest} width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 0C5.41301 3.81544 2.75867 5.90809 0.666266 6.98583C0.343107 7.15228 0.496959 8 0.860466 8H6V0Z" fill={fillColor || "#FF8AB4"} />
    </svg>
  );
}

export default TailComponent;
