import { useFormik } from "formik";
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useEffect,
} from "react";
import * as Yup from "yup";
import {
  callApiStatus,
  fileNameType,
  reportStatusLabel,
} from "../../../constants";
import {
  defaultFormatNumberOfPeople,
  defaultFormatNumberOfSample,
} from "../../../utils";
import CaseStatusCell from "../../../components/Table/Project/TestScheduleTableInfo/CaseStatusCell";
import {
  commonServices,
  reportServices,
} from "../../../services/api";
import { toast } from "react-toastify";
import messages from "../../../services/api/messages";
import moment from "moment";
import InputInForm from "../../../components/UI/Form/Input/InputInForm";
import validateMessages from "../../../validateMessages";

const Item = forwardRef(
  ({ data, fileDataArray, file, scheduleId }, ref) => {
    const [loadInitState, setLoadInitState] = useState({
      loadingStatus: callApiStatus.idle,
      data: null,
      error: null,
    });
    const caseId = data?.caseId || "";
    const customerId = data?.customer?._id || "";
    const fileType = fileNameType["report"];
    const timeStamp = moment().format("YYYYMMDDHHmmss");

    const loadInitReport = useCallback(() => {
      setLoadInitState({
        loadingStatus: callApiStatus.loading,
        data: null,
        error: null,
      });

      reportServices
        .getInitNewReport(data.customer._id, data.quotation)
        .then((initReport) => {
          setLoadInitState({
            loadingStatus: callApiStatus.success,
            data: {
              ...initReport,
              reportTestMaterialInfos: fileDataArray,
              generatedPdf: null,
              generatedWord: null,
            },
            error: null,
          });
        })
        .catch((error) => {
          toast.error(messages.callApiFail);
          setLoadInitState({
            loadingStatus: callApiStatus.error,
            data: null,
            error: error,
          });
        });
    }, [data, fileDataArray]);

    const handleCreateReport = async () => {
      const createFileDownloadData = {
        customerId,
        testScheduleId: scheduleId,
        testId: loadInitState.data.testId,
        doctor: values.doctor,
        hasTestPlan: !!data?.testPlanInfo,
        department: values.department || "",
        reportTestMaterialInfos:
          loadInitState.data.reportTestMaterialInfos,
        file: file,
      };

      const confirmResult = await reportServices
        .createFileDownload(createFileDownloadData)
        .then((res) => {
          if (res.data?.success) {
            setTimeout(() => {
              commonServices
                .downloadWithFileId(
                  res.data?.result?.generatedWord?._id,
                  "docx",
                  `${fileType}_${caseId}_${customerId}_${timeStamp}`
                )
                .catch(() => toast.error(messages.downloadFail));
            }, 500);
            toast.success(messages.confirm(true));
            return res.data?.result;
          }
        })
        .catch(() => {
          toast.error(messages.confirm(false));
        });

      const createReportData = {
        customerId,
        testScheduleId: scheduleId,
        caseInfoId: data._id,
        testId: loadInitState.data.testId,
        doctor: values.doctor,
        department: values?.department || "",
        reportCustomerPIC: {
          department: values?.department || "",
          nameKanji: values?.nameKanji || "",
          phone: values?.phone || "",
        },
        reportTestMaterialInfos: Array.isArray(
          loadInitState.data.reportTestMaterialInfos
        )
          ? loadInitState.data.reportTestMaterialInfos.map(
              (item) => ({
                name: item.name,
                preprocess: item.preprocess,
                materialNumber: item.materialNumber,
                capacity: item.capacity,
              })
            )
          : [],
        testResult: "",
        generatedPdf: confirmResult?.generatedWord?._id,
        generatedWord: confirmResult?.generatedWord?._id,
      };

      reportServices.createReport(createReportData).then(() => {
        toast.success(messages.create(true));
        //
      });
    };

    const createReport = () => {
      if (fileDataArray.length > 0) {
        handleCreateReport();
      }
    };

    const { handleSubmit, values, touched, errors, setFieldValue } =
      useFormik({
        initialValues: {
          doctor: loadInitState.data?.doctor,
          department:
            loadInitState.data?.reportCustomerPIC?.department,
          nameKanji: loadInitState.data?.reportCustomerPIC?.nameKanji,
          phone: loadInitState.data?.reportCustomerPIC?.phone,
        },
        validationSchema:
          fileDataArray && fileDataArray.length > 0
            ? Yup.object().shape({
                doctor: Yup.string().required(
                  validateMessages.required("判定医師")
                ),
                department: Yup.string().required(
                  validateMessages.required("担当部署")
                ),
              })
            : Yup.object().shape({
                doctor: Yup.string(),
                department: Yup.string(),
                nameKanji: Yup.string(), // Adjust validation as needed
                phone: Yup.string(), // Adjust validation as needed
              }),
        onSubmit: createReport,
        enableReinitialize: true,
      });

    // send submit function to the parent component
    useImperativeHandle(ref, () => ({
      triggerClick: () => {
        handleSubmit();
      },
    }));

    useEffect(() => {
      if (errors.department) {
        toast.error(messages.required("担当部署"));
      }
      if (errors.doctor) {
        toast.error(messages.required("判定医師"));
      }
      }, [errors]);

    useEffect(() => loadInitReport(), [loadInitReport]);

    const cellStyle = `flex items-center justify-center p-2 overflow-hidden whitespace-nowrap border border-[#B3B3B3] ${
      fileDataArray?.length > 0 ? "bg-yellow-200" : ""
    }`;

    return (
      <React.Fragment>
        <div className={"border-l " + cellStyle}>{data.caseId}</div>
        <div className={cellStyle}>
          {reportStatusLabel[data.reportStatus]}
        </div>
        <div className={cellStyle}>
          {data?.customer?.customerId || ""}
        </div>
        <div className={cellStyle}>
          {defaultFormatNumberOfPeople(data.samplePeopleNumber)}
        </div>
        <div className={cellStyle}>
          {defaultFormatNumberOfSample(data.sampleNumber)}
        </div>
        <InputInForm
          type="text"
          value={values.doctor}
          onChange={(value) => {
            setFieldValue("doctor", value);
          }}
          className={cellStyle}
          error={fileDataArray?.length > 0 && errors.doctor}
          touched={touched.doctor}
          otherStyle={
            fileDataArray?.length > 0 ? "bg-yellow-200" : ""
          }
        />
        <InputInForm
          type="text"
          value={values.department}
          onChange={(value) => {
            setFieldValue("department", value);
          }}
          className={cellStyle}
          error={fileDataArray?.length > 0 && errors.department}
          touched={touched.department}
          otherStyle={
            fileDataArray?.length > 0 ? "bg-yellow-200" : ""
          }
        />
        <InputInForm
          type="text"
          value={values.nameKanji}
          onChange={(value) => {
            setFieldValue("nameKanji", value);
          }}
          className={cellStyle}
          error={errors.nameKanji}
          touched={touched.nameKanji}
          otherStyle={
            fileDataArray?.length > 0 ? "bg-yellow-200" : ""
          }
        />
        <InputInForm
          type="text"
          value={values.phone}
          onChange={(value) => {
            setFieldValue("phone", value);
          }}
          className={cellStyle}
          error={errors.phone}
          touched={touched.phone}
          otherStyle={
            fileDataArray?.length > 0 ? "bg-yellow-200" : ""
          }
        />
        <CaseStatusCell
          cellContent={data.caseStatus}
          textAlign="center"
        />
        <div className={cellStyle}>
          {fileDataArray
            ? fileDataArray?.length > 0
              ? "有"
              : "無"
            : null}
        </div>
      </React.Fragment>
    );
  }
);

export default Item;
