import React from "react";

function CalendarSvg() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8333 3.33341H15V1.66675H13.3333V3.33341H6.66667V1.66675H5V3.33341H4.16667C3.24167 3.33341 2.50833 4.08341 2.50833 5.00008L2.5 16.6667C2.5 17.5834 3.24167 18.3334 4.16667 18.3334H15.8333C16.75 18.3334 17.5 17.5834 17.5 16.6667V5.00008C17.5 4.08341 16.75 3.33341 15.8333 3.33341ZM15.8333 16.6667H4.16667V8.33342H15.8333V16.6667ZM15.8333 6.66675H4.16667V5.00008H15.8333V6.66675ZM7.5 11.6667H5.83333V10.0001H7.5V11.6667ZM10.8333 11.6667H9.16667V10.0001H10.8333V11.6667ZM14.1667 11.6667H12.5V10.0001H14.1667V11.6667ZM7.5 15.0001H5.83333V13.3334H7.5V15.0001ZM10.8333 15.0001H9.16667V13.3334H10.8333V15.0001ZM14.1667 15.0001H12.5V13.3334H14.1667V15.0001Z"
        fill="#6C6C6C"
      />
    </svg>
  );
}

export default CalendarSvg;
