const STYLE_LIST = {
  primary: "bg-[#001C4F] text-white",
  danger: "bg-[#CC1C00] text-white",
  disabled: "bg-gray-500 text-white cursor-not-allowed",
};

export default function Button({ children, type, ...rest }) {
  const classByType = type ? STYLE_LIST[type] : STYLE_LIST["primary"];
  return (
    <button className={`rounded-[10px] px-[1.375em] py-[0.75em] text-[0.8125em] ${classByType}`} {...rest}>
      {children}
    </button>
  );
}
