import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { toast } from "react-toastify";

import ContentLayout from "app/layouts/ContentLayout";
import PageError from "app/components/CommonPage/PageError";
import PageNoData from "app/components/CommonPage/PageNoData";
import PageLoading from "app/components/CommonPage/PageLoading";
import PageLoadingBackdrop from "app/components/CommonPage/PageLoadingBackdrop";
import TestScheduleHeader from "app/components/Table/Project/TestScheduleTableInfo/TestScheduleHeader";
import AddBtn from "app/components/UI/Button/AddBtn";
import Button from "app/components/UI/Button";

import routePaths from "app/route/routePaths";
import messages from "app/services/api/messages";
import CalendarSvg from "app/assets/svg/Calendar";
import { projectServices } from "app/services/api";
import { checkIsSameDate } from "app/utils";

import TrashSVG from "app/assets/svg/trash.svg";
import RestoreSVG from "app/assets/svg/restore.svg";

const testScheduleRowStatusKey = {
  new: "new",
  notUpdate: "notUpdate",
  updating: "updating",
  prepareToDelete: "prepareToDelete",
};

const testScheduleRowStatusStyle = {
  new: {
    backgroundColor: "#dcfce7",
  },
  notUpdate: {},
  updating: {
    backgroundColor: "#fef9c3",
  },
  prepareToDelete: {
    backgroundColor: "#fecaca",
  },
};

const tableConfig = [
  {
    title: "試験日",
    width: "19%",
    dataKey: "testDate",
  },
  {
    title: "報告書納品日",
    width: "19%",
    dataKey: "reportDeliveryDate",
  },
  {
    title: "検体納付期限",
    width: "19%",
    dataKey: "samplePaymentDeadline",
  },
  {
    title: "申込期限",
    width: "19%",
    dataKey: "applicationDeadline",
  },
  {
    title: "最大申込可能数",
    width: "19%",
    dataKey: "possibleApplicationNumber",
  },
  {
    title: "",
    width: "5%",
    isHeaderBlank: true
  },
];

function DateInputCell(props) {
  const { dateStr, onChangeDateStr, width, ...rest } = props;

  const value = useMemo(() => (dateStr ? moment(dateStr) : null), [dateStr]);
  const onChange = (newValue) => {
    if (newValue) onChangeDateStr(newValue.toISOString());
    else onChangeDateStr("");
  };

  return (
    <div style={{ width: width }}>
      <DatePicker
        slots={{
          openPickerIcon: CalendarSvg,
        }}
        slotProps={{
          textField: {
            sx: {
              "& .MuiInputBase-root": {
                height: "32px",
                borderRadius: "10px",
                fontSize: "13px",
                letterSpacing: "1.75px",
                fontWeight: "400",
              },
            },
          },
        }}
        format="YYYY年MM月DD日"
        value={value}
        onChange={onChange}
        {...rest}
      />
    </div>
  );
}

function NumberInputCell(props) {
  const { nummberValue, onChangeNumberValue, width, ...rest } = props;

  const value = useMemo(
    () => (!Number.isNaN(Number(nummberValue)) ? Number(nummberValue) : 0),
    [nummberValue]
  );

  return (
    <div
      style={{
        width: width,
      }}
    >
      <input
        value={`${value}`}
        type="number"
        onChange={(e) => {
          const inputValue = e.currentTarget.value;

          if (Number.isNaN(Number(inputValue)) || Number(inputValue) < 0)
            onChangeNumberValue(0);
          // else if (Number(inputValue) > 37) onChangeNumberValue(37);
          else onChangeNumberValue(Math.trunc(Number(inputValue)));
        }}
        className="w-full h-8 px-3 py-1 input-border-bg-colo text-center border border-[#B3B3B3] rounded-[10px] !outline-none bg-transparent"
        {...rest}
        min={0}
        // max={37}
        // style={
        //   value < 0 || value > 37
        //     ? {
        //         borderColor: "#f87171",
        //       }
        //     : {}
        // }
      />
    </div>
  );
}

function DeleteOrRestoreBtnCell(props) {
  const { width, onClick, isDelete } = props;
  return (
    <div
      style={{ width: width }}
      className="h-full flex items-center justify-center"
    >
      <button type="button" onClick={onClick} className="">
        <img alt="" src={isDelete ? RestoreSVG : TrashSVG} className="" />
      </button>
    </div>
  );
}

function UpdateTestScheduleListRow(props) {
  const {
    testSchedule,
    onChangeTestSchedule,
    initTestScheduleList,
    handleDeleteOrRestore,
  } = props;

  const rowStatus = useMemo(() => {
    if (!testSchedule?.id) return testScheduleRowStatusKey.new;
    if (testSchedule?.isDelete) return testScheduleRowStatusKey.prepareToDelete;

    const initTestSchedule = initTestScheduleList?.find(
      (item) => item.id === testSchedule.id
    );
    if (
      !checkIsSameDate(testSchedule?.testDate, initTestSchedule?.testDate) ||
      !checkIsSameDate(
        testSchedule?.reportDeliveryDate,
        initTestSchedule?.reportDeliveryDate
      ) ||
      !checkIsSameDate(
        testSchedule?.samplePaymentDeadline,
        initTestSchedule?.samplePaymentDeadline
      ) ||
      !checkIsSameDate(
        testSchedule?.applicationDeadline,
        initTestSchedule?.applicationDeadline
      ) ||
      testSchedule?.possibleApplicationNumber !==
        initTestSchedule?.possibleApplicationNumber
    )
      return testScheduleRowStatusKey.updating;

    return testScheduleRowStatusKey.notUpdate;
  }, [initTestScheduleList, testSchedule]);

  return (
    <div
      className="w-full h-[32px] flex"
      style={testScheduleRowStatusStyle[rowStatus]}
    >
      <DateInputCell
        width={tableConfig[0].width}
        dateStr={testSchedule?.[tableConfig[0].dataKey]}
        onChangeDateStr={(newValue) =>
          onChangeTestSchedule({
            ...testSchedule,
            [tableConfig[0].dataKey]: newValue,
          })
        }
      />
      <DateInputCell
        width={tableConfig[1].width}
        dateStr={testSchedule?.[tableConfig[1].dataKey]}
        onChangeDateStr={(newValue) =>
          onChangeTestSchedule({
            ...testSchedule,
            [tableConfig[1].dataKey]: newValue,
          })
        }
      />
      <DateInputCell
        width={tableConfig[2].width}
        dateStr={testSchedule?.[tableConfig[2].dataKey]}
        onChangeDateStr={(newValue) =>
          onChangeTestSchedule({
            ...testSchedule,
            [tableConfig[2].dataKey]: newValue,
          })
        }
      />
      <DateInputCell
        width={tableConfig[3].width}
        dateStr={testSchedule?.[tableConfig[3].dataKey]}
        onChangeDateStr={(newValue) =>
          onChangeTestSchedule({
            ...testSchedule,
            [tableConfig[3].dataKey]: newValue,
          })
        }
      />
      <NumberInputCell
        width={tableConfig[4].width}
        nummberValue={testSchedule?.[tableConfig[4].dataKey]}
        onChangeNumberValue={(newValue) =>
          onChangeTestSchedule({
            ...testSchedule,
            [tableConfig[4].dataKey]: newValue,
          })
        }
      />

      <DeleteOrRestoreBtnCell
        width={tableConfig[5].width}
        onClick={handleDeleteOrRestore}
        isDelete={testSchedule?.isDelete}
      />
    </div>
  );
}

function UpdateTestScheduleListTable(props) {
  const { initTestScheduleList, testScheduleList, setTestScheduleList } = props;

  const handleChangeTestSchedule = (index, newValue) => {
    const newTestScheduleList = [...testScheduleList];
    newTestScheduleList[index] = newValue;

    setTestScheduleList(newTestScheduleList);
  };

  const handleDeleteOrRestore = (deleteIndex) => {
    if (!testScheduleList[deleteIndex]?.id)
      setTestScheduleList(
        testScheduleList.filter((_, index) => index !== deleteIndex)
      );
    else {
      const newTestScheduleList = [...testScheduleList];
      newTestScheduleList[deleteIndex] = {
        ...testScheduleList[deleteIndex],
        isDelete: !testScheduleList[deleteIndex]?.isDelete,
      };
      setTestScheduleList(newTestScheduleList);
    }
  };

  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto overflow-x-auto overflow-y-hidden">
        <div className="w-full h-auto min-w-[944px]">
          <TestScheduleHeader tableConfig={tableConfig} />

          {Array.isArray(testScheduleList) && testScheduleList.length > 0 ? (
            testScheduleList.map((rowData, index) => (
              <UpdateTestScheduleListRow
                key={rowData?.id || rowData?.tempId}
                testSchedule={rowData}
                onChangeTestSchedule={(newValue) =>
                  handleChangeTestSchedule(index, newValue)
                }
                initTestScheduleList={initTestScheduleList}
                handleDeleteOrRestore={() => handleDeleteOrRestore(index)}
              />
            ))
          ) : (
            <PageNoData />
          )}
        </div>
      </div>
    </div>
  );
}

function UpdateTestScheduleList(props) {
  const { initTestScheduleList } = props;

  const [testScheduleList, setTestScheduleList] =
    useState(initTestScheduleList);
  const [isUpdating, setIsUpdating] = useState(false);

  const navigate = useNavigate();

  const handleAddTestSchedule = () => {
    const currentDate = moment();
    const datestr = currentDate.toISOString();

    const newTestSchedule = {
      tempId: uuidv4(),
      applicationDeadline: datestr,
      arrivedSampleNumber: 0,
      possibleApplicationNumber: 37,
      reportDeliveryDate: datestr,
      samplePaymentDeadline: datestr,
      testDate: datestr,
      undeliveredSampleNumber: 0,
    };
    setTestScheduleList((oldState) => [...oldState, newTestSchedule]);
  };

  const updateTestSchedule = () => {
    if (isUpdating) return;
    setIsUpdating(true);
    const data = testScheduleList
      .filter((item) => !item?.isDelete)
      .map((item) => ({ ...item, tempId: undefined }));

    projectServices
      .updateTestSchedule(data)
      .then((res) => {
        if (res.data.success) {
          toast.success(messages.update(true));
          navigate(routePaths.project);
        }
        setIsUpdating(false);
      })
      .catch((err) => {
        setIsUpdating(false);
        toast.error(messages.update(false));
      });
  };

  return (
    <>
      <div className="w-full h-auto">
        <div className="w-full h-10 flex items-center justify-between mb-3">
          <p className="text-custom-size whitespace-nowrap">試験スケジュール</p>
          <div className="h-auto">
            <Button onClick={updateTestSchedule}>新規登録</Button>
          </div>
        </div>

        <UpdateTestScheduleListTable
          initTestScheduleList={initTestScheduleList}
          testScheduleList={testScheduleList}
          setTestScheduleList={setTestScheduleList}
        />

        <div className="w-full h-auto flex items-center justify-end">
          <AddBtn
            className="mt-[6px] ml-auto"
            onClick={handleAddTestSchedule}
          />
        </div>
      </div>
      <PageLoadingBackdrop open={isUpdating} />
    </>
  );
}

function ProjectTestSchedulePage() {
  const [breadcrumb] = useOutletContext();

  const [loadInitState, setLoadInitState] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const loadInit = useCallback(() => {
    setLoadInitState({
      isLoading: true,
      data: null,
      error: null,
    });
    projectServices
      .getScheduleList()
      .then((res) => {
        setLoadInitState({
          isLoading: false,
          data: res.scheduleList,
          error: null,
        });
      })
      .catch((err) => {
        toast.error(messages.callApiFail);
        setLoadInitState({
          isLoading: false,
          scheduleList: null,
          error: err,
        });
      });
  }, []);

  useEffect(() => {
    loadInit();
  }, [loadInit]);

  return (
    <ContentLayout
      sideMenuLinkOptions={[
        { linkTo: routePaths.project, linkTitle: "試験スケジュール" },
        {
          linkTo: routePaths.projectTestSchedule,
          linkTitle: "スケジュールの登録",
        },
        { linkTo: routePaths.topMenu, linkTitle: "トップへ戻る" },
      ]}
      breadcrumb={breadcrumb}
    >
      <div className="w-full h-auto">
        {loadInitState.isLoading ? (
          <div className="w-full h-auto">
            <div className="w-full h-10 flex items-center justify-between">
              <p className="text-custom-size whitespace-nowrap">
                試験スケジュール
              </p>
              <div></div>
            </div>

            <PageLoading />
          </div>
        ) : null}

        {!loadInitState.isLoading && loadInitState.error ? (
          <div className="w-full h-auto">
            <div className="w-full h-10 flex items-center justify-between">
              <p className="text-custom-size whitespace-nowrap">
                試験スケジュール
              </p>
              <div></div>
            </div>

            <PageError />
          </div>
        ) : null}

        {!loadInitState.isLoading &&
        !loadInitState.error &&
        Array.isArray(loadInitState.data) ? (
          <div className="w-full h-full">
            <UpdateTestScheduleList initTestScheduleList={loadInitState.data} />
          </div>
        ) : null}
      </div>
    </ContentLayout>
  );
}

export default ProjectTestSchedulePage;
