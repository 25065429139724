import React from "react";
import { reportStatusLabel } from "../../../constants";
import CaseStatusCell from "../../../components/Table/Project/TestScheduleTableInfo/CaseStatusCell";
import InputInForm from "../../../components/UI/Form/Input/InputInForm";
import InputDate from "../../../components/UI/Form/Input/InputDate";

const Item = ({ data, index, formik }) => {
  const { values, errors, setFieldValue } = formik;
  const cellStyle = `flex items-center justify-center p-2 overflow-hidden whitespace-nowrap border border-[#B3B3B3]`;

  return (
    <React.Fragment>
      <div className={"border-l " + cellStyle}>{data.caseId}</div>
      <div className={cellStyle}>
        {reportStatusLabel[data.invoiceStatus]}
      </div>
      <div className={cellStyle}>
        {data?.customer?.customerId || ""}
      </div>
      <InputDate
        id="paymentDeadline"
        value={values?.[index]?.paymentDeadline}
        onChange={(newValue) => {
          setFieldValue(`${index}.paymentDeadline`, newValue);
        }}
        errors={errors?.[index]?.paymentDeadline}
        touched={true}
        isRequired
        isFormStyle
      />
      <InputInForm
        className={cellStyle}
        onChange={(newValue) => {
          setFieldValue(`${index}.memo`, newValue);
        }}
      />

      <CaseStatusCell
        cellContent={data.caseStatus}
        textAlign="center"
      />
    </React.Fragment>
  );
};

export default Item;
