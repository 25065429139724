import React from "react";

import { styled } from "@mui/material";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";

const PopupBody = styled("div")(
  ({ theme }) => `
  width: max-content;
  padding: 10px;
  // margin: 8px;
  border-radius: 8px;
  border: "2px solid red";
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  z-index: 1;
  box-shadow: 0 2px 1px ${
    theme.palette.mode === "dark"
      ? "rgba(0, 0, 0, 0.5)"
      : "rgba(45, 45, 60, 0.2)"
  };
  background-color: #EAE8D7

`
);

export default function Popup(props) {
  const { id, open, anchor, children } = props;
  return (
    <BasePopup id={id} open={open} anchor={anchor}>
      <PopupBody>{children}</PopupBody>
    </BasePopup>
  );
}
