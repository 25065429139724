import React, { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

export default function SearchField(props) {
  const { id, value, onChange, title, isBottom, ...rest } = props;

  const inputId = useMemo(() => {
    if (!id) {
      return uuidv4();
    }
    return id;
  }, [id]);

  const onChangeValue = (e) => {
    onChange(e.currentTarget.value);
  };

  return (
    <div
      className={`w-full h-auto flex items-center ${!isBottom ? "mb-4" : ""}`}
    >
      <label
        htmlFor="furigana"
        className="w-32 pr-6 shrink-0 h-full flex items-center justify-end"
      >
        {title}
      </label>

      <input
        id={inputId}
        type="text"
        value={value}
        onChange={onChangeValue}
        className="w-[calc(100%-128px)] h-[38px] py-1.5 px-3 rounded-lg border border-[#B3B3B3] text-custom-size leading-6 !outline-none"
        {...rest}
      />
    </div>
  );
}
