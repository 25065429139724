import React, { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";

function DropdownIndicator() {
  return (
    <div className="w-8 h-full rounded-r-[10px] bg-[#6C6C6C] flex items-center justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="15"
        fill="none"
        viewBox="0 0 18 15"
      >
        <path fill="#D9D9D9" d="M9 15L.34 0h17.32L9 15z"></path>
      </svg>
    </div>
  );
}

export default function ReactSelect({
  id,
  value,
  onChange,
  label,
  options,
  touched,
  setTouched,
  errors,
  labelWidth,
  placeholder,
  isRequired = false,
  isWhite = false,
  ...rest
}) {
  const inputId = useMemo(() => {
    if (!id) {
      return uuidv4();
    }
    return id;
  }, [id]);

  const customStyles = {
    control: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isWhite ? "#fff" : "rgba(179, 179, 179, 0.26)",
      border: isFocused ? "1px solid #B3B3B3" : "1px solid #9ca3af",
      borderRadius: "10px",
      boxShadow: "none",
      height: "52px",
    }),
    input: (styles) => ({
      ...styles,
      lineHeight: "24px",
      margin: 0,
      padding: 0,
    }),
  };

  const selectedOption = useMemo(() => {
    if (!Array.isArray(options)) return undefined;
    return options.find((item) => item.value === value);
  }, [value, options]);

  return (
    <>
      <div className="w-full h-[52px] flex items-center">
        {label ? (
          <label
            htmlFor={inputId}
            className="h-full pr-4 flex items-center"
            style={{
              width: `${labelWidth || 0}px`,
            }}
          >
            <span
              className="w-full text-sm leading-[22px] tracking-[.2em] whitespace-nowrap"
              style={{
                direction: "rtl",
              }}
            >
              {label}
            </span>
            {isRequired && <span style={{ color: "red" }}>*</span>}
          </label>
        ) : null}

        <div
          className={`h-full relative rounded-[10px] ${
            touched && errors
              ? "shadow-[0px_0px_2px_2px_#dc2626]"
              : ""
          }`}
          style={{
            width: `calc(100% - ${labelWidth || 0}px)`,
          }}
        >
          <Select
            id={id}
            className="basic-single background-['blue']"
            classNamePrefix="select"
            isSearchable={true}
            isClearable={false}
            options={options}
            styles={customStyles}
            value={selectedOption}
            components={{
              DropdownIndicator: DropdownIndicator,
              IndicatorSeparator: null,
            }}
            onChange={(newSelected) => {
              onChange(newSelected ? newSelected.value : undefined);
            }}
            {...rest}
            onMenuClose={setTouched}
            placeholder={placeholder || ""}
          />
        </div>
      </div>
    </>
  );
}
