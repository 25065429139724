import React from "react";
import InputText from "../Input/InputText";

function NameFieldGroup(props) {
  const {
    errors,
    values,
    setFieldValue,
    touched,
    handleBlur,
    fieldSuffix = "",
    labelWidth = 136,
    displaysReplacementValueSuffix,
  } = props;

  return (
    <div className="w-full h-auto">
      <InputText
        id={`lastNameKanji${fieldSuffix}`}
        value={
          displaysReplacementValueSuffix
            ? values[`lastNameKanji${displaysReplacementValueSuffix}`]
            : values[`lastNameKanji${fieldSuffix}`]
        }
        onChange={(newValue) =>
          setFieldValue(`lastNameKanji${fieldSuffix}`, newValue)
        }
        label="姓"
        labelWidth={labelWidth}
        handleBlur={handleBlur}
        disabled={displaysReplacementValueSuffix}
        placeholder="山田"
        errors={errors[`lastNameKanji${fieldSuffix}`]}
        touched={touched[`lastNameKanji${fieldSuffix}`]}
      />

      <InputText
        id={`firstNameKanji${fieldSuffix}`}
        value={
          displaysReplacementValueSuffix
            ? values[`firstNameKanji${displaysReplacementValueSuffix}`]
            : values[`firstNameKanji${fieldSuffix}`]
        }
        onChange={(newValue) =>
          setFieldValue(`firstNameKanji${fieldSuffix}`, newValue)
        }
        label="名"
        labelWidth={labelWidth}
        handleBlur={handleBlur}
        disabled={displaysReplacementValueSuffix}
        placeholder="太郎"
        errors={errors[`firstNameKanji${fieldSuffix}`]}
        touched={touched[`firstNameKanji${fieldSuffix}`]}
      />

      <InputText
        id={`lastNameKana${fieldSuffix}`}
        value={
          displaysReplacementValueSuffix
            ? values[`lastNameKana${displaysReplacementValueSuffix}`]
            : values[`lastNameKana${fieldSuffix}`]
        }
        onChange={(newValue) =>
          setFieldValue(`lastNameKana${fieldSuffix}`, newValue)
        }
        label="セイ"
        labelWidth={labelWidth}
        handleBlur={handleBlur}
        disabled={displaysReplacementValueSuffix}
        placeholder="やまだ"
        errors={errors[`lastNameKana${fieldSuffix}`]}
        touched={touched[`lastNameKana${fieldSuffix}`]}
      />

      <InputText
        id={`firstNameKana${fieldSuffix}`}
        value={
          displaysReplacementValueSuffix
            ? values[`firstNameKana${displaysReplacementValueSuffix}`]
            : values[`firstNameKana${fieldSuffix}`]
        }
        onChange={(newValue) =>
          setFieldValue(`firstNameKana${fieldSuffix}`, newValue)
        }
        label="メイ"
        labelWidth={labelWidth}
        handleBlur={handleBlur}
        disabled={displaysReplacementValueSuffix}
        placeholder="たろう"
        errors={errors[`firstNameKana${fieldSuffix}`]}
        touched={touched[`firstNameKana${fieldSuffix}`]}
      />
    </div>
  );
}

export default NameFieldGroup;
