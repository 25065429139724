import { caseStatusLabel, caseStatusBgColor } from "../../../../constants";
export default function CaseStatusCell(props) {
  const { cellContent, width, textAlign } = props;

  return (
    <div
      className={`h-full border border-[#B3B3B3] py-[5px] flex items-center`}
      style={{
        width: width,
        textAlign: textAlign || "start",
        backgroundColor: caseStatusBgColor[cellContent],
      }}
    >
      <p className="w-full truncate text-sm tracking-[1.75px]">
        {caseStatusLabel[cellContent]}
      </p>
    </div>
  );
}
