import { toast } from "react-toastify";

import { projectServices, commonServices } from "app/services/api";
import { caseStatus, quotationStatus } from "app/constants";
import messages from "../../services/api/messages";

const useCallApi = ({ setDisplayData, onClickReturn }) => {
  const getDownloadFile = async (id, fileName = "fileName") => {
    commonServices.downloadPDFWithFileId(id, fileName);
  };

  const updateQuotation = ({ id, index, changeValue }) => {
    projectServices
      .updateStatus({
        id,
        value: changeValue,
        statusType: "quotationStatus",
      })
      .then((res) => {
        onClickReturn();
        if (res.data?.result?.code === 400) {
          toast.error(res.data.result.msg);
          return;
        } else if (res.data?.result?.code === 500) {
          toast.error(res.data.result.msg);
          return;
        }
        setDisplayData((data) => {
          const updatedItem = {
            ...data[index],
            quotationStatus:
              changeValue === quotationStatus.submitted
                ? quotationStatus.submitted
                : quotationStatus.lost,
            caseStatus:
              changeValue === quotationStatus.lost
                ? caseStatus.lost
                : caseStatus.quotation,
          };
          const newData = [
            ...data.slice(0, index),
            updatedItem,
            ...data.slice(index + 1),
          ];
          toast.success(messages.update(true));

          return newData;
        });
      })
      .catch((err) => {
        toast.error(messages.update(false));
      });
  };

  const updateRqForm = ({ id, changeValue, data, index }) => {
    projectServices
      .updateStatus({
        id,
        value: changeValue,
        data,
        statusType: "requestFormStatus",
      })
      .then((res) => {
        onClickReturn();
        setDisplayData((data) => {
          toast.success(res.data);
          const updatedItem = {
            ...data[index],
            requestFormStatus: changeValue,
          };
          const newData = [
            ...data.slice(0, index),
            updatedItem,
            ...data.slice(index + 1),
          ];
          toast.success(messages.update(true));
          return newData;
        });
      })
      .catch((err) => {
        toast.error(messages.update(false));
      });
  };

  const updateSampleStatus = ({ id, changeValue, index }) => {
    projectServices
      .updateStatus({
        id,
        value: changeValue,
        statusType: "sampleStatus",
      })
      .then((res) => {
        onClickReturn();
        if (res.data?.result?.code === 400) {
          toast.error(res.data.result.msg);
          return;
        }
        setDisplayData((data) => {
          const updatedItem = {
            ...data[index],
            sampleStatus: changeValue,
          };
          const newData = [
            ...data.slice(0, index),
            updatedItem,
            ...data.slice(index + 1),
          ];
          toast.success(messages.update(true));
          return newData;
        });
      })
      .catch((err) => {
        toast.error(messages.update(false));
      });
  };

  const updateTestPlanStatus = ({ id, changeValue, index }) => {
    projectServices
      .updateStatus({
        id,
        value: changeValue,
        statusType: "testPlanStatus",
      })
      .then((res) => {
        onClickReturn();
        if (res.data?.result?.code === 400) {
          toast.error(res.data.result.msg);
          return;
        }
        setDisplayData((data) => {
          const updatedItem = {
            ...data[index],
            testPlanStatus: changeValue,
          };
          const newData = [
            ...data.slice(0, index),
            updatedItem,
            ...data.slice(index + 1),
          ];
          toast.success(messages.update(true));
          return newData;
        });
      })
      .catch((err) => {
        toast.error(messages.update(false));
      });
  };

  const updateInvoiceStatus = ({ id, changeValue, index }) => {
    projectServices
      .updateStatus({
        id,
        value: changeValue,
        statusType: "invoiceStatus",
      })
      .then((res) => {
        onClickReturn();
        if (res.data?.result?.code === 400) {
          toast.error(res.data.result.msg);
          return;
        }
        setDisplayData((data) => {
          const updatedItem = {
            ...data[index],
            invoiceStatus: changeValue,
          };
          const newData = [
            ...data.slice(0, index),
            updatedItem,
            ...data.slice(index + 1),
          ];
          toast.success(messages.update(true));
          return newData;
        });
      })
      .catch((err) => {
        toast.error(messages.update(false));
      });
  };

  return {
    updateQuotation,
    updateRqForm,
    updateSampleStatus,
    updateTestPlanStatus,
    updateInvoiceStatus,
    getDownloadFile,
  };
};

export default useCallApi;
