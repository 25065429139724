import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import PencilSVG from "app/assets/svg/pencil.svg";

export default function EditBtn(props) {
  const { onClick, width, style, showInput } = props;
  return (
    <button
      onClick={onClick}
      style={{
        width: width,
        display: "flex",
        alignItems: "center",
        ...style,
      }}
    >
      {showInput ? (
        <FontAwesomeIcon icon={faCheck} size="lg" />
      ) : (
        <img src={PencilSVG} className="" alt="" />
      )}
    </button>
  );
}
