// FE-1
import React from "react";
import BlankAvatar from "app/assets/images/blank_avatar.png";
import InfiniteScroll from "../../../components/UI/InfiniteScroll";

function ContentLayout({
  children,
  listContact,
  onSelect,
  selectingId,
  onLoadMore,
  hasMore,
}) {
  return (
    <div className="absolute top-0 mt-[136px] pb-8 w-full h-[calc(100vh-136px)]">
      <div className="custom-container h-full">
        <div className="w-full h-full relative md:pl-[259px]">
          {/* Side Menu Content */}
          <div className="hidden md:block absolute inset-y-0 left-0 w-[259px]  mt-10 pb-14 border border-[#001C4F]">
            <div className="w-full text-center py-2 bg-[#001C4F] text-white">
              チャットリスト
            </div>
            <div className="w-full h-full py-5 overflow-hidden">
              <InfiniteScroll
                list={listContact}
                onLoadMore={onLoadMore}
                offset={50}
                hasMore={hasMore}
                render={({ info, _id }) => {
                  return (
                    <div
                      className={`px-[16px] py-1 cursor-pointer ${
                        selectingId === _id && "bg-[#EBEBEB]"
                      }`}
                      key={_id}
                    >
                      <div
                        onClick={() => {
                          onSelect(_id);
                        }}
                        className={`border-b border-[#001C4F]   flex gap-4 py-4`}
                      >
                        <img
                          className="h-[40px] w-[40px] object-cover rounded-full"
                          src={BlankAvatar}
                          alt=""
                        />
                        <div className="line-clamp-2">
                          {info?.firstNameKanji + info?.lastNameKanji}
                        </div>
                      </div>
                    </div>
                  );
                }}
              />
            </div>
          </div>
          {/* Main Content */}
          <div className="w-full h-full pl-[36px] pt-10 flex flex-col">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContentLayout;
