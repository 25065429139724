const validateMessages = {
  required: (fieldName) => `${fieldName}は必須です。`,
  maxLength: (fieldName, length) =>
    `${fieldName}は ${length}文字以下で入力してください。`,
  minLength: (fieldName, length) =>
    `${fieldName}は ${length}文字以上で入力してください。`,
  emailWrongFormat: "メールアドレスの情報が正しくありません。",
};

export default validateMessages;
