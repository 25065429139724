import client from "./client";

const getById = (id) => {
  return client
    .get("/invoice-info/get-by-id", {
      params: {
        id,
      },
    })
    .then((res) => {
      if (res.data?.success) {
        const result = res.data.result;
        return {
          data: result
            ? {
                id: result._id,
                paymentDeadline: result.paymentDeadline,
                memo: result.memo,
                customerPic: result.personInCharge,
                invoiceItemInfos: Array.isArray(result.items)
                  ? result.items.map((item) => ({
                      name: item?.name || "",
                      contractType: item?.contractType || "",
                      volumn: item?.volumn || "",
                      testPeopleNumber: item?.testPeopleNumber || 0,
                      testSampleNumber: item?.testSampleNumber || 0,
                      unitPrice: item?.unitPrice || 0,
                      totalPrice: item?.totalPrice || 0,
                    }))
                  : [],

                generatedInvoicePdf: result?.generatedInvoicePdf,
                generatedInvoiceExcel: result?.generatedInvoiceExcel,
                generatedDeliverySlipPdf:
                  result?.generatedDeliverySlipPdf,
                generatedDeliverySlipExcel:
                  result?.generatedDeliverySlipExcel,
              }
            : null,
        };
      }

      throw new Error("Malformed data!");
    });
};

const getInitNewInvoice = (customerId, quotationInfoId) => {
  return Promise.all([
    client.get("/quotation-info/get-by-id", {
      params: { id: quotationInfoId },
    }),
    client.get(`/customer-info/get?id=${customerId}`),
  ]).then((res) => {
    if (res[0].data?.success && res[1].data?.success) {
      const quotation = res[0].data.result;
      const customerInfo = res[1].data.result;
      const paymentDeadlineList = Array.isArray(
        quotation?.paymentDeadline
      )
        ? quotation?.paymentDeadline.filter((item) => item)
        : [];
      return {
        quotationId: quotation?.quotationId,
        customerPic: customerInfo?.pic?._id,
        paymentDeadline: paymentDeadlineList[0],
        paymentDeadlineList: paymentDeadlineList,
        invoiceItemInfos: Array.isArray(quotation?.quotation_tasks)
          ? quotation?.quotation_tasks.map((item) => ({
              name: item?.name || "",
              contractType: item?.contractType || "",
              volumn: item?.volumn,
              testPeopleNumber: item?.inspectionPeopleNumber || 0,
              testSampleNumber: item?.testSampleNumber || 0,
              unitPrice: item?.quotationPrice || 0,
              totalPrice:
                Number(item?.testSampleNumber) *
                Number(item?.quotationPrice),
            }))
          : [],
        generatedInvoicePdf: "",
        generatedInvoiceExcel: "",
        generatedDeliverySlipPdf: "",
        generatedDeliverySlipExcel: "",
      };
    }
    throw new Error("Malformed data!");
  });
};

const createInvoice = (formData) => {
  return client
    .post("/invoice-info/create", {
      caseInfoId: formData.caseInfoId,
      customerId: formData.customerId,
      testScheduleId: formData.testScheduleId,
      personInCharge: formData.personInCharge,
      memo: formData.memo || "",
      paymentDeadline: formData.paymentDeadline,
      invoiceItems: Array.isArray(formData.invoiceItemInfos)
        ? formData.invoiceItemInfos.map((item) => ({
            name: item.name,
            contractType: item.contractType,
            volumn: item.volumn,
            testPeopleNumber: item.testPeopleNumber,
            testSampleNumber: item.testSampleNumber,
            unitPrice: item.unitPrice,
            totalPrice: item.totalPrice,
          }))
        : [],
      generatedInvoicePdf: formData.generatedInvoicePdf,
      generatedInvoiceExcel: formData.generatedInvoiceExcel,
      generatedDeliverySlipPdf: formData.generatedDeliverySlipPdf,
      generatedDeliverySlipExcel: formData.generatedDeliverySlipExcel,
      isUpdate: formData.isUpdate,
    })
    .then((res) => {
      if (res.data?.success)
        return {
          data: res.data.result ? { id: res.data.result?._id } : null,
        };
      throw new Error("Malformed data!");
    });
};

const createFileDownload = (formData) => {
  return client
    .post("/invoice-info/create-file-download", {
      caseInfoId: formData.caseInfoId,
      customerId: formData.customerId,
      testScheduleId: formData.testScheduleId,
      personInCharge: formData.personInCharge,
      memo: formData.memo,
      paymentDeadline: formData.paymentDeadline,
      invoiceItems: Array.isArray(formData.invoiceItemInfos)
        ? formData.invoiceItemInfos.map((item) => ({
            name: item.name,
            contractType: item.contractType,
            volumn: item.volumn,
            testPeopleNumber: item.testPeopleNumber,
            testSampleNumber: item.testSampleNumber,
            unitPrice: item.unitPrice,
            totalPrice: item.totalPrice,
          }))
        : [],
    })
    .then((res) => {
      if (res.data?.success)
        return {
          data: res.data.result,
        };
      throw new Error("Malformed data!");
    });
  // return clientFormData.post("/invoice-info/create-file-download", data);
};

const invoiceServices = {
  getById,
  getInitNewInvoice,
  createInvoice,
  createFileDownload,
};

export default invoiceServices;
