import { DEFAULT_ITEM_PER_PAGE } from "app/constants";
import Apis from "./Path";
import client from "./client";

const search = (page, searchParams) => {
  return client
    .get("/customer-info/all", {
      params: {
        page: page,
        perPage: DEFAULT_ITEM_PER_PAGE,
        customerId: searchParams?.customerId,
        companyNameKana: searchParams?.companyNameKana,
        companyNameKanji: searchParams?.companyNameKanji,
      },
    })
    .then((res) => {
      if (
        res.data?.success &&
        res.data?.result &&
        Array.isArray(res.data?.result.rows)
      )
        return {
          data: {
            rows: res.data?.result.rows.map((item) => ({
              id: item._id,
              companyNameKanji: item.companyNameKanji,
              companyNameKana: item.companyNameKana,
              customerId: item.customerId,
              email: item.email,
              userAccount: item.userAccount,
            })),
            currentPage: Number(res.data.result?.page),
            totalItems: Number(res.data.result?.total),
            totalPages: Number(res.data.result?.totalPages),
          },
        };
      throw new Error("Malformed data!");
    });
};

const getDetail = (id) => {
  return client.get(`/customer-info/get?id=${id}`).then((res) => {
    if (res.data?.success && res.data?.result)
      return {
        data: {
          id: res.data.result?._id,
          companyNameKanji: res.data.result?.companyNameKanji,
          companyNameKana: res.data?.result?.companyNameKana,
          customerId: res.data.result?.customerId,
          email: res.data.result.email,
          password: res.data.result?.password,
          postalCode: res.data.result?.postalCode,
          prefecture: res.data.result?.prefecture,
          address1: res.data.result?.address1,
          address2: res.data.result?.address2,
          address3: res.data.result?.address3,
          phone1: res.data.result?.phone1,
          phone2: res.data.result?.phone2,
          userAccount: res.data.result?.userAccount,
          billing: res.data.result?.billing
            ? {
                id: res.data.result?.billing?._id,
                ...res.data.result?.billing,
              }
            : null,
          pic: res.data.result?.pic
            ? {
                id: res.data.result?.pic?._id,
                ...res.data.result?.pic,
              }
            : null,
          quotationBasics: Array.isArray(
            res.data.result?.quotation_basics
          )
            ? res.data.result?.quotation_basics
            : [],
          contracts: Array.isArray(
            res.data.result?.customer_contracts
          )
            ? res.data.result?.customer_contracts.map((item) => ({
                id: item._id,
                contractId: item.contractId,
                name: item.name,
                contractDate: item.contractDate,
                progress: item.progress,
                generatedWord: item.generatedWord,
                contractCategory: item.contractCategory,
              }))
            : [],
          accountInfos: res.data.result?.accountInfos,
        },
      };
    throw new Error("Malformed data!");
  });
};

const updateInfo = (data) => {
  return client.post("/customer-info/create", data).then((res) => {
    if (res.data?.success && res.data?.result)
      return {
        data: {
          id: res.data.result?._id,
        },
      };
    throw new Error("Malformed data!");
  });
};

const deleteCustomer = (id) => {
  return client.delete(`/customer-info/delete?id=${id}`);
};

const getAllCustomerId = () => {
  return client.get("/customer-info/all-with-field").then((res) => {
    if (res.data?.success && Array.isArray(res.data?.result))
      return {
        data: res.data?.result.map((item) => ({
          id: item._id,
          companyNameKanji: item.companyNameKanji,
          companyNameKana: item.companyNameKana,
          customerId: item.customerId,
        })),
      };
    throw new Error("Malformed data!");
  });
};

const getAllPic = () => {
  return client.get("/pic-info/all").then((res) => {
    if (res.data?.success && Array.isArray(res.data?.result))
      return {
        data: res.data?.result.map((item) => ({
          id: item._id,
          postalCode: item.postalCode,
          prefecture: item.prefecture,
          address1: item.address1,
          address2: item.address2,
          address3: item.address3,
          department: item.department,
          lastNameKanji: item.lastNameKanji,
          firstNameKanji: item.firstNameKanji,
          lastNameKana: item.lastNameKana,
          firstNameKana: item.firstNameKana,
          phone1: item.phone1,
          phone2: item.phone2,
          fax: item.fax,
          email: item.email,
        })),
      };
    throw new Error("Malformed data!");
  });
};

const getCaseList = async (customerId, page) => {
  return client.get(Apis.getCaseListByCustomerId, {
    params: {
      customerId,
      page: Number(page),
      perPage: DEFAULT_ITEM_PER_PAGE,
    },
  });
};

const customerServices = {
  search,
  getDetail,
  updateInfo,
  deleteCustomer,
  getCaseList,
  getAllCustomerId,
  getAllPic,
};
export default customerServices;
