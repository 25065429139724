// FE-1
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import ContentLayout from "app/layouts/ContentLayout";
import SearchField from "app/components/UI/Form/SearchField";
import CustomerSearchResult from "app/components/Table/Customer/CustomerSearchResult";
import PageLoading from "app/components/CommonPage/PageLoading";
import PageError from "app/components/CommonPage/PageError";
import PageNoData from "app/components/CommonPage/PageNoData";
import CustomPagination from "app/components/UI/Pagination";
import { customerServices } from "app/services/api";
import routePaths from "app/route/routePaths";
import { useOutletContext } from "react-router-dom";
import messages from "../../services/api/messages";

const initSearchValue = {
  companyNameKana: "",
  companyNameKanji: "",
  customerId: "",
};

function CustomerPage() {
  const [breadcrumb] = useOutletContext();

  const [searchValue, setSearchValue] = useState(initSearchValue);

  const [loadInitState, setLoadInitState] = useState({
    isLoading: false,
    data: null,
    error: null,
  });
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalItems: 0,
    totalPages: 0,
  });

  const loadInit = useCallback((page, searchParams) => {
    setLoadInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    customerServices
      .search(page, searchParams)
      .then((res) => {
        setPagination({
          currentPage: res.data.currentPage,
          totalItems: res.data.totalItems,
          totalPages: res.data.totalPages,
        });

        setLoadInitState({
          isLoading: false,
          data: res.data.rows,
          error: null,
        });
      })
      .catch((error) => {
        toast.error(messages.callApiFail);
        setLoadInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, []);

  useEffect(() => {
    loadInit(1, initSearchValue);
  }, [loadInit]);

  const handleResetPagination = () =>
    setPagination({
      currentPage: 1,
      totalItems: 0,
      totalPages: 0,
    });

  const handleSearch = () => {
    handleResetPagination();
    loadInit(1, searchValue);
  };

  const handleResetSearch = () => {
    handleResetPagination();
    setSearchValue(initSearchValue);
    loadInit(1, initSearchValue);
  };

  const handleChangePage = (newPage) => {
    setPagination((oldState) => ({
      ...oldState,
      currentPage: newPage,
    }));
    loadInit(newPage, initSearchValue);
  };

  const updateSearchValue = (field, value) => {
    setSearchValue((oldValue) => ({ ...oldValue, [field]: value }));
  };

  return (
    <ContentLayout
      sideMenuLinkOptions={[
        { linkTo: "/customer", linkTitle: "顧客情報の検索" },
        { linkTo: "/customer/create", linkTitle: "顧客情報の登録" },
        { linkTo: routePaths.topMenu, linkTitle: "トップへ戻る" },
      ]}
      breadcrumb={breadcrumb}
    >
      <div className="w-full grow flex flex-col">
        <div className="w-full h-auto text-custom-size-sm md:text-custom-size mb-9">
          <p>顧客情報の検索を行うことができます。</p>
          <p>詳細を確認する場合は検索結果の名前をクリックしてください。</p>
          <p>※空欄で検索するとすべての情報が出てきます</p>
        </div>

        <div className="w-full h-auto flex items-end mb-[56px] flex-wrap">
          <div className="w-full md:w-[calc(100%-270px)]">
            <SearchField
              id="companyNameKana"
              value={searchValue.companyNameKana}
              onChange={(newValue) =>
                updateSearchValue("companyNameKana", newValue)
              }
              title="フリガナ"
              placeholder="フェイスリサーチ"
            />

            <SearchField
              id="companyNameKanji"
              value={searchValue.companyNameKanji}
              onChange={(newValue) =>
                updateSearchValue("companyNameKanji", newValue)
              }
              title="会社名"
              placeholder="フェイスリサーチ"
            />

            <SearchField
              id="customerId"
              value={searchValue.customerId}
              onChange={(newValue) => updateSearchValue("customerId", newValue)}
              title="お客様ID"
              placeholder="ABC12345"
              isBottom
            />
          </div>
          <div className="w-full md:w-[270px] flex justify-end items-end gap-7 mt-4 md:mt-0">
            <button
              type="button"
              onClick={handleResetSearch}
              className="w-24 h-[38px] rounded-[10px] flex items-center justify-center bg-[#CC1C00]"
            >
              <span className="text-white text-custom-size">クリア</span>
            </button>
            <button
              type="button"
              onClick={handleSearch}
              className="w-24 h-[38px] rounded-[10px] flex items-center justify-center bg-[#001C4F]"
            >
              <span className="text-white text-custom-size">検索する</span>
            </button>
          </div>
        </div>

        <div className="w-full h-full grow relative min-h-[300px]">
          <div className="absolute inset-0 w-full h-full">
            <div className="w-full h-full flex flex-col flex-nowrap">
              <h3 className="shrink-0 text-custom-size mb-[14px]">検索結果</h3>

              <div className="w-full h-full grow shrink overflow-hidden">
                {loadInitState.isLoading ? <PageLoading /> : null}

                {!loadInitState.isLoading && loadInitState.error ? (
                  <PageError />
                ) : null}

                {!loadInitState.isLoading &&
                !loadInitState.error &&
                Array.isArray(loadInitState.data) ? (
                  loadInitState.data.length > 0 ? (
                    <CustomerSearchResult customerList={loadInitState.data} />
                  ) : (
                    <PageNoData noDataMessage="データなし!" noDataAction />
                  )
                ) : null}
              </div>

              <div className="shrink-0">
                {pagination.totalItems > 0 ? (
                  <CustomPagination
                    currentPage={pagination.currentPage}
                    totalItems={pagination.totalItems}
                    handleChangePage={handleChangePage}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContentLayout>
  );
}

export default CustomerPage;
