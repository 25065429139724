import { useEffect, useState } from "react";
import notificationServices from "../services/api/notificationServices";
import { urlBase64ToUint8Array, windowShowNotification } from "../utils";
import { getCurrentUserId } from "../services/socket";
import { PUBLIC_VAPID_KEY } from "../constants";

export default function useServiceWorker() {
  const [msgPayload, setMsgPayload] = useState([]);

  const swUrl = `${process.env.PUBLIC_URL}/sw.js`;
  const destroySwUrl = `${process.env.PUBLIC_URL}/destroySw.js`;

  const unregister = () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistration(`${process.env.PUBLIC_URL}/sw.js`).then((registration) => {
        registration.unregister();
        navigator.serviceWorker.register(destroySwUrl);
      });
      notificationServices.deleteSubscription(getCurrentUserId());
    }
  };

  const register = (url) => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register(url);

      const sw = window.navigator.serviceWorker;
      sw.addEventListener("message", ({ data }) => {
        const { event, payload } = data || {};

        switch (event) {
          case "activate":
            navigator.serviceWorker.getRegistration(`${process.env.PUBLIC_URL}/sw.js`).then((registration) => {
              registration.pushManager
                .subscribe({
                  userVisibleOnly: true,
                  applicationServerKey: urlBase64ToUint8Array(PUBLIC_VAPID_KEY),
                })
                .then((res) => {
                  notificationServices.saveSubscription(res);
                });
            });

            break;
          case "push":
            setMsgPayload((prev) => [...prev, JSON.parse(payload)]);
            windowShowNotification("You have new notification");
            break;

          default:
            break;
        }
      });
    }
  };

  useEffect(() => {
    windowShowNotification();
    register(swUrl);
  }, []);

  return { msgPayload, unregister, register };
}
