import { toast } from "react-toastify";
import client from "./client";
import Apis from "./Path";
import store from "app/redux/store";
import axios from "axios";
import messages from "./messages";

const verifyToken = async (callbackFailed) => {
  return client
    .get(Apis.verifyToken)
    .then((res) => console.log("VERIFY TOKEN SUCCESS RES", res))
    .catch((err) => {
      console.log("VERIFY TOKEN FAILED ERR", err);
      typeof callbackFailed === "function" && callbackFailed();
    });
};

const getAllUserInfo = async () => {
  return client.get(Apis.allUserInfo).then((res) => {
    if (res.data?.success && res.data?.result) {
      return {
        data: res.data.result,
      };
    }
    throw new Error("Malformed data!");
  });
};

const getAllPersonInCharge = async () => {
  return client.get(Apis.getAllPIC).then((res) => {
    if (res.data?.success && res.data?.result) {
      return {
        data: res.data.result,
      };
    }
    throw new Error("Malformed data!");
  });
};

const downloadWithFileId = async (id, fileType, filename = "filename") => {
  const downloadClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 30000,
    responseType: "arraybuffer",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: store.getState()?.auth.token?.accessToken || "",
    },
  });
  return downloadClient.get(Apis.getFileById(id)).then((res) => {
    if (!res.data || res.data.length === 0 || !!res.headers.error) return res;
    var mimeType = "application/octet-stream";
    const name = `${filename}.${fileType}`;
    var blob = new Blob([res.data], { type: mimeType });

    var a = document.createElement("a");
    a.download = name;
    a.target = "_blank";

    if (window.navigator.msSaveBlob) {
      // for IE
      window.navigator.msSaveBlob(blob, name);
    } else if (window.URL && window.URL.createObjectURL) {
      // for Firefox
      a.href = window.URL.createObjectURL(blob);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else if (window.webkitURL && window.webkitURL.createObject) {
      // for Chrome
      a.href = window.webkitURL.createObjectURL(blob);
      a.click();
    } else {
      // for Safari
      window.open(
        "data:" + mimeType + ";base64," + window.btoa(res.data),
        "_blank"
      );
    }
  });
};

const downloadWithFilePath = (path, fileType = "pdf") => {
  const downloadClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 30000,
    responseType: "arraybuffer",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: store.getState()?.auth.token?.accessToken || "",
    },
  });
  return downloadClient.get(Apis.downloadFileByPath, {
    params: {
      path,
    },
  });
};

const downloadPDFWithFileId = async (id, filename = "filename") => {
  const downloadClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 30000,
    responseType: "arraybuffer",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: store.getState()?.auth.token?.accessToken || "",
    },
  });
  return downloadClient
    .get(`/download/download-pdf-with-file-id?fileId=${id}`)
    .then((res) => {
      if (!res.data || res.data.length === 0 || !!res.headers.error) return res;
      var mimeType = "application/octet-stream";
      const name = `${filename}.pdf`;
      var blob = new Blob([res.data], { type: mimeType });

      var a = document.createElement("a");
      a.download = name;
      a.target = "_blank";

      if (window.navigator.msSaveBlob) {
        // for IE
        window.navigator.msSaveBlob(blob, name);
      } else if (window.URL && window.URL.createObjectURL) {
        // for Firefox
        a.href = window.URL.createObjectURL(blob);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else if (window.webkitURL && window.webkitURL.createObject) {
        // for Chrome
        a.href = window.webkitURL.createObjectURL(blob);
        a.click();
      } else {
        // for Safari
        window.open(
          "data:" + mimeType + ";base64," + window.btoa(res.data),
          "_blank"
        );
      }
    })
    .catch((e) => {
      toast.error(messages.common_error);
    });
};

const commonServices = {
  getAllPersonInCharge,
  downloadWithFileId,
  getAllUserInfo,
  downloadWithFilePath,
  downloadPDFWithFileId,
  verifyToken
};

export default commonServices;
