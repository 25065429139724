import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import AddressFieldGroup from "app/components/UI/Form/AddressFieldGroup";
import InputText from "app/components/UI/Form/Input/InputText";
import NameFieldGroup from "app/components/UI/Form/NameFieldGroup";
import PhoneAndFaxFieldGroup from "app/components/UI/Form/PhoneAndFaxFieldGroup";
import EstimateBasicInformation from "app/components/Table/Customer/EstimateBasicInformation";
import SelectPopover from "app/components/UI/Form/Select/SelectPopover";
import { billingTypeOptions } from "app/constants";
import messages from "../../services/api/messages";
import validateMessages from "../../validateMessages";
import "app/utils/yup-custom";

const labelWidth = 146;

const blankCustomerInfo = {
  // Additional information for customers
  postalCode: "",
  prefecture: "",
  address1: "",
  address2: "",
  address3: "",
  phone1: "",
  phone2: "",

  // Additional PIC
  postalCodePIC: "",
  prefecturePIC: "",
  address1PIC: "",
  address2PIC: "",
  address3PIC: "",
  departmentPIC: "",
  lastNameKanjiPIC: "",
  firstNameKanjiPIC: "",
  lastNameKanaPIC: "",
  firstNameKanaPIC: "",
  phone1PIC: "",
  phone2PIC: "",
  faxPIC: "",
  emailPIC: "",

  // Additional BillingInfo
  postalCodeBillingInfo: "",
  prefectureBillingInfo: "",
  address1BillingInfo: "",
  address2BillingInfo: "",
  address3BillingInfo: "",
  departmentBillingInfo: "",
  lastNameKanjiBillingInfo: "",
  firstNameKanjiBillingInfo: "",
  lastNameKanaBillingInfo: "",
  firstNameKanaBillingInfo: "",
  phone1BillingInfo: "",
  phone2BillingInfo: "",
  faxBillingInfo: "",
  emailBillingInfo: "",
  paymentTermsBillingInfo: billingTypeOptions[0].value,
};

function SelectPaymentTerms(props) {
  const { selectedValue, setSelectedValue } = props;
  return (
    <div className="w-full h-[52px] flex items-center">
      <label
        className="h-full pr-4 flex items-center"
        style={{
          width: `${labelWidth || 0}px`,
        }}
      >
        <span
          className="w-full text-sm leading-[22px] tracking-[.2em] whitespace-nowrap"
          style={{
            direction: "rtl",
          }}
        >
          支払い条件
        </span>
      </label>

      <div
        className="h-full relative"
        style={{
          width: `calc(100% - ${labelWidth}px)`,
        }}
      >
        <SelectPopover
          options={billingTypeOptions}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          customerSelectedLabelStyle={{
            width: "100%",
            textAlign: "start",
          }}
        />
      </div>
    </div>
  );
}

const CustomerInfoForm = forwardRef(function CustomerInfoForm(props, ref) {
  const { customerBaseInfo, handleSubmitForm } = props;

  const [quotationBasics, setQuotationBasics] = useState(
    Array.isArray(customerBaseInfo?.quotationBasics)
      ? customerBaseInfo?.quotationBasics.map((item) => ({
          tempId: uuidv4(),
          ...item,
        }))
      : []
  );
  const [usePICAsBillingInfo, setUsePICAsBillingInfo] = useState(false);

  const validationSchema = Yup.object().shape({
    // Additional information for customers
    postalCode: Yup.string(),
    prefecture: Yup.string(),
    address1: Yup.string(),
    address2: Yup.string(),
    address3: Yup.string().required(messages.required("ビル・部屋番号など")),
    phone1: Yup.string(),
    phone2: Yup.string(),
    email: Yup.string().mailNest(),

    // Additional PIC
    postalCodePIC: Yup.string(),
    prefecturePIC: Yup.string(),
    address1PIC: Yup.string(),
    address2PIC: Yup.string(),
    address3PIC: Yup.string(),
    departmentPIC: Yup.string(),
    lastNameKanjiPIC: Yup.string(),
    firstNameKanjiPIC: Yup.string(),
    lastNameKanaPIC: Yup.string(),
    firstNameKanaPIC: Yup.string(),
    phone1PIC: Yup.string(),
    phone2PIC: Yup.string(),
    faxPIC: Yup.string(),
    emailPIC: Yup.string().mailNest(),

    // Additional BillingInfo
    postalCodeBillingInfo: Yup.string(),
    prefectureBillingInfo: Yup.string(),
    address1BillingInfo: Yup.string(),
    address2BillingInfo: Yup.string(),
    address3BillingInfo: Yup.string(),
    departmentBillingInfo: Yup.string(),
    lastNameKanjiBillingInfo: Yup.string(),
    firstNameKanjiBillingInfo: Yup.string(),
    lastNameKanaBillingInfo: Yup.string(),
    firstNameKanaBillingInfo: Yup.string(),
    phone1BillingInfo: Yup.string(),
    phone2BillingInfo: Yup.string(),
    faxBillingInfo: Yup.string(),
    emailBillingInfo: Yup.string().mailNest(),
    paymentTermsBillingInfo: Yup.string(),
  });

  const initialValues = useMemo(
    () =>
      customerBaseInfo?.id
        ? {
            postalCode: customerBaseInfo.postalCode || "",
            prefecture: customerBaseInfo.prefecture || "",
            address1: customerBaseInfo.address1 || "",
            address2: customerBaseInfo.address2 || "",
            address3: customerBaseInfo.address3 || "",
            phone1: customerBaseInfo.phone1 || "",
            phone2: customerBaseInfo.phone2 || "",

            // Additional PIC
            postalCodePIC: customerBaseInfo.pic?.postalCode || "",
            prefecturePIC: customerBaseInfo.pic?.prefecture || "",
            address1PIC: customerBaseInfo.pic?.address1 || "",
            address2PIC: customerBaseInfo.pic?.address2 || "",
            address3PIC: customerBaseInfo.pic?.address3 || "",
            departmentPIC: customerBaseInfo.pic?.department || "",
            lastNameKanjiPIC: customerBaseInfo.pic?.lastNameKanji || "",
            firstNameKanjiPIC: customerBaseInfo.pic?.firstNameKanji || "",
            lastNameKanaPIC: customerBaseInfo.pic?.lastNameKana || "",
            firstNameKanaPIC: customerBaseInfo.pic?.firstNameKana || "",
            phone1PIC: customerBaseInfo.pic?.phone1 || "",
            phone2PIC: customerBaseInfo.pic?.phone2 || "",
            faxPIC: customerBaseInfo.pic?.fax || "",
            emailPIC: customerBaseInfo.pic?.email || "",

            // Additional BillingInfo
            postalCodeBillingInfo: customerBaseInfo.billing?.postalCode || "",
            prefectureBillingInfo: customerBaseInfo.billing?.prefecture || "",
            address1BillingInfo: customerBaseInfo.billing?.address1 || "",
            address2BillingInfo: customerBaseInfo.billing?.address2 || "",
            address3BillingInfo: customerBaseInfo.billing?.address3 || "",
            departmentBillingInfo: customerBaseInfo.billing?.address3 || "",
            lastNameKanjiBillingInfo:
              customerBaseInfo.billing?.lastNameKanji || "",
            firstNameKanjiBillingInfo:
              customerBaseInfo.billing?.firstNameKanji || "",
            lastNameKanaBillingInfo:
              customerBaseInfo.billing?.lastNameKana || "",
            firstNameKanaBillingInfo:
              customerBaseInfo.billing?.firstNameKana || "",
            phone1BillingInfo: customerBaseInfo.billing?.phone1 || "",
            phone2BillingInfo: customerBaseInfo.billing?.phone2 || "",
            faxBillingInfo: customerBaseInfo.billing?.fax || "",
            emailBillingInfo: customerBaseInfo.billing?.email || "",
            paymentTermsBillingInfo:
              customerBaseInfo.billing?.paymentTerms ||
              billingTypeOptions[0].value,
          }
        : blankCustomerInfo,
    [customerBaseInfo]
  );

  const onSubmit = async (values) => {
    const formData = {
      postalCode: values.postalCode,
      prefecture: values.prefecture,
      address1: values.address1,
      address2: values.address2,
      address3: values.address3,
      phone1: values.phone1,
      phone2: values.phone2,

      // Additional PIC
      pic: {
        postalCode: values.postalCodePIC,
        prefecture: values.prefecturePIC,
        address1: values.address1PIC,
        address2: values.address2PIC,
        address3: values.address3PIC,
        department: values.departmentPIC,
        lastNameKanji: values.lastNameKanjiPIC,
        firstNameKanji: values.firstNameKanjiPIC,
        lastNameKana: values.lastNameKanaPIC,
        firstNameKana: values.firstNameKanaPIC,
        phone1: values.phone1PIC,
        phone2: values.phone2PIC,
        fax: values.faxPIC,
        email: values.emailPIC,
      },
    };

    if (usePICAsBillingInfo) {
      formData.billing = {
        ...formData.pic,
        paymentTerms: values.paymentTermsBillingInfo,
      };
    } else {
      formData.billing = {
        postalCode: values.postalCodeBillingInfo,
        prefecture: values.prefectureBillingInfo,
        address1: values.address1BillingInfo,
        address2: values.address2BillingInfo,
        address3: values.address3BillingInfo,
        department: values.departmentBillingInfo,
        lastNameKanji: values.lastNameKanjiBillingInfo,
        firstNameKanji: values.firstNameKanjiBillingInfo,
        lastNameKana: values.lastNameKanaBillingInfo,
        firstNameKana: values.firstNameKanaBillingInfo,
        phone1: values.phone1BillingInfo,
        phone2: values.phone2BillingInfo,
        fax: values.faxBillingInfo,
        email: values.emailBillingInfo,
        paymentTerms: values.paymentTermsBillingInfo,
      };
    }

    formData.quotationBasics = quotationBasics.map((item) => ({
      contractType: item.contractType,
      billingTiming: item.billingTiming,
      inspectionPeopleNumber: item.inspectionPeopleNumber,
      testSampleNumber: item.testSampleNumber,
      quotationPrice: item.quotationPrice,
      quotationPriceUnit: item.quotationPriceUnit,
    }));

    handleSubmitForm(formData);
  };

  const {
    handleSubmit,
    errors,
    values,
    setFieldValue,
    touched,
    handleBlur,
    // setFieldTouched,
  } = useFormik({
    initialValues: initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  useImperativeHandle(
    ref,
    () => {
      return {
        clickSubmit() {
          handleSubmit();
        },
      };
    },
    [handleSubmit]
  );

  return (
    <div className="w-full h-auto">
      <div className="mb-8">
        <AddressFieldGroup
          fieldSuffix=""
          errors={errors}
          values={values}
          setFieldValue={setFieldValue}
          touched={touched}
          handleBlur={handleBlur}
          labelWidth={labelWidth}
        />
        <PhoneAndFaxFieldGroup
          fieldSuffix=""
          errors={errors}
          values={values}
          setFieldValue={setFieldValue}
          touched={touched}
          handleBlur={handleBlur}
          labelWidth={labelWidth}
          hiddenFax={true}
          placeholder="00-0000-0000"
        />
      </div>

      <div className="mb-8">
        <h3 className="text-custom-size mb-5">担当者情報</h3>
        <AddressFieldGroup
          fieldSuffix="PIC"
          errors={errors}
          values={values}
          setFieldValue={setFieldValue}
          touched={touched}
          handleBlur={handleBlur}
          labelWidth={labelWidth}
        />
        <InputText
          id="departmentPIC"
          value={values.departmentPIC}
          onChange={(newValue) => setFieldValue("departmentPIC", newValue)}
          label="担当部署"
          labelWidth={labelWidth}
          handleBlur={handleBlur}
          disabled={false}
          placeholder="J-all事業部"
          errors={errors.departmentPIC}
          touched={touched.departmentPIC}
        />

        <NameFieldGroup
          fieldSuffix="PIC"
          errors={errors}
          values={values}
          setFieldValue={setFieldValue}
          touched={touched}
          handleBlur={handleBlur}
          labelWidth={labelWidth}
        />

        <PhoneAndFaxFieldGroup
          fieldSuffix="PIC"
          errors={errors}
          values={values}
          setFieldValue={setFieldValue}
          touched={touched}
          handleBlur={handleBlur}
          labelWidth={labelWidth}
        />

        <InputText
          id="emailPIC"
          value={values.emailPIC}
          onChange={(newValue) => setFieldValue("emailPIC", newValue)}
          label="メールアドレス"
          labelWidth={labelWidth}
          handleBlur={handleBlur}
          disabled={false}
          placeholder="t.yamamoto@faith.com"
          errors={errors.emailPIC}
          touched={touched.emailPIC}
        />
      </div>

      <div className="mb-[72px]">
        <div className="w-full h-auto flex items-center">
          <h3
            className="text-custom-size mb-5"
            style={{
              width: `${labelWidth}px`,
            }}
          >
            請求先情報
          </h3>

          <div
            className="flex items-center"
            style={{
              width: `calc(100% - ${labelWidth}px)`,
            }}
          >
            <button
              type="button"
              onClick={() => setUsePICAsBillingInfo((oldState) => !oldState)}
              className="flex items-center gap-2"
            >
              <div className="w-6 h-6 rounded border border-black cursor-pointer flex items-center justify-center">
                {usePICAsBillingInfo ? (
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="text-green-500"
                    size="lg"
                  />
                ) : null}
              </div>
              <p className="text-custom-size">
                担当者情報と同じ場合はチェックしてください
              </p>
            </button>
          </div>
        </div>
        <AddressFieldGroup
          fieldSuffix="BillingInfo"
          errors={errors}
          values={values}
          setFieldValue={setFieldValue}
          touched={touched}
          handleBlur={handleBlur}
          labelWidth={labelWidth}
          disabled={usePICAsBillingInfo}
          displaysReplacementValueSuffix={usePICAsBillingInfo ? "PIC" : ""}
        />
        <InputText
          id="departmentBillingInfo"
          value={
            usePICAsBillingInfo
              ? values.departmentPIC
              : values.departmentBillingInfo
          }
          onChange={(newValue) =>
            setFieldValue("departmentBillingInfo", newValue)
          }
          label="担当部署"
          labelWidth={labelWidth}
          handleBlur={handleBlur}
          placeholder="J-all事業部"
          errors={errors.departmentBillingInfo}
          touched={touched.departmentBillingInfo}
          disabled={usePICAsBillingInfo}
        />

        <NameFieldGroup
          fieldSuffix="BillingInfo"
          errors={errors}
          values={values}
          setFieldValue={setFieldValue}
          touched={touched}
          handleBlur={handleBlur}
          labelWidth={labelWidth}
          disabled={usePICAsBillingInfo}
          displaysReplacementValueSuffix={usePICAsBillingInfo ? "PIC" : ""}
        />

        <PhoneAndFaxFieldGroup
          fieldSuffix="BillingInfo"
          errors={errors}
          values={values}
          setFieldValue={setFieldValue}
          touched={touched}
          handleBlur={handleBlur}
          labelWidth={labelWidth}
          displaysReplacementValueSuffix={usePICAsBillingInfo ? "PIC" : ""}
        />

        <InputText
          id="emailBillingInfo"
          value={
            usePICAsBillingInfo ? values.emailPIC : values.emailBillingInfo
          }
          onChange={(newValue) => setFieldValue("emailBillingInfo", newValue)}
          label="メールアドレス"
          labelWidth={labelWidth}
          handleBlur={handleBlur}
          placeholder="t.yamamoto@faith.com"
          errors={errors.emailBillingInfo}
          touched={touched.emailBillingInfo}
          disabled={usePICAsBillingInfo}
        />

        <SelectPaymentTerms
          selectedValue={values.paymentTermsBillingInfo}
          setSelectedValue={(newValue) =>
            setFieldValue("paymentTermsBillingInfo", newValue)
          }
        />
      </div>

      <div className="">
        <EstimateBasicInformation
          quotationBasics={quotationBasics}
          setQuotationBasics={setQuotationBasics}
        />
      </div>
    </div>
  );
});
export default CustomerInfoForm;
